import { Box, TextField, IconButton, CircularProgress } from "@mui/material";
import { ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import StopIcon from "@mui/icons-material/Stop";
import { localStorageInstance } from "../../../../../../utils/localStorage";
import MessageBox from "./MessageBox";
import { useGetChatbotMessages, useSendChatbotMessages } from "../../../../../../utils/reactQuery/chatbot";
import { useMutation } from "@tanstack/react-query";
import { deleteMessage, sendMessage } from "../../../../../../sevices/apiChatbot";
import StartOver from "./StartOver";

const ChatUI = ({ cardData, toggleChatbotVisibility, descRef, setCardTitle }) => {

  const { title: cardTitle, description: cardDescription, card_id: cardId } = cardData;
  const userId = localStorageInstance.getItem("userId");
  const ref = useRef(null);
  const controllerRef = useRef(new AbortController());

  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [assistantMessage, setAssistantMessage] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingStreaming, setLoadingStreaming] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const { messages: response, getMessageRefetch } = useGetChatbotMessages(cardId, userId)

  const { mutate: sendMessageMutate } = useMutation({
    mutationFn: sendMessage,
    onSuccess: async (response) => {
      setLoadingStreaming(false);
      setIsStreaming(true);

      let accumulatedData = "";
      let buffer = response.data;

      const messages = buffer.split("\n\n").filter(Boolean);

      for (const message of messages) {
        if (message.includes("event: content_block_delta")) {
          console.log("Raw Event:", message);
          try {
            const dataLine = message.split("\n")[1];
            const jsonData = JSON.parse(dataLine.replace("data: ", "").trim());

            if (jsonData.delta?.text) {
              accumulatedData += jsonData.delta.text;
              console.log(jsonData.delta.text);
              setAssistantMessage(accumulatedData);
              await new Promise((resolve) => setTimeout(resolve, 20)); // Simulate typing effect
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            continue;
          }
        }
      }
      setAssistantMessage("");
      setIsStreaming(false);
      setMessages((prev) => [
        ...prev,
        { role: "assistant", content: accumulatedData },
      ]);
    },
  });

  const { mutate } = useMutation({
    mutationFn: deleteMessage,
    onSuccess: (res) => {
      setInputText(`Title: ${cardTitle}, Description: ${cardDescription}`);
      setMessages([]);
      setSubmitClicked(true);
    },
    onError: (error) => {
      console.error(
        "Error sending data:",
        error.response?.data || error.message
      );
    },
  });

  const handleSendMessage = async () => {
    if (inputText.trim()) {
      if (!inputText.startsWith("Title:")) {
        setMessages([...messages, { role: "user", content: inputText }]);
      }
      setInputText("");
      setLoadingStreaming(true);
      controllerRef.current.abort();
      controllerRef.current = new AbortController();
      sendMessageMutate({
        data: {
          user_id: userId,
          card_id: cardId,
          component: "card",
          component_id: cardId,
          message: inputText,
        },
        signal: controllerRef.current.signal,
      });
    }
  };

  const deleteMessages = async () => {
    const data = {
      data: {
        card_id: cardId,
        user_id: userId,
        component: "card",
        component_id: cardId,
      },
    };
    mutate(data);
  };

  const stopStreaming = () => {
    try {
      controllerRef.current.abort();
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Unexpected error stopping stream:", error);
      }
    }
  };

  useEffect(() => {
    if (response) {
      if (response?.data.length === 0) {
        setInputText(`Title: ${cardTitle}, Description: ${cardDescription}`);
        setSubmitClicked(true);
      } else {
        setMessages(response?.data);
      }
    } else {
      getMessageRefetch();
    }
  }, [cardId, userId, response]);

  useEffect(() => {
    if (submitClicked) {
      handleSendMessage();
      setSubmitClicked(false);
    }
  }, [submitClicked]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [assistantMessage]);

  useEffect(() => {
    if (ref.current) {
      requestAnimationFrame(() => {
        ref.current.scrollTo({
          top: ref.current.scrollHeight,
          behavior: "smooth",
        });
      });
    }
  }, [messages]);

  return (
    <Box>
      <Grid container className="sidebar-block">
        <Grid item className="sidebar-details">
          <Grid
            item
            className="sidebar-details-content"
            sx={{ paddingBottom: 0 }}
          >
            <Grid item className="sidebar-worklog">
              <div className="sidebar-worklog-detail">Chatbot</div>
              <div>
                <StartOver deleteMessages={deleteMessages} />
                <IconButton
                  aria-label="close"
                  onClick={() => toggleChatbotVisibility()}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    padding: "0px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>

            <Box
              className="messages-container"
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                marginTop: "10px",
                height: "calc(100vh - 272px)",
              }}
              ref={ref}
            >
              {messages?.map((message, index) => (
                <MessageBox
                  message={message}
                  key={index}
                  descRef={descRef}
                  setCardTitle={setCardTitle}
                  cardId={cardId}
                />
              ))}
              {loadingStreaming && (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1, margin: "16px" }}>
                    <CircularProgress size={10} />
                  </Box>
                </>
              )}

              {assistantMessage && (
                <MessageBox
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    padding: "10px",
                  }}
                  message={{
                    role: "assistant",
                    content: assistantMessage,
                  }}
                  descRef={descRef}
                  setCardTitle={setCardTitle}
                  cardId={cardId}
                />
              )}
            </Box>
            <Box
              sx={{
                padding: "8px 16px",
                backgroundColor: "#f4f6f9",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "4px",
                borderRadius: "8px",
                paddingRight: "8px",
              }}
            >
              <TextField
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSendMessage();
                }}
                fullWidth
                variant="standard"
                placeholder="Ask something..."
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "13px", padding: "0px", overflowY: "auto"}
                }}
                size="small"
                multiline
                minRows={1}
                maxRows={5}
                key={messages.length}
              />
              <IconButton
                onClick={() =>
                  isStreaming ? stopStreaming() : setSubmitClicked(true)
                }
                color="primary"
                sx={{
                  marginLeft: 1,
                  backgroundColor: "#f2f4f6",
                  borderRadius: "10px",
                  padding: "6px 12px",
                  "&:hover": {
                    backgroundColor: "#e0e4e8",
                  },
                }}
              >
                {isStreaming ? <StopIcon /> : <ArrowUpwardIcon />}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatUI;
