import React from "react";
import { Divider, Grid, Stack, Tooltip } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import Avatar from "../../../../components/Avatar";
import {
  createAvtarName,
  fixedDigitsAfterDecimal,
  hasImage,
} from "../../../../utils/helpers";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";
import CustomizedProgressBars from "../../../board/components/progressBar/ProgressBar";
import lateIcon from "../../../../styles/svg/warning-icon.svg";
import { useResponsive } from "../../../../hooks/useResponsive";

function ByTaskTab({ worklogs }) {
  const [setMultipleSearchParams] = useSetMultipleParams();
  const navigate = useNavigate();
  const isMobile = useResponsive("down", "md");

  const openBoard = (boardId) => {
    navigate(`/board/${boardId}`);
  };

  function isSameDay(createDate, dateTime) {
    const localCreatedAt = moment.utc(createDate)?.local();
    const localDateTime = moment.utc(dateTime)?.local();

    return localCreatedAt?.isSame(localDateTime, "day");
  }

  const handleOpenCardDetail = (boardId, cardId) => {
    setMultipleSearchParams([
      {
        name: "brd_id",
        value: boardId,
      },
      {
        name: "card_id",
        value: cardId,
      },
    ]);
  };

  const worklogsByBoard = _.groupBy(
    worklogs,
    (worklog) => worklog.cardlog.card.card_id
  );

  return (
    <div>
      {Object.entries(worklogsByBoard)?.map(([id, card]) => (
        <Grid
          container
          spacing={2}
          sx={{ backgroundColor: "#fff", borderRadius: "4px", width: "100%" }}
          className="worklog-item d-flex justify-content-space-between">
          {isMobile ? (
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                paddingLeft: "4px !important",
                paddingRight: "4px !important",
              }}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"wrap"}>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Avatar
                    // key={`user-avtar-${el.user_id}`}
                    username={`${card[0]?.cardlog?.user?.first_name} ${card[0]?.cardlog?.user?.last_name}`}
                    user_image={card[0]?.cardlog?.user?.user_image}
                    title={`${card[0]?.cardlog?.user?.first_name} ${card[0]?.cardlog?.user?.last_name}`}
                    className="user-avtar-dropdown user-avatar mr-8"
                    style={
                      !hasImage(card[0]?.cardlog?.user?.user_image) && {
                        bgcolor:
                          card[0]?.cardlog?.user?.profile_color?.background,
                        color: card[0]?.cardlog?.user?.profile_color?.text,
                      }
                    }>
                    {createAvtarName(
                      `${card[0]?.cardlog?.user?.first_name} ${card[0]?.cardlog?.user?.last_name}` ||
                        ""
                    )}
                  </Avatar>
                  <div className="d-flex align-items-center">
                    <a
                      className="board-anquor cursor-pointer"
                      onClick={() =>
                        openBoard(card[0]?.cardlog?.card?.board_id)
                      }>
                      {`${card[0]?.key}`?.toUpperCase()}
                    </a>
                    -
                    <a
                      className="board-anquor cursor-pointer mr-1"
                      onClick={() =>
                        handleOpenCardDetail(
                          card[0]?.cardlog?.card?.board_id,
                          card[0]?.cardlog?.card?.card_id
                        )
                      }>
                      {card[0]?.cardlog?.card?.card_id}:
                    </a>
                  </div>

                  {card[0]?.epic_name && (
                    <div className="card-epic-tag mt-0">
                      <p className="card-epic-tag-text">{card[0]?.epic_name}</p>
                    </div>
                  )}
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={3}
                  justifyContent={"end"}
                  sx={{ marginLeft: "auto", alignSelf: "start" }}>
                  <p className="hours">
                    {fixedDigitsAfterDecimal(
                      card.reduce(
                        (sum, cardDetails) =>
                          sum + cardDetails?.cardlog?.hours_spent,
                        0
                      )
                    )}
                    Hour
                  </p>
                  <p className="worklog-status">
                    {card[0]?.workflow_step_name}
                  </p>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={12} md={9}>
              <div className="d-flex w-100">
                <Avatar
                  // key={`user-avtar-${el.user_id}`}
                  username={`${card[0]?.cardlog?.user?.first_name} ${card[0]?.cardlog?.user?.last_name}`}
                  user_image={card[0]?.cardlog?.user?.user_image}
                  title={`${card[0]?.cardlog?.user?.first_name} ${card[0]?.cardlog?.user?.last_name}`}
                  className="user-avtar-dropdown user-avatar mr-8"
                  style={
                    !hasImage(card[0]?.cardlog?.user?.user_image) && {
                      bgcolor:
                        card[0]?.cardlog?.user?.profile_color?.background,
                      color: card[0]?.cardlog?.user?.profile_color?.text,
                    }
                  }>
                  {createAvtarName(
                    `${card[0]?.cardlog?.user?.first_name} ${card[0]?.cardlog?.user?.last_name}` ||
                      ""
                  )}
                </Avatar>
                <div className="w-100">
                  <div className="d-flex justify-content-space-between align-items-center">
                    <div className="d-flex align-items-baseline">
                      <Tooltip title={card[0]?.board_name}>
                        <a
                          className="board-anquor cursor-pointer"
                          onClick={() =>
                            openBoard(card[0]?.cardlog?.card?.board_id)
                          }>
                          {`${card[0]?.key}`?.toUpperCase()}
                        </a>
                      </Tooltip>
                      -
                      <a
                        className="board-anquor cursor-pointer mr-1"
                        onClick={() =>
                          handleOpenCardDetail(
                            card[0]?.cardlog?.card?.board_id,
                            card[0]?.cardlog?.card?.card_id
                          )
                        }>
                        {card[0]?.cardlog?.card?.card_id}:
                      </a>
                      <p className="worklog-title mr-2">
                        {card[0]?.cardlog?.card?.title}
                      </p>
                      {card[0]?.epic_name && (
                        <div className="card-epic-tag mt-0">
                          <p className="card-epic-tag-text">
                            {card[0]?.epic_name}
                          </p>
                        </div>
                      )}
                    </div>
                    <p className="hours min-width-60 align-self-base ml-1">
                      {fixedDigitsAfterDecimal(
                        card.reduce(
                          (sum, cardDetails) =>
                            sum + cardDetails?.cardlog?.hours_spent,
                          0
                        )
                      )}
                      Hour
                    </p>
                  </div>
                  {card.map((cardDetails, index) => (
                    <div className={index === 0 ? "mt-2" : "mt-11"}>
                      <Stack direction={"row"}>
                        <p
                          className="worklog-date-time"
                          style={{ justifyContent: "left" }}>
                          {!isSameDay(
                            cardDetails?.cardlog?.created_at,
                            cardDetails?.cardlog?.date_time
                          ) && (
                            <img
                              src={lateIcon}
                              className="late-icon"
                              alt="late-icon"
                            />
                          )}
                          {moment
                            .utc(cardDetails?.cardlog?.date_time)
                            .local()
                            .format("MMMM D, YYYY [at] h:mm A")}
                        </p>

                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          className="ml-1 mr-6 mt-0 mb-0"
                          sx={{ height: "14px", width: "2px" }}
                        />
                        <p className="worklog-date-time">
                          {`${
                            cardDetails?.cardlog?.hours_spent == 1
                              ? `${cardDetails?.cardlog?.hours_spent}Hour`
                              : `${cardDetails?.cardlog?.hours_spent} Hours`
                          }`}
                        </p>
                      </Stack>
                      <p
                        className="worklog-worklog mt-0"
                        dangerouslySetInnerHTML={{
                          __html: cardDetails?.cardlog?.work_performed,
                        }}></p>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          )}

          {isMobile ? (
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                paddingLeft: "4px !important",
                paddingRight: "4px !important",
              }}>
              <p className="worklog-title mr-2 ml-2">
                {card[0]?.cardlog?.card?.title}
              </p>
              {card.map((cardDetails, index) => (
                <div className={index === 0 ? "mt-2 ml-2" : "mt-11 ml-2"}>
                  <Stack direction={"row"}>
                    <p
                      className="worklog-date-time"
                      style={{ justifyContent: "left" }}>
                      {!isSameDay(
                        cardDetails?.cardlog?.created_at,
                        cardDetails?.cardlog?.date_time
                      ) && (
                        <img
                          src={lateIcon}
                          className="late-icon"
                          alt="late-icon"
                        />
                      )}
                      {moment
                        .utc(cardDetails?.cardlog?.date_time)
                        .local()
                        .format("MMMM D, YYYY [at] h:mm A")}
                    </p>

                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      className="ml-1 mr-6 mt-0 mb-0"
                      sx={{ height: "14px", width: "2px" }}
                    />
                    <p className="worklog-date-time">
                      {`${
                        cardDetails?.cardlog?.hours_spent == 1
                          ? `${cardDetails?.cardlog?.hours_spent}Hour`
                          : `${cardDetails?.cardlog?.hours_spent} Hours`
                      }`}
                    </p>
                  </Stack>
                  <p
                    className="worklog-worklog mt-0"
                    dangerouslySetInnerHTML={{
                      __html: cardDetails?.cardlog?.work_performed,
                    }}></p>
                </div>
              ))}
              <div className="ml-2 mr-2 mt-2">
                <CustomizedProgressBars
                  allocatedHours={card[0]?.cardlog?.card?.allocated_hours}
                  hoursSpent={fixedDigitsAfterDecimal(
                    card.reduce(
                      (sum, cardDetails) =>
                        sum + cardDetails?.cardlog?.hours_spent,
                      0
                    )
                  )}
                  hideDate={true}
                />
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} md={3}>
              <div className="right-details">
                <div className="d-flex justify-content-end">
                  <p className="worklog-status">
                    {card[0]?.workflow_step_name}
                  </p>
                </div>
                <div style={{ minWidth: "180px" }}>
                  <CustomizedProgressBars
                    allocatedHours={card[0]?.cardlog?.card?.allocated_hours}
                    hoursSpent={fixedDigitsAfterDecimal(
                      card.reduce(
                        (sum, cardDetails) =>
                          sum + cardDetails?.cardlog?.hours_spent,
                        0
                      )
                    )}
                    hideDate={true}
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      ))}
    </div>
  );
}

export default ByTaskTab;
