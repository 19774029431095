import * as yup from "yup";

export const userProfileSchema = yup
  .object({
    full_name: yup.string().required("full name is required"),
    email: yup
      .string()
      .email("Invalid mail address")
      .required("email is required"),
    team: yup.string().required("Time is required"),
    isActive: yup.boolean().required("Is active is required"),
    worklogsRequired: yup.boolean().required("worklogs is required"),
    isSuperAdmin: yup.boolean().required(),
  })
  .required();
