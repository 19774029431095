import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';

import TabsController from "../../../../components/TabsController";
import EpicButton from '../BoardHeader/epicButton/EpicButton';
import { useEpic } from '../../../../utils/reactQuery/epics';
import { useCustomSearchParams } from '../../../../hooks/useCustomSearchParams';
import CreateEpic from '../BoardHeader/epicButton/CreateEpic';
import RightArrow from '../../../../styles/svg/right-arrow.svg'
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { TooltipName } from '../../../../utils/Enums';
import { isAdmin } from '../../../../utils/constants';

function MilestoneTabs({ boardName, isOwner }) {
  const { id } = useParams();
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const tabContainerRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const { epics } = useEpic(id);
  const names = epics?.data?.data?.responseData || [];
  const unCompletedMileStones = names?.filter(
    (item) => item.completed == false
  );

  const today = moment();
  const sortedMilestone = unCompletedMileStones
    .filter(
      (item) =>
        item.start_date && !moment(item.start_date).isAfter(today, "day")
    )
    .sort((a, b) => {
      if (a.target_release_date && b.target_release_date) {
        return (
          new Date(a.target_release_date) - new Date(b.target_release_date)
        );
      } else if (a.target_release_date) {
        return -1; // Objects with `target_release_date` come first
      } else if (b.target_release_date) {
        return 1;
      } else {
        return new Date(a.start_date) - new Date(b.start_date);
      }
    });

  const selectMilestone = (event, newValue) => {
    // setMilestone("milestone", newValue);
  };
  const scrollRight = () => {
    const scrollElement = document.querySelector(".MuiTabs-flexContainer");
    if (scrollElement) {
      scrollElement.scrollTo({
        left: scrollElement.scrollLeft + 500, // Adjust as needed
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };

  
  useEffect(() => {
    const checkScrollable = () => {
      if (tabContainerRef.current) {
        // Get parent of tabContainerRef
        const parentElement = tabContainerRef.current.parentNode;

        // Find the 3rd div inside the same parent
        const thirdDiv = parentElement?.querySelector(".MuiTabs-flexContainer");

        if (thirdDiv) {
          // Check if the third div is scrollable
          setIsScrollable(thirdDiv.scrollWidth > thirdDiv.clientWidth +160);
        }
      }
    };

    checkScrollable();
    window.addEventListener("resize", checkScrollable);

    return () => {
      window.removeEventListener("resize", checkScrollable);
    };
  }, [names]);
  return (
    <div className="milestone-tabs-container">
      <TabsController
        value={milestone ? milestone * 1 : ""}
        onChange={selectMilestone}
        tabsOptions={sortedMilestone}
        tabsRef={tabContainerRef}
        leftChild={
          <div className="milestone-drop-down">
            <EpicButton
              boardNameDetails={boardName}
              isTab={true}
              selectedMilestone={milestone ? milestone * 1 : ""}
            />
          </div>
        }
      >
        <>
          <div className="milestone-tab-right-space"></div>
          {isScrollable && (
            <div
              className={`fade-right ${
                isOwner || isAdmin() ? "margin-right-for-admin" : "right-zero"
              }`}
            >
              <Tooltip title={TooltipName.BOARD_PAGE.MORE_MILESTONES}>
                <img
                  src={RightArrow}
                  onClick={scrollRight}
                  className="cursor-pointer"
                  style={{ pointerEvents: "all" }}
                  alt="more-milestone-btn"
                />
              </Tooltip>
            </div>
          )}
          {(isOwner || isAdmin()) && (
            <div
              style={{
                alignContent: "center",
                maxHeight: "41px",
                padding: "6px 10px 6px 60px",
                position: "absolute",
                right: 0,
                background: "#dae0e5",
                borderRadius: "0 8px 0 0",
              }}
            >
              <CreateEpic isTab={true} />
            </div>
          )}
        </>
      </TabsController>
    </div>
  );
}

export default MilestoneTabs