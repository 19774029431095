import React, { useState, useEffect } from "react";
import { useGenericWorkflowStatus, useUserWorkflowList, useWorkflowById } from "../../../../utils/reactQuery/workflows";
import Kanban from "../../components/KanbanBoard/Kanban";
import Loader from "../../../../components/Loader";
import Avatar from "../../../../components/Avatar";
import { createAvtarName } from "../../../../utils/helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalculateCards from "./CalculateCards";
import { useUserCards } from "../../../../utils/reactQuery/cards";
import { useSearchParams } from "react-router-dom";

function UserBoard({ user, userKey, setExpandAll, setExpandedRows, expandedRows, length }) {

  const [searchParams] = useSearchParams();
  const board_id = searchParams.get("board_id");
  const is_generic = searchParams.get("is_generic");
  const { userWorkflows } = useUserWorkflowList();
  const genericWorkflowId = userWorkflows?.data?.data?.responseData?.find((workflow) => workflow.is_generic)?.workflow_id;

  const { cards, refetch } = useUserCards(user.user_id, board_id, genericWorkflowId);

  const { genericWorkflowStatus: data, } = useGenericWorkflowStatus();

  const [isUpdate, setUpdate] = useState(0);
  const [isExpand, setExpand] = useState(false);

  useEffect(() => {
    refetch();
  }, [board_id,genericWorkflowId]);


  const handleChange = (userKey) => {
    if (expandedRows.includes(userKey)) {
      const newExpandedRows = expandedRows.filter((itm) => itm !== userKey);
      setExpandedRows(newExpandedRows)
      setExpandAll(false)
    } else {
      if (expandedRows.length + 1 === length) {
        setExpandAll(true)
      }
      setExpandedRows([...expandedRows, userKey])
    }
  }

  return (
    <>
      <div key={userKey}>
        <Accordion
          expanded={expandedRows.includes(userKey)}
          className="accordion-allocation-board border-not-top"
          onChange={() => handleChange(userKey)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ boxShadow: "none" }}
            className="accordian-summary-group-by-user"
          >
            <div className="justify-content-space-between d-flex w-100 align-items-center">
              <div className="d-flex">
                <Avatar
                  username={`${user.first_name} ${user.last_name}`}
                  user_image={user.user_image}
                  title={`${user.first_name} ${user.last_name}`}
                  className="user-avtar-dropdown user-avatar"
                  style={{
                    bgcolor: user?.profile_color?.background,
                    color: user?.profile_color?.text,
                  }}
                >
                  {createAvtarName(
                    `${user.first_name} ${user.last_name}` || ""
                  )}
                </Avatar>

                <p className="user-heading-name">
                  {user.first_name} {user.last_name}
                </p>
              </div>
              <div>
                {data && cards && (
                  <>
                    {" "}
                    <CalculateCards
                      cards={cards?.data?.data.responseData}
                      workflowSteps={data?.data?.data.responseData
                        .filter((el) => !el.is_completed)
                        .sort((a, b) => a.step_sequence - b.step_sequence)}
                      isExpand={expandedRows.includes(userKey)}
                    />
                  </>
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {data && cards ? (
              cards.data.data.responseData.length > 0 ? (
                <Kanban
                  cards={cards.data.data.responseData}
                  workflowSteps={data.data.data.responseData
                    .filter((el) => !el.is_completed)
                    .sort((a, b) => a.step_sequence - b.step_sequence)}
                  callback={() => setUpdate((data) => data + 1)}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No tasks available
                </div> // You can customize this message as needed
              )
            ) : (
              <div className="loader-center">
                <Loader color={"black"} />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

export default UserBoard;
