import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import Avatar from "../../../../components/Avatar";
import { createAvtarName } from "../../../../utils/helpers";
import DownArrow from "../../../../styles/svg/downArrow.svg";
import { useUsers } from "../../../../utils/reactQuery/users";
import SearchBoard from "./SearchBoard";
import { useSearchParams } from "react-router-dom";

export default function Team({ team, user }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { users } = useUsers();
  const open = Boolean(anchorEl);
  const [openUnassigned, setUnassigned] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const allTeams =
    team?.data.data.responseData?.map((team) => {
      return {
        ...team,
        users: team.users.filter((user) => user.active),
      };
    }) || [];
  const [expandedPanel, setExpandedPanel] = useState([]);

  const handleExpandAccordianChange = (teamId) => (event, isExpanded) => {
    const boardData = expandedPanel.map((element, index) => {
      if (element?.team_id === teamId) {
        return { ...element, isExpand: isExpanded };
      } else {
        return { ...element };
      }
    });
    setExpandedPanel(boardData);
  };

  useEffect(() => {
    if (allTeams) {
      const expandData = allTeams.map((element, index) => {
        return { team_id: element.team_id, isExpand: false };
      });
      setExpandedPanel(expandData);
    }
  }, [team]);

  const setTeam = (teamUser, isChecked) => {
    const selectedUser = user;
    dispatch(setAllGroupUser([]));

    if (isChecked) {
      selectedUser.forEach((element) => {
        if (element.team_id !== teamUser.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    } else {
      teamUser.users.forEach((element) => {
        dispatch(setGroupUser(element));
      });

      selectedUser.forEach((element) => {
        if (element.team_id !== teamUser.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    }
  };

  const setUnassingUser = (teamUser, isChecked) => {
    const selectedUser = user;
    dispatch(setAllGroupUser([]));

    if (isChecked) {
      selectedUser.forEach((element) => {
        if (element.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    } else {
      teamUser.forEach((element) => {
        dispatch(setGroupUser(element));
      });

      selectedUser.forEach((element) => {
        if (element.team_id) {
          dispatch(setGroupUser(element));
        }
      });
    }
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    setSearchQuery("");
  };

  const filteredItems = users
    ? searchQuery
      ? users.data.data.responseData.filter((item) =>
          item.first_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : users.data.data.responseData
    : [];

  const unAssignedTeam = filteredItems
    ? filteredItems
        .filter((el) => el.active)
        .sort(function (a, b) {
          return a.first_name.localeCompare(b.first_name);
        })
        .sort(
          (a, b) =>
            user.some((element) => element.user_id == b.user_id) -
            user.some((element) => element.user_id == a.user_id)
        )
        .filter((ele) => !ele.team_id)
    : [];

  const selectedTeamCount = allTeams.filter((team, idx) =>
    team?.users?.every((ele) =>
      user.some((element) => element.user_id === ele.user_id)
    )
  ).length;

  const removeSearchParams = (paramsToRemove) => {
    const newSearchParams = new URLSearchParams(searchParams);
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });
    setSearchParams(newSearchParams);
  };

  const handleDeleteTeam = () => {
    removeSearchParams(["uid"]);

    dispatch(setAllGroupUser([]));
  };

  const allUser = filteredItems?.filter((el) => el.active);
  const selectAll = () => {
    dispatch(
      setAllGroupUser(
        user.length == allUser.length
          ? []
          : [{ team: "Multiple", users: allUser }]
      )
    );
  };
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<img src={DownArrow} />}
        className="filter-btn white-button ml-2"
        sx={{
          height: "38px",
          "&:hover": {
            outline: "none",
            backgroundColor: "#fff",
            border: "1px solid #1b3e61",
          },
        }}>
        <Chip
          label={
            <div className="d-flex justify-content-space-between">
              <p className="filter-name">Users</p>
              <p className="filter-count">
                {user.length === 0 ? "" : user.length}
              </p>
            </div>
          }
          // label={`team${
          //   selectedTeamCount === 0 ? "" : "(" + selectedTeamCount + ")"
          // }`}
          className="filter-chip"
          {...(user.length > 0 ? { onDelete: handleDeleteTeam } : {})}
        />
      </Button>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list">
        <Box
          sx={{
            boxShadow:
              "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            padding: "8px !important",
            marginBottom: "1px",
          }}>
          <SearchBoard
            onSearch={(val) => setSearchQuery(val)}
            searchQuery={searchQuery}
          />
        </Box>
        {!searchQuery && (
          <MenuItem
            onClick={selectAll}
            sx={{
              boxShadow:
                "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              padding: "12px 16px !important",
              marginBottom: "1px",
            }}>
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id={`board-list-my-task-checkbox-all-board`}
                checked={user.length == allUser.length}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
              <span class="checkmark"></span>
              Select All Users
            </label>
          </MenuItem>
        )}
        {allTeams.map((team, idx) =>
          team &&
          team?.users &&
          team?.users.length &&
          team.users.some((usr) =>
            filteredItems.some((user) => user.user_id === usr.user_id)
          ) ? (
            <Accordion
              expanded={
                expandedPanel?.find(
                  (teamData) => teamData?.team_id === team.team_id
                )?.isExpand ||
                false ||
                searchQuery.length > 0
              }
              onChange={handleExpandAccordianChange(team.team_id)}
              sx={{
                boxShadow:
                  "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                disableGutters
                sx={{ display: "flex", alignItems: "center" }}>
                <Box onClick={(event) => event.stopPropagation()}>
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      checked={team?.users?.every((ele) =>
                        user.some((element) => element.user_id === ele.user_id)
                      )}
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents checkbox click from toggling accordion
                        setTeam(
                          team,
                          team?.users?.every((ele) =>
                            user.some(
                              (element) => element.user_id === ele.user_id
                            )
                          )
                        );
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography>{team.team}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                {team.users.map(
                  (el, index) =>
                    filteredItems.some(
                      (user) => user.user_id === el.user_id
                    ) && (
                      <MenuItem
                        key={`user-avatar-dropdown-admin-assign-${el.user_id}`}
                        onClick={() => {
                          dispatch(setGroupUser(el));
                        }}>
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            id={`user-check-box-${el.user_id}`}
                            checked={user.some(
                              (element) => element.user_id === el.user_id
                            )}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          />
                          <span className="checkmark"></span>
                          <Avatar
                            key={`user-avatar-${el.user_id}`}
                            username={`${el.first_name} ${el.last_name}`}
                            user_image={el.user_image}
                            title={`${el.first_name} ${el.last_name}`}
                            className="user-avatar-dropdown user-avatar mr-8"
                            style={{
                              bgcolor: el?.profile_color?.background,
                              color: el?.profile_color?.text,
                              minWidth: "24px !important",
                              width: "24px !important",
                              height: "24px !important",
                            }}>
                            {createAvtarName(
                              `${el.first_name} ${el.last_name}`
                            )}
                          </Avatar>
                          {`${el.first_name} ${el.last_name}`}
                        </label>
                      </MenuItem>
                    )
                )}
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          )
        )}
        {unAssignedTeam.length > 0 && (
          <Accordion
            expanded={openUnassigned || searchQuery.length > 0}
            onChange={() => setUnassigned((prev) => !prev)}
            sx={{
              boxShadow:
                "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              <label class="custom-checkbox">
                <input
                  type="checkbox"
                  checked={unAssignedTeam?.every((ele) =>
                    user.some((element) => element.user_id == ele.user_id)
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    setUnassingUser(
                      unAssignedTeam,
                      unAssignedTeam?.every((ele) =>
                        user.some((element) => element.user_id == ele.user_id)
                      )
                    );
                  }}
                />
                <span class="checkmark"></span>
                Unassigned Users
              </label>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>
              {unAssignedTeam.map((el, index) => (
                <MenuItem
                  key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
                  onClick={() => {
                    dispatch(setGroupUser(el));
                  }}>
                  <label class="custom-checkbox">
                    <input
                      type="checkbox"
                      id={`user-check-box-${el.user_id}`}
                      checked={user.some(
                        (element) => element.user_id == el.user_id
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                    <span class="checkmark"></span>

                    <Avatar
                      key={`user-avtar-${el.user_id}`}
                      username={`${el.first_name} ${el.last_name}`}
                      user_image={el.user_image}
                      title={`${el.first_name} ${el.last_name}`}
                      className="user-avtar-dropdown user-avatar mr-8"
                      style={{
                        bgcolor: el?.profile_color?.background,
                        color: el?.profile_color?.text,
                        minWidth: "24px !important",
                        width: "24px !important",
                        height: "24px !important",
                      }}>
                      {createAvtarName(`${el.first_name} ${el.last_name}`)}
                    </Avatar>
                    {`${el.first_name} ${el.last_name}`}
                  </label>
                </MenuItem>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Menu>
    </div>
  );
}
