import * as React from "react";
import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { useCard } from "../../../../utils/reactQuery/cards";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { moveWorklog } from "../../../../sevices/apiWorklog";
import { objectToFormData } from "../../../../utils/helpers";
import { clearCheckedIds } from "../../../../redux/CheckboxSclice";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import useToastPromise from "../../../../hooks/useToast";

export default function MoveWorklog({ cardId }) {
  const [cardIdInput, setCardIdInput] = useState("");
  const [targetCard, setTargetCard] = useState(null);
  const [newCardId, setNewCardId] = useState(null);
  const [confirmationMoveWorklog, setConfirmationMoveWorklog] = useState(false);
  const { id } = useParams();
  const [brd_id] = useCustomSearchParams("brd_id");
  const [workflow_id] = useCustomSearchParams("workflow_id");
  const [crd_id] = useCustomSearchParams("card_id");
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const selectedIds = useSelector((state) => state.checkbox.checkedIds);
    const { showToastPromise } = useToastPromise();

  const { card, isLoading } = useCard(cardIdInput);

  useEffect(() => {
    if (card && card?.data) {
      setTargetCard([card?.data?.data?.responseData]);
    } else {
      setTargetCard(null);
    }
  }, [card]);

  const handleConfirmationMoveWorklog = () => {
    setConfirmationMoveWorklog(false);
  };

  const { mutate: MoveWorklogMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(moveWorklog, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`worklog`, cardId ?? crd_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId ?? Number(crd_id)],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id ?? Number(brd_id)],
      });
      queryClient.invalidateQueries({ queryKey: [`teams`] });
      queryClient.invalidateQueries({ queryKey: [`watching`] });
      queryClient.invalidateQueries({ queryKey: [`mineCards`] });
      dispatch(clearCheckedIds());
      setTargetCard(null);
      //toast.success(res?.data?.message);
      handleConfirmationMoveWorklog();
    },
    onError: (error) => {
      console.error("Error Move Worklog", error);
      //toast.error(error?.response?.data?.message);
      handleConfirmationMoveWorklog();
    },
  });

  const callback = () => {
    const data = objectToFormData({
      existing_card_id: cardId,
      new_card_id: newCardId,
      card_log_ids: selectedIds,
    });
    MoveWorklogMutate(data);
  };

  const optionValues = targetCard ? targetCard : [];

  const handleInputId = (Id) => {
    setCardIdInput(Id);
  };
  const debounceSearch = debounce(handleInputId, 1000);

  return (
    <>
      {confirmationMoveWorklog && (
        <ConfirmationDialog
          open={confirmationMoveWorklog}
          handleClose={handleConfirmationMoveWorklog}
          message={"Are you sure you want to Move Worklogs?"}
          callback={callback}
          btnTitle={"Submit"}
          title={"Move confirmation"}
        />
      )}
      <FormControl className="checkbox-outerbox" size="small" fullWidth>
        <Autocomplete
          // open={true}
          // freeSolo
          options={optionValues}
          getOptionLabel={(option) => option.title}
          // getOptionKey={(option) => option.card_id}
          onInputChange={(event, newValue) => {
            debounceSearch(newValue);
            // setCardIdInput(newValue);
          }}
          filterOptions={(optionValues, state) => {
            return optionValues.filter(
              (option) =>
                option?.card_id?.toString()?.includes(state.inputValue) // Search by id
            );
          }}
          onChange={(event, newValue) => {
            setNewCardId(newValue?.card_id);
            // setTargetCard(newValue);
            setConfirmationMoveWorklog(true);
          }}
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter task ID"
              size="small"
              // error={isLoading}
              helperText={isLoading ? "Loading..." : ""}
            />
          )}
        />
      </FormControl>
    </>
  );
}
