import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useAllTeams, useTeams } from "../../../../utils/reactQuery/teams";
import Field from "../../../../components/field/index";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "../../../../sevices/apiUser";
import { objectToFormData } from "../../../../utils/helpers";
import useToastPromise from "../../../../hooks/useToast";

function TeamForm({ params, onClose }) {
  const [loading, setLoading] = React.useState(false);
  const { isLoading, error, teams, isError } = useAllTeams();
    const queryClient = useQueryClient();
  const { register, handleSubmit, reset, getValues, formState, watch } =
    useForm({
      defaultValues: {
        name: `${params.row.first_name} ${params.row.last_name}`,
        team_id: params.row?.teams?.team_id,
      },
    });

     const { errors } = formState;
       const { showToastPromise } = useToastPromise();

     const { mutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateUser, data);
    },
       onSuccess: (res) => {
         queryClient.invalidateQueries({
           queryKey: ["users"],
         });
         setLoading(false);
         //toast.success(res.data.message);
         onClose();
       },
       onError: (error) => {
         console.error("Error Delete Attachments", error);
         //toast.error(error.response.data.message);
         setLoading(false);
       },
     });

     const submit = (data) => {
       /**
        * Create Board
        */
      //  setApiError("");
       setLoading(true);

       mutate({
         id: params.row.user_id,
         data: objectToFormData({ team_id: data.team_id }),
       });
     };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submit)}
      noValidate
      sx={{
        padding: "14px",
      }}
    >
      <Stack spacing={3}>
        {/* {apiError && <Alert severity="error">{apiError}</Alert>} */}
        <TextField
          label={"User Name"}
          required
          disabled
          id="name"
          name="name"
          //   error={errors?.board_name ? true : false}
          //   helperText={errors?.board_name && errors.board_name.message}
          {...register("name")}
        />
        <Field
          label={"Department/Team"}
          required
          id="team_id"
          name="team_id"
          error={errors?.team_id ? true : false}
          helperText={errors?.team_id && errors.team_id.message}
          select
          defaultValue={params.row?.teams?.team_id}
          {...register("team_id")}
        >
          {teams?.data.data.responseData
            .sort(function (a, b) {
              return a.team_name.localeCompare(b.team_name);
            })
            .map((option) => (
              <MenuItem
                key={`workflow-option-${option.value}`}
                id={`workflow-option-id-${option.value}`}
                value={option.team_id}
              >
                {option.team_name}
              </MenuItem>
            ))}
        </Field>
        <Box
          sx={{
            alignSelf: "end",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#1B3E61",
              mr: "10px",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              textTransform: "capitalize",
              minWidth: "130px",
            }}
            disabled={loading}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1B3E61",
              border: "1px solid #1B3E61",
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              opacity: "0.6",
              minWidth: "130px",
            }}
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default TeamForm;
