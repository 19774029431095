const ForgotPasswordMessage = Object.freeze({
  LINK_SEND_SUCCESS: "Check your inbox",
  LINK_SEND_SUCCESS_MESSAGE:
    "We have sent you a password reset link. Please check your email.",
  FORGOT_PASSWORD: "Reset Password",
  FORGOT_PASSWORD_MESSAGE:
    "Enter the email associated with your account, and we will send you instructions to reset your password.",
  CREATE_NEW_PASSWORD: "Create New Password",
  CREATE_NEW_PASSWORD_MESSAGE: "Secure your account by setting new password",
  REQUIREMENTS: [
    "Length: 8–15 characters",
    "Uppercase: At least 1 (A-Z)",
    "Lowercase: At least 1 (a-z)",
    "Number: At least 1 (0-9)",
    "Symbol: At least 1 (e.g., !@#^*_?{}-)",
  ],
});

export default ForgotPasswordMessage;

export const TooltipName = Object.freeze({
  BOARD_PAGE: {
    USER_LIST: "All users",
    ADD_USER: "Add user to this board",
    ADD_ATTACHMENTS: "Add attachments to this board",
    ALL_MILESTONE: "All Milestones",
    CREATE_NEW_MILESTONE: "Create New Milestone",
    MORE_MILESTONES: "More Milestones",
    CREATE_QUICK_TASK: "Create a Quick Task",
    CREATE_TASK: "Create a Task",
    CHECKPOINT: "Checkpoint",
    MILESTONE_LOCKED: "Milestone is Locked",
    MILESTONE_UNLOCKED: "Milestone is Unlocked",
  },
  PROFILE: {
    PROFILE: "User Profile",
  },
  SIDE_PANEL_ACCORDIAN: {
    CHECKPOINT_MISSED: "Checkpoint Missed",
    CARDS_ASSIGNED: "Tasks assigned to you",
  },
  KANBAN_CARD: {
    EDIT_TASK_TITLE: "Edit Task Title",
    DELETE_THIS_TASK: "Delete this task",
  },
  CARD_DETAIL: {
    COLLABRATOR_LIST: "All Collaborators",
    ADD_COLABRATOR: "Add Collaborator to this task",
    OPEN_CREATE_SUBTASK: "Create New Subtask",
    CLOSE_CREATE_SUBTASK: "Cancel creating subtask",
  },
  TEAM: {
    SWITCH_TO_ACCORDIAN_VIEW: "Switch to Team view",
    SWITCH_TO_KANBAN_VIEW: "Switch to Kanban view",
  },
  WORKLOG: {
    FILTER_BY_DATE: "Filter by date-range",
  },
  MANAGE_USER: {
    INTERNAL_USER: "Internal User",
    EXTERNAL_USER: "External User",
    GRANT_ADMIN_RIGHTS: "Grant Admin Rights",
    REVOKE_ADMIN_RIGHTS: "Revoke Admin Rights",
    SEND_RESET_PASSWORD_LINK: "Send Reset Password Link",
    ENABLE_WORKLOG_TRACKING: "Enable Worklog Tracking",
    DISABLE_WORKLOG_TRACKING: "Disable Worklog Tracking",
  },
  MANAGE_BOARD: {
    ARCHIVE_THIS_BOARD: "Archive This board",
    UNARCHIVE_THIS_BOARD: "Unarchive this board",
  },
  MANAGE_USER: {
    ARCHIVED_BOARD: "Board Archived",
  },
  KANBAN_VIEW: "Switch to Kanban view",
  LIST_VIEW: "Switch to List view",
  MORE: "More",
  // TASK_DETAIL: "Open task detail",
  // OPEN_SUBTASK_DETAIL: "Open subtask detail",
  OPEN_PARENT_TASK_DETAIL: "Open parent task detail",
  TICKETS_IN_COLUMN: "",
  CLEAR_FILTERS: "Clear Filters",
  FILTERS: "Filters",
  EXPAND: "Expand",
  COLLAPSE: "Collapse",
});
