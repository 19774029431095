import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useWorkflowStepsbyBoardAndGenericStepId } from '../../../../utils/reactQuery/workflows';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateWorkflowStep } from '../../../../sevices/apiCards';
import toast from 'react-hot-toast';
import { forwardRef } from 'react';
import useToastPromise from '../../../../hooks/useToast';

const ChangeStatusDialogContent = forwardRef(({ cardData, genericStepId, closeDialog, setLoadingChangeStatusDialog }, ref) => {
    const { boardId, cardId, workflowStepId } = cardData;
  const { showToastPromise } = useToastPromise();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { workflowSteps: mappenWorkflowSteps } = useWorkflowStepsbyBoardAndGenericStepId(boardId, genericStepId);
    const queryClient = useQueryClient();

    const { mutate: updateMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateWorkflowStep, data);
    },
        onSuccess: (res) => {
            queryClient.invalidateQueries({
                queryKey: [`cards`, String(boardId)]
            });

            queryClient.invalidateQueries({
                queryKey: [`history`, String(cardId)],
            });
            queryClient.invalidateQueries({
                queryKey: [`card`, cardId],
            });
            queryClient.invalidateQueries({
                queryKey: [`mineCards`],
            });
            queryClient.invalidateQueries({
                queryKey: [`watching`],
            });
            //toast.success(res.data.message);
            closeDialog();
            setLoadingChangeStatusDialog(false);
        },
        onError: (error) => {
            console.error("Error in Change Status", error);
            //toast.error(error?.response?.data?.message);
            setLoadingChangeStatusDialog(false);
        },
    });

    const onSubmit = (data) => {
        
        if (workflowStepId !== data?.workflow_step_id) {
            setLoadingChangeStatusDialog(true);
            updateMutate({
                card_id: cardId,
                workflow_step_id: data?.workflow_step_id,
            });
        }
    }

    return (
        <div>
            <p>Change the task status by selecting a new one from the dropdown below.</p>
            <form ref={ref} onSubmit={handleSubmit(onSubmit)} id="change-workflow-step-dialog-form" className="change-workflow-step-dialog-form" >
                <InputLabel id="workflow-step-label">Status</InputLabel>
                <FormControl fullWidth error={!!errors.workflow_step_id} >
                    <Select
                        labelId="workflow-step-label"
                        id="workflow-step-select"
                        {...register("workflow_step_id", { required: "Workflow Step is required" })}
                    >
                        {mappenWorkflowSteps?.data?.data?.responseData?.map((step) => (
                            <MenuItem key={step?.workflow_step_id} value={step?.workflow_step_id}>
                                {step?.status}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.workflow_step_id && (
                        <FormHelperText>{errors.workflow_step_id.message}</FormHelperText>
                    )}
                </FormControl>
            </form>
        </div>
    )
})

export default ChangeStatusDialogContent