import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Slide,
  styled,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";

import NavigationBoards from "./components/Boards";
import "./dashboard.scss";
import { localStorageInstance } from "../../utils/localStorage";
import { useLoginUser } from "../../utils/reactQuery/users";
import { user } from "../../redux/usersSlice";
import Profile from "../../components/Header/Profile";
import { useResponsive } from "../../hooks/useResponsive";
import { setSideBarDrawer } from "../../redux/Sidebar";
import { useAssets } from "../../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../../utils/constants";
import TenantSiteName from "../../components/TenantSiteName";
import { useBoards } from "../../utils/reactQuery/boards";
import { setActiveBoards } from "../../redux/ActiveBoardSlice";
import SearchBoard from "../../components/Header/SearchBoard";
import WorklogMissing from "../../components/WorklogMissing";
import RemindMeLater from "../../components/WorklogMissing/RemindMeLater";
import RemoveRemindTime from "../../components/WorklogMissing/RemoveRemindTime";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 259,
  transition: "transform 1s ease-in-out",
}));

export default function Dashboard() {
  const userId = localStorageInstance.getItem("userId");
  const [isWorklogRemindExipred, setisWorklogRemindExipred] = useState(
    localStorageInstance.getItem("worklog-remind")
  );
  const { data } = useLoginUser(userId);
  const dispatch = useDispatch();
  const isMobile = useResponsive("down", "md");
  const sideBarDrawer = useSelector(
    (state) => state.sideBarDrawer.sideBarDrawer
  );
  const { isLoading, boards, isError } = useBoards();

  const navigate = useNavigate();
  const token = localStorageInstance.getItem("token");
  useEffect(() => {
    if (
      !token ||
      token === "null" ||
      token === "undefined" ||
      token.trim() === ""
    ) {
      localStorageInstance.clear("token");
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    setisWorklogRemindExipred(localStorageInstance.getItem("worklog-remind"));
  }, [localStorageInstance.getItem("worklog-remind")]);

  useEffect(() => {
    if (boards) {
      dispatch(
        setActiveBoards(
          boards?.data?.data?.responseData
            .filter((el) => !el.is_archive)
            .sort(function (a, b) {
              return a.board_name.localeCompare(b.board_name);
            }) || []
        )
      );
    }
  }, [isLoading, , isError]);

  const { logo, daily_worklog_reminder } = useAssets();
  const apiUrl = getAPIBaseUrl();
  if (data) {
    dispatch(user(data.data.data.responseData));
  }

  function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children ?? <div />}
      </Slide>
    );
  }

  return (
    <Box>
      <RemoveRemindTime />
      {!isWorklogRemindExipred && daily_worklog_reminder && <WorklogMissing />}
      {isMobile ? (
        <div>
          <HideOnScroll>
            <AppBar sx={{ backgroundColor: "#F2F6FE" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => dispatch(setSideBarDrawer(true))}>
                  <MenuIcon sx={{ color: "#1B3E61" }} />
                </IconButton>

                {logo ? (
                  <img
                    src={`${apiUrl}/${logo}`}
                    alt="logo"
                    width={140}
                    className="appbar-logo"
                  />
                ) : (
                  <TenantSiteName />
                )}
                <div className="justify-content-end w-100">
                  <Profile />
                </div>
              </Toolbar>
            </AppBar>
          </HideOnScroll>
          <Toolbar />
          <Outlet />
          {/* <Container>
            <Box sx={{ my: 2 }}>
            </Box>
          </Container> */}
        </div>
      ) : (
        <Box className="wrapper_dashboard">
          <Box className="wrapper_sidebar">
            <div className="logo-container">
              {logo ? (
                <img
                  src={`${apiUrl}/${logo}`}
                  className="board-logo"
                  alt="Sofmen"
                />
              ) : (
                <TenantSiteName />
              )}
            </div>
            {/* <SearchBoard /> */}
            {/* <Profile /> */}
            <Divider />
            <NavigationBoards
              boards={boards?.data?.data?.responseData}
              isLoading={isLoading}
              isError={isError}
            />
          </Box>
          <Box className="wrapper_main_container">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div style={{ height: "40px" }}></div>
                <div className="top-bar">
                  <Profile />
                </div>
                <div className="main-container">
                  <Outlet />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      <Drawer
        open={sideBarDrawer}
        anchor="left"
        onClose={() => dispatch(setSideBarDrawer(false))}>
        <AnimatedDrawerContent
          style={{
            transform: sideBarDrawer ? "translateX(0)" : "translateX(100%)",
          }}>
          <Box className="wrapper_dashboard">
            <Box className="wrapper_sidebar">
              <div className="logo-container">
                <img
                  src={`${apiUrl}/${logo}`}
                  className="board-logo"
                  alt="Sofmen"
                />
              </div>
              {/* <Profile /> */}
              <SearchBoard />
              <Divider />
              <NavigationBoards
                boards={boards?.data?.data?.responseData}
                isLoading={isLoading}
                isError={isError}
              />
            </Box>
          </Box>
        </AnimatedDrawerContent>
      </Drawer>
    </Box>
  );
}
