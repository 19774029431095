import React, { useMemo, useState } from "react";
import { steps } from "./todoConstant";
import AccordionTodo from "./Accordian";

function TodoSteps({ cards, scrollToCardId, height }) {
  const haveOpenCards = cards?.filter(
    (card) => card.card.todo_column === steps[0].name
  );
  const [expanded, setExpanded] = useState(
    haveOpenCards?.length && steps[0].name
  );

  const isHideAccepted = cards.filter(
    (card) => card.card.todo_column === "Accepted"
  )?.length;

  const stepInfo = useMemo(() => {
    return steps.reduce((acc, step) => {
      const stepCards = cards.filter(
        (card) => card.card.todo_column === step.name
      );
      acc[step.name] = {
        hasCards: stepCards.length > 0,
        cardCount: stepCards.length,
      };
      return acc;
    }, {});
  }, [cards]);

  return (
    <div style={{ marginTop: "-6px" }}>
      {steps.map((step, idx) => {
        return (
          <AccordionTodo
            heading={step.name}
            uniqueKey={idx}
            expanded={expanded}
            scrollToCardId={scrollToCardId}
            stepInfo={stepInfo}
            onChange={(val) =>
              val === expanded ? setExpanded("") : setExpanded(val)
            }
            cards={cards.filter((card) => card.card.todo_column === step.name)}
            sx={
              step.name === "Completed" && !isHideAccepted
                ? { ...step.sx, bottom: "0px" }
                : step.sx
            }
            height={height}
          />
        );
      })}
    </div>
  );
}

export default TodoSteps;
