import React, { useEffect, useState } from "react";
import { useBoardWorklogs } from "../../../../utils/reactQuery/boards";
import { useParams, useSearchParams } from "react-router-dom";
import { setDateRange, setWorkHours } from "../../../../redux/SearchSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fixedDigitsAfterDecimal,
  getLastSevenDays,
} from "../../../../utils/helpers";
import Download from "../../../myTask/Worklog/components/Download";
import SelectDatePicker from "../../../myTask/components/Filters/SelectDatePicker";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import Item from "../../../myTask/Worklog/components/Item";
import ByTaskTab from "../../../myTask/Worklog/components/ByTaskTab";
import OpenCardDetail from "../Kanban/openCardDetail";
import Loader from "../../../../components/Loader";

function BoardWorklogComp() {
  const [byTask, setByTask] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const workHours = useSelector((state) => state.search.totalWorkHours);
  const dateRange = useSelector((state) => state.search.dateRange);
  const [selectedUserSearch, updateSearchParams] =
    useCustomSearchParams("search");
  const selectedUsers = selectedUserSearch?.split(",") || [];
  const [cardData] = useCustomSearchParams("card_id");
  const [milestone] = useCustomSearchParams("milestone");
  const [searchParams, setSearchParams] = useSearchParams();

  const { boardWorklog, isLoading } = useBoardWorklogs({
    id: [id],
    user: selectedUsers.map((ele) => ({
      user_id: ele,
    })),
    dateRange,
    milestone: milestone ? [milestone] : [],
  });

  const worklogs = boardWorklog?.data?.data?.responseData || [];

  useEffect(() => {
    dispatch(
      setWorkHours(
        worklogs?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.cardlog.hours_spent;
        }, 0)
      )
    );
  }, [worklogs]);

  useEffect(() => {
    if (searchParams.get("date")) {
      const date = JSON.parse(searchParams.get("date"));
      if (date) {
        const obj = {
          startDate: new Date(date.startDate),
          endDate: new Date(date.endDate),
          key: "selection",
        };
        dispatch(setDateRange(obj));
      }
    } else {
      let newDate = JSON.parse(decodeURIComponent(getLastSevenDays()));
      updateSearchParams("date", JSON.stringify(newDate));
      const obj = {
        startDate: new Date(newDate.startDate),
        endDate: new Date(newDate.endDate),
        key: "selection",
      };
      dispatch(setDateRange(obj));
    }
  }, []);
  return (
    <div>
      {cardData && <OpenCardDetail />}
      {isLoading ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-space-between mb-4">
            <div className="d-flex">
              <p className="heading-worklog-tab mr-32">Worklogs</p>
              <div className="d-flex align-items-center">
                <div className="mr-32">
                  {/* <p className="total-work">Total Work</p> */}
                  <span className="hours-header mt-4">
                    {fixedDigitsAfterDecimal(workHours)} hours
                  </span>
                </div>
                <Download byTask={byTask} />
              </div>
            </div>

            <div className="ml-3 d-flex align-items-center">
              <label className="switch">
                <input
                  type="checkbox"
                  value={byTask}
                  checked={byTask}
                  onChange={(e) => {
                    setByTask((data) => !data);
                  }}
                />
                <div className="slider"></div>
              </label>
              <p className="weight-500 font-size-13 mr-24 ml-1">By Task</p>

              <SelectDatePicker />
            </div>
          </div>

          <div className="worklog-scroll">
            {worklogs.length === 0 ? (
              <p className="loader-center">Worklog Not Found</p>
            ) : byTask ? (
              <ByTaskTab worklogs={worklogs} />
            ) : (
              worklogs?.map((worklog, idx) => (
                <Item worklog={worklog} key={idx} />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default BoardWorklogComp;
