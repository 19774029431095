import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useFieldArray, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormHelperText, Stack } from "@mui/material";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import crossIcon from "../../../../../styles/svg/cross-icon.svg";
import adminWorkflowPlusIcon from "../../../../../styles/svg/adminWorkflowPlusIcon.svg";
import WorkflowSteps from "./WorkflowSteps";
import {
  createWorkflow,
  updateWorkflow,
} from "../../../../../sevices/apiWorkflows";
import toast from "react-hot-toast";

import { useResponsive } from "../../../../../hooks/useResponsive";
import useToastPromise from "../../../../../hooks/useToast";
export default function CreateWorkflow({
  open,
  close,
  workflowData = null,
  edit,
  workflowId,
}) {
  const [showSteps, setShowSteps] = useState(false);
  const theme = useTheme();
  const isMobile = useResponsive("down", "md");
  const queryClient = useQueryClient();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      workflow_name: workflowData?.col1 ? workflowData?.col1 : "",
      workflow_steps: workflowData?.col2
        ? workflowData.col2.map((step, index) => ({
            status: step.status,
            workflow_step_id: step.workflowstep_id,
            is_completed: step.is_completed,
            generic_step_id: step.generic_step_id,
          }))
        : [],
    },
  });
    const { showToastPromise } = useToastPromise();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "workflow_steps",
  });

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(fields);

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    move(result.source.index, result.destination.index);
  }

  const { mutate: createWorkflowmutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(createWorkflow, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`adminWorkflow`],
      });
      //toast.success(res.data.message);
      close(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      //toast.error(error.response.data.message);
    },
  });

  const { mutate: updateWorkflowmutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateWorkflow, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`adminWorkflow`],
      });
      //toast.success(res.data.message);
      close(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      //toast.error(error.response.data.message);
    },
  });

  const onSubmit = (data) => {
    const { workflow_name, workflow_steps } = data;
    if (workflow_steps.length === 0) {
      setError("workflow_steps", {
        type: "manual",
        message: "At least one workflow step is required.",
      });
      return;
    }
    if (edit) {
      const mappedSteps = workflow_steps.map((step, index) => ({
        ...step,
        step_sequence: index + 1,
        status: step.status,
        generic_step_id: step.generic_step_id,
      }));

      const payload = {
        workflow_name,
        workflow_steps: mappedSteps,
      };

      updateWorkflowmutate({ workflow_id: workflowId, data: payload });
    } else {
      const mappedSteps = workflow_steps.map((step, index) => ({
        ...step,
        step_sequence: index + 1,
        status: step.status,
      }));

      const payload = {
        workflow_name,
        workflow_steps: mappedSteps,
      };

      createWorkflowmutate(payload);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
        sx={{
          height: "100% !important",
        }}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            overflowY: "auto",
            maxHeight: "90vh",
          },
        }}>
        <DialogTitle
          id="responsive-dialog-title"
          className="createWorkflowHeader"
          sx={{
            borderBottom: "1px solid rgba(27, 62, 97, 0.16)",
            backgroundColor: "rgba(242, 246, 254, 1)",
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 0px",
            fontSize: isMobile ? "16px" : "inherit",
          }}>
          {edit ? <p>Edit Workflow</p> : <p>Create Workflow</p>}

          <img src={crossIcon} alt="" onClick={() => close(false)} />
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ padding: isMobile ? "0px 16px" : "20px 24px" }}>
            <div className="workflowName">
              <p>Workflow Name</p>
              <TextField
                id="outlined-basic"
                variant="outlined"
                {...register("workflow_name", {
                  required: "workflow name is required",
                })}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue("workflow_name", value.trimStart(), {
                    shouldValidate: true,
                  });
                }}
                error={errors?.workflow_name?.message}
                inputProps={{
                  padding: isMobile && "8.5px 12px",
                }}
                sx={{
                  width: isMobile ? "100%" : "412px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid rgba(145, 158, 171, 0.25)",
                    },
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    fontSize: "12px !important",
                    color: "red",
                    fontWeight: "400 !important",
                    marginLeft: "0px !important",
                  },
                }}
                helperText={
                  errors?.workflow_name?.message && "workflow name is required"
                }
              />
            </div>
            <Stack
              direction="row"
              justifyContent={!isMobile && "space-evenly"}
              alignItems="start">
              <p className="workflow-step-names">Workflow Steps</p>
              <p className="workflow-step-names">Generic Steps</p>
            </Stack>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="dragbaleSteps">
                {(provided) => (
                  <div
                    className="addWorkflowSteps"
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    <WorkflowSteps
                      fields={fields}
                      remove={remove}
                      register={register}
                      errors={errors}
                      edit={edit}
                      close={close}
                      control={control}
                      setValue={setValue}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div
              className="addWorkflowSteps cursor-pointer"
              onClick={() => {
                setShowSteps(true);
              }}>
              <div
                className="addWorkflowstepButton"
                onClick={() => {
                  append({
                    is_completed: false,
                    status: "",
                    workflow_step_id: null,
                    generic_step_id: null,
                  });
                  clearErrors("workflow_steps");
                }}>
                <img src={adminWorkflowPlusIcon} alt="" />
                <span>Add Step</span>
              </div>
            </div>
            <FormHelperText
              error={!!errors?.workflow_steps?.message}
              sx={{
                fontSize: "12px",
                color: "red",
                fontWeight: "400",
                marginTop: "8px",
              }}>
              {errors?.workflow_steps?.message}
            </FormHelperText>
          </DialogContent>

          <DialogActions
            sx={{
              padding: isMobile ? "5px 17px 20px 23px" : "5px 23px 20px 23px",
            }}>
            <Button
              className="workflow-btn-save"
              autoFocus
              variant="contained"
              type="submit">
              <div className="btn-content">{edit ? "Update" : "Create"}</div>
            </Button>
            <Button
              className="workflow-btn-cancel"
              autoFocus
              variant="outlined"
              onClick={() => close(false)}>
              <div className="btn-content">Cancel</div>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
