import moment from "moment";

export function formatCurrency(value) {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: "EUR",
  }).format(value);
}

export function formatDate(dateStr) {
  return new Intl.DateTimeFormat("en", {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(dateStr));
}

export function calcMinutesLeft(dateStr) {
  const d1 = new Date().getTime();
  const d2 = new Date(dateStr).getTime();
  return Math.round((d2 - d1) / 60000);
}

export function objectToFormData(obj) {
  const formData = new FormData();

  // Iterate through the object's keys
  for (let key in obj) {
    // Check if the key is a property of the object (not inherited)
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
}

export function truncateText(text, maxLength) {
  // if (!text) {
  //   return "";
  // }
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

export function createAvtarName(username) {
  return username
    ?.split(" ")
    ?.map((el, i) => (i < 2 ? el[0] : ""))
    ?.join("");
}

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const differenceInDays = (date) => {
  const date1 = moment(date);
  const date2 = moment();

  // Calculate the difference in days
  return date2.diff(date1, "days");
};

export const differenceInDaysOrHours = (date) => {
  const date1 = moment.utc(date).local();
  const date2 = moment();
  if (date == null) {
    return `not seen yet`;
  }
  const diffInMinutes = date2.diff(date1, "minutes");
  if (diffInMinutes < 1) {
    return `just now`;
  }
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  }
  // Calculate the difference in hours
  const diffInHours = date2.diff(date1, "hours");
  if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else {
    // Calculate the difference in days
    const diffInDays = date2.diff(date1, "days");
    return `${diffInDays} days ago`;
  }
};

export const hasImage = (imagePath) => {
  if (!imagePath) {
    return false;
  }
  // Define a regular expression to match common image file extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg|tiff|ico)$/i;

  // Test if the imagePath ends with one of the image file extensions
  return imageExtensions.test(imagePath);
};

export const compareDates = (dateTime1, dateTime2) => {
  // Extract the date part (YYYY-MM-DD) from the datetime strings
  const date1 = dateTime1.split(" ")[0];
  const date2 = dateTime2.split(" ")[0];

  // Compare the dates and return true if they are equal, otherwise false
  return date1 === date2;
};

export const getInitials = (first_name, last_name) => {
  // const { first_name, last_name } = data;

  // Check if first_name and last_name are provided and not empty
  if (first_name && last_name) {
    const firstInitial = first_name.charAt(0).toUpperCase();
    const lastInitial = last_name.charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  } else {
    return "";
  }
};

export const relativeTime = (date) => {
  if (!date) return "Pending";
  const localDate = moment.utc(date).local();
  return localDate.fromNow();
};

export function removeHtmlTagsAndEntities(str) {
  var htmlTagPattern = /<[^>]*>/g;

  var htmlEntityPattern = /&[^;]+;/g;

  var cleanStr = str?.replace(htmlTagPattern, "");

  cleanStr = cleanStr?.replace(htmlEntityPattern, "");
  return cleanStr;
}

export const formatTimeFromNow = (createdDate) => {
  const newDate = moment.utc(createdDate);
  const localDate = newDate.local().toDate();

  const now = moment();
  const diffInMinutes = now.diff(localDate, "minutes");
  const diffInHours = now.diff(localDate, "hours");
  const diffInDays = now.diff(localDate, "days");
  const diffInMonths = now.diff(localDate, "months");
  const diffInYears = now.diff(localDate, "years");
  if (diffInMinutes === 0) {
    return "just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} Min${diffInMinutes === 1 ? "" : "s"} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} Hr${diffInHours === 1 ? "" : "s"} ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} Day${diffInDays === 1 ? "" : "s"} ago`;
  } else if (diffInDays < 365) {
    return `${diffInMonths} Month${diffInMonths === 1 ? "" : "s"} ago`;
  } else {
    return `${diffInYears} Year${diffInYears === 1 ? "" : "s"} ago`;
  }
};

export const getLastSevenDays = () => {
  const formatDate = (date) => date.toISOString();

  // Get today's date and the date 7 days ago
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  // Format the dates
  const startDateISO = formatDate(startDate);
  const endDateISO = formatDate(endDate);

  // Create the JSON object
  const dateObject = {
    startDate: startDateISO,
    endDate: endDateISO,
    key: "selection",
  };

  // Convert the JSON object to a URL-encoded string
  const encodedDateObject = encodeURIComponent(JSON.stringify(dateObject));

  return encodedDateObject;
};

export const parseLdapUrl = (url) => {
  const regex = /^(ldap[s]?:\/\/)?(?:\[([^\]]+)\]|([^:/?#]+))(?:\:(\d+))?/;
  const match = url.match(regex);

  if (!match) {
    throw new Error("Invalid LDAP URL");
  }

  const host = match[2] || match[3];
  const port = match[4];

  return { host, port };
};
export function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
}

export function fixedDigitsAfterDecimal(num) {
  if (num % 1 === 0) {
    return num;
  } else {
    return parseFloat(num?.toFixed(1));
  }
}

export const ReactQuillsProps = {
  modules: {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      ["blockquote", "code-block"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  },
  formats: [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "color",
    "background",
  ],
};
