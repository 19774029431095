import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { createSvgIcon } from "@mui/material/utils";
import { DialogContent, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import addIcon from "../../../../../styles/svg/group-plus-icon.svg";
import Form from "./Form";
import { isAdmin } from "../../../../../utils/constants";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { AddUserDialog } from "./components/AddUserDialog";
import { TooltipName } from "../../../../../utils/Enums";
const emails = ["username@gmail.com", "user02@gmail.com"];


const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

export default function AddUsers({ boardName, ownerId, users, colabratorBoardName, isColabrotar, handleAddColabrotor, assigneeUserId,canAddCollaborator }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const user = useSelector((state) => state.users.logInUser);
  const isMobile = useResponsive("down", "md");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {(isAdmin() || ownerId == user.user_id || boardName?.data?.responseData?.board_permission?.is_admin || canAddCollaborator) &&
        (isMobile ? (
          <img src={addIcon} onClick={handleClickOpen} alt="add user" />
        ) : (
          <Tooltip title={isColabrotar ? TooltipName.CARD_DETAIL.ADD_COLABRATOR : TooltipName.BOARD_PAGE.ADD_USER}>
          <Button
            onClick={handleClickOpen}
            sx={{ padding: 0, minWidth: 0, paddingTop: "2px" }}>
            <Avatar
              sx={{
                bgcolor: "#1B3E61",
                cursor: "pointer",
                width: 32,
                height: 32,
              }}>
              <PlusIcon />
            </Avatar>
          </Button>
          </Tooltip>
        ))}

      {open && (
        <AddUserDialog
          boardName={boardName}
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
          users={users}
          isColabrotar={isColabrotar}
          colabratorBoardName={colabratorBoardName}
          handleAddColabrotor={handleAddColabrotor}
          setOpen={setOpen}
          assigneeUserId={assigneeUserId}
          isUpdateUser={false}
        />
      )}
    </div>
  );
}
