import { useQuery } from "@tanstack/react-query";
import {
  getCards,
  getCard,
  getCardAttachment,
  getUserCards,
  getMyCards,
  getSubTasks,
  getAssignedCards,
} from "../../sevices/apiCards";
import { getComments } from "../../sevices/apiComment";

export function useCards(id) {
  const {
    isLoading,
    data: cards,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: [`cards`, id],
    queryFn: () => getCards(id),
    enabled: !!id,
  });

  return { isLoading, error, cards, isError, refetch };
}

export function useUserCards(id, board, workflow) {
  const {
    isLoading,
    data: cards,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: [`userCards`, id],
    queryFn: () => getUserCards(id, board, workflow),
    enabled: !!id,
  });

  return { isLoading, error, cards, isError, refetch };
}

export function useComment(id) {
  const {
    isLoading,
    data: comment,
    error,
    isError,
  } = useQuery({
    queryKey: [`comment`, id],
    queryFn: () => getComments(id),
  });

  return { isLoading, error, comment, isError };
}

export function useCard(id) {
  const {
    isLoading,
    data: card,
    error,
    isError,
  } = useQuery({
    queryKey: [`card`, id],
    queryFn: () => getCard(id),
    enabled: !!id,
    retry: 1,
  });

  return { isLoading, error, card, isError };
}

export function useCardAttachment(id) {
  const {
    isLoading,
    data: cardAttachment,
    error,
    isError,
  } = useQuery({
    queryKey: [`cardAttachment`, id],
    queryFn: () => getCardAttachment(id),
  });

  return { isLoading, error, cardAttachment, isError };
}

export function useMineCards(
  user,
  checkedList,
  epicSearch,
  reporterCards,
  workflow_id,
  unassigned_task,
  boards
) {
  const { isLoading, data, error, isError, refetch, isRefetching } = useQuery({
    queryKey: [
      "mineCards",
      workflow_id,
      user[0]?.user_id || '',
      user[1]?.user_id || '',
      boards,
      unassigned_task,
    ],
    queryFn: () => getMyCards(
      user,
      checkedList,
      epicSearch,
      reporterCards,
      workflow_id,
      unassigned_task,
      boards
    ),
    enabled: workflow_id != "undefined" && !!workflow_id,
  });

  return { isLoading, error, data, isError, refetch, isRefetching };
}

export function useSubtasks(cardId) {
  const {
    isLoading,
    data: subtasks,
    error,
    isError,
  } = useQuery({
    queryKey: [`subTasks`, cardId],
    queryFn: () => getSubTasks(cardId),
    enabled: !!cardId,
  });

  return { isLoading, error, subtasks, isError };
}


export const useGetAssignedCards = (userId) => {
  const { data: assignedCards, isLoading, error, isError, refetch: refetchAssignedCards } = useQuery({
    queryKey: ["assignedCards", userId],
    queryFn: () => getAssignedCards(userId),
    enabled: !!userId,
  })

  return { assignedCards, isLoading, error, isError, refetchAssignedCards }
}