import React from 'react'
import { localStorageInstance } from '../../utils/localStorage';

function RemoveRemindTime() {
    const isTimeExpired = () => {
      const storedTime = localStorageInstance.getItem("worklog-remind");
      if (!storedTime) return true; // If no data is stored, consider it expired

      const storedEpoch = parseInt(storedTime, 10);
      const currentEpoch = Math.floor(Date.now() / 1000); // Current time in seconds
      const oneHour = 60 * 60; // 1 hour in seconds

      // Convert epoch to date (YYYY-MM-DD)
      const storedDate = new Date(storedEpoch * 1000)
        .toISOString()
        .split("T")[0];
      const currentDate = new Date(currentEpoch * 1000)
        .toISOString()
        .split("T")[0];

      // Check if the date has changed OR if more than an hour has passed
      return currentDate !== storedDate || currentEpoch - storedEpoch > oneHour;
    };

    setInterval(() => {
      if (isTimeExpired()) {
        localStorageInstance.removeItem("worklog-remind"); // Clear storage or take necessary action
      }
    }, 10000);

  return (
    <></>
  )
}

export default RemoveRemindTime