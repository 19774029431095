import axios from 'axios'
const CHATBOT_BASE_URL = process.env.REACT_APP_CHATBOT_API_BASE_URL;

export const getMessages = async (cardId, userId) => {

    const response = await axios.get(`${CHATBOT_BASE_URL}/chat/get`, {
        params: {
            card_id: cardId,
            user_id: userId,
            component: "card",
            component_id: cardId,
        },
    })
    
    return response;
}

export const sendMessage = async ({data, signal}) => {
    return await axios.post(
        `${CHATBOT_BASE_URL}/chat/send`,
        data,
        {
            responseType: "text",
            signal: signal,
        }
    );
}

export const deleteMessage = async(data)=>{
    return await axios.delete(
        `${CHATBOT_BASE_URL}/delete-messages`,
        data
    );
}