import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useParams } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { createCheckpoint } from "../../../schemas/addCheckpoint";
import { RHFCheckbox } from "../../RFH/RFHcheckbox";
import RFHdatePicker from "../../RFH/RFHdatepPicker";
import RHFTextField from "../../RFH/RFHtextfield";
import { addCheckpoint } from "../../../sevices/apiEpic";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../hooks/useResponsive";
import useMutate from "../../../hooks/useMutate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddCheckpoint({
  message,
  open,
  handleClose,
  callback,
  loading,
  isEdit,
  title,
  btnTitle,
  btnColor = "#1B3E61",
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const methods = useForm({
    resolver: yupResolver(createCheckpoint),
  });
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const isMobile = useResponsive("down", "md");
    const { mutate } = useMutate(addCheckpoint, [], () => {
      queryClient.invalidateQueries({
        queryKey: ["checkpoints", milestone],
      });
      queryClient.invalidateQueries({ queryKey: ["boards"] });
      methods.reset();
      handleClose();
    });

  const onSubmit = (formData) => {
    mutate({
      epic_id: milestone,
      data: {
        ...formData,
        datetime: moment(formData.datetime).format("YYYY-MM-DD"),
      },
    });
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: "613px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}
          className="add-checkpoint-dialogTitle"
        >
          Add Checkpoint
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: isMobile ? 0 : 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent
              sx={{ paddingBottom: "0px" }}
              className="add-checkpoint-dialogContent"
            >
              <DialogContentText id="alert-dialog-slide-description">
                <Typography className="milestone-lable" mt={isMobile ? 1 : 2}>
                  Checkpoint Name
                </Typography>
                <RHFTextField name={"checkpoint_name"} />

                <Typography className="milestone-lable" mt={isMobile ? 1 : 2}>
                  Target Date
                </Typography>
                <RFHdatePicker name={"datetime"} />

                <Stack
                  alignItems="center"
                  direction="row"
                  mt={isMobile ? 1 : 2}
                >
                  <Typography className="milestone-lable" mr={2}>
                    Is Done
                  </Typography>
                  <RHFCheckbox name={"is_done"} />
                </Stack>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                padding: "8px 24px",
                justifyContent: "end",
                mb: isMobile ? 2 : 3,
              }}
            >
              <Button
                onClick={callback}
                disabled={loading}
                variant="contained"
                type="submit"
                sx={{
                  bgcolor: btnColor,
                  mr: "10px",
                  fontSize: isMobile ? "14px" : "16px",
                  padding: isMobile ? "6px 36px" : "10px 16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  minWidth: isMobile ? "" : "130px",
                }}
              >
                Add
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#1B3E61",
                  border: "1px solid #1B3E61",
                  textTransform: "capitalize",
                  fontSize: isMobile ? "14px" : "16px",
                  padding: isMobile ? "6px 24px" : "10px 16px",
                  fontWeight: "600",
                  opacity: "0.6",
                  minWidth: isMobile ? "" : "130px",
                }}
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </React.Fragment>
  );
}
