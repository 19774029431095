import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react'
import toast from "react-hot-toast";
import { deleteWorkflow } from '../../../../sevices/apiWorkflows';
import ConfirmationDialog from '../../../../components/Dialog/ConfirmationDialog';
import useToastPromise from '../../../../hooks/useToast';
const DeleteWorkflow = ({handleDeleteClose,showDelete,workflowId}) => {
    const queryClient = useQueryClient();
      const { showToastPromise } = useToastPromise();
    const { mutate: deleteWorkflowMutate } = useMutation({
          mutationFn: async (data) => {
            return await showToastPromise(deleteWorkflow, data);
          },
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: [`adminWorkflow`],
          });
          //toast.success(res.data.message);
          handleDeleteClose();
        },
        onError: (error) => {
          console.error("Error Delete Attachments", error);
          //toast.error(error.response.data.message);
        //   setLoading(false);
        handleDeleteClose();
        },
      });


      function callback() {
        // setLoading(true);
        deleteWorkflowMutate(workflowId);
      }

  return (
        <ConfirmationDialog
          open={showDelete}
          handleClose={handleDeleteClose}
          message={
            "Are you sure you want to delete this item? This action can not be undone"
          }
          callback={callback}
          // loading={loading}
          btnTitle={"Delete"}
          title={"Delete Confirmation"}
        />
  )
}

export default DeleteWorkflow