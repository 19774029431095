import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCard } from "../../../../../../sevices/apiCards";
import toast from "react-hot-toast";

const MessageBox = ({ message, descRef, setCardTitle, cardId }) => {
  const [isParsable, setIsParsable] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [content, setContent] = useState(message.content);

  useEffect(() => {
    try {
      let parsedContent = JSON.parse(message.content);

      if (parsedContent.suggestedTitle && parsedContent.suggestedDescription) {
        setIsParsable(true);
        setParsedData(parsedContent);

        let formattedContent = message.content.slice(1, -1);

        if (formattedContent.includes("suggestedTitle")) {
          formattedContent = formattedContent.replace(
            /"suggestedTitle":/g,
            "<h3>Title: </h3>"
          );
        }
        if (formattedContent.includes("suggestedDescription")) {
          formattedContent = formattedContent.replace(
            /"suggestedDescription":/g,
            "<br><h4>Description: </h4>"
          );
          formattedContent = formattedContent.replace(/",/g, '"');
          formattedContent = formattedContent.replace(/"/g, "");
        }

        setContent(formattedContent);

      } else {
        setIsParsable(false);
      }
    } catch (error) {
      setIsParsable(false);
      setContent(message.content);
    }
  }, [message.content]);

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["mineCards"],
        exact: false
      });
      queryClient.invalidateQueries({
        queryKey: ["card", cardId],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ["cards", id],
      // });

      queryClient.invalidateQueries({
        queryKey: ["watching"],
      });

      // setLoading(false);
      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("An error occurred while updating task title", error);
      toast.error(error.response.data.message);
      // setLoading(false);
    },
  });


  const handleArrowClick = () => {
    descRef.current.getEditor().clipboard.dangerouslyPasteHTML(parsedData?.suggestedDescription)
    setCardTitle(parsedData?.suggestedTitle)

    mutate({
      card_id: cardId,
      data: { title: parsedData?.suggestedTitle }
    });
  };

  return (
    <Box className={message.role === "user" ? "chat-msg-cont" : "chat-ai-msg-cont"} >
      <Typography
        variant="body1"
        className={message.role === "user" ? "chat-user-msg" : "chat-ai-msg"}
        component="div"
        dangerouslySetInnerHTML={{ __html: content.replace(/\\n/g, "<br />") }}
      />
      {message.role === "assistant" && isParsable && (
        <Box
          sx={{
            cursor: "pointer",
            zIndex: 1,
            fontSize: "50px",
            transition: "transform 0.2s",
            "&:hover": { transform: "translateX(-15px)" },
          }}
          onClick={handleArrowClick}
        >
          ←
        </Box>
      )}
    </Box>
  );
};

export default MessageBox;
