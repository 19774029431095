import React, { useEffect, useState } from 'react'

import { useWorklogMissing } from "../../utils/reactQuery/worklogMissing";
import DialogComponent from './DialogComponent';
import RemindMeLater from './RemindMeLater';
import MissingWorklogList from './MissingWorklogList';
import './missingWorklog.scss';

function WorklogMissing() {
    const { worklog, refetch, isRefetching } = useWorklogMissing();
    const { responseData, daily_worklog_hours } = worklog?.data?.data || {};
    const [dialog,setDialog] = useState(false);

    useEffect(() => {
      if (responseData && responseData?.length) setDialog(true);
      else setDialog(false);
    }, [responseData, isRefetching]);

    const handleClose = ()=>{
        setDialog(false);
    }
  return (
    <>
      {dialog && (
        <DialogComponent dialog={dialog}>
          <div className="dialog-content-missing-worklogs">
            <MissingWorklogList
              list={responseData}
              daily_worklog_hours={daily_worklog_hours}
            />
            <RemindMeLater handleClose={handleClose} />
          </div>
        </DialogComponent>
      )}
    </>
  );
}

export default WorklogMissing