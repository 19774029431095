import axios from "axios";
import Api from "./axios";
import { getAPIBaseUrl } from "../utils/constants";

const baseUrl = getAPIBaseUrl();

export const getUsers = async () => {
  return Api.get(`/users`);
};

export const getUser = async (id) => {
  return Api.get(`/user/${id}`);
};

export const getUserDetailByToken = async (token) => {
  return axios.get(`${baseUrl}/user_anonymous`, {
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
  });
};


export const updateUser = async ({ id, data }) => {
  return Api.put(`/user/${id}`, data);
};

export const inviteExternalUser = async (data) => {
  return Api.post(`/invitation`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getWatchlist = async (id) => {
  return Api.get(`/cards/user/watchlist?workflow_id=${id}`);
};

export const getWatchlistUsers = async () => {
  return Api.get("/cards/user/watchlist_users");
};

export const inviteInternalUser = async (data) => {
  return Api.post(`/ldap-users`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getLdapUserList = async (id) => {
  return Api.get("/ldap_user_na_tb");
};

export const sendResetMail = async ({ email }) => {
  return await Api.post(
    `forget_password/send_link?admin=${true}`,
    { email },
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }
  );
};

export const getUserProfileForAdmin = async (id) => {
  return await Api.get(`/user_profile/${id}`);
};

export const updateUserDetails = async ({ userId, data }) => {
  return await Api.put(`/user/${userId}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getBoardUser = async (boardId, userId) => {
  return Api.get(`/board_permission/${boardId}/user_id/${userId}`);
};
