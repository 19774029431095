import { ListItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SettingIcon from "../../../styles/svg/clarity_administrator-solid.svg";
import Avatar from "../../../components/Avatar/index";
import { createAvtarName, hasImage } from "../../../utils/helpers";
import { searchCard } from "../../../redux/SearchSlice";
import { useDispatch } from "react-redux";
import { filterUser } from "../../../redux/usersSlice";
import { setSideBarDrawer } from "../../../redux/Sidebar";
import { localStorageInstance } from "../../../utils/localStorage";
import checkpointIcon from "../../../styles/svg/next-checkpoint.svg";
import { TooltipName } from "../../../utils/Enums";

export default function SidebarListItem({ board }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  // console.log("userid ==", localStorageInstance.getItem("userId"));

  function handleClick() {
    if (id != board.board_id) {
      dispatch(searchCard(""));
      dispatch(filterUser(null));
      dispatch(setSideBarDrawer(false));
    }
  }
  const userId = localStorageInstance.getItem("userId");

  return (
    <ListItem key={board.board_id} className="menu-ListItem">
      <NavLink
        to={`/board/${board.board_id}`}
        onClick={handleClick}
        className="justify-content-space-between d-flex align-items-center"
      >
        <div className="d-flex align-items-center">
          <Avatar
            key={`board-avtar-${board.board_id}`}
            user_image={board.board_owner_image}
            className="sidebar-board-owner user-avatar"
            title={board.board_owner_name}
            style={
              !hasImage(board.board_owner_image) && {
                bgcolor: board?.board_owner_profile_color?.background,
                color: board?.board_owner_profile_color?.text,
              }
            }
          >
            {`${createAvtarName(board.board_owner_name)}`.toUpperCase()}
          </Avatar>

          {/* <Tooltip title={board.board_name}> */}
          <p className="board-name-text">{board.board_name}</p>
        </div>
        <div className="d-flex align-items-center">
          {board.overdue_checkpoint && (
            <Tooltip title={TooltipName.SIDE_PANEL_ACCORDIAN.CHECKPOINT_MISSED}>
            <img src={checkpointIcon} alt="checkpoint" className="ml-1" />
            </Tooltip>
          )}
          {board.cards_assigned_to_logged_in_user ? (
            <Tooltip title={TooltipName.SIDE_PANEL_ACCORDIAN.CARDS_ASSIGNED}>
            <p
              className="allocated-cards"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/board/${board.board_id}?search=${userId}`);
              }}
            >
              {board.cards_assigned_to_logged_in_user}
            </p>
            </Tooltip>
          ) : ''}
        </div>
        {/* </Tooltip> */}
        {/* {board.is_owner ? (
          <img src={SettingIcon} alt="not found" className="setting-icon-img" />
        ) : null} */}
      </NavLink>
    </ListItem>
  );
}
