import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import Boards from "../../components/Filters/Boards";
import SelectDatePicker from "../../components/Filters/SelectDatePicker";
import Epics from "../../components/Filters/Epics";
import {
  addAllBoard,
  searchCard,
  setDateRange,
} from "../../../../redux/SearchSlice";
import { setAllGroupUser } from "../../../../redux/TaskGroupingSlice";
import { useTeams } from "../../../../utils/reactQuery/teams";
import Team from "../../components/Filters/Team";
import ToggleInput from "../../../../components/ToggleInput";
import GlobalSearch from "../../../../components/Search";
import _ from "lodash";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { TooltipName } from "../../../../utils/Enums";

function Filters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { teams } = useTeams();
  const [isVisible, setIsVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchParam, setSearchParam, removeSearchParam] =
    useCustomSearchParams("txt");

  const handleChange = (event) => {
    const value = event.target.value.trimStart();
    setInputValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
      setSearchParam("txt", value);
    }, 300)
  ).current;

  const removeSearchParams = (paramsToRemove) => {
    const newSearchParams = new URLSearchParams(searchParams);
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });
    setSearchParams(newSearchParams);
  };

  const checkedList = useSelector((state) => state.search.boardSearch);
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );

  const clearFilters = () => {
    dispatch(addAllBoard([]));
    dispatch(setAllGroupUser([]));
    removeSearchParams(["board", "uid"]);
    setIsVisible(false);
  };

  useEffect(() => {
    if (searchParams.get("uid") || searchParams.get("board")) {
      setIsVisible(true);
    }
    return () => {
      dispatch(setAllGroupUser([]));
      dispatch(addAllBoard([]));
      dispatch(setDateRange(null));
    };
  }, []);

  return (
    <div className="worklog-filter d-flex justify-content-space-between align-items-center mt-4">
      <div
        className="d-flex align-items-center"
        style={{ position: "relative" }}>
        <ToggleInput
          value={isVisible}
          setValue={(value) => {
            setIsVisible(value);
            if (!value) {
              clearFilters();
            }
          }}
        />
        <div className={`slide-panel d-flex ${isVisible ? "open" : ""}`}>
          <Team
            user={groupingUser.map((item) => item.users).flat()}
            team={teams}
          />
          <Boards />
          {checkedList && checkedList.length == 1 && <Epics />}
          <div className={checkedList && checkedList.length == 1 ? "" : "ml-2"}>
            <GlobalSearch
              placeholder={"Search..."}
              searchText={inputValue}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div>
        <SelectDatePicker />
      </div>
    </div>
  );
}

export default Filters;
