import React from 'react'
import Users from '../../../BoardHeader/Users/Users'
import { QueryClient } from '@tanstack/react-query';

const Collaborators = ({ ownerId, boardId, boardName, cardCollabrator, handleRemoveColabrotor, handleAddColabrotor, assigneeUserId, canAddCollaborator = false }) => {

  return (
    <Users assigneeUserId={assigneeUserId} colabratorBoardName={boardName} boardId={boardId} ownerId={ownerId} isColabrotar={true} cardCollabrator={cardCollabrator} handleRemoveColabrotor={handleRemoveColabrotor} handleAddColabrotor={handleAddColabrotor} canAddCollaborator={canAddCollaborator} />
  )
}

export default Collaborators