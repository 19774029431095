import { useQuery } from "@tanstack/react-query";
import { getAssets } from "../../sevices/apiDomainAssets";

export function useAssets() {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: ["logo"],
    queryFn: getAssets,
    retry:false,
  });
  const icon = data?.data.data.responseData.fav_icon;
  const logo = data?.data.data.responseData.tenant_logo;
  const { company_name = "", daily_worklog_reminder =false} =
    data?.data.data.responseData || {};
   return {
     isLoading,
     error,
     icon,
     isError,
     logo,
     company_name,
     daily_worklog_reminder,
   };
}
