import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import { MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useBoards } from "../../../../utils/reactQuery/boards";
import arrow from "../../../../styles/svg/arrow.svg";
import Field from "../../../../components/field";
import "./mergeBoard.scss";
import { useResponsive } from "../../../../hooks/useResponsive";
import { MergeBoards } from "../../../../sevices/apiBoards";
import Loader from "../../../../components/Loader";
import { useWorkflow } from "../../../../utils/reactQuery/workflows";
import useToastPromise from "../../../../hooks/useToast";

export default function MergeBoard({
  open,
  close,
  currentBoard,
  admin = false,
}) {
  const theme = useTheme();
  const { boards } = useBoards();
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");
  const navigate = useNavigate();
  const [targetBoardId, setTagetBoardId] = React.useState(null);
  const [sourceBoardId, setSourceBoardId] = React.useState(
    currentBoard?.data?.data?.responseData?.board_id || null
  );
  const [isloading, setIsLoading] = React.useState(false);
  const [sourceWorkflowId, setSourceWorkflowId] = React.useState(
    currentBoard?.data?.data?.responseData?.workflow_id || null
  );
  const [sourceWorkflowName, setSourceWorkflowName] = React.useState(null);
  const currentBoardName = currentBoard?.data?.data?.responseData?.board_name;
  const { workflows } = useWorkflow();
  const workflowOfSource = workflows?.data?.data?.responseData;
    const { showToastPromise } = useToastPromise();
  const {
    register,
    handleSubmit: updateBoardSubmit,
    watch,
    formState: {},
    getValues,
  } = useForm({
    targetStep: "None",
  });

  React.useEffect(() => {
    if (workflowOfSource) {
      setSourceWorkflowName(workflowOfSource);
    }
  }, [workflowOfSource]);

  const selectedTargetStep = watch("targetStep");
  const selectedSourceStep = watch("sourceBoard");

  const { mutate: mergeBoardMutate, isPending } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(MergeBoards, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      setIsLoading(false);
      navigate(`/board/${targetBoardId}`);
      close(false);
      //toast.success(res?.data?.message);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
    },
  });

  const workflow = sourceWorkflowName?.filter(
    (item) => item?.workflow_id === sourceWorkflowId
  );

  const allsourceBoards = boards?.data?.data?.responseData
    ?.filter((item) => item.is_archive === false)
    ?.sort((a, b) => {
      return a.board_name.localeCompare(b.board_name);
    });

  const boardsWithSameWorkFlow = boards?.data?.data?.responseData
    ?.filter(
      (item) =>
        item.workflow_id === sourceWorkflowId &&
        item.board_id !== (sourceBoardId || selectedSourceStep) &&
        item.is_archive === false
    )
    ?.sort((a, b) => {
      return a.board_name.localeCompare(b.board_name);
    });

  const onSubmit = (data) => {
    setTagetBoardId(data?.targetStep);
    setIsLoading(true);

    mergeBoardMutate({
      source_board_id: sourceBoardId ?? selectedSourceStep,
      target_board_id: data?.targetStep,
    });
  };

  const handleClose = () => {
    close(false);
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh !important",
            "& .MuiPaper-root": {
              borderRadius: "8px",

              maxWidth: "494px",
            },
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="move-ticket-dialog-title"
        >
          Merge Board
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: isMobile ? 1 : 9,
            padding: "8px",
            color: (theme) => theme.palette.grey[500],
            "&:hover": {},
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="move-ticket-dialog-content">
          <DialogContentText className="move-ticket-dialog-contentText">
            As the board merges, all tasks will be moved to the target board.
          </DialogContentText>
        </DialogContent>
        <form onSubmit={updateBoardSubmit(onSubmit)}>
          <DialogContent className="move-ticket-form-dialogContent">
            <div
              className={
                isMobile
                  ? "mobile-ticket-reasign-step-container"
                  : "ticket-reasign-step-container"
              }
            >
              <div
                className={
                  isMobile
                    ? "mobile-current-target-board-name"
                    : "current-target-board-name"
                }
              >
                <p>
                  {workflow && workflow.length > 0 && workflow[0]?.workflow_name
                    ? workflow[0]?.workflow_name
                    : "Source board"}
                </p>
                {admin ? (
                  <div className="initial-step">
                    <Field
                      sx={{
                        width: isMobile ? "100%" : "180px",
                      }}
                      className="mui-status-field"
                      required
                      value={getValues(`sourceBoard`)}
                      select
                      {...register(`sourceBoard`)}
                    >
                      {allsourceBoards && allsourceBoards?.length > 0 ? (
                        allsourceBoards?.map((option) => (
                          <MenuItem
                            key={`option.board_id`}
                            id={`option.board_id`}
                            value={option?.board_id}
                            onClick={() =>
                              setSourceWorkflowId(option?.workflow_id)
                            }
                          >
                            {option?.board_name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No boards Found</MenuItem>
                      )}
                    </Field>
                  </div>
                ) : (
                  <div className="initial-step">
                    <TextField
                      className="mui-status-field"
                      disabled
                      value={currentBoardName}
                    />
                  </div>
                )}
              </div>

              <img src={arrow} alt="" />
              <div
                className={
                  isMobile
                    ? "mobile-current-target-board-name"
                    : "current-target-board-name"
                }
              >
                <p>Target board</p>
                <div className="initial-step">
                  <Field
                    sx={{
                      width: isMobile ? "100%" : "180px",
                    }}
                    className="mui-status-field"
                    required
                    value={getValues(`targetStep`)}
                    select
                    {...register(`targetStep`)}
                    disabled={admin ? !selectedSourceStep : false}
                  >
                    {boardsWithSameWorkFlow?.length > 0 ? (
                      boardsWithSameWorkFlow?.map((option) => (
                        <MenuItem
                          key={`option.board_id`}
                          id={`option.board_id`}
                          value={option.board_id}
                        >
                          {option.board_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No boards Found</MenuItem>
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions className="move-ticket-dialogActions">
            <Button
              className="theme-bg-color remove-step-btn-save"
              autoFocus
              variant="contained"
              disabled={!selectedTargetStep || isloading}
              onClick={updateBoardSubmit(onSubmit)}
            >
              <div className="btn-content">Update</div>
            </Button>
            <Button
              className="remove-step-btn-cancel"
              autoFocus
              variant="outlined"
              onClick={handleClose}
            >
              <div className="btn-content">Cancel</div>
            </Button>
          </DialogActions>
        </form>
        {isPending && (
          <div className="popup-loader">
            <Loader color={"black"} />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
}
