import { Button } from '@mui/material';
import React from 'react'
import { localStorageInstance } from '../../utils/localStorage';

function RemindMeLater({ handleClose }) {
  const setTime = () => {
    const now = Math.floor(Date.now() / 1000); // Get current time in seconds (epoch)
     localStorageInstance.setItem({ key: "worklog-remind", value: now });
    handleClose();
  };
  return (
    <Button
      onClick={setTime}
      //   disabled={loading}
      fullWidth
      variant="contained"
      sx={{
        bgcolor: "#1B3E61",
        fontSize: "16px",
        // padding: "10px 16px",
        fontWeight: "600",
        textTransform: "capitalize",
      }}
      className="confirmation-action-btn remind-me-later"
    >
      I'm on it (remind me in 1 hr if I forget)
    </Button>
  );
}

export default RemindMeLater