import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { createSvgIcon } from "@mui/material/utils";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Form from "./../Form";
import { useResponsive } from "../../../../../../hooks/useResponsive";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export function AddUserDialog(props) {
    const { onClose, selectedValue, open, boardName, users, isUpdateUser, currentUser, isColabrotar, colabratorBoardName, handleAddColabrotor, setOpen, assigneeUserId } = props;

    const isMobile = useResponsive("down", "md");
    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <>
            <React.Fragment>
                <BootstrapDialog
                    fullWidth
                    aria-labelledby="responsive-dialog-title"
                    onClose={handleClose}
                    open={open}
                    sx={{
                        height: "90vh",
                        "& .MuiPaper-root": {
                            borderRadius: "12px",
                            maxWidth: "470px",
                        },
                    }}>
                    <DialogTitle
                        sx={{
                            m: 0,
                            p: isMobile ? "8px 12px !important" : 2,
                            fontSize: isMobile && "16px",
                            bgcolor: "rgba(242, 246, 254, 1)",
                            fontWeight: isMobile ? "400" : "600",
                            fontSize: "16px",
                        }}
                        id="responsive-dialog-title">
                        {/* {(isUpdateUser && !isColabrotar) ? "Update" : "Add"} user to {boardName?.data?.responseData?.board_name} */}
                        {isColabrotar ? `Add collaborator` : isUpdateUser ? `Update user to ${boardName?.data?.responseData?.board_name}` : `Add user to ${boardName?.data?.responseData?.board_name}`}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            padding: isMobile ? "0px !important" : "",
                            right: isMobile ? "5px !important" : 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent
                        dividers
                        className={isMobile ? "add-user-form" : "dialog-content-form"}>
                        <Form
                            onClose={handleClose}
                            boardUsers={users}
                            isMobile={isMobile}
                            isColabrotar={isColabrotar}
                            handleAddColabrotor={handleAddColabrotor}
                            setOpen={setOpen}
                            assigneeUserId={assigneeUserId}
                            currentUser={currentUser && currentUser}
                            isUpdateUser={isUpdateUser}
                        />
                    </DialogContent>
                </BootstrapDialog>
            </React.Fragment>
        </>
    );
}
