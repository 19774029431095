import React from 'react'
import DeleteIcon from "../../styles/svg/adminWorkflowDelete.svg"
import EditIcon from "../../styles/svg/edit-title.svg"
export const DeleteIcone = ({ height, width, ...other }) => {
  return (
      <img src={DeleteIcon} alt="Delete Icon" style={{ height: height, width: width, cursor: "pointer" }} {...other} />
  )
}

export const EditIcone = ({ height, width, ...other }) => {
  return (
      <img src={EditIcon} alt="Delete Icon" style={{ height: height, width: width, cursor: "pointer" }} {...other} />
  )
}

