import {
  AvatarGroup,
  Box,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Avatar as Image } from "@mui/material";
import moment from "moment";

import Avatar from "../../../../../components/Avatar";
import AddUsers from "./AddUsers";
import { useBoardUsers, useGetBoardUser } from "../../../../../utils/reactQuery/users";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import RemoveUser from "./RemoveUser";
import { filterUser } from "../../../../../redux/usersSlice";
import UsersIcon from "../../../../../styles/svg/users-icon.svg";
import { useResponsive } from "../../../../../hooks/useResponsive";
import UsersMobile from "./UsersMobile";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { AddUserDialog } from "./components/AddUserDialog";
import { TooltipName } from "../../../../../utils/Enums"
import { currentUserId, isAdmin } from "../../../../../utils/constants";
function Users({ boardName, boardId, ownerId, showAddUser = true, colabratorBoardName, isColabrotar = false, cardCollabrator, handleRemoveColabrotor, handleAddColabrotor, assigneeUserId = null, canAddCollaborator }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useResponsive("down", "md");
  const [selectedUserSearch, updateSearchParams, removeSearchParam] = useCustomSearchParams('search')
  const selectedUsers = selectedUserSearch?.split(',')
  const { isLoading, error, data, isError } = useBoardUsers(id || boardId);
  const [boardUser, setBoardUser] = useState([])
  const [openEditUserDialog, setOpenEditUserDialog] = useState(null);

  const { data: userData } = useGetBoardUser(id, openEditUserDialog)

  useEffect(() => {
    if (!isColabrotar) {
      setUsers(
        data?.data?.data?.responseData ? data?.data?.data?.responseData : []
      );
    } else {
      setUsers(cardCollabrator || [])
    }

  }, [isLoading, id, data, cardCollabrator]);

  useEffect(() => {
    if (isColabrotar && data) {
      setBoardUser(data?.data?.data?.responseData)
    }
  }, [data])

  const closeUserList = () => {
    setAnchorEl(null);
  };


  const filterUserById = (userId) => {
    if (!isColabrotar) {
      let selected = selectedUsers || [];
      if (selected?.includes(String(userId))) {
        selected = selected?.filter(id => id !== String(userId))
      } else {
        selected?.push(userId)
      }
      if (selectedUsers?.[0] === String(userId) && selectedUsers?.length === 1) {
        removeSearchParam('search')
      } else {
        updateSearchParams('search', selected?.join(','))
      }
    }

  }

  const removeSelectedUser = () => {
    removeSearchParam('search')
  }

  const isLessUsers = users.length > 0 ? true : false;

  function createUserName(fn, ln) {
    return `${fn} ${ln}`
  }
  const handleClose = () => {
    setOpenEditUserDialog(null)
  };

  return (
    <>
      {openEditUserDialog && userData && (
        <AddUserDialog
          boardName={boardName}
          // selectedValue={selectedValue}
          open={openEditUserDialog}
          onClose={handleClose}
          users={users}
          isUpdateUser={true}
          currentUser={userData?.data?.data?.responseData}
        />
      )}

      {isMobile ? (
        <div>
          <Stack direction={"row"} justifyContent={'space-between'}>
            <Typography color="#1B3E61" mb={1} fontWeight={600}>
              Select user
            </Typography>
            <AddUsers boardName={boardName} ownerId={ownerId} users={users} />
          </Stack>
          <Box maxHeight={165} overflow={"auto"} minHeight={100}>
            <UsersMobile users={users} filterUserById={filterUserById} ownerId={ownerId} />
          </Box>
        </div>
      ) : (
        <Stack className={`${isColabrotar ? 'align-items-start' : 'align-items-center'}`}>
          <div
            className={
              isMobile ? "" : "d-flex justify-content-center align-items-center"
            }
          >
            <AvatarGroup
              total={users?.length}
              renderSurplus={(surplus) => (
                <span onClick={(event) => setAnchorEl(event?.currentTarget)}>
                  +{users?.length - 4}
                </span>
              )}
              className={`avatar-group cursor-pointer ${"avatar-group-border"}`}
            >
              {
                isColabrotar ? users
                  ?.map((el) => (
                    <Avatar
                      key={`user-avtar-${el?.user_id}`}
                      onClick={() => filterUserById(el?.user_id)}
                      username={createUserName(el?.user?.first_name, el?.user?.last_name)}
                      user_image={el?.user?.user_image}
                      title={createUserName(el?.user?.first_name, el?.user?.last_name)}
                      className={`header-avatar ${selectedUsers?.includes(String(el?.user_id)) ? "active-avtar" : ""
                        }`}
                      style={
                        !hasImage(el?.user?.user_image) && {
                          bgcolor: el?.user?.profile_color?.background,
                          color: el?.user?.profile_color?.text,
                        }
                      }
                      isAdmin={(el?.is_admin || el?.user_id == boardName?.data?.responseData?.owner_user_id || (currentUserId() == el?.user_id && isAdmin()))}
                    >
                      {createAvtarName(createUserName(el?.user?.first_name, el?.user?.last_name))}
                    </Avatar>
                  )) :
                  users
                    ?.sort((a, b) => {
                      const dateA = moment(a?.last_seen);
                      const dateB = moment(b?.last_seen);
                      return dateB - dateA;
                    })
                    ?.map((el) => (
                      <Avatar
                        key={`user-avtar-${el?.user_id}`}
                        onClick={() => filterUserById(el?.user_id)}
                        username={el?.username}
                        user_image={el?.user_image}
                        title={el?.username}
                        className={`header-avatar ${selectedUsers?.includes(String(el?.user_id)) ? "active-avtar" : ""
                          }`}
                        style={
                          !hasImage(el?.user_image) && {
                            bgcolor: el?.profile_color?.background,
                            color: el?.profile_color?.text,
                          }
                        }
                        isAdmin={(el?.is_admin || el?.user_id == boardName?.data?.responseData?.owner_user_id || (currentUserId() == el?.user_id && isAdmin()))}
                      >
                        {createAvtarName(el?.username)}
                      </Avatar>
                    ))
              }

            </AvatarGroup>

            <Tooltip title={isColabrotar ? TooltipName.CARD_DETAIL.COLLABRATOR_LIST : TooltipName.BOARD_PAGE.USER_LIST}>
              <Image
                onClick={(event) => setAnchorEl(event?.currentTarget)}
                src={UsersIcon}
                key={"avatar-12343"}
                className={"header-avatar cursor-pointer"}
              >
                AS
              </Image>
            </Tooltip>

            {
              isLessUsers && (
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={closeUserList}
                  MenuListProps={{
                    "aria-labelledby": "epics-board-dropdown",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 14,
                        height: 14,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  className="assign-board-owner-list"
                >
                  {
                    isColabrotar ? users
                      ?.map((el) => {
                        return (
                          <RemoveUser
                            ownerId={ownerId}
                            user={el}
                            onClick={() => filterUserById(el?.user_id)}
                            closeUserList={closeUserList}
                            isSelected={selectedUsers?.includes(String(el?.user_id))}
                            removeSearchParam={removeSelectedUser}
                            isColabrotar={true}
                            handleRemoveColabrotor={handleRemoveColabrotor}
                            setOpenEditUserDialog={setOpenEditUserDialog}
                            boardName={boardName}
                            boardOwnerId={boardName?.data?.responseData?.owner_user_id}
                          />
                        );
                      }) :
                      users
                        ?.sort((a, b) => {
                          const dateA = moment(a?.last_seen);
                          const dateB = moment(b?.last_seen);
                          return dateB - dateA;
                        })
                        ?.map((el) => {
                          return (
                            <RemoveUser
                              ownerId={ownerId}
                              user={el}
                              onClick={() => filterUserById(el?.user_id)}
                              closeUserList={closeUserList}
                              isSelected={selectedUsers?.includes(String(el?.user_id))}
                              removeSearchParam={removeSelectedUser}
                              setOpenEditUserDialog={setOpenEditUserDialog}
                              boardOwnerId={boardName?.data?.responseData?.owner_user_id}

                            />
                          );
                        })

                  }
                </Menu>
              )
            }


            {showAddUser && (
              <AddUsers boardName={boardName} ownerId={ownerId} users={boardUser} colabratorBoardName={colabratorBoardName} isColabrotar={isColabrotar} handleAddColabrotor={handleAddColabrotor} assigneeUserId={assigneeUserId} canAddCollaborator={canAddCollaborator} />
            )}
          </div>
        </Stack>
      )}
    </>
  );
}

export default Users;
