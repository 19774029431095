import * as React from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import ForgotPasswordMessage from "../../../utils/Enums";
import GraphicElements from "../../../styles/svg/graphic_elements.svg";
import { useResponsive } from "../../../hooks/useResponsive";
import mobileBg from "../../../styles/svg/login_bg_mobile.svg";
import { forgotPassword } from "../../../sevices/apiAuth";
import { useAssets } from "../../../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../../../utils/constants";
import TenantSiteName from "../../../components/TenantSiteName";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useToastPromise from "../../../hooks/useToast";

function ResetPasswordForm({ uuid }) {
  const { logo } = useAssets();
  const apiUrl = getAPIBaseUrl();
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirmPassword: false,
  });

  const { register, handleSubmit, watch, formState } = useForm({
    defaultValues: {},
  });
  const isMobile = useResponsive("down", "md");
  const { errors } = formState;
  const password = watch("password") || "";
  const navigate = useNavigate();
  const { showToastPromise } = useToastPromise();

  const handleClickShowPassword = (newpassword) => {
    if (newpassword === "password") {
      setShowPassword((prev) => ({ ...prev, password: !prev.password }));
    }
    if (newpassword === "ConfirmPassword") {
      setShowPassword((prev) => ({
        ...prev,
        confirmPassword: !prev.confirmPassword,
      }));
    }
  };

  const { mutate: changePasswordmutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(forgotPassword, data);
    },
    onSuccess: (res) => {
      //toast.success(res.data.message);
      navigate("/");
    },
    onError: (error) => {
      console.error("An error occurred while creating task", error);
      //toast.error(error.response.data.message);
    },
  });

  const submit = (data) => {
    const newData = {
      new_password: data.password,
      uuid: uuid,
    };
    changePasswordmutate(newData);
  };

  function onError(errors) {
    console.log(errors);
  }
  const requirements = ForgotPasswordMessage.REQUIREMENTS;
  const validations = [
    password?.length >= 8 && password?.length <= 15,
    /[A-Z]/.test(password),
    /[a-z]/.test(password),
    /\d/.test(password),
    /[!@#^*_?{}\-]/.test(password),
  ];
  const allRequirementsMet = validations.every((validation) => validation);

  return (
    <Box
      component="main"
      className="forgotpassword-box"
      sx={{
        backgroundImage: `url(${isMobile ? mobileBg : GraphicElements})`,
      }}
      disableGutters={true}
    >
      <Box className="forgotpassword-innerBox">
        {logo ? (
          <img
            src={`${apiUrl}/${logo}`}
            alt="not found"
            width={isMobile ? 251 : ""}
            className="login_logo"
          />
        ) : (
          <TenantSiteName />
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(submit, onError)}
          noValidate
          className="submitForm-box"
        >
          <Typography
            className="text-center black-primary-color mb-4 weight-700"
            component={"h1"}
            variant="h5"
          >
            {ForgotPasswordMessage.CREATE_NEW_PASSWORD}
          </Typography>
          <Typography
            className="text-center forgot-password-para mb-2"
            component={"h1"}
            variant="h5"
          >
            {ForgotPasswordMessage.CREATE_NEW_PASSWORD_MESSAGE}
          </Typography>

          <List className="ml-3">
            {requirements.map((requirement, index) => (
              <ListItem key={index} sx={{ alignItems: "center", padding: 0 }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  {validations[index] ? (
                    <CheckCircleIcon sx={{ color: "green" }} />
                  ) : (
                    <RadioButtonUncheckedIcon sx={{ color: "gray" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  className="create-new-password-info"
                  sx={{ textAlign: "left" }}
                  primary={requirement}
                />
              </ListItem>
            ))}
          </List>

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword.password ? "text" : "password"}
            id="password"
            error={errors?.password ? true : false}
            helperText={errors?.password && errors.password.message}
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password", // Ensures password suggestion
              form: "reset-password-form", // Helps some browsers recognize the intent
            }}
            {...register("password", {
              required: "Password is required",
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("password")}
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm Password"
            type={showPassword.confirmPassword ? "text" : "password"}
            id="confirmpassword"
            error={!!errors?.confirmpassword}
            helperText={
              errors?.confirmpassword ? errors.confirmpassword.message : ""
            }
            autoComplete="new-password"
            {...register("confirmpassword", {
              required: "Confirm Password is required",
              validate: (value) =>
                value === password || "Passwords do not match",
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("ConfirmPassword")}
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {showPassword.confirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="btn-blue capitalize submit-button"
            disabled={isLoading || !allRequirementsMet}>
            Reset Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPasswordForm;
