import { Box, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";

import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import Avatar from "../../../../../components/Avatar";
import editUserPermissionIcon from '../../../../../styles/svg/editPermissionIcon.svg'
import { useSelector } from "react-redux";
import { isAdmin } from "../../../../../utils/constants";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";

function UsersMobile({ users, filterUserById, ownerId }) {

  const [selectedUserSearch] = useCustomSearchParams('search')
  const selectedUser = selectedUserSearch?.split(',')
  const currentUser = useSelector((state) => state.users.logInUser);

  const handleEditPermission = ()=>{
    // handle edit popup
    console.log("clicked")
  }

  return (
    <Box>
      {users?.map((ele) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => filterUserById(ele?.user_id)}
            bgcolor={
              selectedUser?.includes(String(ele?.user_id)) ? "#C5DCF3" : "rgb(242, 244, 246)"
            }
            p={"5px"}
            borderRadius={1}
            mb={1}
            className="d-flex justify-content-space-between "
          >
            <div className="d-flex align-items-center">
            <Avatar
              key={`user-avtar-${ele?.user_id}`}
              username={ele?.username}
              user_image={ele?.user_image}
              title={ele?.username}
              className={`header-avatar ${
                selectedUser?.includes(String(ele?.user_id)) ? "active-avtar-mbl" : ""
              }`}
              style={
                !hasImage(ele?.user_image) && {
                  bgcolor: ele?.profile_color?.background,
                  color: ele?.profile_color?.text,
                }
              }
            >
              {createAvtarName(ele?.username)}
            </Avatar>
            <Typography fontSize={13} fontWeight={400} className="ml-2">
              {ele?.username}
            </Typography>
            </div>
          {(ownerId == currentUser.user_id || isAdmin()) &&  (
            <img src={editUserPermissionIcon} alt="edit permission" onClick={handleEditPermission} className="pr-8"/>
          )}
          </Stack>
        );
      })}
    </Box>
  );
}

export default UsersMobile;
