import {
  Box,
  Button,
  Drawer,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";

import Download from "./Download";
import GlobalSearch from "../../../../components/Search";
import { useResponsive } from "../../../../hooks/useResponsive";
import filterIcon from "../../../../styles/svg/epic-icon.svg";
import User from "../../Grouping/components/User";
import Boards from "../../components/Filters/Boards";
import TeamFilter from "../../Grouping/components/TeamFilter";
import SelectDatePicker from "../../components/Filters/SelectDatePicker";
import {
  addAllBoard,
  searchCard,
  setDateRange,
} from "../../../../redux/SearchSlice";
import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import { useTeams } from "../../../../utils/reactQuery/teams";
import { fixedDigitsAfterDecimal } from "../../../../utils/helpers";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useUsers } from "../../../../utils/reactQuery/users";
import ToggleInput from "../../../../components/ToggleInput";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function Header() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam, removeSearchParam] =
    useCustomSearchParams("txt");
  const workHours = useSelector((state) => state.search.totalWorkHours);
  const dispatch = useDispatch();
  const isMobile = useResponsive("down", "md");
  const trigger = useScrollTrigger();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const Oldboard = searchParams.get("board");
  const Olddate = searchParams.get("date");
  const { teams, isLoading: teamIsLoading } = useTeams();
  const [filterdUser, setFilterUser] = useState([]);
  const [userParams, setUserParams] = useCustomSearchParams("uid");
  const { users, isLoading: usersIsLoading } = useUsers();

  useEffect(() => {
    setInputValue(searchParam || "");
    dispatch(searchCard(searchParam || ""));
  }, []);

  useEffect(() => {
    if (teams && users) {
      if (userParams && userParams[0]) {
        userParams?.split(",").forEach((element) => {
          dispatch(
            setGroupUser(
              users.data.data.responseData.find(
                (user) => user.user_id === element * 1
              )
            )
          );
        });
      }
    }
  }, [teamIsLoading, usersIsLoading]);

  const handleChange = (event) => {
    const value = event.target.value.trimStart();
    setInputValue(value);
    debouncedSearch(value);
  };
  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
      setSearchParam("txt", value);
    }, 300)
  ).current;

  const removeSearchParams = (paramsToRemove) => {
    const newSearchParams = new URLSearchParams(searchParams);
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });
    setSearchParams(newSearchParams);
  };

  const clearFilters = () => {
    dispatch(addAllBoard([]));
    dispatch(setAllGroupUser([]));
    dispatch(setDateRange(null));
    removeSearchParams(["date", "board"]);
  };

  useEffect(() => {
    const date = JSON.parse(Olddate);
    if (date && isMobile) {
      const obj = {
        startDate: new Date(date.startDate),
        endDate: new Date(date.endDate),
        key: "selection",
      };
      dispatch(setDateRange(obj));
    }
  }, []);

  useEffect(() => {
    setFilterUser(
      groupingUser
        .map((item) => item.users)
        .flat()
        .filter((el) => el.active)
    );
  }, [groupingUser]);

  useEffect(() => {
    if (
      filterdUser &&
      filterdUser.length &&
      userParams?.split(",").length != filterdUser
    ) {
      setUserParams(
        "uid",
        filterdUser.map((el) => el.user_id)
      );
    }
  }, [filterdUser]);

  return (
    <>
      {isMobile ? (
        <div
          className="pos-sticky mt-4 ml-4 mb-2"
          style={{ top: trigger ? "0px" : "50px" }}>
          <div className="d-flex mb-2 mr-3 justify-content-space-between align-items-center ">
            <Typography className="worklog_head">Worklogs</Typography>

            <span className="hours-header">
              {fixedDigitsAfterDecimal(workHours)} hours
            </span>
          </div>

          <div className="d-flex justify-content-space-between">
            <Download />
            <img
              src={filterIcon}
              alt="filter"
              style={{ marginRight: "8px" }}
              onClick={() => setFilterDrawer(true)}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-space-between mb-4">
          <div className="d-flex">
            <p className="worklog_head mr-4">Worklogs</p>
            <span className="hours-header">
              {fixedDigitsAfterDecimal(workHours)} hours
            </span>
          </div>
        </div>
      )}
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}>
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}>
            <Box p={1}>
              <Stack justifyContent={"end"} alignItems={"end"}>
                {(Oldboard || groupingUser.length > 0 || Olddate) && (
                  <Button
                    size="small"
                    endIcon={<ClearIcon />}
                    className="mr-2 capitalize"
                    onClick={clearFilters}>
                    Clear Filter
                  </Button>
                )}
              </Stack>

              <div className="mt-2">
                <p className="filter-text">Select Date:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <SelectDatePicker />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Users:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <User user={groupingUser.map((item) => item?.users).flat()} />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Board:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <Boards />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select team:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  {teams && (
                    <TeamFilter
                      team={teams}
                      user={groupingUser.map((item) => item?.users).flat()}
                    />
                  )}
                </div>
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default Header;
