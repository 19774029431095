import React, { useState } from "react";
import DeleteIcon from "../../../../../styles/svg/delete-board.svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { deleteBoard } from "../../../../../sevices/apiBoards";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import useToastPromise from "../../../../../hooks/useToast";
import { DeleteIcone } from "../../../../../components/UI/Icons";

function Delete({ id }) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [archiveDialog, setArchiveDialog] = useState(false);
    const { showToastPromise } = useToastPromise();

  const handleCloseDialog = () => {
    setArchiveDialog(false);
  };

  const { mutate: deleteMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(deleteBoard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      setLoading(false);
      //toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    setLoading(true);
    deleteMutate(id);
  };
  return (
    <>
      <div
           className="cursor-pointer"
           onClick={() => setArchiveDialog(true)}
      >
        <DeleteIcone height={"24px"} width={"24px"}/>
      </div>
      {archiveDialog && (
        <ConfirmationDialog
          open={archiveDialog}
          handleClose={handleCloseDialog}
          message={"Are you sure to delete this board?"}
          callback={callback}
          loading={loading}
          btnTitle={"Delete"}
          title={"Delete Board"}
          btnColor="var(--dark-light-error, #B71D18)"
        />
      )}
    </>
  );
}

export default Delete;
