import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

export default function PieChartComp({ data, totalHours }) {
  const COLORS = [
    "#8979FF",
    "#6FD195",
    "#537FF1",
    "#FFAE4C",
    "#3CC3DF",
    "#FF928A",
  ];
  const labelPositions = [];

  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    // ✅ Completely skip label & lines if slice is less than 5%
    if (percent < 0.05) return null;

    const RADIAN = Math.PI / 180;
    const sliceX = cx + outerRadius * Math.cos(-midAngle * RADIAN);
    const sliceY = cy + outerRadius * Math.sin(-midAngle * RADIAN);
    const bendX = cx + (outerRadius + 70) * Math.cos(-midAngle * RADIAN);
    const bendY = cy + (outerRadius + 70) * Math.sin(-midAngle * RADIAN);
    const labelX = bendX > cx ? bendX + 70 : bendX - 70;
    let labelY = bendY;

    if (labelPositions.length > 0) {
      const lastY = labelPositions[labelPositions.length - 1];
      if (Math.abs(labelY - lastY) < 24) {
        labelY = lastY + 24;
      }
    }
    labelPositions.push(labelY);

    return (
      <>
        {/* ✅ Only render lines if percent >= 5% */}
        {percent >= 0.05 && (
          <>
            <line
              x1={sliceX}
              y1={sliceY}
              x2={bendX}
              y2={bendY}
              stroke={COLORS[index % COLORS.length]}
              strokeWidth={1}
            />
            <line
              x1={bendX}
              y1={bendY}
              x2={labelX}
              y2={labelY}
              stroke={COLORS[index % COLORS.length]}
              strokeWidth={1}
            />
            <text
              x={labelX}
              y={labelY}
              fill="black"
              textAnchor={labelX > cx ? "start" : "end"}
              dominantBaseline="central"
              fontSize={12}>
              {data[index].name} ({data[index].value})
            </text>
          </>
        )}
      </>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <PieChart width={700} height={600}>
        <Pie
          data={data} // ✅ Keep all slices, just remove label & lines for small ones
          cx="50%"
          cy="50%"
          label={renderCustomLabel}
          outerRadius={180}
          innerRadius={80}
          fill="#8884d8"
          labelLine={false}
          dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* Center text */}
        <text
          x="50%"
          y="45%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={16}
          fontWeight="bold"
          fill="#333">
          TOTAL
        </text>
        <text
          x="50%"
          y="53%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={18}
          fontWeight="bold"
          fill="#333">
          {Number.isInteger(totalHours)
            ? totalHours
            : parseFloat(totalHours.toFixed(2))}
        </text>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
