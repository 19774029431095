import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateWorkflowStep } from "../../../../../sevices/apiCards";
import toast from "react-hot-toast";
import "./ChangeStatus.scss";
import { useWorkflowById } from "../../../../../utils/reactQuery/workflows";
import useToastPromise from "../../../../../hooks/useToast";

export default function ChangeStatus({
  currentStatusId,
  cardId,
  checkCondition,
  card,
}) {
  const [status, setStatus] = React.useState("");
  const [cachedData, setCachedData] = React.useState("");
  const queryClient = useQueryClient();
    const { showToastPromise } = useToastPromise();

  const {
    board: { board_id, workflow_id },
  } = card?.data?.data?.responseData;

  const { data } = useWorkflowById(workflow_id, board_id);

  React.useEffect(() => {
    if (data) {
      setCachedData(data);
    }
  }, [data]);

  React.useEffect(() => {
    setStatus(currentStatusId);
  }, [currentStatusId]);

  const { mutate: updateMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateWorkflowStep, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });
      queryClient.invalidateQueries({
        queryKey: ["cards", String(board_id)],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: ["watching"]
      })
      queryClient.invalidateQueries({
        queryKey: ["mineCards"]
      })
      //toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("An error occurred while updating task", error);
      //toast.error(error.response.data.message);
      setStatus(currentStatusId);
    },
  });

  const handleChange = (event) => {
    updateMutate({
      card_id: cardId,
      workflow_step_id: event.target.value,
    });
    setStatus(event.target.value);
  };

  const showStatus =
    cachedData &&
    cachedData?.data?.data?.responseData?.filter(
      (el) => el.workflow_step_id == currentStatusId
    )[0];

  return (
    <Box sx={{}}>
      {(checkCondition) ? (
        <FormControl size="small" className="card-status-dropdown-parent">
          <Select
            labelId="demo-simple-select-label"
            value={showStatus && status}
            onChange={handleChange}
            className="card-status-dropdown"
          >
            {cachedData &&
              cachedData?.data?.data?.totalRecord &&
              cachedData?.data?.data?.responseData
                ?.sort((a, b) => a.step_sequence - b.step_sequence)
                .map((el, index) => (
                  <MenuItem className="uppercase" value={el.workflow_step_id}>
                    {el.status}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      ) : (
        <div className="project-status d-flex justify-content-center align-items-center">
          <button className="project-status-button">
            {showStatus && showStatus?.status}
          </button>
          <div className="project-status-icon">
            {/* <KeyboardArrowDownIcon/> */}
          </div>
        </div>
      )}
    </Box>
  );
}
