import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmationDialog({
  message,
  open,
  handleClose,
  callback,
  loading,
  title,
  btnTitle,
  btnColor = "#1B3E61",
  hideConfirmationText = false,
  className = "",
  maxWidth="470px",
  dialogHeight = "90vh",
}) {
  const handleClickOpen = () => {
    // setOpen(true);
  };
  const isMobile = useResponsive("down", "md");
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={className}
        sx={{
          height: dialogHeight,
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: maxWidth,
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}
          className="confirmation-title"
        >{`${hideConfirmationText ? title : "Confirmation: " + title}`}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: isMobile ? 0 : 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="confirmation-content">
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ justifyContent: "center", display: "flex" }}
            className="confirmation-contentText"
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", mb: 3 }}
          className="confirmation-dialogAction"
        >
          <Button
            onClick={callback}
            disabled={loading}
            variant="contained"
            sx={{
              bgcolor: btnColor,
              mr: "10px",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              textTransform: "capitalize",
              minWidth: "130px",
            }}
            className="confirmation-action-btn"
          >
            {btnTitle}
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1B3E61",
              border: "1px solid #1B3E61",
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              opacity: "0.6",
              minWidth: "130px",
            }}
            onClick={handleClose}
            disabled={loading}
            className="confirmation-action-btn"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
