import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { archiveBoard } from "../../../../../sevices/apiBoards";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { objectToFormData } from "../../../../../utils/helpers";
import useToastPromise from "../../../../../hooks/useToast";
import { Tooltip } from "@mui/material";
import { TooltipName } from "../../../../../utils/Enums";

function Status({ isArchive, id }) {
  const [status, setStatus] = useState(isArchive);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [archiveDialog, setArchiveDialog] = useState(false);
    const { showToastPromise } = useToastPromise();

  const { mutate: archiveMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(archiveBoard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      queryClient.invalidateQueries({
        queryKey: ["boardName", id],
      });
      setStatus((data) => !data);
      setLoading(false);
      //toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const handleCloseDialog = () => {
    setArchiveDialog(false);
  };

  const callback = () => {
    // console.log("isArchive", isArchive);
    const data = objectToFormData({ is_archive: isArchive, board_id: id });
    setLoading(true);
    archiveMutate({ id, data });
  };
  return (
    <div className="w-100 d-flex justify-content-center">
      <Tooltip title={isArchive ? TooltipName.MANAGE_BOARD.UNARCHIVE_THIS_BOARD : TooltipName.MANAGE_BOARD.ARCHIVE_THIS_BOARD}>
      <label className="switch">
        <input
          type="checkbox"
          value={status}
          checked={status}
          onChange={(e) => {
            setArchiveDialog(true);
          }}
        />
        <div className="slider"></div>
      </label>
      </Tooltip>
      {archiveDialog && (
        <ConfirmationDialog
          open={archiveDialog}
          handleClose={handleCloseDialog}
          message={
            !isArchive
              ? "Are you sure you want to restore the board ?"
              : "Are you sure you want to archive this board? Archiving the board will hide it from view and move it to the archive section. You can always restore it later if needed. Please confirm your action."
          }
          callback={callback}
          loading={loading}
          btnTitle={!isArchive ? "Restore" : "Archive"}
          title={!isArchive ? "Restore Board" : "Archive Board"}
        />
      )}
    </div>
  );
}

export default Status;
