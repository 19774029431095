import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isAdmin } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import EditTitle from "../../../board/components/Kanban/EditTitle";
import { Tooltip } from "@mui/material";
import AssignCard from "../../../board/components/Kanban/AssignCard";
import CustomizedProgressBars from "../../../board/components/progressBar/ProgressBar";
import DeleteCard from "../../../board/components/Kanban/DeleteCard";
import checkMark from "../../../../styles/svg/checkMark.svg";
import CustomDateFormatter from "../../../board/components/dateTimeFormateConverter/dateTimeFormateConverter";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";
import EditIcon from "../../../../styles/svg/edit-title.svg";
import moment from "moment";
import mediumPriorityIcon from "../../../../styles/svg/medium_priority.svg";
import highPriorityIcon from "../../../../styles/svg/high_priority.svg";
import lowPriorityIcon from "../../../../styles/svg/low_priority.svg";
import criticalPriorityIcon from "../../../../styles/svg/critical_priority.svg";
import CardViewTooltip from "../../../board/components/details/sidebar/components/subTask/CardViewTooltip";
import { TooltipName } from "../../../../utils/Enums";
import { EditIcone } from "../../../../components/UI/Icons";

function KanbanCard({
  project: card,
  onDragEnd,
  index,
  id,
  callback: refetch,
}) {
  const { id: boardId } = useParams();

  const [project, setProject] = useState(card);
  const [setMultipleSearchParams] = useSetMultipleParams();
  const [collapsed, setCollapsed] = useState(true);
  const currentUser = useSelector((state) => state.users.logInUser);
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", boardId]);
  const [isEditing, setIsEditing] = useState(false);
  const tooltipName = `${project?.card?.assigned_to_user?.first_name || ""} ${project?.card?.assigned_to_user?.last_name || ""
    }`;
  const firstName = project?.card?.assigned_to_user?.first_name[0];
  const lastName = project?.card?.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const boardPermission = project?.board_permission;

  useEffect(() => {
    if (card) setProject(card);
  }, [card]);

  const handleDetails = (e) => {
    setMultipleSearchParams([
      { name: "card_id", value: project?.card?.card_id },
      { name: "brd_id", value: project?.card?.board_id },
    ]);
  };  

  const checkOwnerOrAdmin = isAdmin() ||
    currentUser?.user_id == project?.board_admin ||
    currentUser?.user_id == card?.board_admin ||
    boardPermission?.is_admin;

  const accessForDeleteCard = checkOwnerOrAdmin;
  // || boardPermission?.can_delete_card;

  const accessForAssign = checkOwnerOrAdmin || currentUser?.user_id == project?.card?.reporter || currentUser?.user_id == project?.card?.assigned_to_user_id;
  //  || project?.card?.is_card_collaborator;
  // boardPermission?.can_reassign_user;
  const accessForEditCard = checkOwnerOrAdmin || currentUser?.user_id == project?.card?.reporter || currentUser?.user_id == project?.card?.assigned_to_user_id;
  //  boardPermission?.can_edit_card;
  const accessForChangeStatus = checkOwnerOrAdmin || currentUser?.user_id == project?.card?.reporter || currentUser?.user_id == project?.card?.assigned_to_user_id;
  // || boardPermission?.can_update_status;

  const formatTimeFromNow = (createdDate) => {
    const newDate = moment.utc(createdDate);
    const localDate = newDate?.local().toDate();

    const now = moment();
    const diffInMinutes = now.diff(localDate, "minutes");
    const diffInHours = now.diff(localDate, "hours");
    const diffInDays = now.diff(localDate, "days");
    const diffInMonths = now.diff(localDate, "months");
    const diffInYears = now.diff(localDate, "years");
    if (diffInMinutes === 0) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} Min${diffInMinutes === 1 ? "" : "s"} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} Hr${diffInHours === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} Day${diffInDays === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 365) {
      return `${diffInMonths} Month${diffInMonths === 1 ? "" : "s"} ago`;
    } else {
      return `${diffInYears} Year${diffInYears === 1 ? "" : "s"} ago`;
    }
  };

  const openEditField = (e) => {
    e.stopPropagation();
    accessForEditCard && setIsEditing(true);
  };

  const openSubtask = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    setMultipleSearchParams([{ name: "card_id", value: id }]);
  };

  return (
    <>
      <Tooltip title={project?.board_name}>
        <div
          className="card-style cursor-pointer display-card-icons"
          // className={
          //   project.card.parent_card_id && !project.is_completed
          //     ? "card-style-blur cursor-pointer"
          //     : "card-style cursor-pointer"
          // }
          draggable={accessForChangeStatus}
          onDragEnd={(e) => {
            onDragEnd(e, project?.card);
          }}
          onClick={handleDetails}
        >
          {project?.card?.parent_card_id && (
            <Tooltip title={TooltipName.OPEN_PARENT_TASK_DETAIL}>
              <span className="related_time parent-title-id mb-2">
                {`${project?.key}`?.toUpperCase()}-
                {project?.card?.parent_card_id}
              </span>
            </Tooltip>
          )}
          <div className="d-flex justify-content-space-between">
            <div className="d-flex align-items-center">
              <div className="avatar-container">
                {project?.assigned_user_last_seen && (
                  <Tooltip
                    title={
                      <CustomDateFormatter
                        date={project?.assigned_user_last_seen}
                      />
                    }
                    arrow
                  >
                    <img
                      src={checkMark}
                      alt="checkMark"
                      className="cursor-pointer checkMark-position"
                    />
                  </Tooltip>
                )}
                <div>
                  <AssignCard
                    tooltipName={tooltipName}
                    project={project?.card}
                    name={name}
                    accessForAssign={accessForAssign}
                    cardId={project?.card?.card_id}
                    isShowAssignedCards={true}
                  />
                </div>
              </div>
              <div className="ml-1">
                <p className="d-flex align-items-center">
                  <Tooltip title={`priority: ${project?.card?.priority}`}>
                    <img
                      className="priority_icon"
                      src={
                        project?.card?.priority === "Critical"
                          ? criticalPriorityIcon
                          : project?.card?.priority === "High"
                          ? highPriorityIcon
                          : project?.card?.priority === "Medium"
                          ? mediumPriorityIcon
                          : project?.card?.priority === "Low"
                          ? lowPriorityIcon
                          : ""
                      }
                      alt="priority"
                    />
                  </Tooltip>
                  <p className="card-id-number">
                    {`${project?.key}`?.toUpperCase()}-{project?.card?.card_id}
                  </p>
                </p>
                <p className="related_time">
                  {formatTimeFromNow(project?.card?.date_created)}
                </p>
              </div>
            </div>
            {/* <div className="d-flex align-items-center">
              {accessForEditCard && (
                <Tooltip title={TooltipName.KANBAN_CARD.EDIT_TASK_TITLE}>
                <div  className="cursor-pointer edit-icon">
                <EditIcone className="kanban-card-edit-icon"/>
                </div>
                </Tooltip>
              )}
              {accessForDeleteCard && (
                <DeleteCard
                  className={"delete-icon"}
                  cardId={project?.card?.card_id}
                  boardId={boardId}
                  kanbanCardDelete={true}
                  DeleteIconStyle={'kanban-card-delete-icon'}
                />
              )}
            </div> */}
          </div>
          <div className="subtasks subtasks-uline ml-44">
            {project?.sub_tasks?.length > 0 &&
              project?.sub_tasks?.map((subtask) => (
                // <div
                //   key={subtask?.card_id}
                //   className="subtask d-flex align-items-center justify-content-space-between "
                // >
                //   <div className="w-70">
                //     <span className="related_time">
                //       {`${project?.key}`?.toUpperCase()}-{subtask?.card_id}
                //     </span>
                //   </div>
                //   <div className="related_time w-38">
                //     {subtask?.total_hours_spent !== null
                //       ? subtask?.total_hours_spent
                //       : 0}
                //     /
                //     {subtask?.allocated_hours !== null
                //       ? subtask?.allocated_hours
                //       : 0}
                //   </div>
                //   <div className="related_time w-23 d-flex justify-content-end">
                //     {subtask?.allocated_hours && subtask?.total_hours_spent
                //       ? Math?.round(
                //         (subtask?.total_hours_spent /
                //           subtask?.allocated_hours) *
                //         100
                //       )
                //       : 0}
                //     %
                //   </div>
                // </div>

                <Tooltip
                  title={
                    <CardViewTooltip
                      project={subtask}
                      boardName={project?.key}
                    />
                  }
                  arrow
                  placement="top"
                  enterNextDelay={1100}
                  disableInteractive
                  componentsProps={{
                    tooltip: {
                      sx: {
                        // backgroundColor: "grey",
                        // color: "white",
                        width: "320px",
                        maxWidth: "320px",
                        padding: 0,
                        borderRadius: 2,
                        boxShadow: 4,
                      },
                    },
                  }}
                  className="subtask-tooltip"
                >
                  <div
                    key={subtask?.card.card_id}
                    className="subtask d-flex align-items-center justify-content-space-between "
                  >
                    <div
                      className="w-70"
                      onClick={(event) =>
                        openSubtask(event, subtask?.card.card_id)
                      }
                    >
                      <span className="related_time">
                        {project?.key}-{subtask?.card.card_id}
                      </span>
                    </div>
                    <div className="related_time w-38">
                      {subtask?.total_hours_spent !== null
                        ? subtask?.total_hours_spent
                        : 0}
                      /
                      {subtask?.card.allocated_hours !== null
                        ? subtask?.card.allocated_hours
                        : 0}
                    </div>
                    <div className="related_time w-23 d-flex justify-content-end">
                      {subtask?.card.allocated_hours &&
                      subtask?.total_hours_spent
                        ? Math.round(
                            (subtask?.total_hours_spent /
                              subtask?.card.allocated_hours) *
                              100
                          )
                        : 0}
                      %
                    </div>
                  </div>
                </Tooltip>
              ))}
          </div>
          <div
            className={`mt-2 ${accessForEditCard ? "cursor-text" : ""}`}
            onClick={openEditField}
          >
            <EditTitle
              project={project}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isCardTitle={true}
              onClick={openEditField}
            />
          </div>
          {collapsed ? null : (
            <div>
              <strong>Description: </strong>
              {project?.card?.description}
              <br />
            </div>
          )}

          <div className="d-flex flex-wrap">
            {project?.labels?.[0] != "" && (
              <div className="d-flex flex-wrap">
                {project?.labels?.map((el) => (
                  <div className="card-level-tag mr-1">
                    <p className="card-level-tag-text">{el}</p>
                  </div>
                ))}
              </div>
            )}

            {project?.card?.epic && (
              <div className="d-flex">
                <div className="card-epic-tag mr-1">
                  <p className="card-epic-tag-text">
                    {project?.card?.epic?.epic_name}
                  </p>
                </div>
              </div>
            )}
          </div>
          <CustomizedProgressBars
            allocatedHours={project?.card?.allocated_hours}
            hoursSpent={
              project?.total_hours_spent ? project?.total_hours_spent : 0
            }
            showOnCardOnBoard={true}
          />
        </div>
      </Tooltip>
    </>
  );
}

export default KanbanCard;
