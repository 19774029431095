import React, { useEffect, useState } from "react";
import {
  DateRange as DateRangeMobile,
  defaultStaticRanges,
  DefinedRange,
} from "react-date-range";


import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch } from "react-redux";
import { setDateRange } from "../../../../redux/SearchSlice";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../../hooks/useResponsive";
import CustomDateRangePicker from "./CustomDateRangePicker";

function DateRange({ onClose, customStaticRanges }) {
  const [Olddate, updateParams, removeSearchParam] =
    useCustomSearchParams("date");
  const dispatch = useDispatch();
  const dateRange = useSelector((state) => state.search.dateRange);
  const date = JSON.parse(Olddate);
  const isMobile = useResponsive("down", "md");

  const [showCustomComponent, setShowCustomComponent] = useState(false);
  const [state, setState] = useState([
    dateRange
      ? dateRange
      : {
        startDate: date ? new Date(date.startDate) : new Date(),
        endDate: date ? new Date(date.endDate) : new Date(),
        key: "selection",
      },
  ]);

  const setData = () => {
    dispatch(setDateRange(state[0]));
    updateParams("date", JSON.stringify(state[0]));
    onClose();
  };

  const handleCustomDateChange = (item) => {
    setState([item.selection]);
  }

  const handleShowCustomComponent = () => {
    setShowCustomComponent(true)
  }

  const renderStaticRangeLabel = (range) => {
    if (range.label === 'Custom') {
      return (
        <span className={`${showCustomComponent ? "rdrStaticRangeSelected custom-active-color" : ""}`} onClick={handleShowCustomComponent}>
          {range.label}
        </span>
      );
    }
    return range.label;
  };

  const handleChange = (item) => {
    const { startDate, endDate } = item.selection;

    if (startDate.getTime() !== endDate.getTime() && startDate.toISOString() !== endDate.toISOString()) {
      setState([item.selection]);
      dispatch(setDateRange(item.selection));
      updateParams("date", JSON.stringify(item?.selection));
      setShowCustomComponent(false);
      onClose();
    } else {
      setState([item.selection]);
      setShowCustomComponent(true);

      window.dispatchEvent(new Event("resize"));
    }
  };

  return (
    <div className="d-grid">
      <div className={`d-flex worklog-date-filter ${isMobile ? "reverse-filter-section" : ""}`}>
        <div>
          {
            ((isMobile && !showCustomComponent) || !isMobile) && (
              <DefinedRange
                onChange={handleChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
                staticRanges={[...defaultStaticRanges.filter((defRange) => defRange.label !== "Last Week"), ...customStaticRanges]}
                renderStaticRangeLabel={renderStaticRangeLabel}
              />
            )
          }

          {
            showCustomComponent && (
              <div className={`d-flex align-items-center ${isMobile ? "mt-2" : ""}`}>
                <Button
                  onClick={() => {
                    dispatch(setDateRange(null));
                    onClose();
                    removeSearchParam("date");
                  }}
                  className="reset-date-picker"
                >
                  Reset
                </Button>
                <Button
                  onClick={setData}
                  className="submit-date-picker"
                  variant="contained"
                >
                  Apply
                </Button>
              </div>
            )}
        </div>

        {showCustomComponent && (
          <CustomDateRangePicker
            state={state}
            handleCustomDateChange={handleCustomDateChange}
          />
        )}
      </div>
      {/* )} */}
    </div>
  );
}

export default DateRange;
