import React from 'react'
import { useAssets } from '../utils/reactQuery/domainAssets';

function TenantSiteName() {
   const {  company_name } = useAssets();
   
  return (
    <div>
      <h1 className="uppercase" style={{ color: "#1b3e61" }}>
        {company_name ? company_name : window.location.hostname.split('.')[0]}
      </h1>
    </div>
  );
}

export default TenantSiteName