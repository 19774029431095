import React, { useState } from "react";
import Epic from "../details/sidebar/components/epic/epic";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCard } from "../../../../sevices/apiCards";
import { useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import { useBoardName } from "../../../../utils/reactQuery/boards";
import useToastPromise from "../../../../hooks/useToast";

export default function ChangeMilestone({ project, listView = false, isChangable = true, canChangeMilestone = false }) {
  const [showEpic, setShowEpic] = useState(false);
  const queryClient = useQueryClient();
  const { id: boardId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const milestone = searchParams.get("milestone");
  const { boardName } = useBoardName(boardId);
  const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id;
  const currentUserIdVariable = currentUserId();
  const { showToastPromise } = useToastPromise();

  const handleShowEpic = () => {
    if (isChangable) {
      if (
        isAdmin() ||
        boardOwnerId == currentUserIdVariable || canChangeMilestone
        // project?.assigned_to_user_id == currentUserIdVariable ||
        // project?.reporter == currentUserIdVariable
      ) {
        setShowEpic(true);
      }
    }

  };
  const { mutate: updateCardmutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateCard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, String(project.card_id)],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, project?.board_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, String(boardId)],
      });
      queryClient.invalidateQueries({
        queryKey: ["milestone_history", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        predicate: (query) => query?.queryKey[0] === "mineCards", // Match only queries that start with 'user'
      });
      //toast.success(res?.data?.message);
      setShowEpic(false);
    },
    onError: (error) => {
      console.error("Error in update Milestone", error);
      //toast.error(error?.response?.data?.message);
    },
  });
  const handleEpicBlur = (epic_id) => {
    setShowEpic(false);
    if (project?.epic?.epic_id != epic_id) {
      updateCardmutate({
        card_id: project?.card_id,
        data: {
          epic_id: epic_id,
        },
      });
    }
  };

  return (
    <div>
      {showEpic ? (
        <Epic
          handleEpicBlur={handleEpicBlur}
          cardId={project?.card_id}
          showEpic={showEpic}
          setShowEpic={setShowEpic}
          boardId={project?.board_id}
        />
      ) : (
        <div onClick={handleShowEpic}>
          {" "}
          <div>
            {" "}
            {project?.epic?.epic_name ? (
              <p
                className={`${listView
                    ? `card-epic-tag card-epic-tag-text`
                    : `due-date-input-none`
                  }`}
              >
                {project?.epic?.epic_name}
              </p>
            ) : (
              <p
                className={`${listView
                    ? `due-date-input-none listView-fontColor`
                    : `due-date-input-none`
                  }`}
              >
                None
              </p>
            )}{" "}
          </div>{" "}
        </div>
      )}
    </div>
  );
}
