import { Tooltip, IconButton } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";

const StartOver = ({ deleteMessages }) => {
  return (
    <Tooltip title="Start Over" placement="top">
      <IconButton onClick={deleteMessages} sx={{padding: "0px", marginRight:"10px"}}>
        <RestartAlt />
      </IconButton>
    </Tooltip>
  );
}

export default StartOver;
