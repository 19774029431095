import { Controller, useFormContext } from "react-hook-form";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

// ----------------------------------------------------------------------

const CustomRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    color: "#00C0E4", // Custom blue color for checked state
  },
}));

const CustomRadioIcon = () => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 20,
      height: 20,
      borderRadius: "50%",
      border: "2px solid #00C0E4", // Blue border when not selected
      backgroundColor: "transparent", // Transparent background
    }}
  />
);

const CustomCheckedIcon = () => (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: "#00C0E4", // Blue background when selected
      color: "white",
    }}
  >
    <CheckIcon style={{ fontSize: 18 }} />
  </span>
);

export function RHFRadioGroup({ name, options, row = false, className, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <RadioGroup {...field} row={row} {...other} className={className}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <CustomRadio
                    icon={<CustomRadioIcon />}
                    checkedIcon={<CustomCheckedIcon />}
                  />
                }
                label={option.label}
              />
            ))}
          </RadioGroup>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
