import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useParams, useSearchParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AssignCard from "./AssignCard";
import EditTitle from "./EditTitle";
import { isAdmin } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import CustomizedProgressBars from "../progressBar/ProgressBar";
import DeleteCard from "./DeleteCard";
import checkMark from "../../../../styles/svg/checkMark.svg";
import CustomDateFormatter from "../dateTimeFormateConverter/dateTimeFormateConverter";
import moment from "moment";
import EditIcon from "../../../../styles/svg/edit-title.svg";
import mediumPriorityIcon from "../../../../styles/svg/medium_priority.svg";
import highPriorityIcon from "../../../../styles/svg/high_priority.svg";
import lowPriorityIcon from "../../../../styles/svg/low_priority.svg";
import criticalPriorityIcon from "../../../../styles/svg/critical_priority.svg";
import CardViewTooltip from "../details/sidebar/components/subTask/CardViewTooltip";
import DueDate from "./DueDate";
import TodoChangeStatus from "./TodoChangeStatus";
import { useResponsive } from "../../../../hooks/useResponsive";
import { TooltipName } from "../../../../utils/Enums";
import { EditIcone } from "../../../../components/UI/Icons";

function KanbanCard({
  project,
  onDragEnd,
  boardName,
  index,
  id,
  status,
  isTodo = false,
  style = false,
  isAssignedUser = false
}) {
  const { id: boardId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [collapsed, setCollapsed] = useState(true);
  const currentUser = useSelector((state) => state.users.logInUser);
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", boardId]);
  const [isEditing, setIsEditing] = useState(false);
  const isMobile = useResponsive("down", "md");

  const tooltipName = `${project?.card?.assigned_to_user?.first_name || ""} ${project?.card?.assigned_to_user?.last_name || ""
    }`;
  const firstName = project?.card?.assigned_to_user?.first_name[0];
  const lastName = project?.card?.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const boardPermission = boardName?.board_permission ?? {};

  const handleDetails = (event) => {
    if (!isAssignedUser) {
      updateSearchParams("card_id", project?.card?.card_id);
    } else {
      event.stopPropagation();
    }
  };

  const openSubtask = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    updateSearchParams("card_id", id);
  };

  const checkOwnerOrAdmin = isAdmin() ||
    currentUser?.user_id == cachedData?.data?.data?.responseData?.owner_user_id ||
    boardPermission?.is_admin;

  const accessForDeleteCard =
    checkOwnerOrAdmin;
  //  || boardPermission?.can_delete_card;

  const accessDragCard =
    checkOwnerOrAdmin ||
    currentUser?.user_id == project?.card?.assigned_to_user_id ||
    currentUser?.user_id == project?.card?.reporter;
    //  || project?.card?.is_card_collaborator;
  //  || boardPermission?.can_update_status;

  const accessForEditCard = checkOwnerOrAdmin || currentUser?.user_id == project?.card?.reporter;
  // boardPermission?.can_edit_card;

  const isAssignee = currentUser?.user_id == project?.card?.assigned_to_user_id

  const accessForAssign = checkOwnerOrAdmin || currentUser?.user_id == project?.card?.reporter || isAssignee || project?.card?.is_card_collaborator;
  //  boardPermission?.can_reassign_user;

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };
  const formatTimeFromNow = (createdDate) => {
    const newDate = moment.utc(createdDate);
    const localDate = newDate?.local().toDate();

    const now = moment();
    const diffInMinutes = now.diff(localDate, "minutes");
    const diffInHours = now.diff(localDate, "hours");
    const diffInDays = now.diff(localDate, "days");
    const diffInMonths = now.diff(localDate, "months");
    const diffInYears = now.diff(localDate, "years");

    if (diffInMinutes === 0) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} Min${diffInMinutes === 1 ? "" : "s"} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} Hr${diffInHours === 1 ? "" : "s"} ago`;
    } else if (diffInDays <= 31) {
      return `${diffInDays} Day${diffInDays === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 365) {
      return `${diffInMonths} Month${diffInMonths === 1 ? "" : "s"} ago`;
    } else {
      return `${diffInYears} Year${diffInYears === 1 ? "" : "s"} ago`;
    }
  };

  const openEditField = (e) => {
    e.stopPropagation();
    accessForEditCard && setIsEditing(true);
  };

  return (
    <Draggable
      key={id.toString()}
      draggableId={project?.card?.card_id?.toString()}
      index={index}
      isDragDisabled={!accessDragCard || isTodo || isAssignedUser}
    >
      {(provided, snapshot) => (
        <>
          <div
            className="card-style display-card-icons"
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={handleDetails}
            {...(style ? { style } : {})}
          >
            {project?.card?.parent_card_id && !isAssignedUser && (
              <Tooltip title={TooltipName.OPEN_PARENT_TASK_DETAIL}>
                <span className="related_time parent-title-id mb-2 cursor-pointer">
                  {boardName?.key || project?.key}-
                  {project?.card?.parent_card_id}
                </span>
              </Tooltip>
            )}

            <div className="d-flex justify-content-space-between">
              <div
                className={`d-flex align-items-center ${
                  isAssignedUser ? "reverse-order-icons" : ""
                }`}
              >
                <div className="avatar-container">
                  {project?.assigned_user_last_seen && !isAssignedUser && (
                    <Tooltip
                      title={
                        <CustomDateFormatter
                          date={project?.assigned_user_last_seen}
                        />
                      }
                      arrow
                    >
                      <img
                        src={checkMark}
                        alt="checkMark"
                        className="cursor-pointer checkMark-position"
                      />
                    </Tooltip>
                  )}
                  {!isAssignedUser && (
                    <div>
                      <AssignCard
                        tooltipName={tooltipName}
                        project={project?.card}
                        name={name}
                        accessForAssign={accessForAssign}
                        cardId={project?.card?.card_id}
                        isShowAssignedCards={true}
                        isTodo={isTodo}
                        accessForQuickTask={
                          project?.card?.todo_column !== "Accepted" &&
                          (checkOwnerOrAdmin ||
                            currentUser?.user_id == project?.card?.reporter)
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="ml-1">
                  <p className="d-flex align-items-center">
                    <Tooltip title={`priority: ${project?.card?.priority}`}>
                      <img
                        className="priority_icon cursor-pointer"
                        src={
                          project?.card?.priority === "Critical"
                            ? criticalPriorityIcon
                            : project?.card?.priority === "High"
                            ? highPriorityIcon
                            : project?.card?.priority === "Medium"
                            ? mediumPriorityIcon
                            : project?.card?.priority === "Low"
                            ? lowPriorityIcon
                            : ""
                        }
                        alt="priority"
                      />
                    </Tooltip>
                    <p className="card-id-number cursor-pointer">
                      {boardName?.key || project?.key}-{project?.card?.card_id}
                    </p>
                  </p>
                  {!isAssignedUser && (
                    <p className="related_time">
                      {formatTimeFromNow(project?.card?.date_created)}
                    </p>
                  )}
                </div>
              </div>
              {isAssignedUser && (
                <p className={`related_time assigned-user-created-time`}>
                  {formatTimeFromNow(project?.card?.date_created)}
                </p>
              )}
              {/* {!isTodo && (
                <div className="d-flex align-items-center">
                  {accessForEditCard && !isMobile && !isAssignedUser && (
                    <Tooltip title={TooltipName.KANBAN_CARD.EDIT_TASK_TITLE}>
                            <div
                         onClick={openEditField}
                        className="cursor-pointer edit-icon"
                        >
                        <EditIcone className="kanban-card-edit-icon"/>
                        </div>
                    </Tooltip>
                  )}
                  {accessForDeleteCard && !isMobile && !isAssignedUser && (
                    <DeleteCard
                      className={"delete-icon"}
                      cardId={project?.card?.card_id}
                      boardId={boardId}
                      kanbanCardDelete={true}
                    DeleteIconStyle={'kanban-card-delete-icon'}
                    />
                  )}
                </div>
              )} */}
              {isTodo && (
                <TodoChangeStatus
                  status={project?.card?.todo_column}
                  cardId={project?.card?.card_id}
                  boardId={boardId}
                  checkOwnerOrAdmin={checkOwnerOrAdmin}
                  isAssignee={isAssignee}
                  creator={currentUser?.user_id == project?.card?.reporter}
                />
              )}
            </div>
            <div className="subtasks subtasks-uline ml-44">
              {project?.sub_tasks?.length > 0 &&
                project?.sub_tasks?.map((subtask) => (
                  <Tooltip
                    title={
                      <CardViewTooltip
                        project={subtask}
                        boardName={boardName?.key || project?.key}
                      />
                    }
                    arrow
                    placement="top"
                    enterNextDelay={1100}
                    disableInteractive
                    componentsProps={{
                      tooltip: {
                        sx: {
                          // backgroundColor: "grey",
                          // color: "white",
                          width: "320px",
                          maxWidth: "320px",
                          padding: 0,
                          borderRadius: 2,
                          boxShadow: 4,
                        },
                      },
                    }}
                    className="subtask-tooltip"
                  >
                    <div
                      key={subtask?.card.card_id}
                      className="subtask d-flex align-items-center justify-content-space-between "
                    >
                      <div className="w-70">
                        <span
                          className="related_time cursor-pointer"
                          onClick={(event) =>
                            openSubtask(event, subtask?.card.card_id)
                          }
                        >
                          {boardName?.key || project?.key}-
                          {subtask?.card.card_id}
                        </span>
                      </div>
                      <div className="related_time w-38">
                        {subtask?.total_hours_spent !== null
                          ? subtask?.total_hours_spent
                          : 0}
                        /
                        {subtask?.card.allocated_hours !== null
                          ? subtask?.card.allocated_hours
                          : 0}
                      </div>
                      <div className="related_time w-23 d-flex justify-content-end">
                        {subtask?.card.allocated_hours &&
                        subtask?.total_hours_spent
                          ? Math.round(
                              (subtask?.total_hours_spent /
                                subtask?.card.allocated_hours) *
                                100
                            )
                          : 0}
                        %
                      </div>
                    </div>
                  </Tooltip>
                ))}
            </div>

            <div
              className={`mt-2 ${accessForEditCard ? "cursor-text" : ""}`}
              onClick={openEditField}
            >
              <EditTitle
                project={project}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isCardTitle={true}
              />
            </div>
            {collapsed ? null : (
              <div>
                <strong>Description: </strong>
                {project?.card?.description}
                <br />
              </div>
            )}

            <div className="d-flex flex-wrap">
              {project?.labels?.[0] != "" && (
                <div className="d-flex flex-wrap">
                  {project?.labels.map((el) => (
                    <div className="card-level-tag mr-1">
                      <p className="card-level-tag-text">{el}</p>
                    </div>
                  ))}
                </div>
              )}

              {project?.card?.epic && (
                <div className="d-flex">
                  <div className="card-epic-tag mr-1">
                    <p className="card-epic-tag-text">
                      {project?.card?.epic?.epic_name}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {isTodo ? (
              project?.card.todo_column === "Open" ? (
                <DueDate date={project?.card?.due_date} />
              ) : (
                ""
              )
            ) : (
              <CustomizedProgressBars
                allocatedHours={project?.card?.allocated_hours}
                hoursSpent={
                  project?.total_hours_spent ? project?.total_hours_spent : 0
                }
                showOnCardOnBoard={true}
              />
            )}
          </div>
        </>
      )}
    </Draggable>
  );
}

export default KanbanCard;
