import { styled, Switch } from "@mui/material";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 43, // Updated width
  height: 25, // Updated height
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 18, // Thumb expands slightly when active
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(18px)", // Adjusted for new width
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3, // Adjusted padding for new size
    "&.Mui-checked": {
      transform: "translateX(18px)", // Adjust translation for switch movement
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1b3e61",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 19, // Adjusted thumb width for balance
    height: 19, // Adjusted thumb height
    borderRadius: "50%", // Make it circular
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 25 / 2, // Half of height for rounded effect
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
