import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import groupPlusIcon from "../../../../../../../styles/svg/group-plus-icon.svg";
import groupMinusIcon from "../../../../../../../styles/svg/group-minus-icon.svg";
import { useWorkflowById } from "../../../../../../../utils/reactQuery/workflows";
import FieldCreateEditCard from "../../../../Kanban/FieldCreateEditCard";
import { useCustomSearchParams } from "../../../../../../../hooks/useCustomSearchParams";
import { createCard } from "../../../../../../../sevices/apiCards";
import AssignSubtask from "./AssignSubtask";
import ListSubtasks from "./ListSubtasks";
import {
  useCards,
  useSubtasks,
} from "../../../../../../../utils/reactQuery/cards";
import { Tooltip } from "@mui/material";
import CardViewTooltip from "./CardViewTooltip";
import { currentUserId, isAdmin } from "../../../../../../../utils/constants";
import { useBoardName } from "../../../../../../../utils/reactQuery/boards";
import useToastPromise from "../../../../../../../hooks/useToast";
import { TooltipName } from "../../../../../../../utils/Enums";

const SubTask = ({ cardDetail, boardKey, boardPermission }) => {
  const [workflowId, setWorkflowId] = useState(null);
  const [workflowSteps, setWorkflowSteps] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [milestone] = useCustomSearchParams("milestone");
  const [boardId] = useCustomSearchParams("brd_id");
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { boardName } = useBoardName(id ?? boardId);

  const [subtaskCards, setSubtaskCards] = useState(null);
  const [openCreateSubtask, setOpenCreateSubtask] = useState(true);
  const { subtasks } = useSubtasks(cardDetail?.card_id);
  const { assigned_to_user_id, reporter, epic_id, is_card_collaborator } = cardDetail || {};
  const { showToastPromise } = useToastPromise();

  useEffect(() => {
    if (subtasks) {
      setSubtaskCards(subtasks?.data?.data?.responseData);
    }
  }, [subtasks]);
  useEffect(() => {
    if (cardDetail) {
      setWorkflowId(cardDetail?.board?.workflow_id);
    }
  }, [cardDetail]);

  const { data } = useWorkflowById(workflowId, workflowId);

  useEffect(() => {
    if (data) {
      setWorkflowSteps(
        data?.data?.data?.responseData?.find(
          (stepSequence) => stepSequence?.step_sequence === 1
        )
      );
    }
  }, [data]);

  const handleCreateCard = () => {
    setOpen((prev) => !prev);
    if (open === true) {
      setOpenCreateSubtask(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "btnClicked") {
      event.key !== "btnClicked" && event.preventDefault();
      let data = {};
      if (milestone || epic_id) {
        data = {
          title,
          workflow_step_id: workflowSteps?.workflow_step_id,
          board_id: id ?? boardId,
          parent_card_id: cardDetail?.card_id,
          epic_id: milestone ? milestone : epic_id ? epic_id : "",
        };
      } else {
        data = {
          title,
          workflow_step_id: workflowSteps?.workflow_step_id,
          board_id: id ?? boardId,
          parent_card_id: cardDetail?.card_id,
        };
      }
      if (title) {
        createMutate(data);
      } else {
        setOpen(false);
      }
    }
  };

  const { mutate: createMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(createCard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id ?? boardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, `${cardDetail?.card_id}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`subTasks`, cardDetail?.card_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`watching`],
      });
      setTitle("");
      //toast.success(res?.data?.message);
      setOpen(false);
    },
    onError: (error) => {
      console.error("An error occurred while creating task", error);
      //toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      {
        !cardDetail?.parent_card_id && (
          <div className="subtask-box">
            <div className="subtask-heading">
              <p>Sub tasks</p>
              {(isAdmin() ||
                boardName?.data?.data?.responseData?.is_owner ||
                boardPermission?.is_admin ||
                currentUserId() === assigned_to_user_id ||
                currentUserId() === reporter 
                // || is_card_collaborator
                // boardPermission?.can_create_card
              ) && <div onClick={handleCreateCard} className="cursor-pointer">
                <Tooltip title={open ? TooltipName.CARD_DETAIL.CLOSE_CREATE_SUBTASK : TooltipName.CARD_DETAIL.OPEN_CREATE_SUBTASK}>
                  <img src={open ? groupMinusIcon : groupPlusIcon} alt="" />
                  </Tooltip>
                </div>
              }

            </div>

            {subtaskCards && subtaskCards.length > 0 && boardKey && (
              <div>
                {subtaskCards?.map((item) => (
                  <Tooltip
                    title={
                      <CardViewTooltip project={item} boardName={boardKey} />
                    }
                    arrow
                    placement="top"
                    enterNextDelay={1100}
                    disableInteractive
                    componentsProps={{
                      tooltip: {
                        sx: {
                          // backgroundColor: "grey",
                          // color: "white",
                          width: "320px",
                          maxWidth: "320px",
                          padding: 0,
                          borderRadius: 2,
                          boxShadow: 4,

                        },
                      },
                    }}
                    className="subtask-tooltip"
                  >
                    <div>
                      <ListSubtasks
                        key={item?.id}
                        card={item}
                        boardKey={boardKey}
                      />
                    </div>
                  </Tooltip>
                ))}
              </div>
            )}
          </div>
        )}
      {open && (
        <div>
          {openCreateSubtask && (
            <div className="subtask-box-input-field">
              <FieldCreateEditCard
                handleKeyDown={handleKeyDown}
                setTitle={setTitle}
                setCreateBtnOpen={null}
                minRows={1}
                subTask={true}
                setOpenCreateSubtask={setOpenCreateSubtask}
              />
            </div>
          )}

          <AssignSubtask cardDetail={cardDetail} />
        </div>
      )}
    </>
  );
};

export default SubTask;
