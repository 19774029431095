import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditTitle from "../EditTitle";
import checkMark from "../../../../../styles/svg/checkMark.svg";
import CheckBox from "../../../../../styles/svg/id-icon.svg";
import CustomizedProgressBars from "../../progressBar/ProgressBar";
import CustomDateFormatter from "../../dateTimeFormateConverter/dateTimeFormateConverter";
import AssignCard from "../AssignCard";
import { useSearchParams } from "react-router-dom";
import OpenCardDetail from "../openCardDetail";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useResponsive } from "../../../../../hooks/useResponsive";
import moment from "moment";
import mediumPriorityIcon from "../../../../../styles/svg/medium_priority.svg";
import highPriorityIcon from "../../../../../styles/svg/high_priority.svg";
import lowPriorityIcon from "../../../../../styles/svg/low_priority.svg";
import criticalPriorityIcon from "../../../../../styles/svg/critical_priority.svg";
import CreateCard from "../CreateCard";
import Todo from "../../../../../components/ToDo";
import MobileTodo from "./MobileTodo";

function MobileKanban({ workflow, data, cards, createCard = false }) {
  const [selctedWorkflow, setSelectedWorkflow] = useState(1);
  const [projects, setProjects] = useState(cards);
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const [searchParams, setSearchParams] = useSearchParams();
  const cardId = searchParams.get("card_id");
  const filterUser = searchParams.get("search")?.split(',');
  const epicSearch = searchParams.get("milestone");
  const isGeneric = searchParams.get("is_generic");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const searchText = useSelector((state) => state.search.searchText);
  const trigger = useScrollTrigger();
  const isMobile = useResponsive("down", "sm");
  const isTab = useResponsive("up", "sm");
  const priorityOrder = {
    Critical: 1,
    High: 2,
    Medium: 3,
    Low: 4,
  };

  useEffect(() => {
    const openStep = workflow
      .sort((a, b) => b.step_sequence - a.step_sequence)
      .find((item) => item.status === "Open" || item.status === "In-Progress");

    if (openStep) {
      setSelectedWorkflow(openStep.workflow_step_id);
    } else {
      let selected = workflow.find((item) => item.step_sequence == 1);
      setSelectedWorkflow(selected.workflow_step_id);
    }
  }, []);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const handleDetails = (id) => {
    updateSearchParams("card_id", id);
  };

  useEffect(() => {
    // Define the debounce delay (e.g., 300 milliseconds)
    const debounceDelay = 1000;
    let allFilteredCards = cards;

    // Create a debounced version of the filter function
    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards.filter(
          (el) =>
            (el?.card.title
              ? `${el.card.title}`.toLowerCase().includes(searchText)
              : "") ||
            (el.card.assigned_to_user?.first_name
              ? `${el.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );

        setProjects(allFilteredCards);
      }
    }, debounceDelay);

    // if (filterUser) {
    //   allFilteredCards = allFilteredCards.filter(
    //     (el) => el.card.assigned_to_user_id == filterUser
    //   );
    //   // setProjects(filteredCardsByUser);
    // }
    if(filterUser){
      const numericFilterUser = filterUser?.map(Number);
      allFilteredCards = allFilteredCards?.filter((el)=> numericFilterUser?.includes(el?.card?.assigned_to_user_id))
    }

    if (epicSearch) {
      allFilteredCards = allFilteredCards.filter(
        (item) => epicSearch == item.card.epic_id
      );
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards.filter(
        (item) => item.card.epic_id == null
      );
    }

    if (searchText) {
      // Call the debounced filter function whenever searchText, id, or cards change
      debouncedFilter();
    }

    setProjects(allFilteredCards);

    return () => {
      // Cleanup the debounce function on component unmount
      debouncedFilter.cancel();
    };
  }, [searchText, filterUser, epicSearch, cards, noMileStoneSearch]);

  const formatTimeFromNow = (createdDate) => {
    const newDate = moment.utc(createdDate);
    const localDate = newDate.local().toDate();

    const now = moment();
    const diffInMinutes = now.diff(localDate, "minutes");
    const diffInHours = now.diff(localDate, "hours");
    const diffInDays = now.diff(localDate, "days");
    const diffInMonths = now.diff(localDate, "months");
    const diffInYears = now.diff(localDate, "years");
    if (diffInMinutes === 0) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} Min${diffInMinutes === 1 ? "" : "s"} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} Hr${diffInHours === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} Day${diffInDays === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 365) {
      return `${diffInMonths} Month${diffInMonths === 1 ? "" : "s"} ago`;
    } else {
      return `${diffInYears} Year${diffInYears === 1 ? "" : "s"} ago`;
    }
  };

  return (
    <Box pt={0}>
      {projects && cardId && <OpenCardDetail cardsOnBoard={projects} />}
      <Box
        className="mobile_board_head"
        sx={{
          top: trigger
            ? milestone
              ? "169px"
              : "62px"
            : milestone
            ? "218px"
            : "111px",
        }}
      >
        <FormControl fullWidth variant="outlined" sx={{ position: "sticky" }}>
          <InputLabel>Select Workflow</InputLabel>
          <Select
            sx={{
              "& .MuiSelect-select": {
                padding: "10px",
                fontSize: "14px",
              },
            }}
            defaultValue=""
            label="Select Workflow"
            value={selctedWorkflow}
            onChange={(event) => setSelectedWorkflow(event.target.value)}
          >
            <MenuItem
              className="mobile_select_workflow"
              key={"todo"}
              value={"todo-column"}
            >
              Quick Tasks
            </MenuItem>
            {workflow
              .sort((a, b) => a.step_sequence - b.step_sequence)
              .map((ele) => (
                <MenuItem
                  className="mobile_select_workflow"
                  key={ele.workflow_step_id}
                  value={ele.workflow_step_id}
                >
                  {ele.status}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {selctedWorkflow === "todo-column" ? <MobileTodo projects={projects} /> :(
      <Box className="mobile_main_cont">
        {createCard && (
          <div className="width-100">
            <CreateCard stage={selctedWorkflow} />
          </div>
        )}
        {projects?.filter((ele) =>
          isGeneric == "true"
            ? ele.generic_step_id == selctedWorkflow
            : ele.card.workflow_step_id == selctedWorkflow
        ).length == 0 && (
          <Stack
            minHeight={200}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <p
              style={{
                border: "2px dashed",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              No task found
            </p>
          </Stack>
        )}

        {projects
          ?.filter((ele) =>
            isGeneric == "true"
              ? ele.generic_step_id == selctedWorkflow
              : ele.card.workflow_step_id == selctedWorkflow
          )
          .sort(
            (a, b) =>
              priorityOrder[a.card.priority] - priorityOrder[b.card.priority]
          )
          .map((project, idx) => (
            <Box
              key={idx}
              onClick={() => handleDetails(project.card.card_id)}
              sx={{
                flex: !isMobile && "1 1 45%",
                // margin: "10px",
                minWidth: isTab ? "280px" : "100%",
                flexGrow: 1,
                maxWidth: isTab ? "49.5%" : "100%",
                height: "auto",
                display: "flex",
              }}
            >
              <div className="card-style">
                {project?.card?.parent_card_id && (
                  <span className="related_time parent-title-id mb-2">
                    {project.key}-{project?.card?.parent_card_id}
                  </span>
                )}
                <div className="d-flex align-items-center position_relative">
                  <AssignCard
                    tooltipName={"tooltipName"}
                    project={project.card}
                    name={"name"}
                    accessForAssign={"accessForAssignOrEditTitle"}
                    cardId={project?.card.card_id}
                  />
                  {project.assigned_user_last_seen && (
                    <img
                      src={checkMark}
                      alt="checkMark"
                      className="cursor-pointer seen-mobile"
                    />
                  )}
                  <div className="ml-1">
                    <p className="card-id-number">
                      {project.key}-{project.card.card_id}
                    </p>
                    <p className="related_time">
                      {formatTimeFromNow(project.card.date_created)}
                    </p>
                  </div>
                  <img
                    className="priority_icon"
                    src={
                      project?.card.priority === "Critical"
                        ? criticalPriorityIcon
                        : project?.card.priority === "High"
                        ? highPriorityIcon
                        : project?.card.priority === "Medium"
                        ? mediumPriorityIcon
                        : project?.card.priority === "Low"
                        ? lowPriorityIcon
                        : ""
                    }
                    alt="priority"
                  />
                </div>
                <div className="subtasks subtasks-uline ml-44">
                  {project?.sub_tasks?.length > 0 &&
                    project.sub_tasks.map((subtask) => (
                      <div
                        key={subtask?.card_id}
                        className="subtask d-flex align-items-center justify-content-space-between "
                      >
                        <div className="w-70">
                          <span className="related_time">
                            {project.key}-{subtask.card_id}
                          </span>
                        </div>
                        <div className="related_time w-38">
                          {subtask?.total_hours_spent !== null
                            ? subtask?.total_hours_spent
                            : 0}
                          /
                          {subtask?.allocated_hours !== null
                            ? subtask?.allocated_hours
                            : 0}
                        </div>
                        <div className="related_time w-23 d-flex justify-content-end">
                          {subtask?.allocated_hours &&
                          subtask?.total_hours_spent
                            ? Math.round(
                                (subtask?.total_hours_spent /
                                  subtask?.allocated_hours) *
                                  100
                              )
                            : 0}
                          %
                        </div>
                      </div>
                    ))}
                </div>

                <div>
                  <div className="d-flex mt-2">
                    <Typography>{project.card.title}</Typography>
                  </div>
                  {project?.card?.epic && (
                    <div className="d-flex">
                      <div className="card-epic-tag mr-1">
                        <p className="card-epic-tag-text">
                          {project?.card?.epic?.epic_name}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <CustomizedProgressBars
                    allocatedHours={project.card.allocated_hours}
                    hoursSpent={
                      project?.total_hours_spent
                        ? project?.total_hours_spent
                        : 0
                    }
                    showOnCardOnBoard={true}
                  />
                </div>
              </div>
            </Box>
          ))}
      </Box>
      )}
    </Box>
  );
}

export default MobileKanban;
