import React from 'react'
import { DateRange } from 'react-date-range'

const CustomDateRangePicker = ({state,handleCustomDateChange, ...rest}) => {
    return (
        <>
            <DateRange
                ranges={state}
                onChange={handleCustomDateChange}
                rangeColors={["#4CAF50"]}
                {...rest}

            />
        </>
    )
}

export default CustomDateRangePicker