import React, { useState,useRef,useLayoutEffect } from "react";
import { Droppable } from "react-beautiful-dnd";

import KanbanCard from "./KanbanCard";
import CreateCard from "./CreateCard";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import { TooltipName } from "../../../../utils/Enums";
import { Tooltip } from "@mui/material";

function KanbanColumn({
  name,
  stage,
  projects,
  onDragEnter,
  onDragEnd,
  boardName,
  id,
}) {
  const [mouseIsHovering, setMouseIsHovering] = useState(false);
  const [milestone] = useCustomSearchParams("milestone");
  const scrollRef = useRef(null); 
  const [scrollToCardId, setScrollToCardId] = useState(null)
  const generateKanbanCards = () => {
    return projects.map((project, index) => (
      <KanbanCard
        status={name}
        boardName={boardName}
        key={project?.card?.card_id}
        id={project?.card?.card_id}
        project={project}
        onDragEnd={onDragEnd}
        index={index}
      />
    ));
  };

  // const checkOwnerOrAdmin = isAdmin() || boardName?.owner_user_id === currentUserId() || boardName?.board_permission?.is_admin;

  const columnStyle = {
    backgroundColor: mouseIsHovering ? "#f0eeee" : "rgba(242, 244, 246, 1)",
  };


  useLayoutEffect(() => {
    if (scrollToCardId && scrollRef.current) {
      const cardElements = scrollRef.current.children;

      // Find the index of the target card
      const cardIndex = projects.findIndex(
        (project) => project?.card?.card_id === scrollToCardId
      );

      if (cardIndex !== -1) {
        const targetCard = cardElements[cardIndex];
        if (targetCard) {
          const offsetTop = targetCard.offsetTop;
          scrollRef.current.scrollTo({
            top: offsetTop - 250, // Adjust for padding if needed
            behavior: "smooth",
          });
        }
      }
    }
  }, [scrollToCardId, projects]);




  return (
    <div className="card-column">
      <div className="coloumn-header">
        <h4 className="coloumn-header-h4">
          <p className="coloumn-header-text">{name}</p>
           <Tooltip title={TooltipName.TICKETS_IN_COLUMN}>
          <span className="coloumn-header-length">{projects.length}</span>
          </Tooltip>
        </h4>
      </div>
      <div
        style={columnStyle}
        className="coloumn-body"
      // onDragEnter={(e) => {
      //   setMouseIsHovering(true);
      //   onDragEnter(e, stage);
      // }}
      // onDragLeave={(e) => setMouseIsHovering(false)}
      >
        <Droppable droppableId={id.toString()}>
          {(provided) => (
            <div
              className="coloumn-all-cards"
              style={{
                height: `calc(94vh - ${milestone ? "340px" : "270px"} )`,
              }}
              // ref={provided.innerRef}
              ref={(node) => {
                provided.innerRef(node);
                scrollRef.current = node; // Attach ref for scrolling
              }}
              {...provided.droppableProps}
            >
              {/* {
                (checkOwnerOrAdmin || boardName?.board_permission?.can_create_card) && ( */}
                  <CreateCard stage={stage} newCreatedCardId={setScrollToCardId}/>
                {/* )
              } */}
              {generateKanbanCards()}
              {/* <CreateCard stage={stage} /> */}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <br />
        {/* <Button size="small">Create New</Button> */}
      </div>
    </div>
  );
}
export default KanbanColumn;
