import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLebels } from "../../../../../../utils/reactQuery/boards";
import { createAndUpdateLabel } from "../../../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToastPromise from "../../../../../../hooks/useToast";

export default function ComboBox({
  labels,
  boardId,
  cardId,
  setShowLebel,
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [selectedLabels, setSelectedLabels] = React.useState(labels.map((item) => item.label));
  const [prevLabels, setPrevLabels] = React.useState([...selectedLabels]);

  const queryClient = useQueryClient();
  const { isLoading, error, lebels: lebelsOpt } = useLebels(boardId);
  const { showToastPromise } = useToastPromise();
  const [isError, setIsError] = React.useState(false)
  const options = lebelsOpt?.data?.data?.responseData.map((item) => item.label) || [];

  const { mutate: createAndUpdateLabelMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(createAndUpdateLabel, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, boardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, boardId.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`watching`],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      // setLoading(false);
      // console.log(res);
      //    reset();
      //toast.success(res.data.message);
      setShowLebel(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error.response.data.message);
    },
  });


  // Function to add new label on Enter or Blur
  const handleLabelSubmission = () => {
    if (inputValue.trim() !== "") {
      setSelectedLabels((prevLabels) => {
        const updatedLabels = [...new Set([...prevLabels, inputValue.trim()])]; // Remove duplicates
        setInputValue(""); // Clear input field
        return updatedLabels;
      });
    }

    const uniqueLabels = [...new Set(selectedLabels)];

    // Make API call with selected labels
    if (JSON.stringify(prevLabels) !== JSON.stringify(selectedLabels)) {
      createAndUpdateLabelMutate({
        card_id: cardId,
        data: { labels: [...uniqueLabels] },
      });

      setShowLebel(false);
      // Update previous labels after API call
      setPrevLabels([...uniqueLabels]);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    const uniqueLabels = [...new Set(newInputValue)]; // Remove duplicates
    setSelectedLabels(uniqueLabels);
  };

  const enterCallback = (event) => {
    if (event.key === "Enter" && !isError) {
      event.preventDefault(); // Prevent form submission
      handleLabelSubmission();
    }
  };

  React.useEffect(() => {
    handleLabelSubmission()
  }, [selectedLabels])

  const handleBlur = (e) => {
    if (!isError) {
      handleLabelSubmission();
    }
    setTimeout(() => {
      setShowLebel(false);
    }, 300)
  };

  const handleChange = (event, newInputValue) => {
    if (newInputValue?.length > 30) {
      setIsError(true);
    } else {
      setIsError(false);
      setInputValue(newInputValue);
    }
  };

  return (
    <Autocomplete
      multiple
      autoFocus
      options={options}
      value={selectedLabels}
      inputValue={inputValue}
      onChange={handleInputChange}
      onInputChange={handleChange}
      onKeyDown={enterCallback}
      onBlur={handleBlur}
      id="size-small-outlined-multi"
      size="small"
      sx={{
        width: "100%",
        height: "32px",
        marginRight: "0px"
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoFocus: true,
            }}
            error={!!isError}
            helperText={isError && "Label Should be Less Than 30 character"}
            style={{
              backgroundColor: "white",
              zIndex: 10
            }}
            sx={{
              "& .MuiFormHelperText-root": {
                position: "absolute",
                bottom: "-20px", // Moves error below the field
                background: "white", // Prevents overlay issues
                zIndex: 1301, // Ensures it's above the dropdown
              },
            }}
          />
        );
      }}
    />
  );
}