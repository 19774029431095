import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { updateBoard } from "../../../../../sevices/apiBoards";
import useToastPromise from "../../../../../hooks/useToast";

function Accessibility({ isPrivate, params }) {
  const [status, setStatus] = useState(isPrivate);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [privatDialog, setPrivatDialog] = useState(false);
    const { showToastPromise } = useToastPromise();

  const handleCloseDialog = () => {
    setPrivatDialog(false);
  };

  const { mutate: archiveMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateBoard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      setStatus((data) => !data);
      setLoading(false);
      //toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    const data = { is_private: !isPrivate };
    setLoading(true);
    archiveMutate({ board_id: params.row.board_id, data });
  };

  return (
    <>
      {privatDialog && (
        <ConfirmationDialog
          open={privatDialog}
          handleClose={() => setPrivatDialog(false)}
          message={`Are you sure to change the Board visibility?`}
          callback={callback}
          loading={loading}
          btnTitle={isPrivate ? "Public" : "Private"}
          title={"Change Board Visibility"}
        />
      )}
      <div className="w-100 d-flex justify-content-center">
        <label class="custom-checkbox">
          <input
            type="checkbox"
            checked={status}
            onClick={(event) => {
              setPrivatDialog(true);
            }}
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </>
  );
}

export default Accessibility;
