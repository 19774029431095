import React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useDispatch } from "react-redux";

import SearchIcon from "../../styles/svg/search-icon.svg";
import { searchCard } from "../../redux/SearchSlice";
import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  marginLeft: 0,

  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: " rgba(145, 158, 171, 1)",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme, boardSearch }) => ({
  color: " rgba(145, 158, 171, 1)",
  width: "100%",
  borderRadius: "6px",
  border: "1px solid rgba(145, 158, 171, 0.12)",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    fontSize: "15px",

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: boardSearch ? "100%" : "11ch",
      height: "20px",
      "&:focus": {
        width: boardSearch ? "100%" : "11ch",
      },
    },
    "&::placeholder": {
      color: "#919EAB", // Placeholder color
    },
  },
}));

function GlobalSearch({searchText, handleChange, placeholder, boardSearch=false}) {
  // const dispatch = useDispatch();
  // const searchText = useSelector((state) => state.search.searchText);

  // function handleChange(event) {
  //   dispatch(searchCard(event.target.value));
  // }
  return (
    <div>
      <Search sx={{marginLeft:"0px !important"}}>
        <SearchIconWrapper>
          <img src={SearchIcon} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={placeholder}
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={handleChange}
          type="search"
          boardSearch={boardSearch}
        />
      </Search>
    </div>
  );
}

export default GlobalSearch;
