import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { truncateText } from "../utils/helpers";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({
  register,
  getValues,
  watch,
  fileChange,
  fileName,
  isMobile,
  name="file"
}) {
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      className={`width-100 accordion-allocation-board capitalize d-flex justify-content-flex-start ${
        isMobile ? `justify-content-center` : `white-button file-upload-button`
      }`}
      startIcon={!fileName && <CloudUploadIcon />}
    >
      {!fileName && selectedFiles.name && truncateText(selectedFiles.name, 12)}
      {!fileName && !selectedFiles.name
        ? "Upload file"
        : truncateText(fileName, 12)}
      <VisuallyHiddenInput
        id="input-file-field"
        multiple
        type="file"
        {...register(name)}
        onChange={(e) => {
          setSelectedFiles(e.target.files);
          fileChange(e);
        }}
      />
    </Button>
  );
}
