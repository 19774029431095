import React, { useState } from "react";
import { MenuItem, IconButton } from "@mui/material";
import { Delete, Clear } from "@mui/icons-material";

import {
  createAvtarName,
  differenceInDaysOrHours,
  hasImage,
} from "../../../../../utils/helpers";
import Avatar from "../../../../../components/Avatar";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { deleteBoardUsers } from "../../../../../sevices/apiBoards";
import { currentUserId, isAdmin } from "../../../../../utils/constants";
import { useSelector } from "react-redux";
import editUserPermissionIcon from '../../../../../styles/svg/edit_user.svg'
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import useToastPromise from "../../../../../hooks/useToast";
import { DeleteIcone, EditIcone } from "../../../../../components/UI/Icons";
function RemoveUser({
  user,
  ownerId,
  onClick,
  closeUserList,
  isSelected,
  isColabrotar = false,
  handleRemoveColabrotor,
  setOpenEditUserDialog,
  boardOwnerId
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [removeDialog, setRemoveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const currentUser = useSelector((state) => state.users.logInUser);
  const [selectedUserSearch, updateSearchParams, removeSearchParam] = useCustomSearchParams('search')
  const filterUser = selectedUserSearch?.split(',')
  const handleCloseDialog = () => {
    setRemoveDialog(false);
  };
  const { showToastPromise } = useToastPromise();

  const { mutate: removeMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(deleteBoardUsers, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boardUsers", id],
      });
      setLoading(false);
      //toast.success(res?.data?.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error?.response?.data?.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    setLoading(true);
    removeMutate({ board_id: id, user_id: user?.user_id });
  };

  const clearFilter = (e, user_id) => {
    e.stopPropagation();
    const newSelected = filterUser?.includes(String(user_id)) && filterUser?.filter((el) => el !== String(user_id))
    if (filterUser?.[0] === String(user_id) && filterUser?.length === 1) {
      removeSearchParam('search')
    } else {
      updateSearchParams('search', newSelected?.join(','))
    }

  };

  const openPopUp = (e) => {
    e.stopPropagation();
    setRemoveDialog(true);
  };

  const handleEditPermission = (event) => {
    // code for  open edit popup
    event.stopPropagation();
    setOpenEditUserDialog(user?.user_id);
  }

  function createUserName(fn, ln) {
    return `${fn} ${ln}`
  }
  const handleDeleteColabrotor = (userId) => {
    handleRemoveColabrotor(userId)
    handleCloseDialog();
  }

  return (
    <>


      {removeDialog && (
        <ConfirmationDialog
          open={removeDialog}
          handleClose={handleCloseDialog}
          message={isColabrotar ? "Are you sure to delete collaborotar" : "Are you sure to delete this user from board?"}
          callback={isColabrotar ? () => handleDeleteColabrotor(user?.user_id) : callback}
          loading={loading}
          btnTitle={"Delete"}
          title={isColabrotar ? "Delete collaborator" : "Delete User From Board"}
          btnColor="var(--dark-light-error, #B71D18);
"
        />
      )}
      <MenuItem
        key={`user-avtar-dropdown-${user?.user_id}`}
        onClick={() => {
          onClick();
          closeUserList();
        }}
        className={`board-users-list-menu ${isSelected ? "active-assign mb-2" : ""}`}
        onMouseEnter={() => setHoveredIndex(true)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        {
          isColabrotar ? <>
            <Avatar
              key={`user-avtar-${user?.user_id}`}
              username={createUserName(user?.user?.first_name, user?.user?.last_name)}
              user_image={user?.user?.user_image}
              title={createUserName(user?.user?.first_name, user?.user?.last_name)}
              className="user-avtar-dropdown user-avatar mr-8"
              style={
                !hasImage(user?.user?.user_image) && {
                  bgcolor: user?.user?.profile_color?.background,
                  color: user?.user?.profile_color?.text,
                }
              }
              isAdmin={(user?.is_admin || user?.user_id == boardOwnerId || (currentUserId() == user?.user_id && isAdmin()))}
            >
              {createAvtarName(createUserName(user?.user?.first_name, user?.user?.last_name))}
            </Avatar>
            <div>
              {createUserName(user?.user?.first_name, user?.user?.last_name)}
            </div>
          </>
            :
            <>
              <Avatar
                key={`user-avtar-${user?.user_id}`}
                username={user?.username}
                user_image={user?.user_image}
                title={user?.username}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(user?.user_image) && {
                    bgcolor: user?.profile_color?.background,
                    color: user?.profile_color?.text,
                  }
                }
                isAdmin={(user?.is_admin || user?.user_id == boardOwnerId || (currentUserId() == user?.user_id && isAdmin()))}
              >
                {createAvtarName(user?.username)}
              </Avatar>
              <div>
                {user?.username}
                <p
                  style={{
                    fontWeight: "400px",
                    fontSize: "14px",
                    color: "rgba(33, 43, 54, 0.6)",
                  }}
                >
                  {differenceInDaysOrHours(user?.last_seen)}
                </p>
              </div>
            </>
        }

        <div className="remove-user-delete-icon">

          {(ownerId == currentUser?.user_id || isAdmin()) && hoveredIndex && (
            <> {
              !isColabrotar && user?.active && (
                  <div className="mr-5" onClick={handleEditPermission}>
                    <EditIcone  height={"20px"} width={"20px"}/>
                  </div>
              )
            }
            <div onClick={openPopUp}>
              <DeleteIcone height={"24px"} width={"24px"}/>
            </div>
            </>
          )}
          {!isColabrotar && isSelected && <Clear onClick={(e) => clearFilter(e, user?.user_id)} />}
        </div >
      </MenuItem >
    </>
  );
}

export default RemoveUser;
