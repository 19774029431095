import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

import SidebarListItem from "./SidebarListItem";
import IsReporter from "./IsReporter";
import AssignedBoardIcon from "../../../styles/svg/assigned-board.svg";
import { isAdmin } from "../../../utils/constants";
// import searchIcon from "../../../style/svg/search-icon.svg"
import newSearchIcon from '../../../styles/svg/newSearchIcon.svg'
import SearchBoard from "../../../components/Header/SearchBoard";

function AccordionNav({
  heading,
  icon,
  listing,
  defaultExpanded,
  uniqueKey,
  expanded,
  onChange,
  isBoardSearch
}) {
  const [viewAssignedBoards, setAssigned] = useState(false);
  const [searchBar, setSearchBar] = useState({
    showSearchIcon: true,
    showSearchBar: false
  });
  const handleSearch = (e) => {
    e.stopPropagation()
    setSearchBar({
      showSearchIcon: false,
      showSearchBar: true,
    });
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      sx={{ boxShadow: "none" }}
      defaultExpanded={defaultExpanded}
      className="menu-Accordion"
    >
      <AccordionSummary
        expandIcon={(isBoardSearch && searchBar.showSearchBar) ? '' : <ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={`search-board-accordian background-color-0 menu-AccordionSummary`}
      >
        <div className="d-flex align-items-center">
          <img src={icon} alt="Not Found" className="side-bar-icon"/>
          {<span className="navigation-heading">{heading}</span>}
          {isBoardSearch && searchBar.showSearchIcon && <img src={newSearchIcon} className="ml-1 boards-search-icon" onClick={handleSearch}/>}
        </div>
        {
         isBoardSearch && searchBar.showSearchBar && <div><SearchBoard isBoardSearch={isBoardSearch} setSearchBar={setSearchBar}/></div>
        }
      </AccordionSummary>
      <AccordionDetails className="menu-AccordionDetails" key={uniqueKey}>
        {/* {isAdmin() && (
          // <div className="d-flex justify-content-space-between">
          //   <div className="d-flex align-items-center ml-30">
          //     <img src={AssignedBoardIcon} alt="Not Found" />
          //     {<span className="assigned-board">My Boards</span>}
          //   </div>
          //   <IsReporter
          //     viewAssignedBoards={viewAssignedBoards}
          //     setAssigned={(val) => setAssigned(val)}
          //   />
          // </div>
        )} */}

        {listing && listing.length ? (
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {viewAssignedBoards
              ? listing
                  .filter((el) => el.is_super_admin_and_board_user)
                  .map((board) => (
                    <SidebarListItem key={board.board_id} board={board} />
                  ))
              : listing.map((board) => (
                  <SidebarListItem key={board.board_id} board={board} />
                ))}
          </List>
        ) : (
          <Typography
            variant="caption"
            gutterBottom
            className="text-center color-white d-flex justify-content-center pl-0"
            sx={{ marginLeft: "-16px" }}
          >
            Boards Not Found
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionNav;
