import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  MenuItem,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import Field from "../../../../components/field/index";
import { useNavigate, useParams } from "react-router-dom";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { createCard } from "../../../../sevices/apiCards";
import toast from "react-hot-toast";
import useToastPromise from "../../../../hooks/useToast";
function Form({ onClose, workflowSteps }) {
  const queryClient = useQueryClient();
  //   const [apiError, setApiError] = React.useState("");
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const { id } = useParams();
  const isMobile = useResponsive("down", "md");
    const { showToastPromise } = useToastPromise();

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    workflow_step: yup.string().required("workflow step is required"),
  });

  const { register, handleSubmit, reset, getValues, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      workflow_step: workflowSteps?.sort(
        (a, b) => a.step_sequence - b.step_sequence
      )[0].workflow_step_id,
    },
  });

  const { errors } = formState;

  const { mutate: createMutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(createCard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      //toast.success(res.data.message);
      onClose();
    },
    onError: (error) => {
      console.error("An error occurred while creating task", error);
      //toast.error(error.response.data.message);
    },
  });

  const submit = (data) => {
    let newdata = {};
    if (milestone) {
      newdata = {
        title: data.title,
        workflow_step_id: data.workflow_step,
        board_id: id,
        epic_id: milestone ? milestone : "",
      };
    } else {
      newdata = {
        title: data.title,
        workflow_step_id: data.workflow_step,
        board_id: id,
      };
    }

    if (data.title) {
      createMutate(newdata);
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["milestone_history", milestone],
      });
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(submit)}
        noValidate
        sx={{
          padding: isMobile ? "0px" : "14px",
        }}
      >
        <Stack spacing={3}>
          {/* {apiError && <Alert severity="error">{apiError}</Alert>} */}
          <Textarea
            placeholder="What needs to be done?"
            size="lg"
            required
            id="title"
            name="title"
            minRows={4}
            // error={errors?.title ? true : false}
            // helperText={errors?.title && errors.title.message}
            error={!!errors?.title}
            {...register("title")}
          />
          {errors?.title && (
            <Box
              sx={{
                color: "error.main",
                fontSize: "12px",
                marginTop: "5px !important",
                paddingLeft: "12px",
              }}
            >
              {errors.title.message}
            </Box>
          )}
          <Field
            label={"Workflow_step"}
            required
            id="workflow_step"
            name="workflow_step"
            error={errors?.workflow_step ? true : false}
            helperText={errors?.workflow_step && errors.workflow_step.message}
            select
            defaultValue={
              workflowSteps?.sort(
                (a, b) => a.step_sequence - b.step_sequence
              )[0].workflow_step_id
            }
            {...register("workflow_step")}
          >
            {workflowSteps?.map((option) => (
              <MenuItem
                key={`${option.workflow_step_id}`}
                id={`${option.workflow_step_id}`}
                value={option.workflow_step_id}
              >
                {option.status}
              </MenuItem>
            ))}
          </Field>

          <Box
            sx={{
              alignSelf: "end",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#1B3E61",
                mr: "10px",
                fontSize: isMobile ? "14px" : "16px",
                padding: isMobile ? "5px 8px" : "10px 16px",
                fontWeight: "600",
                textTransform: "capitalize",
                minWidth: "130px",
              }}
              disabled={isLoading}
            >
              Create
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#1B3E61",
                border: "1px solid #1B3E61",
                textTransform: "capitalize",
                fontSize: isMobile ? "14px" : "16px",
                padding: isMobile ? "5px 8px" : "10px 16px",
                fontWeight: "600",
                opacity: "0.6",
                minWidth: "130px",
              }}
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default Form;
