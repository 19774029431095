import { useQuery } from "@tanstack/react-query";
import { checkLDAPConnection } from "../../sevices/apiSystemSettings";

export function useLDAPConnection() {
  const {
    isLoading,
    data,
    error,
    isError,
  } = useQuery({
    queryKey: ["LDAP-Connection"],
    queryFn: checkLDAPConnection,
  });

  return { isLoading, error, data, isError };
}