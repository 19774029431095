import { toast } from "react-hot-toast";

const useToastPromise = () => {
  const showToastPromise = async (apiFunc, data, successMessage = "Operation successful!", errorMessage = "Something went wrong!") => {
    // Dismiss any previous toasts before starting a new one
    toast.dismiss();
    // Show promise toast and return the API function promise
    try {
      const response = await toast.promise(apiFunc(data), {
        loading: "Processing...",
        success: (res) => {
          return res?.data?.message || successMessage;
        },
        error: (error) => {
          return error?.response?.data?.message || errorMessage;
        },
      });
      console.log("response", response);
      return response;
    } catch (error) {
      throw error; // Ensure the error propagates to onError
    }
  };

  return { showToastPromise };
};

export default useToastPromise;
