import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function FormDialog({ dialog,close, children }) {
  const isMobile = useResponsive("down", "md");
  return (
    <React.Fragment>
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        onClose={close}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: 1300,
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: "470px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}
          className="confirmation-title"
        >
          Worklog Missing
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: isMobile ? 0 : 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          //   className="confirmation-content"
          sx={{ padding: 0 }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default FormDialog;
