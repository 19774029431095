import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, InputAdornment, Stack, Typography } from "@mui/material";

import RHFTextField from "../../../components/RFH/RFHtextfield";
import useMutate from "../../../hooks/useMutate";
import { updateTenantWorkingHours } from "../../../sevices/apiSystemSettings";
import { useResponsive } from "../../../hooks/useResponsive";
import RHFToggle from "../../../components/RFH/RHFToggle";

function WorkingHours({ initialData }) {
  const [loading, setLoading] = useState(false);
  const { mutate } = useMutate(updateTenantWorkingHours, ['logo'], () =>
    setLoading(false)
  );
  const methods = useForm({
    defaultValues: initialData,
  });
  const isMobile = useResponsive("down", "md");
  // Watch form values for real-time changes (optional)
  const formValues = methods.watch();

  // Function to check if any field has a value
  const isAnyFieldFilled = () => {
    return Object.values(formValues).some(
      (value) => value !== undefined && value !== "" && value !== null
    );
  };

  const onSubmit = (formData) => {
    mutate(formData);
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="mt-32 form-section  ">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div>
              <Typography className="setting-heading mb-2  ml-0">
                Working Hours
              </Typography>

              <Stack
                mt={3}
                direction={isMobile ? "column" : "row"}
                spacing={2.5}
              >
                <RHFTextField
                  name="daily_worklog_hours"
                  label="Working Hours Per Day"
                  type="number"
                  sx={{
                    width: isMobile ? "100%" : "50%",
                    backgroundColor: "#fff",
                  }}
                />
              </Stack>
            </div>
            <div className="d-flex mt-32 align-items-center">
              <div className="mr-4">
                <Typography className="setting-heading  ml-0">
                  Remind Users to Enter Worklogs
                </Typography>
                <div className=" ldap-note ">
                  <Typography className="logo-note">
                    When enabled, users will see reminders to enter worklogs.
                  </Typography>
                </div>
              </div>
              <div className="ml-2">
                <RHFToggle name="daily_worklog_reminder"  />
              </div>
            </div>
            <Stack alignItems="end" className="mt-32">
              <Button
                variant="contained"
                type="submit"
                sx={{ padding: "8px 32px" }}
                className="theme-bg-color"
                disabled={loading || !isAnyFieldFilled()}
              >
                Update
              </Button>
            </Stack>
          </form>
        </div>
      </FormProvider>
    </>
  );
}

export default WorkingHours;
