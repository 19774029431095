import React from 'react'
import Column from './Column'
import './todo.scss';

function Todo({ name, stage, projects, height }) {
  return (
    <>
      <Column name={name} stage={stage} cards={projects} height={height} />
    </>
  );
}

export default Todo