import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useDispatch } from "react-redux";

import SearchIcon from "../../../../styles/svg/search-icon.svg";
// import { searchCard } from "../../redux/SearchSlice";
import { useSelector } from "react-redux";
import { useResponsive } from "../../../../hooks/useResponsive";

function SearchBoard({ onSearch, searchQuery }) {
  const isMobile = useResponsive("down", "md");
  const inputRef = React.useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchQuery]);
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    // height: "30px",
    borderRadius: theme.shape.borderRadius,
    //   backgroundColor: alpha(theme.palette.common.white, 0.15),
    //   "&:hover": {
    //     backgroundColor: alpha(theme.palette.common.white, 0.25),
    //   },
    marginLeft: 0,

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    color: " rgba(145, 158, 171, 1)",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: " rgba(145, 158, 171, 1)",
    width: "96%",
    borderRadius: "6px",
    border: "1px solid rgba(145, 158, 171, 0.12)",
    "& .MuiInputBase-input": {
      padding: isMobile ? "5px 5px 5px 0px" : theme.spacing(1, 1, 1, 0),

      fontSize: isMobile ? "13px" : "15px",

      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "20ch",
        height: "10px",
      },
      "&::placeholder": {
        color: "#919EAB", // Placeholder color
      },
    },
  }));
  function handleChange(event) {
    event.stopPropagation();
    event.preventDefault();
    onSearch(event.target.value);
  }
  return (
    <Search>
      <SearchIconWrapper>
        <img src={SearchIcon} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        value={searchQuery}
        onChange={handleChange}
        onKeyDown={(event) => event.stopPropagation()}
        type="search"
        // autoFocus={!isMobile}
        inputRef={inputRef}
      />
    </Search>
  );
}

export default SearchBoard;
