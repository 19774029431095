import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { IconButton } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";


import RHFTextField from "../RFH/RFHtextfield";
import TextEditor from "../../pages/board/components/textEditor/textEditor";
import InputFileUpload from "../FileUploadButton";
import { createTicket } from "../../sevices/osTicketApi";
import { DeleteIconFun, removeHtmlTagsAndEntities } from "../../utils/helpers";
import useMutate from '../../hooks/useMutate'
import DeleteIcon from "./../../styles/svg/adminWorkflowDelete.svg";
import useToastPromise from "../../hooks/useToast";
import {DeleteIcone} from "../UI/Icons.js";


export const FeedbackDialogContent = ({ schema, handleClose }) => {
  const [textContent, setTextContent] = useState('')
  const { register, getValues, watch, handleSubmit, setValue, formState: { errors }, ...methods } = useForm({ resolver: yupResolver(schema) });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { showToastPromise } = useToastPromise();
  const currentUser = useSelector((state) => state.users.logInUser);

  const { mutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(createTicket, data, `Thank you for submitting your request. We will get back to you via email at ${currentUser?.email}.`);
    },
    onSuccess: (res) => {
      handleClose();
    },

  });

  const onSubmit = async (formData) => {

    if (selectedFiles.length > 0) {
      const totalSize = selectedFiles.reduce((acc, file) => acc + (file?.size || 0), 0) / (1024 * 1024);

      if (totalSize > 10) {
        toast.error("Total file size must not exceed 10MB.");
        return;
      } else if (selectedFiles.length > 5) {
        toast.error("Cannot Upload More than 5 files.");
        return;
      }
    }

    const feedBackFormData = new FormData();

    feedBackFormData.append("message", formData?.message);
    feedBackFormData.append("subject", formData?.subject);
    feedBackFormData.append("taskboard", window.location.host)

    selectedFiles.forEach((file) => {
      feedBackFormData.append("attachments", file);
    });
    mutate(feedBackFormData);

  }

  const handleSelectedFiles = (event) => {

    let filesArray = Object.values(event?.target?.files || {});

    // Filter out already selected files
    filesArray = filesArray.filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });

    // Merge the new filtered files with the existing selected files
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...filesArray]);
  }

  const handleDeleteFile = (filename) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles?.filter((file) => file.name !== filename)
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id="feedbackForm" onSubmit={handleSubmit(onSubmit)}>
          <p className="text">Thanks for trying TaskBoard!<br />
            If you have <b>questions about features,</b> need <b>help solving a problem</b> using Taskboard, or found a bug, please use the form below for <b>support requests, bug reports, and suggestions.</b></p>
          <p className="text label-text">Subject</p>
          <RHFTextField name={"subject"} />
          <p className="text label-text">Description</p>
          <TextEditor
            id="message"
            name="message"
            description={""}
            setTextContent={(message) => {
              setValue("message", message)
              setTextContent(() => removeHtmlTagsAndEntities(message).trim())
            }
            }
            {...register("message")}
          />
          {
            errors?.message && textContent == "" &&
            <p className="error-message">
              {errors?.message?.message}
            </p>
          }
          <p className="text label-text">Attachments</p>
          <InputFileUpload
            watch={watch}
            register={register}
            getValues={getValues}
            name={"attachments"}
            fileChange={handleSelectedFiles}
          />
          <ul className="files-list">
            {
              selectedFiles?.length > 0 && selectedFiles?.map((file, index) => (

                <li key={`${file?.name}${index}`} className="d-flex justify-content-space-between text" >
                  {file?.name}
                  <IconButton onClick={() => handleDeleteFile(file?.name)}><DeleteIcone height="24px" width="24px"/></IconButton>
                </li>
              ))
            }
          </ul>
        </form>
      </FormProvider>
    </>
  )
}