import React, { useState } from "react";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import DeleteSvg from "../../../../styles/svg/delete-attachment.svg";
import DownloadSvg from "../../../../styles/svg/download-attachment.svg";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { deleteAttachments } from "../../../../sevices/apiBoards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getDownloadAttachment } from "../../../../sevices/apiBoards";
import useDownloader from "react-use-downloader";
import { getAPIBaseUrl } from "../../../../utils/constants";
import { handleDownloadAttachment } from "../../../../utils/downloadAttachments";
import useToastPromise from "../../../../hooks/useToast";
import { DeleteIcone } from "../../../../components/UI/Icons";

function ListItems({ attachment, refetch, isMobile }) {
  const { id } = useParams();
  // console.log("ListItems attachment", attachment);
  const getUrl = getAPIBaseUrl();
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();
  const queryClient = useQueryClient();
  const [openDeleteDialog, setopenDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);
    const { showToastPromise } = useToastPromise();

  function deleteAttachment() {
    setopenDeleteDialog(true);
  }

  const { mutate: deleteAttachmentMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(deleteAttachments, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`attachments-${id}`],
      });
      setLoading(false);
      // console.log(res);
      //    reset();
      //toast.success(res.data.message);
      handleClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error.response.data.message);
      setLoading(false);
      handleClose();
    },
  });

  function callback() {
    setLoading(true);
    deleteAttachmentMutate(attachment.board_attachment_id);
  }

  function handleClose() {
    setopenDeleteDialog(false);
  }

  const handleDownload = (e, attachment) => {
    // console.log("handleDownload attachment", attachment);
    const getResponse = getDownloadAttachment(attachment);
    // console.log("getResponse", getResponse);
    getResponse
      .then((res) => {
        handleDownloadAttachment(
          res.data.data.responseData.url,
          res.data.data.responseData.filename,
          download
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          handleClose={handleClose}
          message={"Are you sure to delete this attachment?"}
          callback={callback}
          loading={loading}
          btnTitle={"Delete"}
          title={"Delete Attachment"}
          btnColor="var(--dark-light-error, #B71D18)"
        />
      )}
      <ListItem
        key={attachment.board_attachment_id}
        sx={{
          width: "100%",
          backgroundColor: "white",
          border: "1px solid #919EAB40",
          marginBottom: "8px",
          borderRadius: "4px",
        }}
      >
        <Grid container spacing={2} sx={{ marginTop: 0 }}>
          <Grid
            item
            xs={10}
            sx={{
              paddingBottom: isMobile ? "5px" : "16px",
            }}
            className={isMobile ? "padding-top-0" : ""}
          >
            <p className="attachment-name-text">
              {attachment.attachment_name
                ? attachment.attachment_name
                : attachment.attachment}
            </p>
            <p className="attachment-file-name-text">{attachment.attachment}</p>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              paddingBottom: isMobile ? "5px" : "16px",
              paddingLeft: "8px",
              alignItems: "center",
            }}
            className={isMobile ? "padding-top-0" : ""}
          >
            <div className={isMobile ? "attachment-download-div" : ""}>
              <img
                src={DownloadSvg}
                className="attachment-action-img"
                alt="not found"
                onClick={(e) =>
                  handleDownload(e, attachment.board_attachment_id)
                }
              />
            </div>
            <div onClick={deleteAttachment} >
            <DeleteIcone height={"24px"} width={"24px"}/>
            </div>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}

export default ListItems;
