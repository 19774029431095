import React, { useEffect, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Checkbox, FormControlLabel, MenuItem, Stack } from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { useUsers } from "../../../../../utils/reactQuery/users";
import { assignUser } from "../../../../../sevices/apiBoards";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader";
import useToastPromise from "../../../../../hooks/useToast";

import { RHFCheckbox } from '../../../../../components/RFH/RFHcheckbox'
import { RFHAutocomplete } from "../../../../../components/RFH/RFHautocomplete";
import { createCheckpoint } from "../../../../../schemas/addCheckpoint";
import { addBoardUser } from "../../../../../schemas/addBoardUser";
import { RHFSelect } from "../../../../../components/RFH/RFHselect";


function Form({ onClose, boardUsers, isMobile, isColabrotar, handleAddColabrotor, setOpen, assigneeUserId, currentUser, isUpdateUser }) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { isLoading: isUserLoading, users, isError: isUserError } = useUsers();
  const [userOption, setUserOption] = useState([]);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [newBoardUser, setNewBoardUser] = useState([])
  const { showToastPromise } = useToastPromise();
  const [isSelectedRole, setIsSelectedRole] = useState('User');

  useEffect(() => {
    if (!isColabrotar) {
      if (!isUserLoading && !isUserError) {
        let filteredArray = users.data.data.responseData
          .filter((el) => el.active)
          .sort(function (a, b) {
            return a.first_name.localeCompare(b.first_name);
          });
        setUserOption(
          filteredArray.map((el) => {
            return {
              label: `${el.first_name} ${el.last_name} (${el.email})`,
              value: el.user_id,
            };
          })
        );
      }
      setIsSelectedRole(currentUser?.is_admin ? "Board Admin" : "User");
    } else {
      setNewBoardUser(
        boardUsers
          ?.filter((el) => el.user_id !== assigneeUserId)
          ?.map((el) => {
            return {
              label: el.username,
              value: el.user_id,
            };
          })
      );
    }
  }, [users, isUserLoading, boardUsers, currentUser]);

  const roles = [
    {
      label: "Board Admin",
      value: "Board Admin",
    },
    {
      label: "User",
      value: "User",
    },
  ];

  function removeEmailsFromUserOptions(userOption) {
    if (!Array.isArray(userOption)) {
      throw new TypeError("Expected an array of user options");
    }

    return userOption.map((option) => {
      if (typeof option.label !== "string") {
        throw new TypeError("Each option must have a string `label` field");
      }

      // Regular expression to match and capture the email address within parentheses
      const emailRegex = /\s\([^)]*\)$/;

      // Remove email address and parentheses from the label
      const newLabel = option.label.replace(emailRegex, "").trim();

      // Return a new object with the updated label
      return {
        ...option,
        label: newLabel,
      };
    });
  }

  const updatedUserOptions = removeEmailsFromUserOptions(userOption).filter(
    (item) =>
      !boardUsers?.some((el) => el.user_id === item.value) ||
      currentUser?.user_id === item.value
  );


  // React Hook Form
  const { register, handleSubmit, formState, control, setValue, reset, watch, ...methods } = useForm({
    defaultValues: {
      board_id: id,
      user_id: currentUser?.user_id,
      user_role: isSelectedRole,
      // can_create_card: isSelectedRole == "Board Admin" || currentUser?.can_create_card || !isUpdateUser,
      // can_edit_card: isSelectedRole == "Board Admin" || currentUser?.can_edit_card || !isUpdateUser,
      // can_delete_card: isSelectedRole == "Board Admin" || currentUser?.can_delete_card,
      // can_reassign_user: isSelectedRole == "Board Admin" || currentUser?.can_reassign_user || !isUpdateUser,
      // can_work_before_estimation: isSelectedRole == "Board Admin" || currentUser?.can_work_before_estimation || !isUpdateUser,
      // can_update_status: isSelectedRole == "Board Admin" || currentUser?.can_update_status || !isUpdateUser,
      // can_add_work_log: isSelectedRole == "Board Admin" || currentUser?.can_add_work_log || !isUpdateUser,
      // can_edit_work_log: isSelectedRole == "Board Admin" || currentUser?.can_edit_work_log || !isUpdateUser,
      // can_delete_work_log: isSelectedRole == "Board Admin" || currentUser?.can_delete_work_log,
      // can_move_edit_work_log: isSelectedRole == "Board Admin" || currentUser?.can_move_edit_work_log,
      // can_add_user: isSelectedRole == "Board Admin",
      // can_change_milestone: isSelectedRole == "Board Admin",
    },
    resolver: yupResolver(addBoardUser),
  });

  useEffect(() => {
    const newValues = {
      board_id: id,
      user_role: isSelectedRole,
      // can_create_card: isSelectedRole === "Board Admin" || currentUser?.can_create_card || !isUpdateUser,
      // can_edit_card: isSelectedRole === "Board Admin" || currentUser?.can_edit_card || !isUpdateUser,
      // can_delete_card: isSelectedRole === "Board Admin" || currentUser?.can_delete_card,
      // can_reassign_user: isSelectedRole === "Board Admin" || currentUser?.can_reassign_user || !isUpdateUser,
      // can_work_before_estimation: isSelectedRole === "Board Admin" || currentUser?.can_work_before_estimation || !isUpdateUser,
      // can_update_status: isSelectedRole === "Board Admin" || currentUser?.can_update_status || !isUpdateUser,
      // can_add_work_log: isSelectedRole === "Board Admin" || currentUser?.can_add_work_log || !isUpdateUser,
      // can_edit_work_log: isSelectedRole === "Board Admin" || currentUser?.can_edit_work_log || !isUpdateUser,
      // can_delete_work_log: isSelectedRole === "Board Admin" || currentUser?.can_delete_work_log,
      // can_move_edit_work_log: isSelectedRole === "Board Admin" || currentUser?.can_move_edit_work_log,
      // can_add_user: isSelectedRole === "Board Admin",
      // can_change_milestone: isSelectedRole === "Board Admin",
    };

    Object.keys(newValues).forEach((key) => {
      setValue(key, newValues[key]);
    });
  }, [isSelectedRole, setValue, id, currentUser]);


  const { mutate: assignUserMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(assignUser, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boardUsers`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`boardName`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      queryClient.invalidateQueries({
        queryKey: ["boardUser", currentUser?.user_id]
      });

      setLoading(false);
      // toast.success(res.data.message);
      onClose();
    },
    onError: (error) => {
      console.log(error);
      //   console.error("Error Delete Attachments", error);
      //toast.error(error.response.data.message);
      setLoading(false);
      //   handleClose();
    },
  });

  const submit = (data) => {
    /**
     * Create Board
    */

    setApiError("");
    setLoading(true);
    if (!isColabrotar) {
      assignUserMutate(data);
    } else {
      handleAddColabrotor(data?.user_id)
      setOpen(false)
    }

  };

  return (
    <>

      <FormProvider control={control} {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          noValidate
          sx={{
            // marginTop: 4,
            padding: isMobile ? "8px 5px !important" : "8px",
            // borderRadius: "16px",
            // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
          }}
          className="add-board-user-form"
        >
          <Stack spacing={3}>
            {apiError && <Alert severity="error">{apiError}</Alert>}

            <RFHAutocomplete
              name="user_id"
              options={isColabrotar ? newBoardUser : updatedUserOptions}
            />
            <RHFSelect
              name={"user_role"}
              label={"Role"}
              className={isMobile ? "padding-top-16" : ""}
              sx={{
                "& .MuiInputBase-root": {
                  height:  "56px",
                },
              }}
            >
              {
                isColabrotar ?
                  <MenuItem
                    key={`workflow-option-${roles[1].value}`}
                    id={`workflow-option-id-${roles[1].value}`}
                    value={roles[1].value}
                  >
                    {roles[1].label}
                  </MenuItem>
                  : roles.map((option) => (
                    <MenuItem
                      key={`workflow-option-${option.value}`}
                      id={`workflow-option-id-${option.value}`}
                      value={option.value}
                      onClick={(event) => { event.preventDefault(); setIsSelectedRole(event?.target?.textContent) }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
            </RHFSelect>

            {/* <p className="weight-600 size-16">Permission</p>
            <p className="weight-600 size-15">My Tasks</p>
            <div className="d-flex w-100 justify-content-space-between flex-wrap">

              <RHFCheckbox name={"can_create_card"} label={"Create"} className={"w-33"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_edit_card"} label={"Edit"} className={"w-33"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_delete_card"} label={"Delete"} className={"w-33"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_reassign_user"} label={"Assign"} className={"w-33"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_work_before_estimation"} label={"Work Before Estimation"} className={"w-66"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_update_status"} label={"Update Status"} disabled={isSelectedRole == "Board Admin"} />
            </div>

            <p className="weight-600 size-15">Worklog</p>
            <div className="d-flex w-100 justify-content-space-between flex-wrap">
              <RHFCheckbox name={"can_add_work_log"} label={"Add"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_edit_work_log"} label={"Edit"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_delete_work_log"} label={"Delete"} disabled={isSelectedRole == "Board Admin"} />
              <RHFCheckbox name={"can_move_edit_work_log"} label={"Move"} disabled={isSelectedRole == "Board Admin"} />
            </div>

            <p className="weight-600 size-15">Others</p>
            <div className="d-flex w-50">
              <RHFCheckbox name={"can_add_user"} label={"Add User"} disabled={true} />
              <RHFCheckbox name={"can_change_milestone"} label={"Milestones"} disabled={true} />
            </div> */}

            <Box
              sx={{
                alignSelf: "end",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#1B3E61",
                  mr: "10px",
                  fontSize: isMobile ? "14px" : "16px",
                  padding: isMobile ? "8px 16px" : "10px 16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  minWidth: isMobile ? "107px" : "130px",
                }}
                disabled={loading}
              >
                {isUpdateUser ? "Update" : "Add"}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#1B3E61",
                  border: "1px solid #1B3E61",
                  textTransform: "capitalize",
                  fontSize: isMobile ? "14px" : "16px",
                  padding: isMobile ? "8px 16px" : "10px 16px",
                  fontWeight: "600",
                  opacity: "0.6",
                  minWidth: isMobile ? "107px" : "130px",
                }}
                onClick={onClose}
                disabled={loading}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </Box>
      </FormProvider>
    </>
  );
}

export default Form;
