import React, { useEffect, useState } from 'react'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function DialogComponent({ dialog,children }) {
  return (
    <React.Fragment>
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: 1300,
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: "470px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}
          className="confirmation-title"
        >
          Worklog Missing
        </DialogTitle>
        <DialogContent
        //   className="confirmation-content"
          sx={{padding:0}}
        >
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default DialogComponent