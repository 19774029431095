import {
  Box,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useRef, useState } from "react";
import Search from "../Search";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as yup from "yup";

import Avatar from "../Avatar";
import UploadImage from "./uploadImage";
import { localStorageInstance } from "../../utils/localStorage";
import { hasImage, removeHtmlTagsAndEntities } from "../../utils/helpers";
import LogoutIcon from "../../styles/svg/logout.svg";
import userIcon from "../../styles/svg/users-icon-2.svg";
import workflowIcon from "../../styles/svg/worklog-settings.svg";
import missingStatusIcon from "../../styles/svg/missing-status-report-icon.svg";
import boardsIcon from "../../styles/svg/boards-icon.svg";
import { useDispatch } from "react-redux";
import { searchBoard } from "../../redux/SearchSlice";
import gearIcon from "../../styles/svg/worklogIcon 2.svg";
import _ from "lodash";
import { isAdmin } from "../../utils/constants";
import { changeExpand } from "../../redux/boardListSlice";
import { setSideBarDrawer } from "../../redux/Sidebar";
import MergeBoard from "../../pages/board/components/mergeBoard/MergeBoard";
import mergeBoardIcon from "../../styles/svg/merge.svg";
import systemSettingIcon from "../../styles/svg/systemSettingIcon.svg";
import "./header.scss";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import { useResponsive } from "../../hooks/useResponsive";
import SupportIcon from "../../styles/svg/support.svg";
import { FeedbackDialogContent } from "./FeedBackDialogContent.js";
import { TooltipName } from "../../utils/Enums.js";


export default function Profile() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.users.logInUser);
  const dispatch = useDispatch();
  const isMobile = useResponsive("down", "sm");
  const [openMergeBoard, setOpenMergeBoard] = useState(false);
  const userName =
    Object.hasOwn(user, "first_name") &&
    `${user.first_name[0]}${user.last_name[0]}`.toUpperCase();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false)
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)
  const [showFeedBackDialog, setShowFeedBackDialog] = useState(false);
  const [boardParam, setBoardParam, removeBoardParam] =
    useCustomSearchParams("find-brd");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = (action) => {
    setIsLogoutLoading(true)
    localStorageInstance.clear();
    queryClient.clear();
    navigate(`/`);
    setIsLogoutLoading(false)
  };

  const handleClose = (action) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // setInputValue(boardParam || '');
    dispatch(searchBoard(boardParam || ""));
    if (isAdmin && boardParam) {
      dispatch(changeExpand("allPanel"));
    }
  }, [])

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchBoard(value));
      setBoardParam("find-brd", value);
    }, 300) // 300ms delay
  ).current;

  const handleChange = (event) => {
    if (isAdmin) {
      dispatch(changeExpand("allPanel"));
    }
    const value = event.target.value.trimStart();
    debouncedSearch(value);
  };

  const handleNavigate = (boardNumber) => {
    const today = new Date();
    const yesterday = new Date();

    // Subtract 7 days from the current date
    yesterday.setDate(today.getDate() - 1);

    const date = {
      startDate: yesterday,
      endDate: yesterday,
      key: "selection",
    };

    const newSearchParams = new URLSearchParams({
      tab: "worklog",
      board: boardNumber,
      epic: null,
      missingStatus: JSON.stringify(date),
    });
    navigate(`/admin-report?${newSearchParams.toString()}`);
    setAnchorEl(null);
    dispatch(setSideBarDrawer(false));
  };

  const handleMergeBoard = () => {
    setOpenMergeBoard(true);
  };

  const handleSystemSetting = () => {
    navigate("/system-setting");
    setAnchorEl(null);
  };

  const handleLogoutDialog = () => {
    setShowLogoutDialog(true)
  }

  const handleFeedBackDialog = () => {
    setShowFeedBackDialog(true);
  }

  const handleFeedBackForm = () => {
    document.getElementById("feedbackForm").requestSubmit()
  }

  const schema = yup.object({
    subject: yup.string().required("subject is required"),
    message: yup
      .string()
      .required("Description is required")
      .test(
        "no-only-spaces",
        "Description is required",
        (value) => removeHtmlTagsAndEntities(value).trim().length > 0
      ),
  })

  return (
    <>
      {
        showLogoutDialog && (
          <ConfirmationDialog
            open={showLogoutDialog}
            handleClose={() => setShowLogoutDialog(false)}
            loading={isLogoutLoading}
            callback={handleLogout}
            message="Are you sure you want to log out?"
            btnTitle="Logout"
            title="Log Out"
          />
        )
      }

      {
        showFeedBackDialog && (
          <ConfirmationDialog
            open={showFeedBackDialog}
            handleClose={() => setShowFeedBackDialog(false)}
            loading={isLogoutLoading}
            callback={handleFeedBackForm}
            message={<><FeedbackDialogContent schema={schema} handleClose={() => setShowFeedBackDialog(false)} /></>}
            btnTitle="Submit"
            title="TaskBoard Beta – We’d Love Your Feedback!"
            hideConfirmationText={true}
            className="feedback-form"
            maxWidth={"800px"}
            dialogHeight={"100vh"}
          />
        )
      }
      {
        openMergeBoard && (
          <MergeBoard
            close={setOpenMergeBoard}
            open={openMergeBoard}
            admin={true}
          />
        )
      }
      {/* <Grid item xs={5} className=""> */}
      <div className="header-right">
        {/* <Box mx={2} mb={2}>
            <Search
              searchText={inputValue}
              handleChange={handleChange}
              placeholder={"Find Board"}
              boardSearch='true'
            />
          </Box> */}
        {Object.hasOwn(user, "first_name") && (
          <Box padding={0.5}>
            <Stack direction="row" justifyContent={"end"}>
              <Tooltip title={TooltipName.PROFILE.PROFILE}>
              <Button
                onClick={handleClick}
                endIcon={
                  <KeyboardArrowDownIcon className="profile-down-arrow" />
                }
              >
                <Avatar
                  title={`${user.first_name} ${user.last_name}`}
                  user_image={user?.user_image}
                  className="user-avatar"
                  style={
                    !hasImage(user?.user_image) && {
                      bgcolor: user?.profile_color?.background,
                      color: user?.profile_color?.text,
                    }
                  }
                  isDisable={true}
                >
                  {userName}
                </Avatar>
                {!isMobile && <div>
                  <Typography className="user-name">
                    {user?.first_name} {user.last_name}
                  </Typography>
                  {/* <Typography className="user-email" >{user.email}</Typography> */}
                </div>}
              </Button>
              </Tooltip>
            </Stack>

            <Menu
              id="account-menu"
              className="profile-header"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 14,
                    height: 14,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "left", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
              <MenuItem disableRipple sx={{ cursor: "default" }}>
                {/* <Avatar
                      user_image={user?.user_image}
                      title={`${user?.first_name} ${user?.last_name}`}
                      className="user-avatar"
                      style={{
                        bgcolor: user?.profile_color?.background,
                        color: user?.profile_color?.text,
                      }}
                    >
                      {userName}
                    </Avatar> */}
                <Stack className="profile-container">
                  <UploadImage
                    user={user}
                    userName={userName}
                    setAnchorEl={setAnchorEl}
                  />

                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography className="profile-dropdown-name">
                          {user.first_name} {user.last_name}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography className="profile-dropdown-email">
                          {user.email}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {/* <img
                    src={LogoutIcon}
                    alt="logout"
                    className="logout-icon"
                    onClick={() => handleClose("logout")}
                  /> */}
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleFeedBackDialog}>
                <ListItemIcon sx={{ minWidth: "26px !important" }}>
                  <img src={SupportIcon} />
                </ListItemIcon>
                Help & Feedback
              </MenuItem>
              {/* <Divider /> */}
              {isAdmin() && (
                <MenuItem
                  onClick={() => {
                    navigate("/admin-user?users-status=active");
                    setAnchorEl(null);
                    dispatch(setSideBarDrawer(false));
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={userIcon} alt="user" />
                  </ListItemIcon>
                  Manage Users
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem
                  onClick={() => {
                    navigate("/admin-board?boards-type=active");
                    setAnchorEl(null);
                    dispatch(setSideBarDrawer(false));
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={boardsIcon} alt="board" />
                  </ListItemIcon>
                  Manage Boards
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem
                  onClick={() => {
                    navigate("/admin-workflow");
                    setAnchorEl(null);
                    dispatch(setSideBarDrawer(false));
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={workflowIcon} alt="workflow" />
                  </ListItemIcon>
                  Workflows Settings
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem onClick={() => handleNavigate()}>
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={missingStatusIcon} alt="missing status" />
                  </ListItemIcon>
                  Missing Status Report
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem onClick={handleMergeBoard}>
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={mergeBoardIcon} alt="missing status" />
                  </ListItemIcon>
                  Merge Board
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem onClick={handleSystemSetting}>
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={systemSettingIcon} alt="system setting" />
                  </ListItemIcon>
                  System Settings
                </MenuItem>
              )}

              <MenuItem onClick={handleLogoutDialog}>
                <ListItemIcon sx={{ minWidth: "26px !important" }}>
                  <img src={LogoutIcon} alt="log out" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        )}
      </div>
      {/* </Grid> */}
    </>
  );
}
