import { Box, Divider, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";

import LockMilestone from '../styles/svg/LockUnlock.svg';
import { truncateText } from "../utils/helpers";
import { useCustomSearchParams } from "../hooks/useCustomSearchParams";
import { useSetMultipleParams } from "../hooks/useSetMultipleParams";

function TabsController({
  value,
  onChange,
  tabsOptions,
  children,
  leftChild,
  tabsRef,
}) {
  const [NoMilestone, setMilestone] = useCustomSearchParams("noMilestone");
  const [setMultipleParams] = useSetMultipleParams();
  const tabRefs = useRef({});

  useEffect(() => {
    if (tabRefs.current[value]) {
      tabRefs.current[value].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center", // Ensures horizontal scrolling
      });
    }
  }, [value]);
  return (
    <>
      <Tabs
        value={value}
        ref={tabsRef}
        // onChange={(event, newValue) => onChange(event, newValue)}
        textColor="primary"
        indicatorColor="primary"
        aria-label="controlled tabs with specific values"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#000",
            display: "none",
          },
        }}
        sx={{
          ".MuiTabs-flexContainer": {
            borderRadius: "8px 8px 0px 0px",
            backgroundColor: "rgba(27, 62, 97, 0.16)",
            // borderBottom: "1.5px solid rgba(27, 62, 97, 0.24)",
            overflowX: "auto",
            // width: "auto",
            paddingLeft: "64px",
            // display: "flex",
            scrollBehavior: "smooth",
            scrollbarWidth: "none", // Hide scrollbar
            "&::-webkit-scrollbar": { display: "none" },
          },
          minHeight: "24px",
          margin: "8px 0px",
        }}
      >
        {leftChild}
        <Tab
          value={""}
          label={"All"}
          onClick={() => {
            setMultipleParams([
              {
                name: "noMilestone",
                value: false,
              },
              {
                name: "milestone",
                value: "",
              },
            ]);
          }}
          className={
            value === "" && NoMilestone !== "true"
              ? "custom-tab-active-no-milestone"
              : "custom-tab-milestone"
          }
          sx={
            value === "" && NoMilestone === "true"
              ? {}
              : { borderRight: 2, borderColor: " #1B3E611F" }
          }
        />

        <Tab
          value={""}
          label={"Not Set"}
          onClick={() => {
            setMultipleParams([
              {
                name: "noMilestone",
                value: true,
              },
              {
                name: "milestone",
                value: "",
              },
            ]);
          }}
          className={
            value === "" && NoMilestone === "true"
              ? "custom-tab-active-no-milestone"
              : "custom-tab-milestone"
          }
          sx={
            value === tabsOptions[0]?.epic_id
              ? {}
              : { borderRight: 2, borderColor: " #1B3E611F" }
          }
        />
        {tabsOptions?.map((openMilestone, idx) => {
          const isNextTabActive = tabsOptions[idx + 1]?.epic_id === value;
          return (
              <Tab
                value={openMilestone.epic_id}
                label={truncateText(openMilestone.epic_name, 30)}
                icon={openMilestone?.is_locked && <img src={LockMilestone} />}
                ref={(el) => (tabRefs.current[openMilestone.epic_id] = el)}
                onClick={() => {
                  setMultipleParams([
                    {
                      name: "noMilestone",
                      value: false,
                    },
                    {
                      name: "milestone",
                      value: openMilestone.epic_id,
                    },
                  ]);
                }}
                iconPosition="start"
                className={
                  value === openMilestone.epic_id
                    ? "custom-tab-active-milestone"
                    : "custom-tab-milestone"
                }
                sx={
                  isNextTabActive
                    ? {}
                    : { borderRight: 2, borderColor: " #1B3E611F" }
                }
              />
          );
        })}
        {children}
      </Tabs>
    </>
  );
}

export default TabsController;
