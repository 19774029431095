import React from "react";

import DueDateIcon from "../../../../styles/svg/dueWatch.svg";
import recurringIcon from "../../../../styles/svg/recurring.svg";
import moment from "moment";

function DueDate({ date, isRecuring= false }) {
  const isDateDue = ()=>{
    const now = moment();
    const target = moment(date).endOf("day");
    const isPast = target.isBefore(now);
    
    let diff = moment.duration(target.diff(now)); // Get duration

    // Convert to absolute values for formatting
    const days = Math.abs(diff.days());
    const hours = Math.abs(diff.hours());
    const minutes = Math.abs(diff.minutes());

    let result = "";
    
    if (days > 0) {
      result = `${days} days ${hours} hours`;
    } else if (hours > 0) {
      result = `${hours} hours ${minutes} mins`;
    } else {
      result = `${minutes} mins`;
    }

    return isPast
      ? <p className="overdue-hours">Overdue by {result}</p>
      : <p className="due-hours">Due in {result}</p>;
  };

  return (
    <div>
      { (date || isRecuring) &&
        <>
          <div
            style={{ borderTop: "1px solid #919EAB40", margin: "10px 0px" }}
          ></div>
          <div className="d-flex align-items-center justify-content-space-between">
            <div className="d-flex align-items-center">
              <img src={DueDateIcon} />
              {date ? isDateDue() : <p className="due-hours">None</p>}
            </div>
            <div>{isRecuring && <img src={recurringIcon} />}</div>
          </div>
        </>
      }
    </div>
  );
}

export default DueDate;
