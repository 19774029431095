import { Chip, Tooltip } from "@mui/material";
import React from "react";

import plus from "../../../../styles/svg/group-plus-icon.svg";
import minus from "../../../../styles/svg/group-minus-icon.svg";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { TooltipName } from "../../../../utils/Enums";

function CalculateCards({ cards, workflowSteps, isExpand }) {
  const [isGeneric] = useCustomSearchParams("is_generic");
  const statusCards = (status) => {
    const filteredCards = cards.filter(
      (card) =>card.generic_step_id * 1 === status);
    const remainingHour = filteredCards.reduce((accumulator, currentValue) => {
      if (currentValue.card.allocated_hours) {
        if (currentValue.total_hours_spent) {
          if (
            currentValue.card.allocated_hours >= currentValue.total_hours_spent
          ) {
            return Number.isInteger(
              accumulator +
                (currentValue.card.allocated_hours -
                  currentValue.total_hours_spent)
            )
              ? accumulator +
                  (currentValue.card.allocated_hours -
                    currentValue.total_hours_spent)
              : parseFloat(
                  accumulator +
                    (
                      currentValue.card.allocated_hours -
                      currentValue.total_hours_spent
                    ).toFixed(2)
                );
          } else {
            return Number.isInteger(accumulator)
              ? accumulator
              : parseFloat(accumulator.toFixed(2));
          }
        } else {
          return Number.isInteger(
            currentValue.card.allocated_hours + accumulator
          )
            ? currentValue.card.allocated_hours + accumulator
            : parseFloat(
                currentValue.card.allocated_hours + accumulator.toFixed(2)
              );
        }
      } else {
        return Number.isInteger(accumulator)
          ? accumulator
          : parseFloat(accumulator.toFixed(2));
      }
    }, 0);

    return `${remainingHour} Hrs (${filteredCards.length}) `;
  };
  const calculateRemainingHours = (status) => {};
  return (
    <div className="d-flex align-items-center ">
      {workflowSteps.map((el, idx) => (
        <p
          key={`grouping-status-chips-${idx}`}
          className=" w-25 grouping-status-chips team-status-title">{`${statusCards(
          el.workflow_step_id
        )}`}</p>
      ))}
      {/* <p className=" w-25 grouping-status-chips">{`${statusCards(1)}`}</p>
      <p className=" w-25 grouping-status-chips">{`${statusCards(2)}`}</p>
      <p className=" w-25 grouping-status-chips">{`${statusCards(3)}`}</p>
      <p className="grouping-status-chips w-25 ">{`${statusCards(4)}`}</p> */}
      <div className=" w-25 d-flex justify-content-end-important">
        {<Tooltip title={isExpand ? TooltipName.COLLAPSE : TooltipName.EXPAND}>
          <img src={isExpand ? minus : plus}/>
          </Tooltip>}
        {/* {isExpand ? <img src={minus} /> : <img src={plus} />} */}
      </div>
    </div>
  );
}

export default CalculateCards;
