import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCard, updateWorkflowStep } from "../../sevices/apiCards";
import toast from "react-hot-toast";
import "./ChangeStatus.scss";
import { useWorkflowById } from "../../utils/reactQuery/workflows";
import useToastPromise from "../../hooks/useToast";

export default function ChangeStatus({
  boardId,
  checkCondition,
  workflow_id,
  cardId,
  workflowStepId,
}) {
  const [status, setStatus] = React.useState("");
  const [cachedData, setCachedData] = React.useState("");
  const queryClient = useQueryClient();
  const { data: workflows } = useWorkflowById(workflow_id, boardId);
  const { showToastPromise } = useToastPromise();

  React.useEffect(() => {
    if (boardId && workflows) {
      setCachedData(workflows);
    }
  }, [boardId, workflows]);

  React.useEffect(() => {
    setStatus(workflowStepId);
  }, [workflowStepId]);

  const { mutate: updateMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateWorkflowStep, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, String(boardId)],
      });

      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, String(cardId)],
      });
      //toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      setStatus(workflowStepId);
    },
  });

  const handleChange = (event) => {
    updateMutate({
      card_id: cardId,
      workflow_step_id: event.target.value,
    });
    setStatus(event.target.value);
  };


  const showStatus =
    cachedData &&
    cachedData?.data?.data?.responseData?.filter(
      (workflow) => workflow.workflow_step_id == status
    )[0];
  return (
    <Box sx={{}}>
      {checkCondition ? (
        <FormControl size="small" className="card-status-dropdown-parent">
          <Select
            labelId="demo-simple-select-label"
            value={showStatus && status}
            onChange={handleChange}
            className="card-status-dropdown"
          >
            {cachedData &&
              cachedData?.data?.data?.totalRecord &&
              cachedData?.data?.data?.responseData
                ?.sort((a, b) => a.step_sequence - b.step_sequence)
                .map((workflow, index) => (
                  <MenuItem
                    value={workflow.workflow_step_id}
                    className="uppercase"
                  >
                    {workflow.status}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      ) : (
        <div className="project-status d-inline-flex justify-content-center align-items-center">
          <button className="project-status-button">
            {showStatus && showStatus?.status}
          </button>
          <div className="project-status-icon">
            {/* <KeyboardArrowDownIcon/> */}
          </div>
        </div>
      )}
    </Box>
  );
}
