import { Controller, useFormContext } from "react-hook-form";
import { Switch, FormControlLabel } from "@mui/material";

export default function RHFToggle({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <label className="switch">
              <input
                type="checkbox"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                {...other}
              />
              <div className="slider"></div>
            </label>
          }
        />
      )}
    />
  );
}
