import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// import { updateBoard } from "../../../../../sevices/apiBoards";
import { schema } from "../../../../schemas/createTeam";
import CheckboxesTags from "../../../../components/MultiSelectAutocomplete";
import { useUsers } from "../../../../utils/reactQuery/users";
import { createTeam } from "../../../../sevices/apiTeam";
import { useResponsive } from "../../../../hooks/useResponsive";
import useToastPromise from "../../../../hooks/useToast";

function CreateTeamForm({ onClose }) {
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient();
  const { users } = useUsers();
  const isMobile = useResponsive("down", "md");
  // React Hook Form
  const { register, setValue, handleSubmit, reset, getValues, formState } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        user_ids: [],
      },
    });
  const { errors } = formState;
    const { showToastPromise } = useToastPromise();

  const { mutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(createTeam, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["teams"],
      });
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      setLoading(false);
      //toast.success(res.data.message);
      onClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const submit = (data) => {
    /**
     * Create Board
     */
    setApiError("");
    setLoading(true);
    mutate({ ...data, user_ids: data.user_ids.map((el) => el.user_id) });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submit)}
      noValidate
      sx={
        {
          // padding: "14px",
        }
      }>
      <Stack spacing={3}>
        {apiError && <Alert severity="error">{apiError}</Alert>}
        <TextField
          label={"Team Name"}
          required
          id="team_name"
          name="team_name"
          error={errors?.team_name ? true : false}
          helperText={errors?.team_name && errors.team_name.message}
          {...register("team_name")}
          onChange={(e) => {
            const value = e.target.value;
            setValue("team_name", value.trimStart(), { shouldValidate: true });
          }}
        />
        <CheckboxesTags
          options={users?.data.data.responseData
            .filter((el) => el.active)
            .sort(function (a, b) {
              return a.first_name.localeCompare(b.first_name);
            })}
          setValue={setValue}
          errors={errors}
        />
        <Box
          sx={{
            alignSelf: "end",
          }}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#1B3E61",
              mr: "10px",
              fontSize: isMobile ? "14px" : "16px",
              padding: isMobile ? "5px 8px" : "10px 16px",
              fontWeight: "600",
              textTransform: "capitalize",
              minWidth: "130px",
            }}
            disabled={loading}>
            Add
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1B3E61",
              border: "1px solid #1B3E61",
              textTransform: "capitalize",
              fontSize: isMobile ? "14px" : "16px",
              padding: isMobile ? "5px 8px" : "10px 16px",
              fontWeight: "600",
              opacity: "0.6",
              minWidth: "130px",
            }}
            onClick={onClose}
            disabled={loading}>
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default CreateTeamForm;
