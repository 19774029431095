import React, { useLayoutEffect, useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";

import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import CreateCard from "../../pages/board/components/Kanban/CreateCard";
import TodoSteps from "./TodoSteps";
import { useResponsive } from "../../hooks/useResponsive";

function Column({ name, stage, cards, height }) {
  const [milestone] = useCustomSearchParams("milestone");
  const isMobile = useResponsive("down", "md");

  const [scrollToCardId, setScrollToCardId] = useState(null)

  


  return (
    <div className="card-column todo-column ">
      <div
        style={{
          borderRadius: "12px",
          border: "1px solid rgba(34, 197, 94, 0.16)",
        }}
      >
        {!isMobile && (
          <div
            className="coloumn-header"
            style={{
              backgroundColor: " #22C55E29",
              padding: "16px 16px",
            }}
          >
            <h4 className="coloumn-header-h4">
              <p className="coloumn-header-text">{name}</p>
            </h4>
          </div>
        )}
        <div
          className={isMobile ? "mobile-todo-column-body" : "coloumn-body"}
          style={{
            backgroundColor: "#fff",
            // borderRadius: "12px",
            // border: "1.5px solid #C8D1D9",
          }}
        >
          <Droppable droppableId={"todo-column"}>
            {(provided) => (
              <div
                className="coloumn-all-cards"
                style={{
                  height: `calc(94vh - ${
                    milestone ? "340px" : `${height}px`
                  } )`,
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className={isMobile && "width-100"}>
                  <CreateCard
                    stage={stage}
                    isTodo={true}
                    newCreatedCardId={setScrollToCardId}
                  />
                </div>
                <TodoSteps
                  cards={cards}
                  scrollToCardId={scrollToCardId}
                  height={height}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <br />
        </div>
      </div>
    </div>
  );
}
export default Column;
