import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const MultiSelctCheckboxDropdown = ({
  items,
  selectedItems,
  onSelectionChange,
  anchorEl,
  handleClose,
  valueKey,
  displayKey,
  handleClearFilter,
  renderDisplay,
  menuProps,
  isShowUnassigned = false,
  searchAssignee=true
}) => {
  const handleSelect = (item) => {
    let itemValue;
    if(item === 'unassigned'){
      itemValue = 'unassigned'
    }else{
      itemValue = item[valueKey];
    }
    const updatedSelection = selectedItems?.includes(itemValue)
      ? selectedItems?.filter((selected) => selected !== itemValue)
      : [...selectedItems, itemValue];

    onSelectionChange(updatedSelection);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...menuProps} // Allow additional menu props to be passed
      >
        <MenuItem
          className="d-flex justify-content-center clear-filter-color"
          onClick={handleClearFilter}
        >
          Clear Filters
        </MenuItem>
        {
          (isShowUnassigned && searchAssignee) &&   <MenuItem onClick={()=> handleSelect('unassigned')}>
          <Checkbox
                className="checkbox"
                checked={selectedItems?.includes('unassigned')}
              />
              <ListItemText
                className="ml-3"
                primary={'unassigned'}
              />
          </MenuItem>
        }
      
        {items?.map((item, index) => (
          <MenuItem key={item} onClick={() => handleSelect(item)}>
            <Checkbox
              className="checkbox"
              checked={selectedItems?.includes(item[valueKey])}
            />
            <ListItemText
              className="ml-3"
              primary={renderDisplay ? renderDisplay(item) : item[displayKey]}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MultiSelctCheckboxDropdown;
