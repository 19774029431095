import Api from "./axios";
import { objectToFormData, parseLdapUrl } from "../utils/helpers";

export const updateTenantInfo = async (data) => {
  const { domain, company_name } = data;
  return await Api.put(
    domain
      ? `/update_tenant_variables?domain=${domain}&company_name=${company_name}`
      : `/update_tenant_variables?company_name=${company_name}`
  );
};

export const updateTenantLogos = async (data) => {
  return await Api.put(`/update_tenant_icons`, objectToFormData(data));
};

export const updateTenantLDAP = async (data) => {
    return Api.put(`/update_tenant_ldap`,data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  })
};

export const getTenantInfo = async () => {
  return await Api.get(`/get_tenant_var`);
};

export const checkDomain = async (data) => {
  return await Api.get(`/check_tenant?domain=${data?.domain || ""}`);
};

export const checkLDAPConnection = async () => {
  return await Api.get(`/ldap/test_connection`)
}
export const updateTenantWorkingHours = async (data) => {
  return await Api.put(
    `/update_worklog_info?${
      data?.daily_worklog_hours
        ? `daily_worklog_hours=${data.daily_worklog_hours}&`
        : ""
    }${`daily_worklog_reminder=${data?.daily_worklog_reminder || false}`}`
  );
};