import { useQuery } from "@tanstack/react-query";
import { getMissingWorklog } from "../../sevices/apiWorklog";

export function useWorklogMissing() {
  const {
    isLoading,
    data: worklog,
    error,
    isError,
    refetch,
    isRefetching
  } = useQuery({
    queryKey: [`worklog-missing`],
    queryFn: getMissingWorklog,
  });

  return { isLoading, error, worklog, isError, refetch, isRefetching };
}