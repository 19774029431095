import { Popover, Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useCards, useGetAssignedCards, useMineCards } from '../../../../../utils/reactQuery/cards';
import { useBoardName } from '../../../../../utils/reactQuery/boards'
import assignedHours from '../../.../../../../../styles/svg/assignedHours.svg'
import assignedTasks from '../../.../../../../../styles/svg/assignedTotalTasks.svg'
import KanbanCard from '../KanbanCard';
import { useGenericWorkflowStatus } from '../../../../../utils/reactQuery/workflows';
import Loader from '../../../../../components/Loader';


const AssignedCardMenu = ({ openAssignedTask, handleClose, userId, openWorkload }) => {

    const { assignedCards, isLoading, error, isError, refetchAssignedCards } = useGetAssignedCards(userId);
    const [inCompleteCards, setInCompleteCards] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [totalTasks, setTotalTasks] = useState(0);

    useEffect(() => {
        if (userId) {
            refetchAssignedCards();
        }
    }, [userId])

    useEffect(() => {
        if (assignedCards) {

            let totalHoursCount = 0;
            let totalTasksCount = 0;

            const filteredCards = assignedCards?.data?.data?.responseData?.filter((item) => {
                const { card } = item;

                if (card?.is_todo === false && card?.assigned_to_user?.user_id === userId) {
                    if (card?.allocated_hours) {
                        totalHoursCount += (item?.total_hours_spent > card?.allocated_hours)
                            ? 0
                            : (card?.allocated_hours - item?.total_hours_spent);
                    } else {
                        totalTasksCount += 1;
                    }
                    return item;
                }
            })

            setTotalHours(totalHoursCount);
            setTotalTasks(totalTasksCount);
            setInCompleteCards(filteredCards);
        }
    }, [assignedCards, userId]);

    return (
        <>
            <Popover
                anchorEl={openAssignedTask}
                open={Boolean(openAssignedTask)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    sx: {
                        width: "230px",
                        marginLeft: "35px",
                        padding: "10px",
                        height: "300px",
                    },
                    onMouseLeave: handleClose,
                }}
                className={`assigned-user-tasks ${openWorkload ? "z-1301" : ""}`}
            >
                <Box
                    className="d-flex justify-content-space-between assigned-menu-header" onClick={(e) => e.stopPropagation()}>
                    <Box className="total-hours d-flex"> <img src={assignedHours} alt="total hours" />{totalHours > 1 ? totalHours + " Hrs" : totalHours + " Hr"}</Box>
                    <Box className="total-tasks d-flex"><img src={assignedTasks} alt="total task assinged" />{totalTasks > 1 ? totalTasks + " Tasks" : totalTasks + " Task"}</Box>
                </Box>
                <Box
                    className={`cards-data ${inCompleteCards?.length === 0 ? "no-data" : ""}`}>
                    {isLoading ? (
                        <div className="loader-center">
                            <Loader color="black" />
                        </div>
                    ) : (
                        <>
                            {inCompleteCards?.length === 0 && (
                                <p className="d-flex justify-content-center align-items-center ">
                                    No Cards Assigned
                                </p>
                            )}

                            {inCompleteCards?.length > 0 && inCompleteCards.map((card, index) => (
                                <>
                                    <KanbanCard
                                        key={card?.card?.card_id}
                                        id={card?.card?.card_id}
                                        project={card}
                                        index={index}
                                        isAssignedUser={true}
                                    />
                                </>
                            ))
                            }
                        </>
                    )}
                </Box>
            </Popover >
        </>
    );
}

export default AssignedCardMenu


