import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { RHFSelect } from "../../../../components/RFH/RFHselect";
import FormDialog from "../../../../components/Dialog/FormDialog";
import archivedBoard from "../../../../styles/svg/Vector-archived.svg";
import RHFTextField from "../../../../components/RFH/RFHtextfield";
import { userProfileSchema } from "../../../../schemas/userProfile";
import { RHFCheckbox } from "../../../../components/RFH/RFHcheckbox";
import { RHFSwitch } from "../../../../components/RFH/RFHSwitch";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useUserProfileForAdmin } from "../../../../utils/reactQuery/users";
import Loader from "../../../../components/Loader";
import { useTeams } from "../../../../utils/reactQuery/teams";
import uploadUserProfile from "../../../../styles/svg/update_user_profile.svg";
import { setGroupUser } from "../../../../redux/TaskGroupingSlice";
import { getLastSevenDays } from "../../../../utils/helpers";
import useMutate from "../../../../hooks/useMutate";
import { updateUserDetails } from "../../../../sevices/apiUser";
import { getAPIBaseUrl } from "../../../../utils/constants";
import ChangePassword from "./ChangePassword";
import { updateImage } from "../../../../sevices/apiUploadImage";
import { TooltipName } from "../../../../utils/Enums";

function UserProfile({ open, userId, handleClose }) {
  const isMobile = useResponsive("down", "md");
  const navigate = useNavigate("");
  const { isLoading, profileData } = useUserProfileForAdmin(userId);
  const { teams } = useTeams();
  const teamsArray = teams?.data?.data?.responseData;
  const fileInputRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [changePassword, setChangePassword] = useState(false);
  const methods = useForm({
    resolver: yupResolver(userProfileSchema),
    defaultValues: {
      full_name: "",
      last_name: "",
      email: "",
      team: "",
      isActive: false,
      worklogsRequired: false,
      isSuperAdmin: false,
    },
  });
  const { mutate } = useMutate(updateUserDetails, ["users"], () => {
    queryClient.invalidateQueries({
      queryKey: ["users", userId],
    });
    handleClose();
  });

  const { mutate: mutateProfile } = useMutate(updateImage, ["users"], () => {
    queryClient.invalidateQueries({
      queryKey: ["users", userId],
    });
    handleClose();
  });

  useEffect(() => {
    methods.setValue(
      "full_name",
      profileData?.user?.first_name + " " + profileData?.user?.last_name
    );
    methods.setValue("email", profileData?.user?.email);
    methods.setValue("team", profileData?.user?.team_id);
    methods.setValue("isActive", profileData?.user?.active);
    methods.setValue("worklogsRequired", profileData?.user?.cardlog_required);
    methods.setValue(
      "isSuperAdmin",
      profileData?.user?.role == "User" ? false : true
    );
    setPreview(
      profileData?.user?.user_image &&
        getAPIBaseUrl() + "/" + profileData?.user?.user_image
    );
  }, [isLoading]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      methods.setValue("profilePicture", file);
      setPreview(URL.createObjectURL(file));

      mutateProfile({ user_id: userId, user_image: file });
    }
    setPreview(URL.createObjectURL(file));
  };

  const handleAssignTask = () => {
    navigate(`/my-task?tab=team&user=true&uid=${userId}`);
  };
  const handleWorklog = () => {
    navigate(`/my-task?tab=worklog&date=${getLastSevenDays()}&uid=${userId}`);
  };

  const onSubmit = (formData) => {
    var temp = formData.full_name.split(" ");
    var last_name = temp.pop();
    const obj = {
      first_name: temp.join(),
      last_name: last_name,
      role: formData.isSuperAdmin ? "Super Admin" : "user",
      team_id: formData.team,
      cardlog_required: formData.worklogsRequired,
      active: formData.isActive,
      ...(formData.email !== profileData.user.email && {
        email: formData.email,
      }),
    };
    mutate({ userId: userId, data: obj });
  };

  return (
    <FormDialog
      open={open}
      handleClose={handleClose}
      title={"Profile"}
      btnTitle={"Update"}
      width={"932px"}>
      <FormProvider {...methods}>
        {changePassword && (
          <FormDialog
            open={changePassword}
            handleClose={() => setChangePassword(false)}
            title={"Change Password"}
            btnTitle={"Update"}>
            <ChangePassword
              userId={userId}
              onClose={() => setChangePassword(false)}
            />
          </FormDialog>
        )}
        {isLoading ? (
          <div className="loader-center">
            <Loader color={"black"} />
          </div>
        ) : (
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container className="user-profile-cont">
              <Grid item xs={5} pr={6}>
                <Box>
                  <ul
                    style={{
                      paddingLeft: "14px",
                    }}>
                    <li style={{ width: "fit-content", cursor: "auto" }}>
                      Assigned Tasks
                    </li>
                    <li style={{ width: "fit-content", cursor: "auto" }}>
                      Worklogs
                    </li>
                    <li
                      onClick={() => setChangePassword(true)}
                      style={{ width: "fit-content" }}>
                      Change Password
                    </li>
                  </ul>
                </Box>

                <p className="head-user-profile">Boards</p>
                <Box
                  sx={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 410px)", // Adjust height relative to viewport
                    minHeight: "200px", // Ensures it doesn't collapse
                  }}>
                  {profileData?.boards.length > 0 ? (
                    <ul
                      style={{
                        paddingLeft: "14px",
                      }}>
                      {profileData?.boards.map((ele, idx) => (
                        <li>
                          <div className="boards-user-profile">
                            <p
                            // onClick={() =>
                            //   navigate(`/board/${ele.board_id}`)
                            // }
                            >
                              {ele.board_name}
                            </p>
                            {ele.is_archive && (
                              <Tooltip
                                title={TooltipName.MANAGE_USER.ARCHIVED_BOARD}>
                                <img src={archivedBoard} alt="archived board" />
                              </Tooltip>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No boards found"
                  )}
                </Box>
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid item xs={6.5} pl={8} className="d-flex flex-dir-col">
                <Stack alignItems={"center"} sx={{ position: "relative" }}>
                  <Avatar
                    sx={{
                      width: 100,
                      height: 107,
                      border: "1px solid rgba(145, 158, 171, 0.25)",
                    }}
                    src={preview || ""}>
                    {" "}
                    {!profileData?.user.user_image &&
                      profileData?.user?.first_name[0] +
                        " " +
                        profileData?.user?.last_name[0]}
                  </Avatar>
                  {/* <img
                    src={uploadUserProfile}
                    alt="update profile"
                    className="userUploadProfileIcon"
                  /> */}
                  <img
                    src={uploadUserProfile}
                    alt="update profile"
                    className="userUploadProfileIcon"
                    onClick={() => fileInputRef.current.click()}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  <p className="user-profile-edit-text mt-2">
                    Click the edit icon to upload a new profile picture
                  </p>
                </Stack>
                <RHFTextField
                  name="full_name"
                  label="Full Name"
                  className="mt-32"
                />
                <RHFTextField
                  name="email"
                  label="Email Address"
                  className="mt-4"
                />
                <RHFSelect name="team" className="mt-4" label="Team">
                  {teamsArray?.map((ele, idx) => {
                    return <MenuItem value={ele.team_id}>{ele.team}</MenuItem>;
                  })}
                </RHFSelect>

                <div className="d-flex justify-content-space-between mt-4">
                  <p className="user_profile_head">Is Active</p>
                  <RHFSwitch name={"isActive"} />
                </div>

                <div className="d-flex justify-content-space-between mt-4">
                  <p className="user_profile_head">Worklogs Required</p>
                  <RHFSwitch name={"worklogsRequired"} />
                </div>

                <div className="d-flex justify-content-space-between mt-4">
                  <p className="user_profile_head">Is Super Admin</p>
                  <RHFSwitch name={"isSuperAdmin"} />
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  // disabled={isPending}
                  sx={{
                    bgcolor: "#1B3E61",
                    fontSize: isMobile ? "14px" : "16px",
                    padding: isMobile ? "6px 16px" : "10px 16px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    minWidth: isMobile ? "0px" : "130px",
                    alignSelf: "end",
                  }}
                  className="mt-25"
                  onClick={(e) => e.stopPropagation()}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </FormProvider>
    </FormDialog>
  );
}

export default UserProfile;
