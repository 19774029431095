import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import DialogContent from "@mui/material/DialogContent";
import { Button, Drawer, Stack, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import mobileArrow from "../../../../styles/svg/mobileArrow.svg";
import mobileDots from "../../../../styles/svg/mobileDots.svg";
import BasicGrid from "./detailGrid";
import "./details.scss";
import { useCard } from "../../../../utils/reactQuery/cards";
import Loader from "../../../../components/Loader";
import ChangeStatus from "./components/ChangeStatus";
import AttachFile from "./components/changeBoard/attachFile/AttachFile";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import ChangeBoard from "./components/changeBoard/ChangeBoard";
import DeleteCard from "../Kanban/DeleteCard";
import { clearCheckedIds } from "../../../../redux/CheckboxSclice";
import { useResponsive } from "../../../../hooks/useResponsive";
import { descriptionUpdate } from "../../../../sevices/apiDescription";
import { useRemoveMultipleParams } from "../../../../hooks/useRemoveMultipleParams";
import DependsOnDropdown from "./sidebar/components/dependsOnDropdown";
import CardNotFound from "./components/CardNotFound";
import errorIcon from "../../../../styles/svg/critical_priority.svg";
import ChangeTodoStatus from "../../../../components/ToDo/ChangeTodoStatus";
import useToastPromise from "../../../../hooks/useToast";
import { useBoardName } from "../../../../utils/reactQuery/boards";
import { TooltipName } from "../../../../utils/Enums";
import { removeHtmlTagsAndEntities } from "../../../../utils/helpers";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

export default function CustomizedDialogs({
  data,
  cardId,
  boardId,
  cardsOfSameWorkflowStepId,
  showDetails,
  setShowDetails,
  cardsOnBoard,
}) {
  const [currentCardIndex, setCurrentCardIndex] = useState(null);
  const [removeMultiParams] = useRemoveMultipleParams();
  const queryClient = useQueryClient();
  // const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const descRef = React.useRef("");
  const [isFocus, setIsFocus] = useState(false);
  const isMobile = useResponsive("down", "md");
  const isBetween700And900 = useResponsive("between", 700, 900);
  const [cardTitle, setCardTitle] = useState("");

  const { showToastPromise } = useToastPromise();
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);

  const cardDetail = card?.data?.data?.responseData;
  const { boardName } = useBoardName(card?.data?.data?.responseData?.board_id)
  const board_permission = boardName?.data?.data?.responseData?.board_permission;
  const dispatch = useDispatch();

  useEffect(() => {
    if (cardId && cardsOfSameWorkflowStepId.length > 0) {
      const index = cardsOfSameWorkflowStepId.findIndex(
        (item) => item.card.card_id == cardId
      );
      setCurrentCardIndex(index !== -1 ? index : null);
    }
  }, [cardId, cardsOfSameWorkflowStepId, boardName]);

  useEffect(() => {
    setCardTitle(card?.data.data.responseData?.title)
  }, [card, cardId])

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const checkIsOwnerOrAdmin = isAdmin() ||
    card?.data?.data?.responseData?.board.owner_user_id === currentUserId() ||
    board_permission?.is_admin;

  const accessForDeleteCard =
    checkIsOwnerOrAdmin;
  // card?.data?.data?.responseData?.reporter === currentUserId() ||
  // board_permission?.can_delete_card;

  const accessForEditCard = checkIsOwnerOrAdmin ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();
  //  || card?.data?.data?.responseData?.is_card_collaborator;
  // board_permission?.can_edit_card;

  const conditionCheck =
    checkIsOwnerOrAdmin ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();
  //  || card?.data?.data?.responseData?.is_card_collaborator;
  // &&boardPermission?.can_edit_card


  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      const newIndex = currentCardIndex - 1;

      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  const handleNext = () => {
    if (currentCardIndex < cardsOfSameWorkflowStepId.length - 1) {
      const newIndex = currentCardIndex + 1;

      const newCardId = cardsOfSameWorkflowStepId[newIndex]?.card.card_id;
      updateSearchParams("card_id", newCardId);
      setCurrentCardIndex(newIndex);
    }
  };

  const handleClose = () => {
    if (isFocus) {
      handleSave();
    }
    removeMultiParams(["card_id", "brd_id"]);
    setShowDetails(false);
    dispatch(clearCheckedIds());
  };

  const { mutate: descriptionUpdateMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(descriptionUpdate, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [`card`, cardId] });
      //toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("An error occurred while updating task description", error);
      //toast.error(error.response.data.message);
    },
  });

  const handleSave = (text) => {
    const currentDescription = removeHtmlTagsAndEntities(card?.data?.data?.responseData?.description || '')?.trim();
    const newDescription = removeHtmlTagsAndEntities(descRef?.current?.value || '')?.trim();
    if (currentDescription !== newDescription) {
      descriptionUpdateMutate({
        card_id: cardId,
        data: {
          description: descRef?.current?.value,
        },
      });
    }

  };

  const handleReload = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("card_id");
    setSearchParams(newSearchParams);
  };


  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="card-detail-dialog"
        open={showDetails}
        fullScreen={isMobile}
        sx={{
          zIndex: 1200,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: isMobile ? "100vh" : "calc(100vh - 30px)",
              borderRadius: isMobile ? "" : "12px",
              margin: isMobile ? "auto" : "15px auto",
              // marginTop: isMobile ? "25px" : "",
              maxWidth: "1180px",
            },
          },
        }}
      >
        {isCardLoading ? (
          <div className="loader-center">
            <Loader color={"black"} />
          </div>
        ) : isCardError ? (
          <CardNotFound
            title="The card you are looking for does not exist or has been
            deleted."
            btnTitle="Reload page"
            handleReload={handleReload}
            icon={errorIcon}
          />
        ) : (
          <>
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                alignItems: "center",
                backgroundColor: "rgba(242, 246, 254, 1)",
                borderBottom: "1px solid rgba(27, 62, 97, 0.16)",
              }}
              id="customized-dialog-title"
              className={`d-flex header ${card?.data?.data?.responseData?.parent_card_id &&
                "parent-card-detail"
                }`}
            >
              {card?.data?.data?.responseData?.parent_card_id && (
                <Tooltip title={TooltipName.OPEN_PARENT_TASK_DETAIL}>
                  <span
                    className="related_time parent-title-id mb-2 cursor-pointer"
                    onClick={() => {
                      updateSearchParams(
                        "card_id",
                        card?.data?.data?.responseData?.parent_card_id
                      );
                    }}
                  >
                    {`${card?.data?.data?.responseData?.board?.key} - ${card?.data?.data?.responseData?.parent_card_id}`}
                  </span>
                </Tooltip>
              )}
              <div className="modal-header-container">
                {isMobile && (
                  <img
                    src={mobileArrow}
                    alt=""
                    onClick={handleClose}
                    className="board-owner-avtar-click"
                    style={{ opacity: "1 !important", pointerEvents: "auto" }}
                  />
                )}
                <div
                  className={`${isMobile ? (isBetween700And900 ? "ipad-" : "mobile-") : ""
                    }modal-header-inner-container`}
                >
                  <div
                    className={
                      isMobile
                        ? "mobile-left-heading"
                        : "d-flex align-items-center"
                    }
                  >
                    <div
                      className={
                        isMobile ? "mobile-project-name" : "project-name d-flex"
                      }
                    >
                      <div>
                        <div
                          className={
                            isMobile ? "mobile-project-name" : "project-name"
                          }
                        >
                          {`${card?.data?.data?.responseData?.board?.key} - ${cardId}`}
                        </div>
                      </div>
                    </div>

                    {card && (
                      <div
                        className={
                          card?.data?.data?.responseData?.parent_card_id
                            ? "pt-10"
                            : ""
                        }
                      >
                        {card && cardDetail && cardDetail?.workflow_step_id ? (
                          <ChangeStatus
                            currentStatusId={cardDetail.workflow_step_id}
                            cardId={cardId}
                            checkCondition={conditionCheck}
                            card={card}
                          />
                        ) : (
                          <ChangeTodoStatus
                            todoStatus={cardDetail?.todo_column}
                            cardId={cardId}
                            card={card}
                            isBoardAdmins={board_permission?.is_admin}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {isMobile && accessForEditCard && (
                    <div className="mobile-attachment">
                      <AttachFile cardId={cardId} isMobile={true} />
                    </div>
                  )}
                </div>

                {!isMobile && (
                  <div className="d-flex right-heading">
                    {(card && cardDetail && !cardDetail?.card_has_subtask && accessForEditCard) && (
                      <DependsOnDropdown
                        cardId={Number(cardId)}
                        card={card}
                      />
                    )}
                    {(card &&
                      !card?.data?.data?.responseData?.card_has_subtask &&
                      !card?.data?.data?.responseData?.parent_card_id && checkIsOwnerOrAdmin) && (
                        <ChangeBoard
                          cardId={cardId}
                          card={card}
                        />
                      )}
                    {accessForEditCard && (
                      <div className="header-attach-btn">
                        <AttachFile cardId={cardId} />
                      </div>
                    )}
                    <>
                      {accessForDeleteCard && (
                        <DeleteCard
                          cardId={cardId}
                          boardId={boardId}
                          setShowDetails={setShowDetails}
                          DeleteIconStyle={'cardDetail-delete-icon'}
                        />
                      )}
                    </>
                  </div>
                )}
              </div>
            </DialogTitle>

            {isMobile ? (
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 9,
                  padding: "8px",
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={() => {
                  setFilterDrawer(true);
                }}
              >
                <img src={mobileDots} alt="" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  padding: "8px",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            <DialogContent dividers sx={{ paddingBottom: "50px !important" }}>
              <Typography gutterBottom>
                <BasicGrid
                  description={card?.data.data.responseData?.description}
                  title={cardTitle}
                  cardId={cardId}
                  data={card?.data.data.responseData}
                  descRef={descRef}
                  isfocus={setIsFocus}
                  handleDescriptionSave={handleSave}
                  setCardTitle={setCardTitle}
                  boardPermission={board_permission}
                />
              </Typography>
            </DialogContent>
            {currentCardIndex > 0 && (
              <IconButton
                className="card-detail-prev-icon"
                aria-label="previous"
                onClick={handlePrevious}
                sx={{
                  position: "absolute",
                  left: 16,
                  bottom: "50%",
                  padding: "12px",
                  backgroundColor: "#DCDCDC",
                  borderRadius: "50%",

                  zIndex: 1000,
                  transition: "all 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
            {currentCardIndex < cardsOfSameWorkflowStepId.length - 1 && (
              <IconButton
                aria-label="next"
                className="card-detail-next-icon"
                onClick={handleNext}
                sx={{
                  position: "absolute",
                  right: 16,
                  bottom: "50%",
                  padding: "12px",
                  backgroundColor: "#DCDCDC",
                  borderRadius: "50%",

                  zIndex: 1000,
                  transition: "all 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </>
        )}
      </BootstrapDialog>
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}
          sx={{ zIndex: 1300 }}
        >
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}
          >
            <Box p={1}>
              {accessForDeleteCard && (
                <DeleteCard
                  cardId={cardId}
                  boardId={boardId}
                  setShowDetails={setShowDetails}
                />
              )}
              {(card && checkIsOwnerOrAdmin) && <ChangeBoard cardId={cardId} card={card} accessForEditCard={checkIsOwnerOrAdmin} />}

              {(card && cardDetail && !cardDetail?.card_has_subtask && accessForEditCard) && (
                <DependsOnDropdown cardId={Number(cardId)} card={card} />
              )}
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}
