import React, { useState } from "react";
import { createAvtarName } from "../../../../utils/helpers";
import Avatar from "../../../../components/Avatar";
import UserProfile from "./UserProfile";

function UserName({ params }) {
  const [userProfile, setUserProfile] = useState(false);

  const handleUserClick = () => {
    setUserProfile(true);
  };

  return (
    <>
      {userProfile && (
        <UserProfile
          open={userProfile}
          userId={params?.row?.user_id}
          handleClose={() => setUserProfile(false)}
        />
      )}
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={handleUserClick}>
        <Avatar
          // key={`user-avtar-${el.user_id}`}
          username={`${params.row.first_name} ${params.row.last_name}`}
          user_image={params.row.user_image}
          title={`${params.row.first_name} ${params.row.last_name}`}
          className="user-avtar-dropdown user-avatar  mr-2"
          style={
            !params.row.user_image && {
              bgcolor: params.row?.profile_color?.background,
              color: params.row?.profile_color?.text,
            }
          }>
          {createAvtarName(`${params.row.first_name} ${params.row.last_name}`)}
        </Avatar>

        <p
          style={{
            textDecoration: "underline",
          }}>{`${params.row.first_name} ${params.row.last_name}`}</p>
      </div>
    </>
  );
}

export default UserName;
