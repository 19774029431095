import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { updateCard } from "../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useToastPromise from "../../../../hooks/useToast";

export default function CardTitleField({
  value,
  cardId,
  isReadOnly,
  height = 32,
  fontColor = "",
}) {
  const { id: boardId } = useParams();
  const queryClient = useQueryClient();
  const { showToastPromise } = useToastPromise();
  const [title, setTitle] = useState(value)

  useEffect(() => {
    setTitle(value)
  }, [value]);

  const { mutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateCard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["card", cardId],
      });
      queryClient.invalidateQueries({
        queryKey: ["cards", boardId],
      });
      //toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("An error occurred while updating task title", error);
      //toast.error(error.response.data.message);
    },
  });

  const handleUpdatedTitle = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      e.preventDefault(); // 🚀 Prevents new line
      const trimmedTitle = title?.trim();
      if (trimmedTitle && (trimmedTitle !== value)) {
        mutate({
          card_id: cardId,
          data: { title: trimmedTitle },
        });
      }
    }
  };

  const handleChangeTitle = (e) => {
    e.stopPropagation();
    setTitle(e.target.value)
  }

  return (
    <TextField
      variant="standard"
      onKeyDown={(e) => {
        // e.stopPropagation();
        handleUpdatedTitle(e);
      }}
      onChange={handleChangeTitle}
      sx={{width: '100%'}}
    // value={title}
    value={title}
      multiline
      InputProps={{
        disableUnderline: true, // <== added this
        readOnly: isReadOnly,
        style: {
          height: height,
          border: "none",
          fontSize: "14px",
          color: fontColor,
        },
      }}
    />
  );
}
