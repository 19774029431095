import moment from "moment";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputAdornment, Stack, Typography, Box } from "@mui/material";
import { useResponsive } from "../../hooks/useResponsive";
import { worklogMissingSchemas } from "../../schemas/worklogMissing";
import { RHFCheckbox } from "../RFH/RFHcheckbox";
import RHFTextField from "../RFH/RFHtextfield";
import { RHFRadioGroup } from "../RFH/RHFRadioGroup";
import useMutate from "../../hooks/useMutate";
import { createtMissingWorklog } from "../../sevices/apiWorklog";
import { useSelector } from "react-redux";

function WorklogMissingForm({ date, time, handleClose, dayName }) {
  const isMobile = useResponsive("down", "md");
  const currentUser = useSelector((state) => state.users.logInUser);
  const methods = useForm({
    resolver: yupResolver(worklogMissingSchemas),
    defaultValues: {},
  });
  const { mutate, isPending } = useMutate(
    createtMissingWorklog,
    ["worklog-missing"],
    () => {
      handleClose();
    }
  );

  const onSubmit = async (formData) => {
    const data = {
      user_id: currentUser.user_id,
      leave_date: date,
      ...formData,
    };
    mutate(data);
  };
  const leaveTypeOptions = [
    { value: "Weekend", label: "Weekend" },
    { value: "Holiday", label: "Company Holiday" },
    { value: "Personal Leave", label: "Personal Leave" },
  ];
  return (
    <div className="dialog-content-missing-worklogs">
      <div className="missing-worklog-list-containor">
        <div className="d-flex justify-content-space-between">
          <p className="note">Worklog missing</p>
          <p className="date-hour">{`${dayName} ${moment(date).format(
            "MMM DD, YYYY"
          )} (${time} hrs)`}</p>
        </div>
        <FormProvider {...methods}>
          <div>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div>
                <Stack spacing={2}>
                  <RHFRadioGroup
                    name={"leave_type"}
                    options={leaveTypeOptions}
                    className={"worklog-missing-reason-options"}
                    row={3}
                  />
                  <RHFTextField
                    name="reason"
                    placeholder={"Enter Reason (Optional).."}
                    defaultPadding={true}
                    sx={{ backgroundColor: "#fff" }}
                    multiline
                    rows={2}
                  />
                </Stack>
              </div>

              <Box className="form-actions">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ padding: "8px 32px" }}
                  className="theme-bg-color mr-2"
                  disabled={isPending}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  disabled={isPending}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </div>
        </FormProvider>
      </div>
    </div>
  );
}

export default WorklogMissingForm;
