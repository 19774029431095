import { TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { updateCard } from "../../../../sevices/apiCards";
import toast from "react-hot-toast";
import useToastPromise from "../../../../hooks/useToast";

const ChangeAlocatedHours = ({ allocatedHours, isAccessToChange = false }) => {
  const [newAllocatedHours, setnewAllocatedHours] = useState(allocatedHours?.allocated_hours);
  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient();
  const { showToastPromise } = useToastPromise();

  const { mutate: updateCardmutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateCard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, String(allocatedHours?.board_id)],
      });
      //toast.success(res?.data?.message);
      setOpen(false);
    },
    onError: (error) => {
      console.error("Error Allocated hours", error);
      //toast.error(error?.response?.data?.message);
    },
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (allocatedHours?.allocated_hours !== Number(newAllocatedHours)) {
        if (
          newAllocatedHours !== undefined &&
          newAllocatedHours !== null &&
          newAllocatedHours?.trim() !== "" &&
          /^[1-9]\d*$/.test(newAllocatedHours)
        ) {
          e.preventDefault();
          updateCardmutate({
            card_id: allocatedHours?.card_id,
            data: {
              allocated_hours: newAllocatedHours,
            },
          });
        }
      }

      setOpen(false);
    }
  };

  const handleInputBlur = (e) => {
    if (allocatedHours?.allocated_hours !== Number(newAllocatedHours)) {
      if (
        newAllocatedHours !== undefined &&
        newAllocatedHours !== null &&
        newAllocatedHours?.trim() !== "" &&
        /^[1-9]\d*$/.test(newAllocatedHours)
      ) {
        e.preventDefault();
        updateCardmutate({
          card_id: allocatedHours?.card_id,
          data: {
            allocated_hours: newAllocatedHours,
          },
        });
      }
    }
    setOpen(false);
  }

  const handleAllocatedHoursInput = () => {
    setOpen(true)
  };

  const handleInputHourChange = (e) => {
    setnewAllocatedHours(e?.target?.value)
  };
  return (
    <>
      {
        (open && isAccessToChange) ? <div>
          <TextField
            id="outlined-basic-input"
            value={newAllocatedHours}
            fullWidth
            className="taskdetail-sidebar-right-col"
            size={"small"}
            variant="outlined"
            onChange={handleInputHourChange}
            onKeyDown={handleKeyDown}
            onBlur={handleInputBlur}
            autoFocus
          />

        </div>
          :
          <p className="listView-fontColor" onClick={handleAllocatedHoursInput}>
            {allocatedHours?.allocated_hours ? allocatedHours?.allocated_hours : 'None'}
          </p>
      }



    </>
  );
};

export default ChangeAlocatedHours;
