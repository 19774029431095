import Api from "./axios";

export const getWorkflows = async () => {
  return await Api.get(`/workflows/steps`);
};

export const getWorkflowById = async (workflow_id, board_id) => {
  return await Api.get(`/workflow_steps/${workflow_id}/workflow_steps`);
};

export const changeBoardWorkflow = async ({ board_id, workflow_id, data }) => {
  return await Api.put(
    `/board/${board_id}/workflow?workflow_id=${workflow_id}`,
    data
  );
};
export const getAdminWorkflow = async () => {
  return await Api.get(`/workflows/steps`);
};

export const deleteWorkflow = async (workflow_id) => {
  return await Api.delete(`/workflow/${workflow_id}`);
};

export const createWorkflow = async (data) => {
  return await Api.post(`/workflow`, data);
};

export const updateWorkflow = async ({ workflow_id, data }) => {
  return await Api.put(`/workflow/${workflow_id}`, data);
};

export const deleteWorkflowStep = async ({
  workflow_step_id,
  target_workflow_step_id,
}) => {
  if (target_workflow_step_id) {
    return await Api.delete(
      `/workflow_step/${workflow_step_id}?target_workflow_step_id=${target_workflow_step_id}`
    );
  } else {
    return await Api.delete(`/workflow_step/${workflow_step_id}`);
  }
  // return await Api.delete(`/workflow_step/${workflow_step_id }${target_workflow_step_id?`?target_workflow_step_id=${target_workflow_step_id}`: ''}`)
};

export const getWorkflowStepNameByWorkflowStepId = async (
  workflow_step_id,
  board_id
) => {
  return await Api.get(
    `/workflow_step/${workflow_step_id}?board_id=${board_id}`
  );
};

export const getUserWorkflows = async () => {
  return await Api.get("/workflows/users");
};

export const getGenericWorkflowStatus = async () => {
  return await Api.get("/workflow_steps/generic_workflow");
};

export const getWorkflowStepsByGenericStepId = async (boardId, genericStepId) => {
  return await Api.get(`/mapped_generic/${boardId}?generic_step_id=${genericStepId}`)
}