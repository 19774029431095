import React, { useState } from "react";
import filterIcon from "../styles/svg/switch_filter.svg";
import greyFilterIcon from "../styles/svg/greyFilter.svg";

function ToggleInput({ value, setValue }) {
  return (
    <>
      <label className="switch_tgl">
        <input
          type="checkbox"
          value={value}
          checked={value}
          // defaultValue={defaultValue}
          onChange={(e) => {
            setValue(e.target.checked);
          }}
        />
        <div
          className={`slider_tgl d-flex align-items-center ${
            value ? "" : "justify-content-end"
          }`}
          style={{
            backgroundColor: value ? " #1B3E61" : "#1B3E6129",
            "--bg-img": value ? `url(${filterIcon})` : `url(${greyFilterIcon})`,
          }}>
          {/* {status ? (
            <p className="clr-white ml-2">on</p>
          ) : (
            <p className="clr-white mr-2">off</p>
          )} */}
        </div>
      </label>
    </>
  );
}

export default ToggleInput;
