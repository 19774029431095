import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useGenericWorkflowStatus, useUserWorkflowList, useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { getMyCards } from "../../../../sevices/apiCards";
import Kanban from "../../components/KanbanBoard/Kanban";
import Loader from "../../../../components/Loader";
import { useMineCards } from "../../../../utils/reactQuery/cards";

function UsersKanban({ users }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const unassigned_task = searchParams.get("isUnassigned");
  const boards = searchParams.get("board");
  const { isLoading: genericWorkflowStepsLoading, genericWorkflowStatus: data } = useGenericWorkflowStatus();
  const { userWorkflows } = useUserWorkflowList();
  const genericWorkflowId = userWorkflows?.data?.data?.responseData?.find((workflow) => workflow.is_generic)?.workflow_id;

  const {
    data: mineCards,
    isLoading,
    refetch,
    isRefetching,
  } = useMineCards(
    users,
    [],
    [],
    false,
    genericWorkflowId,
    Boolean(unassigned_task),
    boards?.split(",")
  );


  useEffect(() => {
    // setLoading(true);
    // getMyCards(users,[],[])
    //   .then((res) => {
    //     setCards(res.data.data.responseData);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     if (err.code != "ERR_CANCELED") setLoading(false);
    //     setCards([]);
    //   });
    refetch();
  }, [users]);


  return (
    <>
      {data && !isLoading && mineCards?.data.data.responseData ? (
        <Kanban
          cards={mineCards?.data.data.responseData}
          workflowSteps={data.data.data.responseData
            .filter((el) => !el.is_completed)
            .sort((a, b) => a.step_sequence - b.step_sequence)}
        />
      ) : (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
    </>
  );
}

export default UsersKanban;
