import * as React from "react";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { updateComment } from "../../../../../../sevices/apiComment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "./editComment.scss";
import { useResponsive } from "../../../../../../hooks/useResponsive";
import MentionUserTextEditor from "../addComment/mentionUser";
import { useParams, useSearchParams } from "react-router-dom";
import { useBoardUsers } from "../../../../../../utils/reactQuery/users";
import { List, ListItem, ListItemText, Menu, Stack } from "@mui/material";
import { hasImage, removeHtmlTagsAndEntities } from "../../../../../../utils/helpers";
import Avatar from "../../../../../../components/Avatar";
import useToastPromise from "../../../../../../hooks/useToast";
export default function EditTextFields({
  cardId,
  messg,
  comment_id,
  setShowEdit,
}) {
  const queryClient = useQueryClient();
  const [newComment, setNewComment] = useState(messg);
  const isMobile = useResponsive("down", "md");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { isLoading, error, data, isError } = useBoardUsers(searchParams.get("brd_id") || id);
  const [selectedUserForTag, setSelectedUserForTag] = useState([])
  const [mentionListVisible, setMentionListVisible] = useState(false);
  const [elementPos, setElementPos] = useState();
  const [cursorPosition, setCursorPosition] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const editorRef = React.useRef(null);
  const [selectedId, setSelectedId] = useState([]);
  const [sortUser, setSortUser] = useState("");
  const [isDisable, setIsDisable] = useState(true)
  const { showToastPromise } = useToastPromise();
  const handleSave = (e) => {
    if (newComment && !isDisable) {
      var helper = [];
      selectedId?.map((ele) => {
        for (let i = 0; data?.data?.data?.responseData?.length > i; i++) {
          if (data?.data?.data?.responseData[i]?.user_id === ele) {
            if (
              newComment
                ?.toLowerCase()
                ?.includes(data?.data?.data?.responseData[i]?.username?.toLowerCase())
            ) {
              helper?.push(ele);
            }
          }
        }
      });
    }

    updateCommentMutate({
      comment_id: comment_id,
      comment: newComment,
      tagged_user: [...new Set(helper)]?.join(",") || null,
    });
    setShowEdit(false);
  };

  const handleCancel = () => {
    setShowEdit(false);
  };

  const { mutate: updateCommentMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateComment, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`comment`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });
      //toast.success(res?.data?.message);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error?.response?.data?.message);
    },
  });

  const handleMention = (cursorPos, editor) => {
    const anchorEl = document.getElementById("forAnchor1");
    setElementPos((ele) => ({
      top: ele?.top + anchorEl.getBoundingClientRect().top,
      left: ele?.left + anchorEl.getBoundingClientRect().left,
    }));
    setCursorPosition(cursorPos - 1);
    setAnchorEl(anchorEl);
    setMentionListVisible(true);
  };

  const handleUserSelect = (user) => {
    const quillEditor = editorRef?.current?.getEditor(); // Access the Quill editor
    setSelectedId([...selectedId, user?.user_id]);

    quillEditor.deleteText(cursorPosition, 1); // Remove '@'
    quillEditor.insertText(cursorPosition, `@${user?.username} `); // Insert user's name
    quillEditor.setSelection(cursorPosition + user?.username?.length + 1); // Move cursor

    setMentionListVisible(false); // Hide mention list
  };

  const handleComment = (e)=>{
    const commentData = removeHtmlTagsAndEntities(editorRef?.current?.value)
    if(commentData){
      setNewComment(e)
      setIsDisable(false)
    }else{
      setIsDisable(true)
      setNewComment(messg)
    }
  }

  useEffect(()=>{
    if(data)
    setSelectedUserForTag(data?.data?.data?.responseData)
  },[data])

  const handleChange = (e)=>{
    setSortUser(e?.target?.value)
    const searchedText = e?.target?.value?.trim()
    const filteredUsers = data?.data?.data?.responseData?.filter((user) =>{
      return user?.username?.toLowerCase()?.includes(searchedText?.toLowerCase())
    }
    );
    setSelectedUserForTag(filteredUsers)
  }


  return (
    <div className="d-flex align-items-center">
      <div
        className={
          isMobile
            ? "mobile-edit-comment-textEditor"
            : "edit-comment-textEditor"
        }
        id="forAnchor1"
      >
        <MentionUserTextEditor
          value={newComment}
          onChange={handleComment}
          onMention={handleMention}
          setEelementPros={setElementPos}
          ref={editorRef}
        />
        <Menu
          open={mentionListVisible}
          anchorEl={anchorEl}
          sx={{
            "& .MuiPaper-root": {
              top: `${elementPos?.top}px !important`,
              left: `${elementPos?.left}px !important`,
            },
          }}
          onClose={() => {
            setSortUser("");
            setMentionListVisible(false);
          }}
        >
          <Stack alignItems={"center"} mx={1}>
            <TextField
              placeholder="search..."
              fullWidth
              autoFocus
              value={sortUser}
              // onChange={(e) => setSortUser(e.target.value)}
              onChange={handleChange}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "8px 10px", // Adjust the padding of the input
                },
              }}
            />
          </Stack>
          <List
            sx={{
              maxHeight: "200px",
              overflow: "auto",
              paddingTop: "0px !important",
            }}
          >
            <List>
              {
               selectedUserForTag.length > 0 ?
               selectedUserForTag
                ?.map((user) => (
                  <ListItem
                    button
                    key={user?.id}
                    onClick={() => handleUserSelect(user)}
                  >
                    <Avatar
                      title={`${user?.first_name} ${user?.last_name}`}
                      user_image={user?.user_image}
                      className="user-avatar"
                      style={
                        !hasImage(user?.user_image) && {
                          bgcolor: user?.profile_color?.background,
                          color: user?.profile_color?.text,
                        }
                      }
                    >
                      {user?.username}
                    </Avatar>

                    <ListItemText
                      sx={{ marginLeft: "8px" }}
                      primary={user?.username}
                    />
                  </ListItem>
                )) : (
                  <ListItem>
                    <ListItemText primary="No cards found" sx={{textAlign: "center"}}/>
                  </ListItem>
                )
                }
            </List>
          </List>
        </Menu>
        <div
          className="text-editor-btn-div"
          style={{ marginBottom: isMobile ? "12px" : "" }}
        >
          <Button
            onClick={handleSave}
            className="text-editor-btn1"
            variant="contained"
          >
            <div className="text-editor-btn-content">Save</div>
          </Button>
          <Button
            onClick={handleCancel}
            className="text-editor-btn2"
            variant="outlined"
          >
            <div className="text-editor-btn-content">Cancel</div>
          </Button>
        </div>
      </div>
    </div>
  );
}
