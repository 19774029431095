import moment from 'moment'
import React, { useState } from 'react'
import FormDialog from './FormDialog';
import WorklogMissingForm from './WorklogMissingForm';

function MissingItem({ worklog, daily_worklog_hours }) {
  const [dialog, setDialog] = useState(false);

  const handleClose = () => {
    setDialog(false);
  };

  const date = new Date(worklog.date);
  const dayName = date.toLocaleDateString("en-US", { weekday: "short" });

  return (
    <>
      <div className="d-flex justify-content-space-between ">
        <p className="date-hour">
          {` ${dayName} ${moment(worklog.date).format("MMM DD, YYYY")} (${
            daily_worklog_hours - worklog?.total_hours_spent
          } hrs)`}
        </p>
        <a className="cursor-pointer" onClick={() => setDialog(true)}>
          Didn’t work
        </a>
      </div>
      {dialog && (
        <FormDialog dialog={dialog} close={handleClose}>
          <WorklogMissingForm
            date={worklog.date}
            time={daily_worklog_hours - worklog?.total_hours_spent}
            handleClose={handleClose}
            dayName={dayName}
          />
        </FormDialog>
      )}
    </>
  );
}

export default MissingItem