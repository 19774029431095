import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";

import { useBoardName } from "../../utils/reactQuery/boards";
import BoradHeader from "./components/BoardHeader/BoradHeader";
import Workflows from "./components/Kanban/Workflows";
import { localStorageInstance } from "../../utils/localStorage";
import { useLoginUser } from "../../utils/reactQuery/users";
import { user } from "../../redux/usersSlice";
import Loader from "../../components/Loader";
import ListView from "./components/listView";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import { useResponsive } from "../../hooks/useResponsive";
import { useCards } from "../../utils/reactQuery/cards";
import BoardWorklogComp from "./components/worklog/BoardWorklogComp";

import MilestoneTabs from "./components/MilestoneSection/MilestoneTabs";

function BoardPage() {
  const userId = localStorageInstance.getItem("userId");
  const { data, isLoading } = useLoginUser(userId);
  const { id } = useParams();
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const [worklogs, setWorklogs] = useCustomSearchParams("worklogs");
  const isMobile = useResponsive("down", "md");
  const { boardName, error, isLoading: boardLoad } = useBoardName(id);
  const dispatch = useDispatch();
  const { cards, isLoading: isCardsLoading, refetch } = useCards(id);
  const [loading, setLoading] = useState(false);

  if (data) {
    dispatch(user(data.data.data.responseData));
  }
  const gridView = useSelector((state) => state.search.gridView);
  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id]);

  useEffect(() => {
    if (!worklogs) {
      setWorklogs("worklogs", false);
    }
  }, []);

  console.log("user role ===== ", localStorageInstance.getItem("role"));

  return (
    <>
      {isLoading || boardLoad ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        <>
          {boardName?.data?.data?.responseData?.workflow_id && (
            <BoradHeader
              boardName={boardName?.data}
              boardId={id}
              ownerId={boardName?.data?.data?.responseData?.owner_user_id}
              workflowId={boardName?.data?.data?.responseData?.workflow_id}
              setLoading={() => setLoading(true)}
            />
          )}

          <div
            className={
              milestone
                ? isMobile
                  ? "mt-0"
                  : "mt-185"
                : isMobile
                ? "mt-0"
                : "mt-115"
            }
          >
            {!boardLoad &&
              error &&
              (error?.response?.data?.message === "Permision Denied" ? (
                <div className="loader-center">
                  You are not authorized to view this board.
                </div>
              ) : (
                <div className="loader-center">
                  The board you are looking for does not exist or has been
                  deleted.
                </div>
              ))}
          </div>

          {!boardLoad &&
            boardName?.data?.data?.responseData?.workflow_id &&
            (worklogs === "true" ? (
              <BoardWorklogComp />
            ) : gridView ? (
              <Workflows
                loading={loading}
                boardId={id}
                workflowId={boardName?.data?.data?.responseData?.workflow_id}
                boardName={boardName?.data?.data?.responseData}
              />
            ) : (
              <Box mt={milestone ? 3 : 7.5}>
                {cards && boardName && (
                  <ListView
                    setLoading={() => setLoading(false)}
                    workflowId={
                      boardName?.data?.data?.responseData?.workflow_id
                    }
                    boardName={boardName?.data?.data?.responseData}
                    cards={cards?.data?.data?.responseData?.filter(
                      (itm) => !itm?.card?.parent_card_id && !itm?.card.is_todo
                    )}
                    isLoading={isCardsLoading}
                    allCardsByBoard={cards}
                  />
                )}
              </Box>
            ))}
        </>
      )}
    </>
  );
}

export default BoardPage;
