import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import { useBoardUsers } from "../../../../utils/reactQuery/users";
import Avatar from "../../../../components/Avatar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAvtarName, hasImage } from "../../../../utils/helpers";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { assignCard } from "../../../../sevices/apiCards";
import NotAssignCardIcon from "../../../../styles/svg/unassigned.svg";
import FormDialog from "../../../../components/Dialog/FormDialog";
import { getAPIBaseUrl } from "../../../../utils/constants";
import CommentForm from "../../../../components/CommentPopUp";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import assignedTasks from "../../../../styles/svg/assignedTasks.svg";
import AssignedCardMenu from "./mobileKanban/AssignedCardMenu";


import useToastPromise from "../../../../hooks/useToast";

function AssignCard({
  tooltipName,
  cardId,
  name,
  project,
  accessForAssign,
  callback: refetch,
  isAssignable = true,
  className = "",
  isShowAssignedCards = false,
  hasReassignPermission = false,
  accessForQuickTask = false,
  isTodo
}) {
  const { id: boardId } = useParams();

  const id = boardId || project?.board_id;
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const getUrl = getAPIBaseUrl();
  const { isLoading, error, data, isError } = useBoardUsers(id);
  const [workflowId] = useCustomSearchParams("workflow_id")
  const [openAssignedCardMenu, setOpenAssignedCardMenu] = useState(null);
  const { showToastPromise } = useToastPromise();
  const [openWorkload, setOpenWorkload] = useState(false);

  useEffect(() => {
    const sortedUsers = data?.data?.data?.responseData
      ? data?.data?.data?.responseData?.sort(function (a, b) {
        return a?.username?.localeCompare(b?.username);
      })
      : [];

    const updatedUsers = sortedUsers?.reduce((acc, user) => {
      if (user?.user_id === project?.assigned_to_user_id) {
        // Place the assigned user at the beginning of the array
        acc.unshift(user);
      } else {
        acc.push(user);
      }
      return acc;
    }, []);

    const activeUsers = updatedUsers?.filter(user => user?.active) || [];
    setUsers(activeUsers);
  }, [isLoading, id, data, project]);

  const handleClick = (event) => {
    event.stopPropagation();
    if (isTodo ? accessForQuickTask : accessForAssign)
      setAnchorEl(event?.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setOpenAssignedCardMenu(null);

  };


  const { mutate: assignCardMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(assignCard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      // queryClient.invalidateQueries({
      //   queryKey: [`cards`, id],
      // });
      queryClient.invalidateQueries({
        queryKey: [`userCards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });

      queryClient.invalidateQueries({
        queryKey: [`watching`, workflowId],
      });

      setUserId(null);
      setOpenAssignedCardMenu(null);
      setLoading(false);
      //toast.success(res?.data?.message);
      setOpenDialog(false);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      setUserId(null);
      setOpenAssignedCardMenu(null);
      //toast.error(error?.response?.data?.message);
      setLoading(false);
      setOpenDialog(false);
    },
  });

  const assignUser = (id) => {
    setOpenDialog(true);
    setOpenAssignedCardMenu(null);
    setUserId(id);
  };

  const assignCallback = (event) => {
    event.stopPropagation();
    setLoading(true);
    assignCardMutate({
      card_id: project?.card_id,
      data: {},
    });
  };

  const closeConfirmation = (event) => {
    event.stopPropagation();
    setOpenDialog(false);
    setUserId(null)
    setOpenAssignedCardMenu(null)
    handleClose();
  };

  const handleAssignedTasks = (event, id) => {
    event.stopPropagation();
    setUserId(id);
    setOpenAssignedCardMenu(event.currentTarget)
  }
  const handleAssignedCardClose = (event) => {
    event?.stopPropagation()
    setOpenAssignedCardMenu(null);
    setUserId(null)
  };

  const handleMouseOut = () => {
    setOpenWorkload(!openWorkload)
  }

  return (
    <div>

      {userId?.user_id === "null" && openDialog && (
        <ConfirmationDialog
          open={openDialog}
          handleClose={closeConfirmation}
          message={`Are you sure you want to unassigned this task`}
          callback={assignCallback}
          loading={loading}
          btnTitle={"Unassign"}
          title={"Unassign task"}
        />
      )}
      {openDialog && userId?.user_id !== "null" && (
        <FormDialog
          open={openDialog}
          handleClose={closeConfirmation}
          title={`Add a Note for ${userId?.username}`}
          width="657px"
        >
          <CommentForm
            cardId={project?.card_id}
            userId={userId?.user_id}
            boardId={id}
            onClose={() => { handleClose(); setOpenDialog(false); setUserId(null); setOpenAssignedCardMenu(null); }}
            workflow_id={workflowId}
            parentTaskCardId={project?.parent_card_id && project?.parent_card_id}
          />
        </FormDialog>
      )}
      <div onClick={handleClick}>
        {project?.assigned_to_user_id ? (
          <Avatar
            title={tooltipName}
            className={`card-avtar header-avatar ${className}`}
            user_image={project?.assigned_to_user?.user_image}
            style={
              !hasImage(project?.assigned_to_user?.user_image) && {
                bgcolor: project?.assigned_to_user?.profile_color?.background,
                color: project?.assigned_to_user?.profile_color?.text,
              }
            }
          >
            {name}
          </Avatar>
        ) : (
          <Avatar
            unassigned={true}
            title={"Unassigned"}
            className={`card-avtar header-avatar ${className}`}
            user_image={NotAssignCardIcon}
          />
        )}
      </div>
      {isAssignable && (
        <>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            // open={open}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            // sx={{
            //   zIndex: 1301
            // }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
              onMouseLeave: handleMouseOut,
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className={`assign-board-owner-list ${!openDialog && (openWorkload ? "" : "z-1301")}`}
          >
            <MenuItem
              onClick={() =>
                assignUser({
                  user_id: "null",
                  username: "unassigned",
                })
              }
            >
              None
            </MenuItem>
            {users?.map((el) => {
              const convertedImage = getUrl + el.user_image;
              return (
                <MenuItem
                  key={`user-avtar-dropdown-card-assign-${el?.user_id}`}
                  onClick={(event) => {
                    // event.stopPropagation();
                    if (el.user_id !== project?.assigned_to_user_id) {
                      assignUser(el);
                    }
                  }}
                  className={
                    `d-flex justify-content-space-between user-menu-item ${project?.assigned_to_user_id &&
                      project.assigned_to_user_id == el.user_id
                      ? "active-assign"
                      : ""}`
                  }
                >
                  <Box className="d-flex align-items-center " >
                    <Avatar
                      key={`user-avtar-${el?.user_id}`}
                      username={el?.username}
                      user_image={el?.user_image}
                      title={el?.username}
                      className="user-avtar-dropdown user-avatar mr-8"
                      style={
                        !hasImage(el?.user_image) && {
                          bgcolor: el?.profile_color?.background,
                          color: el?.profile_color?.text,
                        }
                      }
                    >
                      {createAvtarName(el?.username)}
                    </Avatar>

                    {el?.username}
                  </Box>
                  {isShowAssignedCards && (
                    <IconButton
                      onClick={() => { assignUser(el) }}
                      onMouseOver={(event) => { handleAssignedTasks(event, el) }}
                      className={`user-show-assigned-task-icon ${el?.user_id === userId?.user_id ? "show-assingned-icon" : ""}`}><img src={assignedTasks} alt="assigned Tasks to user" /></IconButton>
                  )}
                </MenuItem>
              );
            })}
          </Menu>
          {
            (openAssignedCardMenu && openAssignedCardMenu !== null && isShowAssignedCards && userId?.user_id && !openDialog) && (
              <AssignedCardMenu openAssignedTask={openAssignedCardMenu} handleClose={handleAssignedCardClose} cardId={cardId} userId={userId?.user_id} boardId={id} openWorkload={openWorkload} handleMouseOut={handleMouseOut} />
            )
          }
        </>
      )}
    </div>
  );
}

export default AssignCard;
