import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { objectToFormData } from "../../../utils/helpers";
import { sendForgotPasswordlink } from "../../../sevices/apiAuth";
import GraphicElements from "../../../styles/svg/graphic_elements.svg";
import Logo from "../../../styles/svg/sofmenLogo.svg";
import { useResponsive } from "../../../hooks/useResponsive";
import mobileBg from "../../../styles/svg/login_bg_mobile.svg";
import "./forgotPassword.scss";
import SendLinkConfirmation from "./SendLinkConfirmation";
import ForgotPasswordMessage from "../../../utils/Enums";
import { useAssets } from "../../../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../../../utils/constants";
import TenantSiteName from "../../../components/TenantSiteName";
import useToastPromise from "../../../hooks/useToast";

function ForgotPassword() {
  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = React.useState(false);
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {},
  });
  const isMobile = useResponsive("down", "md");
  const { errors } = formState;
  const { logo } = useAssets();
  const apiUrl = getAPIBaseUrl();
    const { showToastPromise } = useToastPromise();

  const isEmail = (input) => {
    const emailRegex = /^[a-zA-Z0-9._%+!*-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(input);
  };

  const { mutate: forgetPassword, isLoading } = useMutation({
   mutationFn: async (data) => {
      return await showToastPromise(sendForgotPasswordlink, data);
    },
    onSuccess: (res) => {
      //toast.success(res?.data?.message);
      setShowSuccess(true);
    },
    onError: (error) => {
      console.error("An error occurred while creating task", error);
      //toast.error(error?.response?.data?.message);
    },
  });

  const submitData = (data) => {
    const formData = objectToFormData(data);
    if (isEmail(data?.email)) {
      forgetPassword(formData);
    }
  };

  function onError(errors) {
    console.log(errors);
  }

  return (
    <Box
      component="main"
      className="forgotpassword-box"
      sx={{
        backgroundImage: `url(${isMobile ? mobileBg : GraphicElements})`,
      }}
      disableGutters={true}>
      <Box className="forgotpassword-innerBox">
        {logo ? (
          <img
            src={`${apiUrl}/${logo}`}
            alt="not found"
            width={isMobile ? 251 : ""}
            className="login_logo"
          />
        ) : (
          <TenantSiteName />
        )}
        {showSuccess ? (
          <SendLinkConfirmation />
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit(submitData, onError)}
            noValidate
            className="submitForm-box">
            <Typography
              className="text-center black-primary-color mb-4 weight-700"
              component={"h1"}
              variant="h5">
              {ForgotPasswordMessage.FORGOT_PASSWORD}
            </Typography>
            <Typography
              className="text-center forgot-password-para mb-4"
              component={"h1"}
              variant="h5">
              {ForgotPasswordMessage.FORGOT_PASSWORD_MESSAGE}
            </Typography>

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={"Email Address"}
              name="email"
              type="email"
              error={errors?.email ? true : false}
              helperText={errors?.email && errors.email.message}
              autoComplete="email"
              autoFocus
              {...register("email", {
                required: "Email is required",
                validate: {
                  isValidEmail: (value) =>
                    isEmail(value) || "Please enter a valid email address",
                },
              })}
              className="primary-input-field"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="btn-blue capitalize submit-button"
              disabled={isLoading}>
              Send instructions
            </Button>

            <p
              className="card-anchor d-flex justify-content-center mt-4"
              onClick={() => navigate("/")}>
              Back to login
            </p>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ForgotPassword;
