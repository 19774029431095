import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import Boards from "../Filters/Boards";
import Epics from "../Filters/Epics";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";
import { addAllBoard } from "../../../../redux/SearchSlice";
import { useUserWorkflowList } from "../../../../utils/reactQuery/workflows";
import clearFilterIcon from "../../../../styles/svg/clearFilterIcon.svg";
import { useResponsive } from "../../../../hooks/useResponsive";

function Filter() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const Oldboard = searchParams.get("board");
  const reporter = searchParams.get("reporter");
  const [workflowId, setWorkflowId] = useCustomSearchParams("workflow_id");
  const [setMultipleSearchParams] = useSetMultipleParams();
  const isMobile = useResponsive("down", "md");
  const [genericId, setGenericId] = useState(false);
  const { userWorkflows } = useUserWorkflowList();

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const checkedList = useSelector((state) => state.search.boardSearch);

  const clearFilters = () => {
    // removeSearchParam('board')
    removeSearchParams(["board", "reporter"]);
    dispatch(addAllBoard([]));
  };

  const changeReporterSearch = (event) => {
    event.stopPropagation();
    // event.preventDefault();
    if (reporter == "true") {
      removeSearchParams(["reporter"]);
    } else {
      updateSearchParams("reporter", true);
    }
  };
  const checkGenric = (id) => {
    // console.log("ID", userWorkflows?.data?.data?.responseData);

    const temp = userWorkflows?.data?.data?.responseData.filter((ele) => {
      return id == ele.workflow_id;
    });
    if (temp) {
      const isGeneric = temp[0]?.is_generic;
      setGenericId(isGeneric);

      // Set both workflow_id and generic based on isGeneric
      setMultipleSearchParams([
        { name: "workflow_id", value: id },
        { name: "is_generic", value: isGeneric },
      ]);
    }
  };
  return (
    <div className="d-flex align-items-center">
      {isMobile ? (
        <div className="width-100">
          <p className="filter-text" style={{ marginBottom: "5px" }}>
            Filter By:
          </p>

          <Boards isAssignToMe={true} />

          <Button
            className="filter-btn capitalize mt-2"
            sx={{
              height: "38px",
              width: "100%",
              backgroundColor: "rgb(240 249 254)",
            }}
            variant="outlined"
          >
            <label class="custom-checkbox">
              <input
                type="checkbox"
                checked={reporter == "true"}
                onClick={changeReporterSearch}
              />
              <span class="checkmark"></span>
              Include created by me
            </label>
          </Button>

          {checkedList && checkedList.length == 1 && (
            <Epics isAssignToMe={true} />
          )}
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center">
            {(Oldboard || reporter) && (
              <img
                src={clearFilterIcon}
                className="cursor-pointer"
                onClick={clearFilters}
                alt="clear filter"
              />
            )}
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={"Select Workflow:"}
              value={workflowId}
              sx={{ height: "38px", width: "150px", fontSize: "15px" }}
              onChange={(event) => {
                console.log("WORK", event.target.value);
                // setWorkflowId("workflow_id", event.target.value);
                checkGenric(event.target.value);
              }}>
              {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
                return (
                  <MenuItem value={ele.workflow_id}>
                    {ele.workflow_name}
                  </MenuItem>
                );
              })}
            </Select> */}
          </div>
          {checkedList && checkedList.length == 1 && (
            <Epics isAssignToMe={true} />
          )}
          <div className="mr-4">
            <Boards isAssignToMe={true} />
          </div>
          <Button
            className="filter-btn white-button capitalize"
            sx={{
              height: "38px",
              "&:hover": {
                outline: "none",
                backgroundColor: "rgb(240 249 254)",
                border: "1px solid #1b3e61",
              },
            }}
            variant="outlined"
          >
            <label class="custom-checkbox">
              <input
                type="checkbox"
                checked={reporter == "true"}
                onClick={changeReporterSearch}
              />
              <span class="checkmark"></span>
              Include created by me
            </label>
          </Button>
        </>
      )}
    </div>
  );
}

export default Filter;
