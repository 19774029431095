// useForm.js

import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToastPromise from "./useToast";

const useMutate = (
  apiFunc,
  invalidateQue = [],
  onSettled,
  onClose,
  onErrorCallback
) => {
  const { showToastPromise } = useToastPromise();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(apiFunc,data);
    },
    onSuccess: (res) => {
      invalidateQue.map((el) => {
        queryClient.invalidateQueries({
          queryKey: [el],
        });
      });
      // //toast.success(res.data.message);
      onClose?.(res.data);
    },
    onError: (error) => {
      console.error("Error", error);
      onErrorCallback?.();
      // //toast.error(error.response.data.message);
    },
    onSettled: () => {
      onSettled?.();
    },
  });

  return { mutate, isPending };
};

export default useMutate;
