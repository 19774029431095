export const steps = [
  { name: "Open" },
  {
    name: "Completed",
    sx: { position: "sticky", bottom: "48px", backgroundColor: "#fff" },
  },
  {
    name: "Accepted",
    sx: { position: "sticky", bottom: "0px", backgroundColor: "#fff" },
  },
];