import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

import FieldCreateEditCard from "./FieldCreateEditCard";
import { updateCard } from "../../../../sevices/apiCards";
import { truncateText } from "../../../../utils/helpers";
import { Tooltip } from "@mui/material";
import useToastPromise from "../../../../hooks/useToast";

function EditTitle({
  project,
  isEditing,
  callback,
  setIsEditing,
  subtask = false,
  isCardTitle = false,
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [value, setValue] = useState(project?.card?.title);
  const { showToastPromise } = useToastPromise();


  const closeEditing = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setIsEditing(false);
      updateCardAPICall();
    }
    if (e.key === "btnClicked") {
      setIsEditing(false);
      updateCardAPICall();
    }
  };
  const onBlur = () => {
    setIsEditing(false);
    updateCardAPICall();
  };

  const { mutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateCard, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`subTasks`],
      });
      if (id) {
        queryClient.invalidateQueries({
          queryKey: [`cards`, id],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: [`mineCards`],
        });
      }
      //toast.success(res?.data?.message);
      callback?.(value);
    },
    onError: (error) => {
      console.error("Error assig board", error);
      //toast.error(error?.response?.data?.message);
    },
  });

  const updateCardAPICall = () => {
    if (value != project?.card?.title) {
      mutate({
        card_id: project?.card?.card_id,
        data: { title: value },
      });
    }
  };

  

  return (
    <>
      {isEditing ? (
        <FieldCreateEditCard
          value={value}
          setCreateBtnOpen={onBlur}
          handleKeyDown={closeEditing}
          edit={true}
          setTitle={(val) => setValue(val)}
          minRows={1}
          className={"edit-card-textarea"}
        />
      ) : (
        <>
          {subtask ? (
            <p className="card-title-text" title={project?.card?.title}>
              {truncateText(project?.card?.title, 60)}
            </p>
          ) : (
            <p className="card-title-text">
              {isCardTitle
                ? project?.card?.title
                : truncateText(project?.card?.title, 60)}
            </p>
          )}
        </>
      )}
    </>
  );
}

export default EditTitle;
