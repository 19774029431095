import React from "react";
import StatusColumHeader from "../../pages/board/components/listView/StatusColumHeader.js";
import MilestoneColumnHeader from "../../pages/board/components/listView/MilestoneColumnHeader.js";
import AssignColumnHeader from "../../pages/board/components/listView/AssigneeColumnHeader.js";
import CardId from "./CardId.js";
import CardTitleField from "../../pages/board/components/listView/CardTitleField.js";
import ChangeStatus from "../CardComponents/ChangeStatus.js";
import ListAssignCard from "../../pages/board/components/listView/AssignCard.js";
import ChangeMilestone from "../../pages/board/components/listView/ChangeMilestone.js";
import { Stack } from "@mui/material";
import CustomizedProgressBars from "../../pages/board/components/progressBar/ProgressBar.js";
import moment from "moment";
import ChangeAlocatedHours from "../../pages/board/components/listView/ChangeAlocatedHours.js";
import { currentUserId } from "../../utils/constants.js";

export const dataGridColumn = (boardName, havePermissionToEdit, boardPermission) => {
  return [
    {
      id: "cardId",
      label: "Task Id",
      width: 100,
      flex: 1,
      isSortable: true,
      resizable: false,
      isHidden: false,
      renderItem: (boardCard) => {
        return (
          <CardId id={boardCard?.card?.card_id} boardKey={boardCard?.key} />
        );
      },
    },
    {
      id: "title",
      label: "Task Title",
      width: 160,
      flex: 1,
      isSortable: true,
      resizable: false,
      hideable: true,
      hide: true,
      isHidden: false,
      renderItem: (boardCard) => {
        const isEditAllowed = havePermissionToEdit(boardCard?.card);
        // || boardPermission?.can_edit_card;
        return (
          <CardTitleField
            cardId={boardCard?.card?.card_id}
            value={boardCard?.card?.title}
            isReadOnly={!isEditAllowed}
            height={"auto"}
            fontColor={"rgba(33, 43, 54, 0.8)"}
          />
        );
      },
    },
    {
      id: "col3",
      label: <StatusColumHeader />,
      width: 100,
      flex: 1,
      sortable: true,
      resizable: false,
      isHidden: false,
      renderItem: (boardCard) => {
        const isEditAllowed = havePermissionToEdit(boardCard?.card)
        // || boardPermission?.can_update_status;
        return (
          boardName?.workflow_id && (
            <ChangeStatus
              cardId={boardCard?.card?.card_id}
              workflow_id={boardName?.workflow_id}
              boardId={boardCard?.card?.board_id}
              checkCondition={isEditAllowed}
              workflowStepId={boardCard?.card?.workflow_step_id}
            />
          )
        );
      },
    },

    {
      id: "assigned_to_user_name",
      label: <AssignColumnHeader />,
      width: 160,
      flex: 1,
      isSortable: true,
      resizable: false,
      isHidden: false,
      renderItem: (boardCard) => {

        return (
          <ListAssignCard
            cardData={boardCard?.card}
            name={`${boardCard?.card?.assigned_to_user?.first_name} ${boardCard?.card?.assigned_to_user?.last_name}`}
            cardId={boardCard?.card?.card_id}
            tooltipName={`${boardCard?.card?.assigned_to_user?.first_name} ${boardCard?.card?.assigned_to_user?.last_name}`}
            listView={true}
            isAccessForAssign={havePermissionToEdit(boardCard?.card) ||  currentUserId() == boardCard?.card?.reporter ||  currentUserId() == boardCard?.card?.assigned_to_user_id}
              //  || boardCard?.card?.is_card_collaborator}
            // isAccessForAssign={boardPermission?.is_admin || boardPermission?.can_reassign_user}
          />
        );
      },
    },
    {
      id: "col6",
      label: "Label",
      width: 90,
      flex: 1,
      sortable: false,
      resizable: false,
      isHidden: false,
      renderCell: (value) => (
        <span>{(value?.value)}</span>
      ),
      renderItem: (boardCard) => {
        return boardCard?.labels?.length ? (
          <span style={{ color: "#FD7401" }}>
            {boardCard.labels.join(", ")}
          </span>
        ) : (
          <span className="due-date-input-none listView-fontColor">None</span>
        );
        // return (
        //   <span style={{ color: "#FD7401" }}>
        //     {boardCard?.labels?.join(", ")}
        //   </span>
        // );
      },
    },
    {
      id: "col7",
      label: <MilestoneColumnHeader />,
      width: 110,
      flex: 1,
      sortable: true,
      resizable: false,
      isHidden: false,
      renderItem: (boardCard) => {
        const isEditAllowed = havePermissionToEdit(boardCard?.card);
        // || boardPermission?.can_change_milestone;
        return <ChangeMilestone project={boardCard?.card} listView={true} canChangeMilestone={isEditAllowed} />;
      },
    },
    {
      id: "col8",
      label: "Allocated Hours",
      width: 140,
      flex: 1,
      sortable: false,
      resizable: false,
      isHidden: false,

      renderCell: (value) => (
        <span>
          {value?.value} {value?.value ? "hours" : ""}
        </span>
      ),
      renderItem: (boardCard) => {
        const isEditAllowed = havePermissionToEdit(boardCard?.card);
        // || boardPermission?.can_edit_card;
        return (
          <ChangeAlocatedHours allocatedHours={boardCard?.card} isAccessToChange={isEditAllowed} />
        );
      },
    },
    {
      id: "col9",
      label: "Hours Spent",
      width: 110,
      flex: 1,
      sortable: false,
      resizable: false,
      isHidden: false,
      renderCell: (value) => (
        <span>
          {value?.value}{" "}
          {value?.value ? (value?.value == 1 ? "hour" : "hours") : ""}
        </span>
      ),
      renderItem: (boardCard) => {
        return (
          <p className="listView-fontColor">
            {boardCard?.total_hours_spent}{" "}
            {boardCard?.total_hours_spent
              ? boardCard?.total_hours_spent == 1
                ? "hour"
                : "hours"
              : (<span className="due-date-input-none listView-fontColor">None</span>)}
          </p>
        );
      },
    },
    {
      id: "col10",
      label: "Time Tracking",
      width: 220,
      flex: 1,
      sortable: false,
      resizable: false,
      isHidden: false,
      renderItem: (boardCard) => {
        return (
          <Stack sx={{ width: "100%" }}>
            <CustomizedProgressBars
              allocatedHours={boardCard?.card?.allocated_hours}
              hoursSpent={
                boardCard?.total_hours_spent
                  ? boardCard?.total_hours_spent
                  : "0"
              }
              listView={true}
            />
          </Stack>
        );
      },
    },
    {
      id: "col11",
      label: "Due Date",
      width: 140,
      flex: 1,
      sortable: false,
      resizable: false,
      isHidden: false,
      renderCell: (value) => <span>{value?.value}</span>,
      renderItem: (boardCard) => {
        return (
          <p className="listView-fontColor">
            {boardCard?.card?.due_date
              ? moment(boardCard?.card?.due_date).local().format("MMMM D,YYYY")
              : (<span className="due-date-input-none listView-fontColor">None</span>)}
          </p>
        );
      },
    },
    {
      id: "col12",
      label: "Created Date",
      width: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      isHidden: false,
      renderCell: (value) => <span>{value?.value}</span>,
      renderItem: (boardCard) => {
        return (
          <p className="listView-fontColor">
            {boardCard?.card?.date_created
              ? moment(boardCard?.card?.date_created)
                  .local()
                  .format("MMMM D,YYYY")
              : (<span className="due-date-input-none listView-fontColor">None</span>)}
          </p>
        );
      },
    },
    {
      id: "col13",
      label: "Reporter",
      width: 150,
      flex: 1,
      sortable: false,
      resizable: false,
      isHidden: false,
      renderCell: (value) => <span>{value?.value}</span>,
      renderItem: (boardCard) => {
        return (
          <p className="listView-fontColor">
            {boardCard?.card?.reported_by?.first_name || ""}{" "}
            {boardCard?.card?.reported_by?.last_name || ""}{" "}
          </p>
        );
      },
    },
  ];
};
