import { Button, Chip, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useUserBoardEpic } from "../../../../utils/reactQuery/epics";
import DownArrow from "../../../../styles/svg/downArrow.svg";
import { addAllEpic, addEpic } from "../../../../redux/SearchSlice";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useUserWorkflowList } from "../../../../utils/reactQuery/workflows";
import { useMineCards } from "../../../../utils/reactQuery/cards";
function Epics({ isAssignToMe =false}) {
  const dispatch = useDispatch();
  const checkedList = useSelector((state) => state.search.epicSearch);
  const board_id = useSelector((state) => state.search.boardSearch)[0];
  const { epic } = useUserBoardEpic(board_id);
  const epicResponseData = epic?.data?.data?.responseData || [];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useResponsive("down", "md");

  const [milestones, updateParams] = useCustomSearchParams("milestone");
  const [assignedMilestone, setAssignedMilestone] = useState([]);
  const user = useSelector((state) => state.search.myTaskUser);



      const { userWorkflows } = useUserWorkflowList();
      const genericWorkflowId = userWorkflows?.data?.data?.responseData?.find(
        (workflow) => workflow.is_generic
      )?.workflow_id;
      const {
        data: mineCards,
        isLoading,
        refetch,
        isRefetching,
      } = useMineCards(user || [], "", "", "", genericWorkflowId);


          useEffect(() => {
            if (isAssignToMe && epicResponseData && mineCards) {
              let boardsWithCards = new Set(
                mineCards?.data.data.responseData?.map(
                  (card) => card.card.epic_id
                ) || []
              );
              const filteredBoards = epicResponseData.filter((epic) =>
                boardsWithCards.has(epic.epic_id)
              );
              setAssignedMilestone(filteredBoards);
            } else {
              setAssignedMilestone(epicResponseData);
            }
          }, [epicResponseData, isLoading]);

  useEffect(() => {
    if ((milestones, epic)) {
      const urlEpic = milestones?.split(",").map((el) => el * 1);
      if (urlEpic)
        dispatch(
          addAllEpic(
            assignedMilestone
              ?.filter((el) => urlEpic.includes(el.epic_id))
              .map((el) => el.epic_id)
          )
        );
    }
  }, [epic]);

  const selectEpic = (event, id) => {
    if (checkedList.includes(id)) {
      updateParams(
        "milestone",
        checkedList.filter((el) => el != id)
      );
      dispatch(addEpic(id));
    } else {
      updateParams("milestone", [...checkedList, id]);
      dispatch(addEpic(id));
    }
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      updateParams(
        "milestone",
        assignedMilestone?.map((el) => el.epic_id)
      );
      dispatch(addAllEpic(assignedMilestone?.map((el) => el.epic_id)));
    } else {
      dispatch(addAllEpic([]));
      updateParams("milestone", []);
    }
  };

  useEffect(() => {
    if (milestones) {
      const milestonesTemp = milestones
        .split(",")
        .map((milestone) => milestone * 1);

      milestonesTemp.length > 1
        ? dispatch(addAllEpic(milestonesTemp))
        : dispatch(addEpic(milestones));
    }
  }, []);

  const handleDeleteMilestones = () => {
    updateParams("milestone", []);
    dispatch(addAllEpic([]));
  };

  const selectedMilestoneCount = checkedList.length;

  return (
    <div className="mr-2">
      {isMobile ? (
        <>
          <div className="milestone_mbl">
            <p className="filter-text">Select Milestone:</p>
            {epic && assignedMilestone?.length > 0 && (
              <MenuItem className="custom-border">
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    onChange={selectAll}
                  />
                  <span class="checkmark"></span>
                  <p className="board-name-filter">Select all milestone</p>
                </label>
              </MenuItem>
            )}
            {epic &&
              assignedMilestone?.length > 0 &&
              assignedMilestone?.map((el, idx) => {
                return (
                  <MenuItem
                    key={`my-task-epic-${idx}`}
                    className="custom-border"
                    onClick={(event) => selectEpic(event, el.epic_id)}
                  >
                    <label class="custom-checkbox">
                      <input
                        type="checkbox"
                        id={`board-list-my-task-checkbox-all-board`}
                        checked={checkedList.includes(el.epic_id)}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                      <span class="checkmark"></span>
                      <p className="board-name-filter">{el.epic_name}</p>
                      {/* <p>
                    {el.epic_id}
                  </p> */}
                    </label>
                  </MenuItem>
                );
              })}
            {epic && !assignedMilestone?.length && (
              <MenuItem>No milestone found</MenuItem>
            )}
          </div>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          id="epics-board-dropdown"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<img src={DownArrow} />}
          className="filter-btn white-button ml-2"
          sx={{
            height: "38px",
            "&:hover": {
              outline: "none",
              backgroundColor: "#fff",
              border: "1px solid #1b3e61",
            },
          }}
        >
          <Chip
            label={
              <div className="d-flex justify-content-space-between">
                <p className="filter-name">milestone</p>
                <p className="filter-count">
                  {selectedMilestoneCount === 0 ? "" : selectedMilestoneCount}
                </p>
              </div>
            }
            // label={`milestone(${selectedMilestoneCount})`}
            className="filter-chip"
            {...(selectedMilestoneCount > 0
              ? { onDelete: handleDeleteMilestones }
              : {})}
          />
        </Button>
      )}
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {epic && assignedMilestone?.length > 0 && (
          <MenuItem>
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id={`board-list-my-task-checkbox-all-board`}
                onChange={selectAll}
              />
              <span class="checkmark"></span>
              Select all milestone
            </label>
          </MenuItem>
        )}
        {epic &&
          assignedMilestone?.length > 0 &&
          assignedMilestone?.map((el, idx) => {
            return (
              <MenuItem
                key={`my-task-epic-${idx}`}
                className="custom-border"
                onClick={(event) => selectEpic(event, el.epic_id)}
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    id={`board-list-my-task-checkbox-all-board`}
                    checked={checkedList.includes(el.epic_id)}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                  <span class="checkmark"></span>
                  <p className="board-name-filter">{el.epic_name}</p>
                </label>
              </MenuItem>
            );
          })}
        {epic && !assignedMilestone?.length && (
          <MenuItem>No milestone found</MenuItem>
        )}
      </Menu>
    </div>
  );
}
export default Epics;
