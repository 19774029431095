import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Drawer,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import User from "./User";
import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import Status from "./Status";
import TeamFilter from "./TeamFilter";
import GlobalSearch from "../../../../components/Search";
import { addAllBoard, searchCard } from "../../../../redux/SearchSlice";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useUserWorkflowList } from "../../../../utils/reactQuery/workflows";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";
import { useBoards } from "../../../../utils/reactQuery/boards";
import { setActiveBoards } from "../../../../redux/ActiveBoardSlice";
import { useResponsive } from "../../../../hooks/useResponsive";
import filterIcon from "../../../../styles/svg/epic-icon.svg";
import { useTeams } from "../../../../utils/reactQuery/teams";
import { useUsers } from "../../../../utils/reactQuery/users";
import Team from "../../components/Filters/Team";
import Users from "../../components/Filters/Users";
import Boards from "../../components/Filters/Boards";
import clearFilterIcon from "../../../../styles/svg/clearFilterIcon.svg";
import ToggleInput from "../../../../components/ToggleInput";
import { TooltipName } from "../../../../utils/Enums";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function Header() {
  const dispatch = useDispatch();
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const checkedList = useSelector((state) => state.search.boardSearch);
  const [filterdUser, setFilterUser] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [boardId] = useCustomSearchParams("board_id");
  const [searchParam, setSearchParam] = useCustomSearchParams("txt");
  const isMobile = useResponsive("down", "md");
  const { boards } = useBoards();
  const trigger = useScrollTrigger();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { teams, isLoading: teamIsLoading } = useTeams();
  const userTeam = useSelector((state) => state.users.logInUser.team_id);
  const [searchParams, setSearchParams] = useSearchParams();
  const { users, isLoading: usersIsLoading } = useUsers();
  const [userParams, setUserParams] = useCustomSearchParams("uid");

  useEffect(() => {
    dispatch(
      setActiveBoards(
        boards?.data?.data?.responseData
          .filter((el) => !el.is_archive)
          .sort(function (a, b) {
            return a.board_name.localeCompare(b.board_name);
          }) || []
      )
    );
  }, [boards]);

  useEffect(() => {
    setInputValue(searchParam || "");
    dispatch(searchCard(searchParam || ""));
    if (
      searchParams.get("board") ||
      searchParams.get("uid") ||
      searchParams.get("switch") == "true"
    ) {
      setIsVisible(true);
    }

    return () => {
      clearFilters();
    };
  }, []);

  const clearFilters = () => {
    dispatch(setAllGroupUser([]));
    dispatch(addAllBoard([]));
    setInputValue("");
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
      setSearchParam("txt", value);
    }, 300)
  ).current;

  const handleChange = (event) => {
    const value = event.target.value.trimStart();
    setInputValue(value);
    debouncedSearch(value);
  };

  // useEffect(() => {
  //   if (teams && users) {
  //     if (userParams && userParams[0]) {
  //       userParams?.split(",").forEach((element) => {
  //         dispatch(
  //           setGroupUser(
  //             users.data.data.responseData.find(
  //               (user) => user.user_id === element * 1
  //             )
  //           )
  //         );
  //       });
  //     } else if (!searchParams.get("user") && teams) {
  //       teams?.data.data.responseData
  //         .filter((ele) => ele.team_id == userTeam)?.[0]
  //         .users.forEach((element) => {
  //           dispatch(setGroupUser(element));
  //         });
  //     }
  //   }
  // }, [teamIsLoading, usersIsLoading]);

  useEffect(() => {
    setFilterUser(
      groupingUser
        .map((item) => item.users)
        .flat()
        .filter((el) => el.active)
    );
  }, [groupingUser]);

  useEffect(() => {
    if (
      filterdUser &&
      filterdUser.length &&
      userParams?.split(",").length != filterdUser
    ) {
      setUserParams(
        "uid",
        filterdUser.map((el) => el.user_id)
      );
    }
  }, [filterdUser]);
  return (
    <div>
      {!isMobile && (
        <div className="d-flex">
          <p className="board-heading-name">Task Assignments</p>
          <Status setIsVisible={() => setIsVisible(true)} />
        </div>
      )}
      {isMobile ? (
        <>
          <Box sx={{ height: "55px", visibility: "hidden" }}></Box>
          <Grid
            container
            xs={12}
            className="remove-container-width fixed-ui"
            sx={{
              position: "fixed !important",
              top: trigger ? "0px" : isMobile ? "51px" : "",
            }}
            justifyContent={"center"}
            pb={"18px !important"}
            pt={trigger ? "24px !important" : "20px !important"}
            alignItems={"center"}>
            <Grid
              item
              xs={6}
              md={12}
              lg={3}
              className="d-flex align-items-center">
              <Typography fontWeight={600}>Assigned to Team</Typography>
            </Grid>
            <Grid item xs={6} md={7} lg={9}>
              <Stack direction="row" justifyContent="end">
                <img
                  src={filterIcon}
                  alt="filter"
                  style={{ marginRight: "8px" }}
                  onClick={() => setFilterDrawer(true)}
                />
              </Stack>{" "}
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="d-flex align-items-center mt-4">
          <ToggleInput
            value={isVisible}
            setValue={(value) => {
              setIsVisible(value);
              if (!value) {
                clearFilters();
              }
            }}
          />
          <div className={`slide-panel d-flex ${isVisible ? "open" : ""}`}>
            {teams && (
              <Team
                team={teams}
                user={groupingUser.map((item) => item.users).flat()}
              />
            )}
            {/* {teams && (
              <Users user={groupingUser.map((item) => item.users).flat()} />
            )} */}
            {/* {teams && <TeamFilter team={teams} />} */}
            <Boards />
            <div className="ml-2">
              <GlobalSearch
                placeholder={"Search..."}
                searchText={inputValue}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      )}
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}>
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}>
            <Box p={1}>
              <div className="mt-4">
                <p className="filter-text">Select user:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <User user={groupingUser.map((item) => item.users).flat()} />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Team:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  {teams && (
                    <TeamFilter
                      team={teams}
                      user={groupingUser.map((item) => item?.users).flat()}
                    />
                  )}
                </div>
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </div>
  );
}

export default Header;
