import { Autocomplete, TextField } from '@mui/material';
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form';

export const RFHAutocomplete = ({ options = [] }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name="user_id"
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    {...field}
                    options={options}
                    getOptionLabel={(option) => option?.label || ""}
                    onChange={(_, value) => {
                        field.onChange(value?.value || ""); // Update form state with selected option's value
                    }}
                    value={
                        options.find(
                            (option) => option?.value === field?.value
                        ) || null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="User"
                            required
                            error={!!error}
                            helperText={error ? error?.message : ""}
                        />
                    )}
                />
            )}
        />
    )
}

