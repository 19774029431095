import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import GroupUser from "../../../../styles/svg/Team-group.svg";
import { Tooltip } from "@mui/material";
import { TooltipName } from "../../../../utils/Enums";

function Status({ setIsVisible }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isChecked = searchParams.get("switch");
  const [status, setStatus] = useState(isChecked);
  const updateSearchParams = (key, value) => {
    if (value) {
      setIsVisible();
    }
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };
  return (
    <div className="ml-3 mr-3 d-flex align-items center">
      <Tooltip
        title={
          status
            ? TooltipName.TEAM.SWITCH_TO_KANBAN_VIEW
            : TooltipName.TEAM.SWITCH_TO_ACCORDIAN_VIEW
        }>
        <label className="switch_tgl">
          <input
            type="checkbox"
            value={status}
            checked={status}
            onChange={(e) => {
              setStatus((data) => !data);
              updateSearchParams("switch", !status);
            }}
          />

        <div
          className={`slider_tgl_att d-flex align-items-center ${
            status ? "" : "justify-content-end"
          }`}
          style={{
            backgroundColor: status ? " #1B3E61" : "#1B3E6129",
            "--bg-img": `url(${GroupUser})`,
            padding: "5px",
          }}>
          {/* {status ? (
            <p className="clr-white ml-2">on</p>
          ) : (
            <p className="clr-white mr-2">off</p>
          )} */}
          </div>
        </label>
      </Tooltip>
    </div>
  );
}

export default Status;
