import React from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Avatar as Muiavatar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/base";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Avatar from "../../../../components/Avatar";

import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { toggleCheckbox } from "../../../../redux/CheckboxSclice";
import { deleteWorklog } from "../../../../sevices/apiWorklog";
import CustomDateFormatter from "../dateTimeFormateConverter/dateTimeFormateConverter";
import EditWorklog from "../../components/worklog/editWorklog/editWorklog";
import { useCard } from "../../../../utils/reactQuery/cards";
import {
  currentUserId,
  isAdmin,
  isLoggedInUserInternal,
} from "../../../../utils/constants";
import GetHeadShotName from "../../../../utils/getHeadShotName";
import { hasImage, compareDates } from "../../../../utils/helpers";
import WorklogText from "./worklogTextEditor";
import warningIcon from "../../../../styles/svg/warningIcon.svg";
import WorklogIcon from "../../../../styles/svg/woklog-icon.svg";
import { useResponsive } from "../../../../hooks/useResponsive";
import "./worklog.scss";
import useToastPromise from "../../../../hooks/useToast";

const SmallAvatar = styled(Muiavatar)(({ theme }) => ({
  width: 16,
  height: 16,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function ListWorklog({
  data,
  moveWorklogPermission,
  cardId,
  showIcon = false,
  boardPermission,
  boardOwnerId,
}) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  const cardData = card?.data?.data?.responseData;
  const isMobile = useResponsive("down", "md");
  const isBetween300And699 = useResponsive("between", 300, 699);
  const checked = useSelector((state) =>
    state.checkbox.checkedIds.includes(data?.card_log_id)
  );
  const dispatch = useDispatch();
  const { showToastPromise } = useToastPromise();

  const headShotName = GetHeadShotName(data?.username);

  const { mutate: deleteWorklogMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(deleteWorklog, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`worklog`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      setLoading(false);

      //toast.success(res?.data?.message);
      handleClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      //toast.error(error?.response?.data?.message);
      setLoading(false);
      handleClose();
    },
  });

  const handleChange = (id) => {
    dispatch(toggleCheckbox(id));
  };

  const handleEdit = () => {
    setShowEdit(true);
  };

  const handleDelete = () => {
    setShowDelete(true);
  };

  const handleClose = () => {
    setShowDelete(false);
  };

  function callback() {
    setLoading(true);

    deleteWorklogMutate(data?.card_log_id);
  }

  return (
    <>
      {showDelete && (
        <ConfirmationDialog
          open={showDelete}
          handleClose={handleClose}
          message={
            "Are you sure to you want to delete this item? This action can not be undone"
          }
          callback={callback}
          loading={loading}
          btnTitle={"Delete"}
          title={"Delete confirmation"}
        />
      )}
      {showEdit && (
        <EditWorklog
          card_log_id={data?.card_log_id}
          cardId={cardId}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
        />
      )}
      <Box
        sx={{
          flexGrow: 1,
          px: isMobile && "",
          pl: isMobile ? 1 : "",
          width: isMobile ? "96%" : "100%",
        }}
      >
        <Grid container spacing={2} className="worklog-container">
          <div
            className="worklog-box"
            style={{ width: isMobile ? "100%" : "" }}
          >
            <Grid>
              {showIcon ? (
                <Badge
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp" src={WorklogIcon} />
                  }
                >
                  <Avatar
                    title={data?.username}
                    className={" header-avatar worklog-box-img"}
                    user_image={data?.user_image}
                    style={
                      !hasImage(data?.user_image) && {
                        bgcolor: data?.user_profile_color?.background,
                        color: data?.user_profile_color?.text,
                      }
                    }
                  >
                    {headShotName}
                  </Avatar>
                </Badge>
              ) : (
                <Avatar
                  title={data?.username}
                  className={" header-avatar worklog-box-img"}
                  user_image={data?.user_image}
                  style={
                    !hasImage(data?.user_image) && {
                      bgcolor: data?.user_profile_color?.background,
                      color: data?.user_profile_color?.text,
                    }
                  }
                >
                  {headShotName}
                </Avatar>
              )}
            </Grid>
            <div
              className="worklog-box-body"
              style={{ width: isMobile ? "100%" : "" }}
            >
              <div className="d-flex justify-content-space-between">
                <Grid className="worklog-detail">
                  {isLoggedInUserInternal() ? (
                    <>
                      <Typography
                        className="worklog-box-name"
                        sx={{ fontSize: isMobile ? "14px !important" : "" }}>
                        {data.username}
                        <span className="worklog-status">logged</span>
                      </Typography>
                      <Typography
                        className="worklog-box-hours"
                        sx={{ fontSize: isMobile ? "14px !important" : "" }}>
                        {`${data?.hour_spent} hours`}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      className="worklog-box-name"
                      sx={{
                        fontSize: isMobile ? "14px !important" : "",
                      }}>
                      {data.username}
                    </Typography>
                  )}
                  {moveWorklogPermission && (
                    <Checkbox
                      checked={checked}
                      onChange={(e) => handleChange(data?.card_log_id)}
                      inputProps={{ "aria-label": "controlled" }}
                      className="pa-0 ml-1 checkbox-height"
                    />
                  )}
                </Grid>
                <Grid>
                  {isBetween300And699 ? (
                    ""
                  ) : (
                    <Typography className="worklog-box-date ">
                      <div className="d-flex align-items-center justify-content-end">
                        {!compareDates(data?.created_at, data?.worklog_time) && (
                          <div className="warningicon">
                            {" "}
                            <img src={warningIcon} alt="" />{" "}
                          </div>
                        )}
                        <div className="worklog-box-date-field ml-1">
                          <CustomDateFormatter date={data?.worklog_time} />
                        </div>
                      </div>
                      <div className="created-date-worklog">
                        Created at {" "}
                        {<CustomDateFormatter date={data?.modified_at} />}
                      </div>
                    </Typography>
                  )}
                </Grid>
              </div>
              <Grid>
                <WorklogText text={data?.work_performed} />
              </Grid>
              {/* {cardData?.assigned_to_user_id == currentUserId() && ( */}
              <div>
                {
                  (
                    data?.user_id == currentUserId()
                  // isAdmin() || boardOwnerId == currentUserId() || boardPermission?.is_admin ||
                  // || boardPermission?.can_edit_work_log || (boardPermission?.can_work_before_estimation && boardPermission?.can_edit_work_log)
                  ) && (
                  <Button
                    className="worklog-box-btn1"
                      onClick={() => handleEdit()}
                    >
                    Edit
                  </Button>
                  )
                }
                {
                  (data?.user_id == currentUserId())
                  // isAdmin() || boardOwnerId == currentUserId() || boardPermission?.is_admin
                  // || boardPermission?.can_delete_work_log
                    && (
                  <Button
                    onClick={() => handleDelete()}
                        className="worklog-box-btn2"
                      > Delete </Button>
                    )
                }
              </div>
              {/* )} */}

              {isBetween300And699 ? (
                <Typography className="worklog-box-date ">
                  <div className="d-flex align-items-center">
                    {!compareDates(data?.created_at, data?.worklog_time) && (
                      <div className="warningicon">
                        {" "}
                        <img src={warningIcon} alt="" />{" "}
                      </div>
                    )}
                    <div className="worklog-box-date-field ml-1">
                      <CustomDateFormatter date={data?.worklog_time} />
                    </div>
                  </div>
                  <div className="created-date-worklog">
                    Created at {" "} {<CustomDateFormatter date={data?.modified_at} />}
                  </div>
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
}

export default ListWorklog;
