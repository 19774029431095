import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  defaultStaticRanges,
  createStaticRanges,
} from "react-date-range";

import DownArrow from "../../../../styles/svg/date-range.svg";
import DateRange from "./DateRange";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { setDateRange } from "../../../../redux/SearchSlice";
import { useResponsive } from "../../../../hooks/useResponsive";
import { TooltipName } from "../../../../utils/Enums";

function SelectDatePicker() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const [Olddate, updateParams, removeSearchParam] =
    useCustomSearchParams("date");
  const dateRange = useSelector((state) => state.search.dateRange);
  const open = Boolean(anchorEl);
  const date = JSON.parse(Olddate);
  const isMobile = useResponsive("down", "md");
  const [selectedDateLabel, setSelectedDateLabel] = useState("")

  useEffect(() => {
    if (date) {
      const obj = {
        startDate: new Date(date.startDate),
        endDate: new Date(date.endDate),
        key: "selection",
      };
      dispatch(setDateRange(obj));
    }

    if (!isMobile && dateRange) {
      return () => {
        dispatch(setDateRange(null));
      };
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const heading = dateRange
    ? `${moment(dateRange.startDate).format("MM/DD/YYYY")} - ${moment(
      dateRange.endDate
    ).format("MM/DD/YYYY")}`
    : "Select Date Range";

  const handleSelectedLabel = (value) => {
    setSelectedDateLabel(value ?? "");
  }

  const customStaticRanges = createStaticRanges([
    {
      label: "Last Week",
      range: () => {
        const startDate = moment().subtract(1, "weeks").startOf("week").toDate(); // Start of last week
        const endDate = moment().subtract(1, "weeks").endOf("week").toDate(); // End of last week

        return { startDate, endDate };
      }
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: moment().subtract(7, "days").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Last 14 Days",
      range: () => ({
        startDate: moment().subtract(14, "days").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: 'Custom',
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 3)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 3))
      }),
      hasCustomRendering: true,
    },
  ]);

  useEffect(() => {
    if (date) {
      const allRanges = [...defaultStaticRanges, ...customStaticRanges];
      const staticRange = allRanges.find((range) =>
        moment(range.range().startDate).isSame(moment(date?.startDate), "day") &&
        moment(range.range().endDate).isSame(moment(date?.endDate), "day")
      );
      handleSelectedLabel(staticRange?.label);
    } else {
      handleSelectedLabel("");
    }
  }, [date, Olddate]);



  return (
    <div>
      <Tooltip title={TooltipName.WORKLOG.FILTER_BY_DATE}>
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        startIcon={<img src={DownArrow} />}
        // className="white-button epics-btn"
        className="filter-btn white-button"
        sx={{
          height: "38px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        {/* <img src={apicIcon} className="header-filter-icon" alt="not found" />{" "} */}
        <p className="header-action-button-text mr-1">{selectedDateLabel === "" || selectedDateLabel === undefined ? heading : selectedDateLabel}</p>
      </Button>
      </Tooltip>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        className="date-filter"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={() => setAnchorEl(null)}>Epics 1</MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>Epics 2</MenuItem> */}
        <DateRange onClose={() => handleClose()} handleSelectedLabel={handleSelectedLabel} customStaticRanges={customStaticRanges} />
      </Menu>
    </div>
  );
}
export default SelectDatePicker;
