import { useQuery } from "@tanstack/react-query";
import { getMessages } from "../../sevices/apiChatbot";


export function useGetChatbotMessages(cardId, userId) {
    const {
        isLoading,
        data: messages,
        error,
        isError,
        refetch: getMessageRefetch
    } = useQuery({
        queryKey: ["getMessage", cardId, userId],
        queryFn: () => getMessages(cardId, userId),
        enabled: !!(cardId && userId)
    });

    return { isLoading, error, messages, getMessageRefetch, isError };
}

