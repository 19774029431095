import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AntSwitch } from "../Switch/antSwitch";

export function RHFSwitch({ name, label, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset" error={!!error} variant="standard">
          <FormControlLabel
            control={
              <AntSwitch
                {...field}
                checked={!!field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                // sx={{ height: "25px" }}
              />
            }
            label={label}
            sx={{ marginRight: "0px" }}
            {...other}
          />
          {(!!error || helperText) && (
            <FormHelperText>
              {error ? error.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
