import { Stack } from '@mui/material';
import React from 'react'
import MissingItem from './MissingItem';

function MissingWorklogList({ list, daily_worklog_hours }) {
  return (
    <>
      <div className="missing-worklog-list-containor">
        <p className="note">Submit worklogs for the following days:</p>
        <div>
          <Stack spacing={1}>
            {list?.map((worklog, idx) => (
              <MissingItem
                worklog={worklog}
                daily_worklog_hours={daily_worklog_hours}
                key={`missing-list-item-${idx}`}
              />
            ))}
          </Stack>
        </div>
      </div>
    </>
  );
}

export default MissingWorklogList