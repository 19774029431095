import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import toast from "react-hot-toast";
// import "./ChangeStatus.scss";
import { changeStatusTodo } from "../../sevices/apiTodo";
import useToastPromise from "../../hooks/useToast";
import { isAdmin } from "../../utils/constants";
import { useSelector } from "react-redux";

export default function ChangeTodoStatus({
  todoStatus,
  cardId,
  card,
  isBoardAdmins,
}) {
  const [status, setStatus] = React.useState("");
  const queryClient = useQueryClient();
  const { showToastPromise } = useToastPromise();
  const currentUser = useSelector((state) => state.users.logInUser);

  ////////////-Permissions-//////////

  const checkOwnerOrAdmin =
    isAdmin() ||
    currentUser?.user_id == card?.data?.data?.responseData?.board?.owner_user_id ||
    isBoardAdmins;


   const isAssignee =
     card?.data?.data?.responseData?.assigned_to_user_id ===
       currentUser?.user_id ;
    const isCreator =
      card?.data?.data?.responseData?.reporter === currentUser?.user_id;


 

    const steps = [
      ...(checkOwnerOrAdmin || isAssignee || isCreator
        ? [{ name: "Open" }]
        : []),
      ...(checkOwnerOrAdmin || isCreator || isAssignee
        ? [
            {
              name: "Completed",
              sx: {
                position: "sticky",
                bottom: "48px",
                backgroundColor: "#fff",
              },
            },
          ]
        : []),
      ...(checkOwnerOrAdmin || isCreator
        ? [
            {
              name: "Accepted",
              sx: {
                position: "sticky",
                bottom: "0px",
                backgroundColor: "#fff",
              },
            },
          ]
        : []),
    ];

  ////////////-Permissions-//////////

  const {
    board: { board_id, workflow_id },
  } = card?.data?.data?.responseData;

  React.useEffect(() => {
    setStatus(todoStatus);
  }, [todoStatus]);

  const { mutate: updateMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(changeStatusTodo, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`history`, String(cardId)],
      });
      queryClient.invalidateQueries({
        queryKey: ["cards", String(board_id)],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: ["watching"],
      });
      queryClient.invalidateQueries({
        queryKey: ["mineCards"],
      });
      //toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("An error occurred while updating task", error);
      //toast.error(error.response.data.message);
      setStatus(todoStatus);
    },
  });

  const handleChange = (event) => {
    updateMutate({
      card_id: cardId,
      todo_column: event.target.value,
    });
    setStatus(event.target.value);
  };

  return (
    <Box sx={{}}>
      {steps.some((step) => step.name === status) ? (
        <FormControl size="small" className="card-status-dropdown-parent">
          <Select
            labelId="demo-simple-select-label"
            value={status}
            onChange={handleChange}
            className="card-status-dropdown"
          >
            {steps.map((el, index) => (
              <MenuItem className="uppercase" value={el.name}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <div className="project-status d-flex justify-content-center align-items-center">
          <button className="project-status-button">{status}</button>
          <div className="project-status-icon"></div>
        </div>
      )}
    </Box>
  );
}
