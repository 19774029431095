import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query';

import useMutate from '../../../../hooks/useMutate';
import { changeStatusTodo } from '../../../../sevices/apiTodo';
import DoubleSeen from '../../../../styles/svg/doubleSeen.svg'
import GredCheck from "../../../../styles/svg/gred-check.svg";
import GreenCheck from "../../../../styles/svg/green-check.svg";

function TodoChangeStatus({
  status,
  cardId,
  boardId,
  checkOwnerOrAdmin,
  isAssignee,
  creator,
}) {
  const queryClient = useQueryClient();
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredCompleted, setIsHoveredCompleted] = useState(false);
  const [isHoveredAccepted, setIsHoveredAccepted] = useState(false);
  const [isHoveredReOpen, setIsHoveredReOpen] = useState(false);

  const { mutate } = useMutate(changeStatusTodo, [], () => {
    queryClient.invalidateQueries({
      queryKey: [`cards`, boardId],
    });
  });
  //changeStatusTodo
  const changeStatus = (check) => {
    mutate({ card_id: cardId, todo_column: check });
  };
  
  return (
    <div className="todo-status-icons">
      {status === "Open" && (checkOwnerOrAdmin || isAssignee || creator) && (
        <>
          <img
            className="cursor-pointer ml-1"
            src={isHovered ? GreenCheck : GredCheck}
            onClick={(event) => {
              event.stopPropagation();
              changeStatus("Completed");
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            alt="read-to-completed"
          />
        </>
      )}

      {status === "Completed" && (
        <div className=" ml-1">
          {(checkOwnerOrAdmin || creator || isAssignee) && (
            <img
              className="cursor-pointer"
              src={isHoveredReOpen ? GredCheck : GreenCheck}
              onMouseEnter={() => setIsHoveredReOpen(true)}
              onMouseLeave={() => setIsHoveredReOpen(false)}
              onClick={(event) => {
                event.stopPropagation();
                changeStatus("Open");
              }}
              alt="read-to-accepted"
            />
          )}
          {(checkOwnerOrAdmin || creator ) && (
            <img
              className="cursor-pointer"
              style={{ marginLeft: "-6px" }}
              src={isHoveredCompleted ? GreenCheck : GredCheck}
              onMouseEnter={() => setIsHoveredCompleted(true)}
              onMouseLeave={() => setIsHoveredCompleted(false)}
              onClick={(event) => {
                event.stopPropagation();
                changeStatus("Accepted");
              }}
              alt="read-to-accepted"
            />
          )}
        </div>
      )}
      {status === "Accepted" && (checkOwnerOrAdmin || creator) && (
        <div
          className=" ml-1"
          onClick={(event) => {
            event.stopPropagation();
            changeStatus("Completed");
          }}
          onMouseEnter={() => setIsHoveredAccepted(true)}
          onMouseLeave={() => setIsHoveredAccepted(false)}
        >
          <img
            className="cursor-pointer"
            src={GreenCheck}
            alt="back-to-completed"
          />
          <img
            className="cursor-pointer"
            style={{ marginLeft: "-6px" }}
            src={isHoveredAccepted ? GredCheck : GreenCheck}
            alt="back-to-completed"
          />
        </div>
      )}
    </div>
  );
}

export default TodoChangeStatus