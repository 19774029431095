import React, { useState } from "react";
import { useWorklog } from "../../../../utils/reactQuery/worklog";
import ListWorklog from "./listWorklog";
import Button from "@mui/material/Button";
import "./worklog.scss";
import AddWorklogCustomizedDialogs from "./addWorklog/addWorklogDialog";
import Loader from "../../../../components/Loader";
// import { useParams } from "react-router-dom";
import { useBoardName } from "../../../../utils/reactQuery/boards";
import { useCard } from "../../../../utils/reactQuery/cards";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import NoWorklog from "./noWorklog/noWorklog";
import MoveWorklog from "./MoveWorklog";

const ShowWorklog = ({ cardId, textEditor, boardPermission }) => {
  const [addWorklog, setAddWorklog] = useState(false);
  // const [description, setDescription] = useState(null)

  // const { id } = useParams();
  // const { boardName } = useBoardName(id);
  // const boardOwnerId = "boardName?.data?.data?.responseData?.owner_user_id";
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);

  const { assigned_to_user_id, reporter, board: { owner_user_id: boardOwnerId }, is_card_collaborator, collaborators } = card?.data?.data?.responseData;

  const {
    isLoading: isWorklogLoading,
    worklog,
    isError: isWorklogError,
  } = useWorklog(cardId);

  const cardCollabratorUser = collaborators?.map((itm) => itm?.user_id)

  const addWorklogPermission =
    isAdmin() ||
    boardOwnerId == currentUserId() ||
    boardPermission?.is_admin ||
    assigned_to_user_id == currentUserId() ||
    is_card_collaborator ;
    // || reporter == currentUserId();

  // boardPermission?.can_add_work_log || (boardPermission?.can_work_before_estimation && boardPermission?.can_add_work_log);

  const handleAddWorklog = () => {
    if (addWorklogPermission) {
      setAddWorklog(true);
    }
  };

  const moveWorklogPermission = isAdmin() || boardOwnerId == currentUserId() || boardPermission?.is_admin;
  // || boardPermission?.can_move_edit_work_log;

  const sortWorklogsByDateDescending = (worklogs) => {
    // Check if comments array is defined and not null before sorting
    if (worklogs && worklogs.length > 0) {
      return worklogs.sort(
        (a, b) => new Date(b.worklog_time) - new Date(a.worklog_time)
      );
    } else {
      return []; // Return an empty array if comments are not defined
    }
  };

  return (
    <>
      {!worklog?.data?.data?.responseData[0] || textEditor ? null : (
        <div className="add-worklog-btn">
          <Button
            onClick={handleAddWorklog}
            disabled={!(addWorklogPermission)}
            sx={{
              "&:hover": {
                backgroundColor: "#1B3E61",
                border: "none",
              },
              "&:disabled": {
                // backgroundColor: "gray !important",
                // color: "darkgray",
                // opacity: "24%",
                cursor: "not-allowed",
              },
            }}
            variant="contained"
          >
            Add Work Log
          </Button>
        </div>
      )}
      {/* <div className='add-worklog-btn' onClick={handleAddWorklog}><Button variant="contained">Add Work Log</Button></div> */}
      {addWorklog && (
        <AddWorklogCustomizedDialogs
          cardId={cardId}
          addWorklog={addWorklog}
          setAddWorklog={setAddWorklog}
          heading={"Add Work Log"}
          buttonName={"Save"}
        />
      )}
      {worklog?.data?.data?.responseData[0] && moveWorklogPermission && (
        <div className="d-flex justify-content-end align-items-center mt-4">
          <p>Select target task:</p>
          <MoveWorklog cardId={cardId} />
        </div>
      )}
      {isWorklogLoading ? (
        <div className="worklog-loader">
          <Loader color={"black"} />
        </div>
      ) : worklog?.data?.data?.responseData[0] ? (
        sortWorklogsByDateDescending(worklog?.data?.data?.responseData).map(
          (data) => (
            <ListWorklog
              data={data}
              cardId={cardId}
              moveWorklogPermission={moveWorklogPermission}
              boardPermission={boardPermission}
              boardOwnerId={boardOwnerId}
            />
          )
        )
      ) : (
        <NoWorklog cardId={cardId} premission={addWorklogPermission} />
      )}
    </>
  );
};

export default ShowWorklog;
