import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";

import manageColumnIcon from "../../../../styles/svg/Vector.svg";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { dataGridColumn } from "../../../../components/dataGridColumn";
import OpenCardDetail from "../Kanban/openCardDetail";
import addIcon from "../../../../styles/svg/addIcon.svg";
import CreateCardDialogListview from "./CreateCardDialogListview";
import { currentUserId, isAdmin } from "../../../../utils/constants";
import ChildTaskData from "./ChildTaskData";
import Loader from "../../../../components/Loader";
import { manageColumnOrder } from "../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { objectToFormData } from "../../../../utils/helpers";
import {
  setWorkflowIds,
  setMilestoneIds,
  setAssigneeIds,
} from "../../../../redux/ListViewColumnHeaderFilterSclice";
import downloadTasks from '../../../../styles/svg/downloadTasks.svg'
import { useResponsive } from "../../../../hooks/useResponsive";
import useToastPromise from "../../../../hooks/useToast";
const GlobalTableComponent = React.lazy(() =>
  import("../../../../components/Table/Table/Table")
);

export default function ListView({ workflowId, boardName, cards, isLoading, allCardsByBoard }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id: boardId } = useParams();
  const [expandcardId, setExpandCardId] = useState(null);
  const [subtaskCards, setSubtaskCards] = useState([]);
  const [isSubtaskLoading, setIsSubtaskLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [boardCards, setBoardCards] = useState(cards);
  const { data: workflows } = useWorkflowById(workflowId, boardId);
  const [openCreateCard, setOpenCreateCard] = useState(false);
  const filterUser = searchParams.get("search")?.split(',');
  const noMileStoneSearch = searchParams.get("noMilestone");
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const searchText = useSelector((state) => state.search.searchText);
  const cardId = searchParams.get("card_id");
  const statusSearch = searchParams.get("workflow_Step");
  const columnHeaderStatus = searchParams.get("statusFilter");
  const columnHeaderMilestone = searchParams.get("columnMilestone");
  const columnHeaderAssignee = searchParams.get("assigneeFilter");
  const milestoneIsSelected = searchParams.get("milestone");
  const currentUserIdVariable = currentUserId();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isMobile = useResponsive("down", "md");
  const { showToastPromise } = useToastPromise();

  const havePermissionToEdit = (data) => {
    if (
      isAdmin() ||
      boardName?.owner_user_id == currentUserIdVariable ||
      boardName?.board_permission?.is_admin
      // data?.assigned_to_user_id == currentUserIdVariable ||
      //   data?.reporter == currentUserIdVariable
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [columns, setColumns] = useState(
    dataGridColumn(boardName, havePermissionToEdit, boardName?.board_permission)
  );

  const reorderColumns = (dataGridColumns, columnSequence) => {
    if (!columnSequence) return dataGridColumns;
    return dataGridColumns.sort(
      (a, b) => columnSequence[a.id] - columnSequence[b.id]
    );
  };
  const reorderedColumns = reorderColumns(
    dataGridColumn(boardName, havePermissionToEdit, boardName?.board_permission),
    boardName?.card_column_sequence
  );

  useEffect(() => {
    if (boardName?.card_column_sequence) {
      setColumns(reorderedColumns);
    }
  }, [boardName?.card_column_sequence]);

  const handleSelectColumn = (columnId) => {
    setColumns(
      columns.map((item) =>
        item.id === columnId ? { ...item, isHidden: !item.isHidden } : item
      )
    );
  };

  const workflowSteps = workflows?.data?.data?.responseData;

  useEffect(() => {
    let project;

    if (subtaskCards) {
      //  const newSubtask = subtaskCards.map((item) => ({ ...item }));
      project = boardCards?.map((item, idx) => {
        if (item?.card?.card_id === expandcardId) {
          return { ...item, subRow: subtaskCards };
        }

        return item;
      });
      setBoardCards(project);
    }
  }, [subtaskCards]);

  useEffect(() => {
    const debounceDelay = 1000;
    let allFilteredCards = cards;
    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards?.filter(
          (cardData) =>
            (cardData?.card?.title
              ? `${cardData?.card?.title}`.toLowerCase().includes(searchText)
              : "") ||
            cardData?.card?.card_id
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchText) ||
            (cardData?.card.assigned_to_user?.first_name
              ? `${cardData?.card.assigned_to_user?.first_name}`
                .toLowerCase()
                .includes(searchText)
              : "")
        );
        setBoardCards(allFilteredCards);
      }
    }, debounceDelay);
    if (filterUser) {
      searchParams.set("assigneeFilter", "");
      searchParams.delete("assigneeFilter");
      setSearchParams(searchParams);
      const numericFilterUser = filterUser.map(Number);
      allFilteredCards = allFilteredCards?.filter((el) => numericFilterUser?.includes(el?.card?.assigned_to_user_id))
    }
    if (epicSearch && epicSearch?.length > 0) {
      searchParams.set("columnMilestone", "");
      searchParams.delete("columnMilestone");
      setSearchParams(searchParams);
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        epicSearch.includes(cardData?.card?.epic_id)
      );
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards?.filter(
        (cardData) => cardData?.card?.epic_id == null
      );
    }

    if (searchText) {
      debouncedFilter();
    }

    if (statusSearch) {
      searchParams.set("statusFilter", "");
      searchParams.delete("statusFilter");
      setSearchParams(searchParams);
      allFilteredCards = allFilteredCards?.filter((cardData) => {
        return cardData?.card?.workflow_step_id == statusSearch;
      });
    }
    if (columnHeaderStatus) {
      const columnStatusArray = columnHeaderStatus?.split(","); // Assuming comma-separated IDs
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        columnStatusArray?.includes(
          cardData?.card?.workflow_step_id?.toString()
        )
      );
    }

    if (columnHeaderMilestone) {
      const columnMilestoneArray = columnHeaderMilestone?.split(","); // Assuming comma-separated IDs
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        columnMilestoneArray?.includes(
          cardData?.card?.epic?.epic_id?.toString()
        )
      );
    }

    if (columnHeaderAssignee) {
      const columnAssigneeArray = columnHeaderAssignee?.split(","); // Assuming comma-separated IDs
      allFilteredCards = allFilteredCards?.filter((cardData) => {
        const userId = cardData?.card?.assigned_to_user?.user_id?.toString();
    
        // Check if user ID is in the array or if it's "unassigned" and userId is null/undefined
        return columnAssigneeArray.includes(userId) || 
               (columnAssigneeArray.includes("unassigned") && !userId);
      });
    }
    
    setBoardCards(allFilteredCards);

    return () => {
      debouncedFilter.cancel();
    };
  }, [
    searchText,
    filterUser,
    epicSearch,
    boardId,
    cards,
    statusSearch,
    noMileStoneSearch,
    columnHeaderStatus,
    columnHeaderMilestone,
    columnHeaderAssignee,
  ]);
  const handleClickOpen = () => {
    setOpenCreateCard(true);
  };

  const handleExpandedData = (cardId) => {
    setExpandCardId(cardId);
  };

  const handleSelectAll = () => {
    if (
      columns.every((value) =>
        value.id === "cardId" ||
          value.id === "title" ||
          value.id === "assigned_to_user_name"
          ? true
          : value.isHidden === true
      )
    ) {
      setColumns(
        columns.map((item) =>
          item.id === "cardId" ||
            item.id === "title" ||
            item.id === "assigned_to_user_name"
            ? { ...item }
            : { ...item, isHidden: false }
        )
      );
    } else {
      setColumns(
        columns.map((item) =>
          item.id === "cardId" ||
            item.id === "title" ||
            item.id === "assigned_to_user_name"
            ? { ...item }
            : { ...item, isHidden: true }
        )
      );
    }
  };

  const loader = {
    style: { color: "black" || "#ffffff" },
    size: 20,
  };

  const subTableProps = {
    isCollapsible: true,
    isHiddenRowExpandIcon: true,
    isHeaderHidden: true,
    isLoading: isSubtaskLoading,
    loadingProps: loader,
  };

  const { mutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(manageColumnOrder, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boardName", id],
      });
      //toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("column sequence not updated", error);
      //toast.error(error.response.data.message);
    },
  });

  const handleColumOrder = (data) => {
    const newArrangement = {};
    data?.map((item, index) => {
      newArrangement[item.id] = index;
    });

    mutate(
      objectToFormData({
        field_sequence: JSON.stringify(newArrangement),
      })
    );
  };

  useEffect(() => {
    const updateWorkflowIds = () => {
      let ids = [];
      const parsedStatusSearch = Number(statusSearch);
      if (parsedStatusSearch) {
        // If `statusSearch` exists, use it as `ids`
        ids = [parsedStatusSearch];
      } else if (cards?.length > 0) {
        // If `cards` data is available, extract unique `workflow_step_id`
        ids = [
          ...new Set(
            cards?.map(
              (item) => item?.card?.workflow_step_id
            )
          ),
        ];
      }

      if (ids.length > 0) {
        dispatch(
          setWorkflowIds({
            stepIds: ids,
            workflowId: boardName?.workflow_id,
          })
        );
      }
    };

    updateWorkflowIds();
  }, [
    statusSearch,
    cards,
    dispatch,
    boardName?.workflow_id,
  ]);

  useEffect(() => {
    let ids = [];
    const parsedMilestoneSearch = Number(epicSearch);
    if (parsedMilestoneSearch) {
      if (cards?.length > 0) {
        ids = [
          ...new Map(
            cards
              .map((item) => item.card?.epic) // Extract the epic object
              .filter((epic) => epic?.epic_id === parsedMilestoneSearch) // Filter out epics with null epic_id
              .map((epic) => [epic.epic_id, epic]) // Map epic_id to the epic object to ensure uniqueness
          ).values(),
        ];
      }
    } else if (cards.length > 0) {
      ids = [
        ...new Map(
          cards
            .map((item) => item.card?.epic) // Extract the epic object
            .filter((epic) => epic?.epic_id != null) // Filter out epics with null epic_id
            .map((epic) => [epic.epic_id, epic]) // Map epic_id to the epic object to ensure uniqueness
        ).values(),
      ];
    }
    if (ids?.length > 0) {
      dispatch(setMilestoneIds(ids));
    }
  }, [cards, dispatch, epicSearch]);

  useEffect(() => {
    let ids = [];
    const parsedAssigneeSearch = Number(filterUser);
    if (parsedAssigneeSearch) {
      if (cards?.length > 0) {
        ids = [
          ...new Map(
            cards
              .map((item) => item?.card?.assigned_to_user) // Extract the epic object
              .filter((assignee) => assignee?.user_id === parsedAssigneeSearch)
              .map((assignee) => [assignee?.user_id, assignee]) // Map epic_id to the epic object to ensure uniqueness
          ).values(),
        ];
      }
    } else if (cards.length > 0) {
      ids = [
        ...new Map(
          cards
            .map((item) => item?.card?.assigned_to_user) // Extract the epic object
            .filter((assignee) => assignee !== null)
            .map((assignee) => [assignee?.user_id, assignee]) // Map epic_id to the epic object to ensure uniqueness
        ).values(),
      ];
    }
    if (ids?.length > 0) {
      dispatch(setAssigneeIds(ids));
    }
  }, [cards, dispatch, filterUser]);


  const generateCSV = () => {
    const allCards = boardCards.flatMap((boardCard) => {
      const subTasks = allCardsByBoard?.data?.data?.responseData?.filter(
        (card) => card.card.parent_card_id === boardCard.card.card_id
      );
      return [boardCard, ...subTasks];
    });


    const parentMap = new Map();
    const independentRows = [];

    allCards?.forEach((item) => {
      const { card } = item;

      const obj = {
        "Task Id": card?.card_id, // Swap Task Id and Parent Task ID here
        "Parent Task ID": card?.parent_card_id,
        "Status": workflowSteps?.find((workflowStep) => card?.workflow_step_id === workflowStep?.workflow_step_id)?.status,
        "Label": item?.labels && item?.labels.join(", "),
        "Allocated Hours": card?.allocated_hours,
        "Milestone": card?.epic?.epic_name,
        "Task Title": card?.title,
        "Assignee": card?.assigned_to_user && card?.assigned_to_user?.first_name + " " + card?.assigned_to_user?.last_name,
        "Hours Spent": item?.total_hours_spent,
        "Due Date": card?.due_date && new Date(card?.due_date).toLocaleDateString('en-GB'),
        "Created Date": card?.date_created && new Date(card?.date_created).toLocaleDateString('en-GB'),
        "Reporter": card?.reported_by && card?.reported_by?.first_name + " " + card?.reported_by?.last_name,
        "Last Updated": card?.updated_at && new Date(card?.updated_at).toLocaleDateString('en-GB'),
      }

      const hiddenLabels = columns
        .filter(column => column.isHidden)
        .map(column => {
          if (column?.id === "assigned_to_user_name") {
            return "Assignee"
          }
          if (column?.id === "col3") {
            return "Status"
          }
          if (column?.id === "col7") {
            return "Milestone"
          }
          return column.label
        });

      hiddenLabels.forEach(label => {
        if (obj.hasOwnProperty(label)) {
          delete obj[label];
        }
      });

      if (item?.card?.parent_card_id) {
        if (!parentMap.has(item?.card.parent_card_id)) {
          parentMap.set(item?.card.parent_card_id, []);
        }

        parentMap.get(item?.card?.parent_card_id).push(obj);
      } else {
        independentRows.push(obj);
      }
    });

    const orderedRows = [];

    independentRows.forEach((parent) => {
      orderedRows.push({
        "Task Id": parent["Task Id"],
        "Parent Task ID": " - ",
        ...parent,
      });

      if (parentMap.has(parent["Task Id"])) {
        parentMap.get(parent["Task Id"]).forEach((child) => {
          orderedRows.push({
            "Task Id": child["Task Id"],
            "Parent Task ID": child["Parent Task ID"],
            ...child,
            "Task Title": "  - " + child["Task Title"], // Indent subtasks (if any)
          });
        });
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(orderedRows); // Convert JSON data to worksheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // Append the worksheet
    XLSX.writeFile(workbook, `test.xlsx`); // Export the workbook to an Excel file
  };

  return (
    <>
      {expandcardId && (
        <ChildTaskData
          cardId={expandcardId}
          setSubtaskCards={setSubtaskCards}
          setIsSubtaskLoading={setIsSubtaskLoading}
        />
      )}
      {boardCards && cardId && <OpenCardDetail cardsOnBoard={boardCards} />}
      {openCreateCard && workflowSteps && (
        <CreateCardDialogListview
          open={openCreateCard}
          handleClose={() => setOpenCreateCard(false)}
          workflowSteps={workflowSteps}
        />
      )}
      <div>
        <Stack
          direction="row"
          className="d-flex justify-content-end align-items-center"
          mb={3}
        >

          <Stack
            direction="row"
            justifyContent="end"
            onClick={(event) => setAnchorEl(event.currentTarget)}>
            <img
              src={manageColumnIcon}
              width={14}
              alt="manage column"
              style={{ cursor: "pointer" }}
            />
            <Typography className="manage-board">
              Manage Columns
            </Typography>
          </Stack>
          <Button
            onClick={handleClickOpen}
            className="theme-bg-color capitalize ml-3"
            variant="contained"
            startIcon={<img src={addIcon} alt="addSubtaskIcone" />}
            // disabled={!(havePermissionToEdit() || boardName?.board_permission?.can_create_card)}
            sx={{
              position: "static",
              "&:hover": {
                backgroundColor: "#1B3E61",
                border: "none",
                boxShadow: 0,
              },
            }}
          >
            Create Card
          </Button>
          <Button
            onClick={generateCSV}
            className="orange-color capitalize ml-3"
            variant="contained"
            color={"warning"}
            startIcon={<img src={downloadTasks} alt="downloadTasksList" />}
            sx={{
              "&:hover": {
                backgroundColor: "orange-color",
              },
            }}
          >
            Download
          </Button>
        </Stack>
        <Box>
          <Suspense
            fallback={
              <div className="loader-center">
                <Loader color={"black"} />
              </div>
            }
          >
            <GlobalTableComponent
              data={
                boardCards
                  ? boardCards?.map((boardCard) => {
                    return {
                      id: boardCard?.card?.card_id,
                      cardId: boardCard?.card?.card_id,
                      title: boardCard?.card?.title,
                      date_created: boardCard?.card?.date_created,
                      assigned_to_user_name:
                        boardCard?.card?.assigned_to_user?.first_name || "z",
                      isSubtask: boardCard?.sub_tasks?.length > 0 ? true : false,
                      ...boardCard,
                      subRow:
                        boardCard?.subRow && boardCard?.subRow?.length > 0
                          ? boardCard?.subRow?.map((cardSubTask, idx) => {
                            return {
                              id: cardSubTask?.card_id,
                              ...cardSubTask,
                              key: boardCard?.key,
                              board_name: boardCard?.board_name,
                              board_admin: boardCard?.board_admin,
                            };
                          })
                          : null,
                    };
                  })
                  : []
              }
              headers={columns}
              subHeader={columns.map((item, index) =>
                index === 0
                  ? {
                    ...item,
                    alignContent: "center",
                  }
                  : item
              )}
              isCollapsible={true}
              isDraggableColumn={true}
              isPagination={false}
              rowCount={10}
              handleExpandedData={handleExpandedData}
              subTableProps={subTableProps}
              tableBoxClass={
                milestoneIsSelected
                  ? "parent-milestone-table-box"
                  : "parent-table-box"
              }
              tableContainerClass={"parent-table-boarder"}
              isLoading={isLoading}
              loadingProps={loader}
              loadingClass={"h-70vh"}
              headerClass={"table-header-listview"}
              handleColumOrder={handleColumOrder}
              defaultSortBy="date_created"
            />
          </Suspense>
        </Box>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(false)}
          MenuListProps={{
            "aria-labelledby": "epics-board-dropdown",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          className="assign-board-owner-list"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.every((value) => value.isHidden === false)}
                  onChange={handleSelectAll}
                />
              }
              label="Select All"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col3" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col3")}
                />
              }
              label="Status"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col6" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col6")}
                />
              }
              label="Label"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col7" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col7")}
                />
              }
              label="Milestone"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col8" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col8")}
                />
              }
              label="Allocated Hours"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col9" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col9")}
                />
              }
              label="Hours Spent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col10" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col10")}
                />
              }
              label="Time Tracking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col11" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col11")}
                />
              }
              label="Due Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col12" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col12")}
                />
              }
              label="Created Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.some((column) =>
                    column.id === "col13" ? !column.isHidden : false
                  )}
                  onChange={() => handleSelectColumn("col13")}
                />
              }
              label="Reporter"
            />
          </FormGroup>
        </Menu>
      </div>
    </>
  );
}
