import * as yup from "yup";

export const createMilestone = yup
  .object({
    epic_name: yup.string().trim().required("Name is required").max(30, "Maximum 30 characters allowed"),
    description: yup
      .string()
      .test("not-empty", "Description cannot be empty", (value) => {
        // Remove HTML tags and trim spaces
        const cleanedDescription = value.replace(/<[^>]*>/g, "").trim();
        return cleanedDescription.length > 0; // Ensure there's actual content
      })
      .required("Description is required"),
    start_date: yup
      .date()
      .max(
        yup.ref("target_release_date"),
        "start date can't be after target date"
      ),
    target_release_date: yup
      .date()
      .min(yup.ref("start_date"), "end date can't be before start date"),
  })
  .required();
