import React, { useEffect, useState } from "react";
import {
  useGenericWorkflowStatus,
  useUserWorkflowList,
  useWorkflowById,
} from "../../../../utils/reactQuery/workflows";
import Loader from "../../../../components/Loader";
import Kanban from "./Kanban";
import { getMyCards } from "../../../../sevices/apiCards";
import { useDispatch, useSelector } from "react-redux";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useMineCards } from "../../../../utils/reactQuery/cards";
import { setAllGroupUser } from "../../../../redux/TaskGroupingSlice";
import { addAllBoard } from "../../../../redux/SearchSlice";
import { useSearchParams } from "react-router-dom";
import OpenCardDetail from "../../../board/components/Kanban/openCardDetail";

function Workflow() {
  const user = useSelector((state) => state.search.myTaskUser);
  const checkedList = useSelector((state) => state.search.boardSearch);
  const epicSearch = useSelector((state) => state.search.epicSearch);
  const [reporterCards] = useCustomSearchParams("reporter");
  const { userWorkflows } = useUserWorkflowList();
  const genericWorkflowId = userWorkflows?.data?.data?.responseData?.find(
    (workflow) => workflow.is_generic
  )?.workflow_id;

  const dispatch = useDispatch();
  const {
    data: mineCards,
    isLoading,
    refetch,
    isRefetching,
  } = useMineCards(
    user || [],
    checkedList,
    epicSearch,
    reporterCards,
    genericWorkflowId
  );

  const { isLoading: genericWorkflowStepsLoading, genericWorkflowStatus } =
    useGenericWorkflowStatus();

  useEffect(() => {
    refetch();
  }, [user, checkedList, epicSearch, reporterCards]);

  useEffect(() => {
    return () => {
      dispatch(setAllGroupUser([]));
      dispatch(addAllBoard([]));
    };
  }, []);

  return (
    <>
      <OpenCardDetail />
      {genericWorkflowStatus && !isLoading ? (
        <Kanban
          cards={mineCards?.data.data.responseData || []}
          workflowSteps={genericWorkflowStatus.data.data.responseData
            .filter((el) => !el.is_completed)
            .sort((a, b) => a.step_sequence - b.step_sequence)}
        />
      ) : (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
    </>
  );
}

export default Workflow;
