import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import "./epicbutton.scss";
import addEpicIcone from "../../../../../styles/svg/add-epic.svg";
import addIcon from "../../../../../styles/svg/group-plus-icon.svg";
import AddMilestone from "../../../../../components/Milestone/addMilestone";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { TooltipName } from "../../../../../utils/Enums";

const CreateEpic = ({ isTab = false }) => {
  const [addMilestonePop, setAddMileStonePop] = useState(false);
  const isMobile = useResponsive("down", "md");

  return (
    <>
      {isTab ? (
        <Tooltip title={TooltipName.BOARD_PAGE.CREATE_NEW_MILESTONE}>
        <Button
          size="small"
          className="create-card-btn"
          onClick={() => setAddMileStonePop(true)}
          sx={{ color: "rgba(27, 62, 97, 1)", minWidth: "0" }}
        >
          <AddIcon sx={{ fontSize: "20px" }} />
        </Button>
        </Tooltip>
      ) : (
        <div
          className="create-epic-btn"
          onClick={() => setAddMileStonePop(true)}
        >
          {isMobile ? (
            <img src={addIcon} alt="create milestone" />
          ) : (
            <Button
              sx={{
                backgroundColor: "#1B3E61",
                "&:hover": {
                  backgroundColor: "#1B3E61",
                  border: "none",
                },
              }}
              variant="contained"
            >
              <img src={addEpicIcone} alt="add milestone" /> Create New
            </Button>
          )}
        </div>
      )}

      <AddMilestone
        open={addMilestonePop}
        handleClose={() => setAddMileStonePop(false)}
        title={"Create Milestone"}
        btnTitle={"Create"}
      />
    </>
  );
};

export default CreateEpic;
