import { objectToFormData } from "../utils/helpers";
import Api from "./axios";

export const createCard = async (data) => {
  return await Api.post(`/card`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const changeStatusTodo = async (data) => {
  return await Api.put(`/change_todo`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};
