import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";
import KanbanCard from "../../pages/board/components/Kanban/KanbanCard";
import { useResponsive } from "../../hooks/useResponsive";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";

function AccordionTodo({
  heading,
  setExpanded,
  uniqueKey,
  expanded,
  onChange,
  cards,
  sx = {},
  scrollToCardId,
  height,
  stepInfo,
}) {
    const [milestone] = useCustomSearchParams("milestone");
  const notDesktop = useResponsive("down", "md");
  const isMobile = useResponsive("down", "sm");
  const isTab = useResponsive("up", "sm");
  const scrollRef = useRef(null);

  useLayoutEffect(() => {
    if (scrollToCardId && scrollRef.current) {
      const cardElements = scrollRef.current.children;

      // Find the index of the target card
      const cardIndex = cards.findIndex(
        (project) => project?.card?.card_id === scrollToCardId
      );


      if (cardIndex !== -1) {
        const targetCard = cardElements[cardIndex];

        if (targetCard) {
          const offsetTop = targetCard.offsetTop;
          scrollRef.current.scrollTo({
            top: offsetTop - 250, // Adjust for padding if needed
            behavior: "smooth",
          });
        }
      }
    }
  }, [scrollToCardId, cards]);

  const generateKanbanCards = () => {
    return cards.map((project, index) => (
      <KanbanCard
        // status={name}
        // boardName={boardName}
        key={project?.card?.card_id}
        id={project?.card?.card_id}
        project={project}
        // onDragEnd={onDragEnd}
        isTodo={true}
        index={index}
        style={
          notDesktop
            ? {
                flex: !isMobile && "1 1 45%",
                // margin: "10px",
                minWidth: isTab ? "261px" : "calc(100% - 20px)",
                flexGrow: 1,
                maxWidth: isTab ? "46.5%" : "100%",
                height: "auto",
                // display: "flex",
              }
            : {}
        }
      />
    ));
  };
  return (
    <>
      {cards && cards.length ? (
        <>
          {uniqueKey !== 0 && expanded === heading && (
            <div className="todo-border-top"></div>
          )}
          <Accordion
            expanded={expanded === heading}
            onChange={() => onChange(heading)}
            sx={{
              boxShadow: "none",
              backgroundColor: "transparent",
              padding: 0,
              ...(expanded === heading ? {} : sx),
              zIndex: 9,
            }}
            //   defaultExpanded={defaultExpanded}
            className="menu-Accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="menu-AccordionSummary"
              sx={{
                padding: 0,
                ".MuiAccordionSummary-content": { margin: "0 !important" },
              }}
            >
              <div className="d-flex align-items-center">
                {<span className="todo-step-heading">{heading}</span>}
                <span className="coloumn-header-length">{cards?.length}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className="menu-AccordionDetails"
              key={uniqueKey}
              sx={{
                padding: 0,
                ...(notDesktop
                  ? {
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "stretch",
                      position: "relative",
                      justifyContent: "space-between",
                      overflow: "auto",
                      columnGap: "8px",
                    }
                  : {}),
              }}
            >
              <div
                ref={(node) => {
                  if (heading === "Open") scrollRef.current = node; // Attach ref for scrolling
                }}
                style={{
                  overflow: "auto",
                  // height:'200px'
                  maxHeight: `calc(94vh - ${milestone ? "340px" : height + 'px'} )`,
                }}
              >
                {generateKanbanCards()}
              </div>
              {/* <div className="todo-border"></div> */}
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AccordionTodo;
