import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import ForgotPasswordMessage from "../../../../utils/Enums";
import useMutate from "../../../../hooks/useMutate";
import { updateUserDetails } from "../../../../sevices/apiUser";

function ChangePassword({ userId, onClose }) {
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const { register, handleSubmit, watch, formState } = useForm({
    defaultValues: {},
  });
  const password = watch("password") || "";
  const { errors } = formState;
  const { mutate, isPending } = useMutate(updateUserDetails, [], onClose);

  const submit = (data) => {
    mutate({ userId: userId, data: { password: data.password } });
  };

  function onError(errors) {
    console.log(errors);
  }
  const requirements = ForgotPasswordMessage.REQUIREMENTS;
  const validations = [
    password?.length >= 8 && password?.length <= 15,
    /[A-Z]/.test(password),
    /[a-z]/.test(password),
    /\d/.test(password),
    /[!@#^*_?{}\-]/.test(password),
  ];
  const allRequirementsMet = validations.every((validation) => validation);

  const handleClickShowPassword = (newpassword) => {
    if (newpassword === "password") {
      setShowPassword((prev) => ({ ...prev, password: !prev.password }));
    }
    if (newpassword === "ConfirmPassword") {
      setShowPassword((prev) => ({
        ...prev,
        confirmPassword: !prev.confirmPassword,
      }));
    }
  };

  return (
    <div>
      <Box
        component="form"
        onSubmit={handleSubmit(submit, onError)}
        noValidate
        // className="submitForm-box"
      >
        <Typography
          className="user-forgot-password-para"
          component={"h1"}
          variant="h5">
          {ForgotPasswordMessage.CREATE_NEW_PASSWORD_MESSAGE}
        </Typography>

        <List>
          {requirements.map((requirement, index) => (
            <ListItem key={index} sx={{ padding: 0 }}>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                {validations[index] ? (
                  <CheckCircleIcon sx={{ color: "green" }} />
                ) : (
                  <RadioButtonUncheckedIcon sx={{ color: "#DDDDDD" }} />
                )}
              </ListItemIcon>
              <ListItemText
                className="user-create-new-password-info"
                sx={{ textAlign: "left" }}
                primary={requirement}
              />
            </ListItem>
          ))}
        </List>

        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type={showPassword.password ? "text" : "password"}
          id="password"
          error={errors?.password ? true : false}
          helperText={errors?.password && errors.password.message}
          autoComplete="current-password"
          {...register("password", {
            required: "New Password is required",
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword("password")}
                  edge="end"
                  aria-label="toggle password visibility">
                  {showPassword.password ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmpassword"
          label="Confirm New Password"
          type={showPassword.confirmPassword ? "text" : "password"}
          id="confirmpassword"
          error={!!errors?.confirmpassword}
          helperText={
            errors?.confirmpassword ? errors.confirmpassword.message : ""
          }
          autoComplete="current-password"
          {...register("confirmpassword", {
            required: "Confirm New Password is required",
            validate: (value) => value === password || "Passwords do not match",
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword("ConfirmPassword")}
                  edge="end"
                  aria-label="toggle password visibility">
                  {showPassword.confirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Stack direction={"row"} justifyContent={"end"} className="mt-2">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              width: "130px",
              marginRight: "14px",
              padding: "8px 16px !important",
            }}
            className="btn-blue capitalize "
            disabled={isPending || !allRequirementsMet}>
            Update
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1B3E61",
              border: "1px solid #1B3E61",
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "4px 16px !important",
              fontWeight: "600",
              opacity: "0.6",
              minWidth: "130px",
            }}
            onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </Box>{" "}
    </div>
  );
}

export default ChangePassword;
