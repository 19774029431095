import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Box, Grid, IconButton, InputAdornment, TextField } from "@mui/material";

import Search from "../Search";
import { changeExpand } from "../../redux/boardListSlice";
import { isAdmin } from "../../utils/constants";
import { searchBoard } from "../../redux/SearchSlice";
import crossIcon from "../../styles/svg/crossIcon.svg"
import newSearchIcon from "../../styles/svg/newSearchIcon.svg"
function SearchBoard({ isBoardSearch, setSearchBar }) {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchBoard(value));
    }, 300) // 300ms delay
  ).current;

  const handleChange = (event) => {
    if (isAdmin) {
      dispatch(changeExpand("allPanel"));
    }
    const value = event?.target?.value;
    setInputValue(value);
    debouncedSearch(value);
  };
  const handleClear = (e) => {
    e.stopPropagation()
    setInputValue("");
    dispatch(searchBoard("")); // Clear the search results
    setSearchBar({
      showSearchIcon: true,
      showSearchBar: false,
    })
  };
  return (
    <>
      {isBoardSearch ?
        <TextField
          variant="standard"
          sx={{ flexGrow: 1, paddingLeft: "6px",
            '& .MuiInputBase-input': {
        color: 'rgb(145, 158, 171)', // Change this to your desired text color
      },
           }}
          onChange={handleChange}
          value={inputValue}
          // placeholder="Find Board"
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start" sx={{ marginRight: "4px" }}>
                <img src={newSearchIcon} alt="search" width={18} height={18} />
              </InputAdornment>
            ),
            endAdornment:  (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} size="small">
                  <img src={crossIcon} alt="clear" width={16} height={16} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoFocus
        />
        :
        <Grid item xs={5} className="">
          <div className="header-right">
            <Box mx={2} mb={2}>
              <Search
                searchText={inputValue}
                handleChange={handleChange}
                placeholder={"Find Board"}
              />
            </Box>
          </div>
        </Grid>
      }
    </>
  );
}

export default SearchBoard;
