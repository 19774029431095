import { Box, Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { useResponsive } from "../../../../hooks/useResponsive";
import downloadWorklog from "../../../../styles/svg/download_worklog.svg";
import { useSelector } from "react-redux";
import { getWorklogHistory } from "../../../../sevices/apiWorklog";

function TabsController({ value, onChange }) {
  const isMobile = useResponsive("down", "md");
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const user = groupingUser.map((item) => item?.users).flat();
  const checkedList = useSelector((state) => state.search.boardSearch);
  const dateRange = useSelector((state) => state.search.dateRange);
  const epicSearch = useSelector((state) => state.search.epicSearch);

  const downloadCsv = () => {
    getWorklogHistory(
      user,
      checkedList,
      dateRange,
      epicSearch,
      true,
      value === "by-task"
    )
      .then(async (response) => {
        const text = response.data;

        const blob = new Blob([text], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "worklog.csv"; // Set the desired file name here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={(event, newValue) => onChange(event, newValue)}
        textColor="primary"
        indicatorColor="primary"
        aria-label="controlled tabs with specific values"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#000",
            display: "none",
          },
        }}
        sx={{
          ".MuiTabs-flexContainer": {
            borderRadius: "8px 8px 0px 0px",
            backgroundColor: "rgba(27, 62, 97, 0.16)",
            borderBottom: "1.5px solid rgba(27, 62, 97, 0.24)",
          },
          // position: "relative",
          minHeight: "24px",
          margin: "20px 0px",
          ...(isMobile && { margin: "16px 15px 6px 15px" }),
        }}>
        <Tab
          value="users"
          label="Users"
          className={value === "users" ? "custom-tab-active" : "custom-tab"}
          style={{ ...(value === "by-board" && { border: "0px" }) }}
        />
        <Tab
          value="by-board"
          label="By Board"
          className={value === "by-board" ? "custom-tab-active" : "custom-tab"}
          style={{ ...(value === "by-task" && { border: "0px" }) }}
        />
        <Tab
          value="by-task"
          label="By Task"
          className={value === "by-task" ? "custom-tab-active" : "custom-tab"}
        />
        {value !== "by-board" && (
          <img
            src={downloadWorklog}
            alt="download worklog"
            className="download_worklog"
            onClick={downloadCsv}
          />
        )}
      </Tabs>
    </>
  );
}

export default TabsController;
