import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Todo from '../../../../../components/ToDo';

function MobileTodo({ projects,height }) {
   const priorityOrder = {
     Critical: 1,
     High: 2,
     Medium: 3,
     Low: 4,
   };
  return (
    <DragDropContext onDragEnd={() => {}}>
      <Droppable droppableId="list">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Todo
              name="Quick Tasks"
              stage={"todo-column"}
              projects={
                projects
                  .filter((card) => card.card.is_todo)
                  ?.sort(
                    (a, b) =>
                      priorityOrder[a?.card?.priority] -
                      priorityOrder[b?.card?.priority]
                  ) || []
              }
              height={height}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default MobileTodo