import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Menu,
  MenuItem,
  Divider,
  Box,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useParams, useSearchParams } from "react-router-dom";

import apicIcon from "../../../../../styles/svg/updt_milestone.svg";
import DarkIcon from "../../../../../styles/svg/milestone-dark.svg";
import EditEpic from "./EditEpic";
import CreateEpic from "./CreateEpic";
import "./epicbutton.scss";
import { useEpic } from "../../../../../utils/reactQuery/epics";
import { searchCard } from "../../../../../redux/EpicSearchSlice";
import EditMIlestone from "../../../../../components/Milestone/EdiMilestone";
import { isAdmin } from "../../../../../utils/constants";
import { useResponsive } from "../../../../../hooks/useResponsive";
import MergeMilestone from "../../mergeMilestone/MergeMilestone";
import { TooltipName } from "../../../../../utils/Enums";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";

const EpicButton = ({
  boardNameDetails,
  showNoMile = true,
  isTab = false,
  selectedMilestone,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [NoMilestone, setMilestone] = useCustomSearchParams("noMilestone");
  const [epic, setEpic] = useState(
    searchParams.get("milestone")
      ? searchParams
          .get("milestone")
          .split(",")
          .filter((el) => el)
          .map((el) => el * 1)
      : []
  );
  const [editMileStonePop, setEditMileStonePop] = useState(false);

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showEditArea, setShowEditArea] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const epicUrl = searchParams.get("milestone");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const isMobile = useResponsive("down", "md");
  const { id } = useParams();

  const boardNameTitle = boardNameDetails?.data?.responseData?.board_name
    ?.substring(0, 3)
    ?.toUpperCase();

  const { epics } = useEpic(id);

  const names = epics?.data?.data?.responseData || [];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const handleCheckboxChange = (id) => {
    searchParams.delete("noMilestone");
    setEpic((prevEpic) => {
      if (prevEpic.includes(id)) {
        updateSearchParams(
          "milestone",
          prevEpic.filter((n) => n !== id)
        );
        searchParams.delete("milestone");
        return prevEpic.filter((n) => n !== id);
      } else {
        updateSearchParams("milestone", [id]);
        return [id];
      }
    });
  };

  const handleNoMilestone = () => {
    if (noMileStoneSearch === "true") {
      updateSearchParams("noMilestone", "false");
    } else {
      searchParams.set("milestone", "");
      updateSearchParams("noMilestone", "true");
    }
  };

  useEffect(() => {
    const data = searchParams
      .get("milestone")
      ?.split(",")
      ?.filter((el) => el)
      ?.map((el) => el * 1);
    dispatch(searchCard(data || []));
    setEpic(data || []);
  }, [epicUrl]);

  const showEditButton = (epic_id) => {
    setHoveredIndex(epic_id);
  };

  const handleEditFieldClose = () => {
    setShowEditArea(false);
    setHoveredIndex(null);
  };

  const sortedName = names.sort(
    (a, b) => new Date(b.start_date) - new Date(a.start_date)
  );

  const completedMileStones = sortedName?.filter(
    (item) => item.completed == true
  );
  const unCompletedMileStones = sortedName?.filter(
    (item) => item.completed == false
  );

  return (
    <>
      {isMobile ? (
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color="#1B3E61" mb={1} mt={1} fontWeight={600}>
              Select milestone
            </Typography>
            {isAdmin() || boardNameDetails?.data?.responseData?.is_owner ? (
              <CreateEpic />
            ) : (
              ""
            )}
          </Stack>
          <div
            style={{
              maxHeight: "165px",
              overflowY: "auto",
              minHeight: "130px",
            }}
          >
            {showNoMile && (
              <MenuItem
                sx={{
                  height: "30px",
                  minHeight: "30px",
                  padding: "12px 0px !important ",
                }}
                onClick={() => handleNoMilestone()}
                // onMouseEnter={() => showEditButton()}
                // onMouseLeave={handleEditFieldClose}
              >
                {/* <label class="custom-checkbox major-div">
                  <input
                    type="checkbox"
                    onClick={() => handleNoMilestone()}
                    // id={`epic-list-checkbox-${}`}
                    checked={noMileStoneSearch === "true"}
                  />
                  <span class="checkmark"></span>
                </label> */}
                <div>
                  <p
                    className={
                      noMileStoneSearch === "true"
                        ? "active-epic-name ml-1"
                        : "epic-name ml-1"
                    }
                  >
                    Not Set
                  </p>
                </div>
              </MenuItem>
            )}
            {unCompletedMileStones?.map((item, index) => (
              <React.Fragment key={item?.epic_id}>
                <Divider />
                <MenuItem
                  sx={{
                    height: "30px",
                    minHeight: "30px",
                    padding: "12px 0px !important ",
                  }}
                  onMouseEnter={() => showEditButton(item?.epic_id)}
                  onMouseLeave={handleEditFieldClose}
                  onClick={() => handleCheckboxChange(item?.epic_id)}
                >
                  {/* <label class="custom-checkbox major-div">
                    <input
                      type="checkbox"
                      onClick={() => handleCheckboxChange(item?.epic_id)}
                      id={`epic-list-checkbox-${index}`}
                      checked={epic.indexOf(item?.epic_id) > -1}
                    />
                    <span class="checkmark"></span>
                  </label> */}
                  <div className="epic-name-id-div">
                    <p
                      className={
                        epic.indexOf(item?.epic_id) > -1
                          ? "active-epic-name"
                          : "epic-name"
                      }
                    >
                      {showEditArea && hoveredIndex === item.epic_id ? (
                        <EditEpic
                          epicId={item.epic_id}
                          epicName={item?.epic_name}
                          setShowEditArea={setShowEditArea}
                        />
                      ) : (
                        item?.epic_name
                      )}
                    </p>
                    <p className="epic-id">
                      {!showEditArea && `${boardNameTitle} - ${item?.epic_id}`}
                    </p>
                  </div>
                </MenuItem>
              </React.Fragment>
            ))}
            {epicUrl && (
              <EditMIlestone
                open={editMileStonePop}
                handleClose={() => setEditMileStonePop(false)}
              />
            )}

            {completedMileStones.length > 0 && (
              <div className="mt-2">
                <Divider />
                <div
                  className="completed-epics mt-2"
                  style={{
                    backgroundColor: "rgba(242, 246, 254, 1)",
                    padding: "6px",
                  }}
                >
                  <p>Completed</p>
                </div>
                <Divider />
              </div>
            )}

            {completedMileStones.length === 0 &&
              unCompletedMileStones.length === 0 && (
                <div
                  className="completed-epics"
                  style={{
                    backgroundColor: "rgba(242, 246, 254, 1)",
                    padding: "6px",
                    height: "100px",
                  }}
                >
                  <p>No milestone found</p>
                </div>
              )}

            {completedMileStones?.map((item, index) => (
              <React.Fragment key={item?.epic_id}>
                <MenuItem
                  sx={{
                    height: "40px",
                    minHeight: "36px",
                    padding: "12px 0px !important ",
                  }}
                  onMouseEnter={() => showEditButton(item?.epic_id)}
                  onMouseLeave={handleEditFieldClose}
                  onClick={() => handleCheckboxChange(item?.epic_id)}
                >
                  {/* <label class="custom-checkbox major-div">
                    <input
                      type="checkbox"
                      onClick={() => handleCheckboxChange(item?.epic_id)}
                      id={`epic-list-checkbox-${index}`}
                      checked={epic.indexOf(item?.epic_id) > -1}
                    />
                    <span class="checkmark"></span>
                  </label> */}
                  <div className="epic-name-id-div">
                    <p
                      className={
                        epic.indexOf(item?.epic_id) > -1
                          ? "active-epic-name"
                          : "epic-name"
                      }
                    >
                      {showEditArea && hoveredIndex === item.epic_id ? (
                        <EditEpic
                          epicId={item.epic_id}
                          epicName={item?.epic_name}
                          setShowEditArea={setShowEditArea}
                        />
                      ) : (
                        item?.epic_name
                      )}
                    </p>
                    <p className="epic-id">
                      {!showEditArea && `${boardNameTitle} - ${item?.epic_id}`}
                    </p>
                  </div>
                </MenuItem>
                {index < names.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        </Box>
      ) : (
        <>
          <Tooltip title={TooltipName.BOARD_PAGE.ALL_MILESTONE}>
            <Button
              variant="outlined"
              color="primary"
              id="epics-board-dropdown"
              aria-controls={open ? "user-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={
                <KeyboardArrowDownIcon sx={{ fontSize: 30, fill: "#1b3e62" }} />
              }
              className={
                isTab
                  ? `milestone-drop-down-button ${
                      selectedMilestone === "" && NoMilestone !== "true"
                        ? "milestone-tab-border-none"
                        : "milestone-tab-border"
                    } `
                  : "white-button epics-btn"
              }
              sx={{
                height: "38px",
                padding: "5px 5px 5px 2px",
                "&:hover": {
                  outline: "none",
                  ...(selectedMilestone === "" && NoMilestone !== "true"
                    ? { border: "none" }
                    : {
                        borderBottom: "none",
                        borderLeft: "none",
                        borderTop: "none",
                        borderRight: "1px solid rgba(27, 62, 97, 0.1215686275)",
                      }),
                },
              }}
            >
              <img
                src={isTab ? DarkIcon : apicIcon}
                className="header-filter-icon"
                alt="not found"
              />
              {/* <p className="header-action-button-text" style={{ width: "65px" }}>
              {isTab ? "All Milestones" : "Milestones"}
            </p> */}
            </Button>
          </Tooltip>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "epics-board-dropdown",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                width: "300px",
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            // className="epic-list"
          >
            <div style={{ maxHeight: "222px", overflowY: "auto" }}>
              {showNoMile && (
                <>
                  <MenuItem
                    sx={{
                      height: "30px",
                      minHeight: "30px",
                      padding: "12px 0px !important ",
                    }}
                    onClick={() => handleNoMilestone()}
                    // onMouseEnter={() => showEditButton()}
                    // onMouseLeave={handleEditFieldClose}
                  >
                    {/* <label class="custom-checkbox major-div-noMilestone">
                      <input
                        type="checkbox"
                        onClick={() => handleNoMilestone()}
                        // id={`epic-list-checkbox-${}`}
                        checked={noMileStoneSearch === "true"}
                      />
                      <span class="checkmark"></span>
                    </label> */}
                    <div className="epic-name-id-div-noMilestone">
                      <p
                        className={
                          noMileStoneSearch === "true"
                            ? "active-epic-name"
                            : "epic-name"
                        }
                      >
                        Not Set
                      </p>
                    </div>
                  </MenuItem>
                  <Divider />
                </>
              )}
              {unCompletedMileStones?.map((item, index) => (
                <React.Fragment key={item?.epic_id}>
                  <MenuItem
                    sx={{
                      height: "40px",
                      minHeight: "36px",
                      padding: "12px 8px !important ",
                    }}
                    onMouseEnter={() => showEditButton(item?.epic_id)}
                    onMouseLeave={handleEditFieldClose}
                    onClick={() => handleCheckboxChange(item?.epic_id)}
                  >
                    {/* <label class="custom-checkbox major-div">
                      <input
                        type="checkbox"
                        onClick={() => handleCheckboxChange(item?.epic_id)}
                        id={`epic-list-checkbox-${index}`}
                        checked={epic.indexOf(item?.epic_id) > -1}
                      />
                      <span class="checkmark"></span>
                    </label> */}
                    <div className="epic-name-id-div">
                      <p
                        className={
                          epic.indexOf(item?.epic_id) > -1
                            ? "active-epic-name"
                            : "epic-name"
                        }
                      >
                        {showEditArea && hoveredIndex === item.epic_id ? (
                          <EditEpic
                            epicId={item.epic_id}
                            epicName={item?.epic_name}
                            setShowEditArea={setShowEditArea}
                          />
                        ) : (
                          item?.epic_name
                        )}
                      </p>
                      <p className="epic-id">
                        {!showEditArea &&
                          `${boardNameTitle} - ${item?.epic_id}`}
                      </p>
                    </div>
                  </MenuItem>
                  {index < names.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {epicUrl && (
                <EditMIlestone
                  open={editMileStonePop}
                  handleClose={() => setEditMileStonePop(false)}
                />
              )}
              {/* {isAdmin() ||
                (boardNameDetails?.data?.responseData?.is_owner && (
                  <CreateEpic />
                ))} */}
              {(isAdmin() ||
                boardNameDetails?.data?.responseData?.is_owner) && (
                <CreateEpic />
              )}

              {completedMileStones.length > 0 && (
                <div className="completed-epics mt-2">
                  <p>Completed</p>
                </div>
              )}
              <Divider />
              {completedMileStones?.map((item, index) => (
                <React.Fragment key={item?.epic_id}>
                  <MenuItem
                    sx={{
                      height: "40px",
                      minHeight: "36px",
                      padding: "12px 8px !important ",
                    }}
                    onMouseEnter={() => showEditButton(item?.epic_id)}
                    onMouseLeave={handleEditFieldClose}
                    onClick={() => handleCheckboxChange(item?.epic_id)}
                  >
                    {/* <label class="custom-checkbox major-div">
                      <input
                        type="checkbox"
                        onClick={() => handleCheckboxChange(item?.epic_id)}
                        id={`epic-list-checkbox-${index}`}
                        checked={epic.indexOf(item?.epic_id) > -1}
                      />
                      <span class="checkmark"></span>
                    </label> */}
                    <div className="epic-name-id-div">
                      <p
                        className={
                          epic.indexOf(item?.epic_id) > -1
                            ? "active-epic-name"
                            : "epic-name"
                        }
                      >
                        {showEditArea && hoveredIndex === item.epic_id ? (
                          <EditEpic
                            epicId={item.epic_id}
                            epicName={item?.epic_name}
                            setShowEditArea={setShowEditArea}
                          />
                        ) : (
                          item?.epic_name
                        )}
                      </p>
                      <p className="epic-id">
                        {!showEditArea &&
                          `${boardNameTitle} - ${item?.epic_id}`}
                      </p>
                    </div>
                  </MenuItem>
                  {index < names.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {isAdmin() || boardNameDetails?.data?.responseData?.is_owner ? (
                <>
                  {/* <CreateEpic /> */}
                  {/* <Divider /> */}
                  {names && names.length > 0 && (
                    <MergeMilestone milestones={names} />
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </Menu>
        </>
      )}
    </>
  );
};

export default EpicButton;
