import { EncryptStorage } from "encrypt-storage";

const encryptStorage = new EncryptStorage(
  process.env.REACT_APP_ENCRYPT_STORAGE_SECRET
);

const setItem = function (data) {
  encryptStorage.setItem(data.key, data.value);
};

const getItem = function (key) {
  return encryptStorage.getItem(key);
};

const removeItem = function (key) {
  return encryptStorage.removeItem(key);
};

const clear = function () {
  encryptStorage.clear();
};

export const localStorageInstance = {
  setItem,
  getItem,
  removeItem,
  clear,
};