import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { updateUser } from "../../../../sevices/apiUser";
import { objectToFormData } from "../../../../utils/helpers";
import { localStorageInstance } from "../../../../utils/localStorage";
import useToastPromise from "../../../../hooks/useToast";

function WorklogEmailStatus({ isEnabled, id }) {
  const [status, setStatus] = useState(isEnabled);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const userId = localStorageInstance.getItem("userId");
  const { showToastPromise } = useToastPromise();

  const handleCloseDialog = () => {
    setEmailDialog(false);
  };

  const { mutate: userMutate } = useMutation({
    mutationFn: async (data) => {
      return await showToastPromise(updateUser, data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      setStatus((data) => !data);
      setLoading(false);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error User Mutate", error);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    const data = objectToFormData({ worklog_mail_notification: !isEnabled });
    setLoading(true);
    userMutate({ id, data });
  };

  return (
    <>
      <div className="w-100 d-flex justify-content-center">
        <label className="switch">
          <input
            type="checkbox"
            value={status}
            checked={status}
            onChange={(e) => setEmailDialog(true)}
          />
          <div className={"slider"}></div>
        </label>
        {emailDialog && (
          <ConfirmationDialog
            open={emailDialog}
            handleClose={handleCloseDialog}
            message={
              isEnabled
                ? "By disabling this, this Admin user will no longer receive email notifications for worklogs added by any user. Do you want to proceed?"
                : "By enabling this, this Admin user will receive an email notification for all worklogs added by any user. Do you want to proceed?"
            }
            callback={callback}
            loading={loading}
            btnTitle={isEnabled ? "Disable" : "Enable"}
            title={isEnabled ? "Disable Worklog Email" : "Enable Worklog Email"}
          />
        )}
      </div>
    </>
  );
}

export default WorklogEmailStatus;
