import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import DownArrow from "../../../../../../styles/svg/downArrow.svg";
import note from "../../../../../../styles/svg/note.svg";
import { truncateText } from "../../../../../../utils/helpers";
import {
  useBoardName,
  useBoards,
} from "../../../../../../utils/reactQuery/boards";
import ChangeBoardToDifferentWorkflow from "./ChangeBoardToDifferentWorkflow.js";
import { useResponsive } from "../../../../../../hooks/useResponsive.js";
import { useSearchParams } from "react-router-dom";

function ChangeBoard({ cardId, card }) {
  const [currentBoardName, setCurrentBoardName] = useState("");
  const [openChangeBoard, setOpenChangeBoard] = useState(false);
  const isMobile = useResponsive("down", "md");

  const {
    workflow_step_id: currentWorkflowStepId,
    board: { board_id: boardId, board_name, workflow_id: currentworkflow_id },
  } = card?.data?.data?.responseData;

  const { boards } = useBoards();
  const allBoards = boards?.data?.data?.responseData;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [workflowId, setWorkflowId] = React.useState(null);
  const [targetBoardName, setTargetBoardName] = React.useState(null);
  const [targetBoardId, setTargetBoardId] = React.useState(null);
  const open = Boolean(anchorEl);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setCurrentBoardName(board_name);
  }, [card]);

  const filteredItems = allBoards
    ?.filter(
      (item) => item.board_name.toLowerCase() && item.is_archive == false
    )
    .sort((a, b) => a.board_name.localeCompare(b.board_name));

  const changeBoard = (workflow_id, boardName, boardId) => {
    setOpenChangeBoard(true);
    setTargetBoardName(boardName);
    setWorkflowId(workflow_id);
    setTargetBoardId(boardId);
  };

  return (
    <>
      {openChangeBoard && workflowId && currentWorkflowStepId && (
        <ChangeBoardToDifferentWorkflow
          open={openChangeBoard}
          close={setOpenChangeBoard}
          currentBoardName={
            currentBoardName && truncateText(currentBoardName, 25)
          }
          targetBoardName={targetBoardName && truncateText(targetBoardName, 25)}
          card={card}
          workflowId={workflowId}
          cardId={cardId}
          targetBoardId={targetBoardId}
          currentBoardWorkflowId={currentworkflow_id}
          currentWorkflowStepId={currentWorkflowStepId}
        />
      )}
      {isMobile ? (
        <div className="mt-2">
          <p className="filter-text">Select Target board :</p>

          <Box
            className={"workflow_steps_cont"}
            maxHeight={165}
            overflow={"auto"}
            minHeight={100}>
            {filteredItems
              ?.filter(
                (boardDetails) =>
                  boardDetails.board_id !=
                  card?.data?.data?.responseData?.board?.board_id
              )
              ?.map((board, idx) => {
                return (
                  <Typography
                    className={"workflow_steps"}
                    sx={{
                      backgroundColor: board.board_id == boardId && "#C5DCF3",
                    }}
                    onClick={(event) =>
                      changeBoard(
                        board.workflow_id,
                        board.board_name,
                        board.board_id
                      )
                    }>
                    {truncateText(board.board_name, 26)}
                  </Typography>
                );
              })}
          </Box>
        </div>
      ) : (
        <div className="mr-2">
          <Button
            variant="outlined"
            color="primary"
            id="epics-board-dropdown"
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            endIcon={<img src={DownArrow} alt="arrow" />}
            startIcon={<img src={note} alt="" />}
            className="filter-btn white-button change-board-btn"
            sx={{
              height: "34px",
              "&:hover": {
                outline: "none",
                backgroundColor: "rgb(240 249 254)",
                border: "1px solid #1b3e61",
              },
            }}
          >
            <p className="header-action-button-text mr-1">Change Board</p>
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "epics-board-dropdown",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className="assign-board-owner-list">
            {filteredItems
              ?.filter(
                (boardDetails) =>
                  boardDetails.board_id !=
                  card?.data?.data?.responseData?.board?.board_id
              )
              ?.map((board, idx) => {
                return (
                  <MenuItem
                    key={`board-list-my-task-${idx}`}
                    className="custom-border"
                    onClick={(event) =>
                      changeBoard(
                        board.workflow_id,
                        board.board_name,
                        board.board_id
                      )
                    }>
                    <p className="board-name-filter">
                      {truncateText(board.board_name, 26)}
                    </p>
                  </MenuItem>
                );
              })}
          </Menu>
        </div>
      )}
    </>
  );
}

export default ChangeBoard;
